import { MockResultsTest } from "../models/vocationalTest.interface";

interface MockData {
  id: number;
  question: string;
  answers: {
    id: number;
    answer: string;
  }[];
}

export const mockDataTest: MockData[] = [
  {
    id: 0,
    question: '¿Qué actividades disfrutas más en tu tiempo libre?',
    answers: [
      {
        id: 0,
        answer:
          'Realizar experimentos científicos o leer sobre temas científicos.',
      },
      {
        id: 1,
        answer: 'Dibujar, pintar o hacer manualidades.',
      },
      {
        id: 2,
        answer: 'Escribir en un diario o crear contenido para redes sociales.',
      },
      {
        id: 3,
        answer:
          'Organizar eventos escolares o participar en clubes de estudiantes.',
      },
      {
        id: 4,
        answer: 'Jugar videojuegos o programar pequeños proyectos.',
      },
      {
        id: 5,
        answer:
          'Hacer voluntariado o ayudar a amigos con problemas personales.',
      },
      {
        id: 6,
        answer: 'Resolver problemas matemáticos o juegos de lógica.',
      },
      {
        id: 7,
        answer: 'Aprender nuevos idiomas o conocer culturas diferentes.',
      },
      {
        id: 8,
        answer:
          'Participar en actividades de bienestar como yoga, meditación o ejercicio físico.',
      },
    ],
  },
  {
    id: 1,
    question: '¿Qué materias te gustan más en la escuela?',
    answers: [
      {
        id: 0,
        answer: 'Ciencias (Química, Física, Biología).',
      },
      {
        id: 1,
        answer: 'Arte, Música o Teatro.',
      },
      {
        id: 2,
        answer: 'Literatura, Historia o Filosofía.',
      },
      {
        id: 3,
        answer: 'Economía o Administración.',
      },
      {
        id: 4,
        answer: 'Computación y Tecnología.',
      },
      {
        id: 5,
        answer: 'Educación Física o Salud.',
      },
      {
        id: 6,
        answer: 'Matemáticas.',
      },
      {
        id: 7,
        answer: 'Idiomas extranjeros.',
      },
      {
        id: 8,
        answer: 'Psicología o Educación para la Salud.',
      },
    ],
  },
  {
    id: 2,
    question: '¿Qué tipo de actividades extracurriculares prefieres?',
    answers: [
      {
        id: 0,
        answer: 'Club de ciencias o matemáticas.',
      },
      {
        id: 1,
        answer: 'Talleres de arte o teatro.',
      },
      {
        id: 2,
        answer: 'Club de debate o periodismo.',
      },
      {
        id: 3,
        answer: 'Asociación de estudiantes o club de negocios.',
      },
      {
        id: 4,
        answer: 'Club de robótica o programación.',
      },
      {
        id: 5,
        answer: 'Voluntariado en hospitales o organizaciones comunitarias.',
      },
      {
        id: 6,
        answer: 'Competencias de matemáticas o clubes de ajedrez.',
      },
      {
        id: 7,
        answer: 'Club de idiomas o intercambios culturales.',
      },
      {
        id: 8,
        answer:
          'Grupos de bienestar como clubes de yoga, meditación o deportes.',
      },
    ],
  },
  {
    id: 3,
    question: '¿Qué tipo de proyectos disfrutas realizar?',
    answers: [
      {
        id: 0,
        answer: 'Experimentos científicos o proyectos de investigación.',
      },
      {
        id: 1,
        answer: 'Crear obras de arte o actuaciones teatrales.',
      },
      {
        id: 2,
        answer: 'Escribir ensayos o artículos para el periódico escolar.',
      },
      {
        id: 3,
        answer: 'Planificar y organizar eventos escolares.',
      },
      {
        id: 4,
        answer: 'Desarrollar aplicaciones o construir robots.',
      },
      {
        id: 5,
        answer:
          'Participar en campañas de concientización o proyectos de servicio comunitario.',
      },
      {
        id: 6,
        answer: 'Resolver problemas matemáticos o crear modelos matemáticos.',
      },
      {
        id: 7,
        answer: 'Organizar eventos multiculturales o proyectos de traducción.',
      },
      {
        id: 8,
        answer:
          'Proyectos de promoción del bienestar, como campañas de salud mental o eventos deportivos.',
      },
    ],
  },
  {
    id: 4,
    question: '¿Qué tipo de películas o libros prefieres?',
    answers: [
      {
        id: 0,
        answer: 'Ciencia ficción o documentales científicos.',
      },
      {
        id: 1,
        answer: 'Películas de arte o animación.',
      },
      {
        id: 2,
        answer: 'Novelas históricas o dramas.',
      },
      {
        id: 3,
        answer: 'Documentales de negocios o biografías de emprendedores.',
      },
      {
        id: 4,
        answer: 'Películas de ciencia ficción y tecnología.',
      },
      {
        id: 5,
        answer: 'Documentales de salud y bienestar.',
      },
      {
        id: 6,
        answer: 'Libros de acertijos matemáticos o ciencia popular.',
      },
      {
        id: 7,
        answer: 'Libros en idiomas extranjeros o películas subtituladas.',
      },
      {
        id: 8,
        answer:
          'Libros sobre desarrollo personal y bienestar o documentales sobre salud.',
      },
    ],
  },
  {
    id: 5,
    question: '¿Cómo te describen tus amigos y familiares?',
    answers: [
      {
        id: 0,
        answer: 'Curioso y analítico.',
      },
      {
        id: 1,
        answer: 'Creativo y original.',
      },
      {
        id: 2,
        answer: 'Comunicativo y reflexivo.',
      },
      {
        id: 3,
        answer: 'Ambicioso y organizado.',
      },
      {
        id: 4,
        answer: 'Innovador y tecnológico.',
      },
      {
        id: 5,
        answer: 'Empático y atento.',
      },
      {
        id: 6,
        answer: 'Lógico y meticuloso.',
      },
      {
        id: 7,
        answer: 'Abierto y multicultural.',
      },
      {
        id: 8,
        answer: 'Tranquilo y centrado en el bienestar.',
      },
    ],
  },
  {
    id: 6,
    question: '¿Qué tipo de entorno de trabajo prefieres?',
    answers: [
      {
        id: 0,
        answer: 'Laboratorio de investigación o campo.',
      },
      {
        id: 1,
        answer: 'Estudio de arte o escenario.',
      },
      {
        id: 2,
        answer: 'Oficina editorial o biblioteca.',
      },
      {
        id: 3,
        answer: 'Oficina corporativa.',
      },
      {
        id: 4,
        answer: 'Sala de servidores o taller tecnológico.',
      },
      {
        id: 5,
        answer: 'Clínica u hospital.',
      },
      {
        id: 6,
        answer: 'Oficina de análisis o aula de matemáticas.',
      },
      {
        id: 7,
        answer: 'Oficina de traducción o aula de idiomas.',
      },
      {
        id: 8,
        answer: 'Centros de bienestar o gimnasios.',
      },
    ],
  },
  {
    id: 7,
    question: '¿Qué habilidades te gustaría desarrollar más?',
    answers: [
      {
        id: 0,
        answer: 'Métodos de investigación científica.',
      },
      {
        id: 1,
        answer: 'Técnicas artísticas o escénicas.',
      },
      {
        id: 2,
        answer: 'Habilidades de escritura y comunicación.',
      },
      {
        id: 3,
        answer: 'Estrategias de liderazgo y gestión.',
      },
      {
        id: 4,
        answer: 'Programación y desarrollo tecnológico.',
      },
      {
        id: 5,
        answer: 'Conocimientos médicos y de atención al paciente.',
      },
      {
        id: 6,
        answer: 'Teoremas matemáticos y modelado.',
      },
      {
        id: 7,
        answer: 'Dominio de nuevos idiomas y habilidades de traducción.',
      },
      {
        id: 8,
        answer: 'Técnicas de bienestar como meditación, yoga o nutrición.',
      },
    ],
  },
  {
    id: 8,
    question: '¿Qué tipo de problemas disfrutas resolver?',
    answers: [
      {
        id: 0,
        answer: 'Problemas científicos o matemáticos complejos.',
      },
      {
        id: 1,
        answer: 'Desafíos creativos o artísticos.',
      },
      {
        id: 2,
        answer: 'Dilemas éticos o sociales.',
      },
      {
        id: 3,
        answer: 'Problemas de gestión y organización.',
      },
      {
        id: 4,
        answer: 'Problemas técnicos o de ingeniería.',
      },
      {
        id: 5,
        answer: 'Problemas de salud y bienestar.',
      },
      {
        id: 6,
        answer: 'Problemas matemáticos o lógicos.',
      },
      {
        id: 7,
        answer: 'Desafíos lingüísticos o de comunicación intercultural.',
      },
      {
        id: 8,
        answer: 'Problemas relacionados con el bienestar físico y mental.',
      },
    ],
  },
  {
    id: 9,
    question: '¿Qué te motiva más en tus estudios?',
    answers: [
      {
        id: 0,
        answer: 'Descubrir y aprender cosas nuevas.',
      },
      {
        id: 1,
        answer: 'Expresar tu creatividad y talento.',
      },
      {
        id: 2,
        answer: 'Comunicar ideas y enseñar a otros.',
      },
      {
        id: 3,
        answer: 'Alcanzar metas y obtener resultados.',
      },
      {
        id: 4,
        answer: 'Innovar y resolver problemas tecnológicos.',
      },
      {
        id: 5,
        answer: 'Ayudar y cuidar a los demás.',
      },
      {
        id: 6,
        answer: 'Resolver problemas y teorizar.',
      },
      {
        id: 7,
        answer: 'Aprender sobre nuevas culturas e idiomas.',
      },
      {
        id: 8,
        answer: 'Mejorar la salud y el bienestar propio y de otros.',
      },
    ],
  },
  {
    id: 10,
    question: '¿Qué tipo de eventos disfrutas más?',
    answers: [
      {
        id: 0,
        answer: 'Ferias de ciencias o exposiciones tecnológicas.',
      },
      {
        id: 1,
        answer: 'Concursos de arte o festivales de cine.',
      },
      {
        id: 2,
        answer: 'Charlas y conferencias educativas.',
      },
      {
        id: 3,
        answer: 'Seminarios de negocios y emprendimiento.',
      },
      {
        id: 4,
        answer: 'Competencias de robótica o hackathons.',
      },
      {
        id: 5,
        answer: 'Jornadas de salud y bienestar.',
      },
      {
        id: 6,
        answer: 'Olimpiadas matemáticas o torneos de ajedrez.',
      },
      {
        id: 7,
        answer: 'Eventos multiculturales o festivales de idiomas.',
      },
      {
        id: 8,
        answer: 'Talleres de bienestar como yoga, meditación o nutrición.',
      },
    ],
  },
  {
    id: 11,
    question: '¿Qué piensas sobre el trabajo en equipo?',
    answers: [
      {
        id: 0,
        answer: 'Me gusta colaborar en proyectos de investigación.',
      },
      {
        id: 1,
        answer: 'Disfruto crear proyectos artísticos con otros.',
      },
      {
        id: 2,
        answer: 'Me gusta trabajar en equipo para escribir y editar artículos.',
      },
      {
        id: 3,
        answer: 'Prefiero liderar equipos en proyectos organizacionales.',
      },
      {
        id: 4,
        answer: 'Me encanta colaborar en proyectos tecnológicos.',
      },
      {
        id: 5,
        answer: 'Prefiero trabajar en equipo para ayudar a la comunidad.',
      },
      {
        id: 6,
        answer:
          'Disfruto trabajar en equipo para resolver problemas matemáticos.',
      },
      {
        id: 7,
        answer:
          'Me gusta colaborar en proyectos multiculturales y lingüísticos.',
      },
      {
        id: 8,
        answer: 'Prefiero trabajar en equipo en actividades de bienestar.',
      },
    ],
  },
  {
    id: 12,
    question: '¿En qué tipo de lugar te gustaría trabajar?',
    answers: [
      {
        id: 0,
        answer: 'Laboratorio de investigación o campo.',
      },
      {
        id: 1,
        answer: 'Estudio de arte o galería.',
      },
      {
        id: 2,
        answer: 'Oficina editorial o biblioteca.',
      },
      {
        id: 3,
        answer: 'Oficina corporativa en un rascacielos.',
      },
      {
        id: 4,
        answer: 'Oficina de una empresa de tecnología o taller de desarrollo.',
      },
      {
        id: 5,
        answer: 'Clínica, hospital o centro de salud.',
      },
      {
        id: 6,
        answer: 'Universidad o centro de investigación matemática.',
      },
      {
        id: 7,
        answer: 'Oficina de traducción o centro de idiomas.',
      },
      {
        id: 8,
        answer: 'Centro de bienestar, gimnasio o estudio de yoga.',
      },
    ],
  },
  {
    id: 13,
    question: '¿Qué modalidad de trabajo prefieres?',
    answers: [
      {
        id: 0,
        answer: 'De campo o laboratorio.',
      },
      {
        id: 1,
        answer: 'Presencial en un estudio o taller.',
      },
      {
        id: 2,
        answer: 'Remoto desde casa.',
      },
      {
        id: 3,
        answer: 'Trabajo en una oficina corporativa.',
      },
      {
        id: 4,
        answer: 'Trabajo híbrido, combinando oficina y remoto.',
      },
      {
        id: 5,
        answer: 'Trabajo en centros de salud o clínicas.',
      },
      {
        id: 6,
        answer: 'Trabajo en centros educativos o de investigación.',
      },
      {
        id: 7,
        answer: 'Trabajo en centros multiculturales o de idiomas.',
      },
      {
        id: 8,
        answer: 'Trabajo en centros de bienestar o al aire libre.',
      },
    ],
  },
  {
    id: 14,
    question: '¿Qué rol te gustaría asumir en el trabajo?',
    answers: [
      {
        id: 0,
        answer: 'Investigador principal o científico.',
      },
      {
        id: 1,
        answer: 'Artista o diseñador principal.',
      },
      {
        id: 2,
        answer: 'Editor o escritor principal.',
      },
      {
        id: 3,
        answer: 'Gerente o líder de proyecto.',
      },
      {
        id: 4,
        answer: 'Desarrollador principal o ingeniero.',
      },
      {
        id: 5,
        answer: 'Médico, enfermero o terapeuta principal.',
      },
      {
        id: 6,
        answer: 'Matemático o analista principal.',
      },
      {
        id: 7,
        answer: 'Traductor o intérprete principal.',
      },
      {
        id: 8,
        answer: 'Instructor de bienestar o coach de salud.',
      },
    ],
  },
];

export const mockResultsTest: MockResultsTest[] = [
  {
    id: 0,
    area: 'Ciencias',
    description:
      'Te apasiona el descubrimiento y la exploración de nuevos conocimientos a través de la investigación científica. Disfrutas resolver problemas complejos y trabajar en entornos experimentales. En esta área, podrías trabajar en sectores como la academia, la industria, el medio ambiente y la salud, desempeñándote en roles muy amplios como empleado o empleador que van desde la investigación en innovación y desarrollo hasta la consultoría científica para apoyar a empresas públicas o privadas.',
    potentialJobRoles: [
      {
        sector: 'Sector académico',
        role: 'Investigador, profesor universitario',
      },
      {
        sector: 'Sector industrial',
        role: 'Científico en laboratorios, consultor científico',
      },
      {
        sector: 'Sector ambiental',
        role: 'Especialista en medio ambiente, geocientífico',
      },
      {
        sector: 'Sector de la salud',
        role: 'Investigador biomédico, microbiólogo',
      },
      {
        sector: 'Sector de energía',
        role: 'Ingeniero químico, especialista en energías renovables',
      },
    ],
    recommendedCareers: [
      'Ingeniería Química',
      'Física',
      'Biología',
      'Química',
      'Geología',
      'Bioquímica',
      'Ciencias Ambientales',
      'Astronomía',
      'Oceanografía',
      'Microbiología',
    ],
  },
  {
    id: 1,
    area: 'Artes',
    description:
      'Tienes una gran creatividad y disfrutas expresar tus ideas a través de diversas formas de arte. Te destacas en la creación de obras originales y te apasiona la estética y la expresión personal. Podrías trabajar en sectores como el cultural, los medios, el entretenimiento, la moda y la educación, asumiendo roles que incluyen desde la creación artística hasta la dirección y curaduría.',
    potentialJobRoles: [
      { sector: 'Sector cultural', role: 'Artista, Curador de Museo' },
      {
        sector: 'Sector de medios',
        role: 'Diseñador Gráfico, Ilustrador, Animador, Productor',
      },
      {
        sector: 'Sector de entretenimiento',
        role: 'Escenógrafo, Director de Arte, Músico Profesional',
      },
      {
        sector: 'Sector de la moda',
        role: 'Diseñador de Moda, Asesor de Estilo',
      },
      {
        sector: 'Sector educativo',
        role: 'Profesor de Arte, Investigador de Arte',
      },
    ],
    recommendedCareers: [
      'Bellas Artes',
      'Diseño Gráfico',
      'Arquitectura',
      'Fotografía',
      'Diseño de Moda',
      'Artes Escénicas',
      'Música',
      'Diseño de Interiores',
      'Animación',
      'Historia del Arte',
    ],
  },
  {
    id: 2,
    area: 'Humanidades',
    description:
      'Te interesa comprender la cultura, la historia y la sociedad. Disfrutas de la lectura, la escritura y el análisis crítico de temas diversos. Te motiva comunicar y enseñar a otros. En esta área, podrías trabajar en sectores como el educativo, el editorial, el cultural, el social y el de la comunicación, desempeñándote en roles que van desde la enseñanza y la investigación hasta la asesoría y la gestión cultural.',
    potentialJobRoles: [
      { sector: 'Sector educativo', role: 'Profesor, Investigador' },
      { sector: 'Sector editorial', role: 'Editor, Escritor, Periodista' },
      { sector: 'Sector cultural', role: 'Archivista, Curador de Museo' },
      { sector: 'Sector social', role: 'Trabajador Social, Analista Político' },
      {
        sector: 'Sector de comunicación',
        role: 'Asesor de Comunicación, Especialista en Relaciones Públicas',
      },
    ],
    recommendedCareers: [
      'Historia',
      'Filosofía',
      'Literatura',
      'Sociología',
      'Antropología',
      'Ciencias Políticas',
      'Periodismo',
      'Lingüística',
      'Psicología',
      'Educación',
    ],
  },
  {
    id: 3,
    area: 'Negocios',
    description:
      'Te apasiona el mundo empresarial y te motiva alcanzar metas y resultados. Tienes habilidades de liderazgo y organización, y disfrutas planificar y gestionar proyectos. Podrías trabajar en sectores como el corporativo, el bancario, el marketing, los recursos humanos y el emprendimiento, asumiendo roles que van desde la gestión y la planificación hasta la consultoría y la dirección.',
    potentialJobRoles: [
      {
        sector: 'Sector corporativo',
        role: 'Gerente de Proyectos, Analista Financiero',
      },
      {
        sector: 'Sector bancario',
        role: 'Ejecutivo de Cuentas, Consultor Financiero',
      },
      {
        sector: 'Sector de marketing',
        role: 'Director de Marketing, Especialista en Publicidad',
      },
      {
        sector: 'Sector de recursos humanos',
        role: 'Gerente de Recursos Humanos, Reclutador',
      },
      {
        sector: 'Sector emprendedor',
        role: 'Emprendedor, Consultor de Negocios',
      },
    ],
    recommendedCareers: [
      'Administración de Empresas',
      'Marketing',
      'Contabilidad',
      'Economía',
      'Finanzas',
      'Gestión de Recursos Humanos',
      'Comercio Internacional',
      'Emprendimiento',
      'Gestión de Proyectos',
      'Publicidad y Relaciones Públicas',
    ],
  },
  {
    id: 4,
    area: 'Tecnología',
    description:
      'Te fascina la innovación y el desarrollo de nuevas tecnologías. Disfrutas resolver problemas técnicos y trabajar con dispositivos y sistemas avanzados. Podrías trabajar en sectores como el software, el hardware, las telecomunicaciones, la ciberseguridad y la inteligencia artificial, desempeñándote en roles que incluyen desde el desarrollo y la ingeniería hasta la consultoría y la investigación.',
    potentialJobRoles: [
      {
        sector: 'Sector de software',
        role: 'Desarrollador de Software, Ingeniero de Sistemas',
      },
      {
        sector: 'Sector de hardware',
        role: 'Ingeniero Electrónico, Diseñador de Circuitos',
      },
      {
        sector: 'Sector de telecomunicaciones',
        role: 'Ingeniero de Redes, Especialista en Telecomunicaciones',
      },
      {
        sector: 'Sector de ciberseguridad',
        role: 'Analista de Seguridad, Consultor en Ciberseguridad',
      },
      {
        sector: 'Sector de inteligencia artificial',
        role: 'Científico de Datos, Desarrollador de IA',
      },
    ],
    recommendedCareers: [
      'Ingeniería de Software',
      'Ciencias de la Computación',
      'Ingeniería Electrónica',
      'Ingeniería Mecatrónica',
      'Ingeniería de Telecomunicaciones',
      'Desarrollo de Videojuegos',
      'Seguridad Informática',
      'Inteligencia Artificial',
      'Robótica',
      'Análisis de Datos',
    ],
  },
  {
    id: 5,
    area: 'Salud',
    description:
      'Te motiva ayudar y cuidar a los demás. Disfrutas aprender sobre el cuerpo humano y la salud, y deseas contribuir al bienestar de las personas. Podrías trabajar en sectores como el hospitalario, la rehabilitación, el farmacéutico, la salud pública y la veterinaria, asumiendo roles que van desde la atención directa al paciente hasta la investigación y la gestión de la salud.',
    potentialJobRoles: [
      { sector: 'Sector hospitalario', role: 'Médico, Enfermero' },
      {
        sector: 'Sector de rehabilitación',
        role: 'Fisioterapeuta, Terapeuta Ocupacional',
      },
      {
        sector: 'Sector farmacéutico',
        role: 'Farmacéutico, Investigador Clínico',
      },
      {
        sector: 'Sector de salud pública',
        role: 'Epidemiólogo, Gerente de Salud Pública',
      },
      {
        sector: 'Sector veterinario',
        role: 'Médico Veterinario, Técnico Veterinario',
      },
    ],
    recommendedCareers: [
      'Medicina',
      'Enfermería',
      'Odontología',
      'Fisioterapia',
      'Terapia Ocupacional',
      'Farmacia',
      'Salud Pública',
      'Veterinaria',
      'Nutrición',
      'Psicología',
    ],
  },
  {
    id: 6,
    area: 'Matemáticas',
    description:
      'Te apasiona resolver problemas complejos mediante el uso de modelos matemáticos y teorías. Disfrutas trabajar con números y fórmulas para encontrar soluciones precisas y eficientes. Podrías trabajar en sectores como el financiero, el tecnológico, el académico, la investigación y la ingeniería, desempeñándote en roles que incluyen desde el análisis y la modelación hasta la docencia y la investigación.',
    potentialJobRoles: [
      { sector: 'Sector Financiero', role: 'Actuario, Analista de Riesgos' },
      {
        sector: 'Sector Tecnológico',
        role: 'Ingeniero de Software, Científico de Datos',
      },
      {
        sector: 'Sector Académico',
        role: 'Profesor de Matemáticas, Investigador',
      },
      {
        sector: 'Sector de Investigación',
        role: 'Matemático Aplicado, Estadístico',
      },
      {
        sector: 'Sector de Ingeniería',
        role: 'Ingeniero de Control, Analista de Sistemas',
      },
    ],
    recommendedCareers: [
      'Matemáticas Puras',
      'Estadística',
      'Actuaría',
      'Ingeniería Matemática',
      'Ciencias Actuariales',
      'Matemáticas Aplicadas',
      'Investigación Operativa',
      'Ciencias de la Computación',
      'Física Teórica',
      'Econometría',
    ],
  },
  {
    id: 7,
    area: 'Idiomas',
    description:
      'Te encanta aprender y dominar nuevos idiomas. Disfrutas conocer diferentes culturas y formas de comunicación, y te motiva la posibilidad de trabajar en un entorno internacional. Podrías trabajar en sectores como la traducción, la educación, la diplomacia, el turismo y los medios, desempeñándote en roles que incluyen desde la traducción y la interpretación hasta la enseñanza y la gestión cultural.',
    potentialJobRoles: [
      { sector: 'Sector de Traducción', role: 'Traductor, Intérprete' },
      {
        sector: 'Sector Educativo',
        role: 'Profesor de Idiomas, Investigador Lingüístico',
      },
      {
        sector: 'Sector Diplomático',
        role: 'Diplomático, Oficial de Relaciones Internacionales',
      },
      {
        sector: 'Sector Turístico',
        role: 'Guía Turístico, Coordinador de Viajes',
      },
    ],
    recommendedCareers: [
      'Traducción e Interpretación',
      'Filología',
      'Lenguas Modernas',
      'Comunicación Intercultural',
      'Relaciones Internacionales',
    ],
  },
  {
    id: 8,
    area: 'Bienestar',
    description:
      'Te apasiona mejorar la salud y el bienestar de las personas. Disfrutas aprender y practicar técnicas que promuevan un estilo de vida saludable y equilibrado. En esta área, podrías trabajar en sectores como la salud, el deporte, la educación y el bienestar corporativo, desempeñándote en roles que van desde la atención directa a individuos hasta la consultoría y gestión de programas de bienestar.',
    potentialJobRoles: [
      { sector: 'Sector de Salud', role: 'Nutricionista, Terapeuta' },
      {
        sector: 'Sector del Deporte',
        role: 'Entrenador Personal, Director de Programas de Fitness',
      },
      {
        sector: 'Sector de la Educación',
        role: 'Profesor de Educación Física, Instructor de Yoga',
      },
      {
        sector: 'Sector de la Salud Mental',
        role: 'Consejero, Psicoterapeuta',
      },
      {
        sector: 'Sector de Bienestar Corporativo',
        role: 'Consultor de Bienestar, Coordinador de Programas de Salud en el Trabajo',
      },
    ],
    recommendedCareers: [
      'Nutrición y Dietética',
      'Educación Física y Deporte',
      'Psicología',
      'Terapia Ocupacional',
      'Fisioterapia',
      'Medicina Alternativa',
      'Entrenamiento Personal',
      'Gerontología',
      'Salud Pública',
      'Ciencias del Deporte',
    ],
  },
];
