import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ConfirmPasswordValidator } from 'src/app/util/confirmPasswordValidator';

@Component({
  selector: 'app-custom-recover-pass',
  templateUrl: './custom-recover-pass.component.html',
  styleUrls: ['./custom-recover-pass.component.scss']
})
export class CustomRecoverPassComponent implements OnInit {
  newPassForm: FormGroup;
  password = new FormControl('', [Validators.required,
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(30),
  Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$')]);
  confirmPassword = new FormControl('', Validators.required);

  email!: string;
  mode!: string
  oobCode!: string
  apiKey!: string
  continueUrl!: string
  lang!: string

  constructor(private route: ActivatedRoute, private userService: UserService, private router: Router) {
    this.newPassForm = new FormGroup({
      password: this.password,
      confirmPassword: this.confirmPassword,
      accept: new FormControl()
    }, { validators: ConfirmPasswordValidator });
  }
  hide = true;
  hide2 = true;

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   this.email = params['email']; 
    //   this.mode = params['mode'];
    //   this.oobCode = params['oobCode'];
    //   this.apiKey = params['apiKey'];
    //   this.continueUrl = params['continueUrl'];
    //   this.lang = params['lang'];
    // });
    // this.email =
    this.mode = this.getParameterByName('mode')!;
    this.oobCode = this.getParameterByName('oobCode')!;
    this.apiKey = this.getParameterByName('apiKey')!;
    this.continueUrl = this.getParameterByName('continueUrl')!;
    this.lang = this.getParameterByName('lang')!;

    // console.log(this.email)
    console.log(this.mode)
    console.log(this.oobCode)
    console.log(this.apiKey)
    console.log(this.continueUrl)
    console.log(this.lang)

    switch (this.mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        // this.handleResetPassword(this.oobCode, this.continueUrl, this.lang);
        console.log("Enter to resetPassword controller")
      
        break;
      // case 'recoverEmail':
      //     // Display email recovery handler and UI.
      //     handleRecoverEmail(auth, actionCode, lang);
      //     break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        this.handleVerifyEmail();
        console.log("Enter to verifyEmail controller")
        this.router.navigate(['/inicio']);
        break;
      default:
        break;
    }
  }

  getParameterByName(name: string, url?: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  handleResetPassword() {
    try {
      const password: string = this.newPassForm.value.password;
      console.log("New password", this.password)
      this.userService.resetPassword(this.oobCode, this.continueUrl, this.lang, password)
    } catch (error) {
      console.error("Error in handlerResetPassword", error)
    }

  }

  handleVerifyEmail() {
    try {
      let tempKey = null
      if(this.continueUrl){
        const decodedContinueUrl = decodeURIComponent(this.continueUrl);
        tempKey = this.getParameterByName('tempKey', decodedContinueUrl) || null;
      }
      this.userService.verifyEmail(this.oobCode, this.continueUrl, this.lang, tempKey)
    } catch (error) {
      console.error("Error in handleVerifyEmail", error)

    }
  }


}
