export const NATIONALITIES = [
    "Abudabí",
    "Afgano(a)",
    "Albanés(a)",
    "Alemán(a)",
    "Amsterdamés",
    "Andorrano(a)",
    "Angolano(a)",
    "Antiguayo(a)",
    "Argelino(a)",
    "Argentino(a)",
    "Armenio(a)",
    "Australiano(a)",
    "Austriaco(a)",
    "Azerbaiyano(a)",
    "Bahameño(a)",
    "Bareiní",
    "Bangladesí",
    "Barbadense",
    "Barbudo(a)",
    "Bielorruso(a)",
    "Belga",
    "Belicense",
    "Beninés(a)",
    "Birmano",
    "Bielorruso(a)",
    "Boliviano(a)",
    "Bosnio(a)",
    "Botonués(a)",
    "Brasileño(a)",
    "Británico(a)",
    "Bruneano(a)",
    "Búlgaro(a)",
    "Burkinabé",
    "Butanés",
    "Camerunés",
    "Canadiense",
    "Catarí",
    "Centroafricano",
    "Chadiano",
    "Chileno(a)",
    "Chino(a)",
    "Chipriota(a)",
    "Colombiano(a)",
    "Congoleño(a)",
    "Coreano(a)",
    "Costarricense",
    "Croata(a)",
    "Cubano(a)",
    "Chipriota(a)",
    "Checo(a)",
    "Danés(a)",
    "Djibutiano(a)",
    "Dominicano(a)",
    "Ecuatoriano(a)",
    "Egipcio(a)",
    "Eslovaco",
    "Esloveno(a)",
    "Esrilanqués(a)",
    "Español(a)",
    "Estadounidense",
    "Estonio(a)",
    "Etíope",
    "Filipino",
    "Fiyiano(a)",
    "Finés",
    "Francés(a)",
    "Gallego(a)",
    "Gambiano(a)",
    "Ghanaés",
    "Griego(a)",
    "Guatemalteco(a)",
    "Guineano(a)",
    "Guyanés",
    "Haitiano(a)",
    "Holandés(a)",
    "Hondureño(a)",
    "Húngaro(a)",
    "Iraní",
    "Iraquí",
    "Irlandés",
    "Islandés",
    "Israelí",
    "Italiano(a)",
    "Jamaicano(a)",
    "Japonés(a)",
    "Jordano(a)",
    "Kazajo(a)",
    "Keniano(a)",
    "Kiribatiano(a)",
    "Kirguís",
    "Komorense",
    "Kuwaití",
    "Laosiano(a)",
    "Lesotense",
    "Letón(a)",
    "Libanés(a)",
    "Libio(a)",
    "Liechtensteiniano(a)",
    "Lituano(a)",
    "Luxemburgués(a)",
    "Macedonio(a)",
    "Malgache",
    "Malasio(a)",
    "Malauí",
    "Maldivo",
    "Maliense",
    "Maltés",
    "Marroquí",
    "Mauritano(a)",
    "Mexicano(a)",
    "Micronesio(a)",
    "Moldavo(a)",
    "Monegasco(a)",
    "Mongol(a)",
    "Montenegrino(a)",
    "Mozambiqueño(a)",
    "Myanmar",
    "Namibio(a)",
    "Nepalés",
    "Neerlandés(a)",
    "Nicaragüense",
    "Nigerino(a)",
    "Nigeriano(a)",
    "Noruego(a)",
    "Norcoreano(a)",
    "Neozelandés(a)",
    "Ománi",
    "Panameño(a)",
    "Pakistaní",
    "Palestino",
    "Papú",
    "Paraguayo(a)",
    "Peruano(a)",
    "Polaco(a)",
    "Portugués(a)",
    "Puertorriqueño(a)",
    "Ruandés",
    "Rumano(a)",
    "Ruso(a)",
    "Salvadoreño(a)",
    "Samoano(a)",
    "Sancristobaleño(a)",
    "Sanmarinense",
    "Santalucense",
    "Senegalés(a)",
    "Serbio(a)",
    "Seychellense",
    "Sierraleonés(a)",
    "Singapurense",
    "Somalí",
    "Sudafricano(a)",
    "Sudanés",
    "Surcoreano(a)",
    "Sursudanese",
    "Sueco(a)",
    "Suizo(a)",
    "Surinamés(a)",
    "Tayiko",
    "Tanzano(a)",
    "Taiwánés",
    "Tailandés",
    "Tamil",
    "Timorense",
    "Togolés",
    "Tunecino(a)",
    "Turcomano(a)",
    "Turco(a)",
    "Tuvaluano(a)",
    "Ucraniano(a)",
    "Ugandés(a)",
    "Uruguayo(a)",
    "Uzbeko(a)",
    "Varsoviano(a)",
    "Venezolano(a)",
    "Vietnamita",
    "Yemení",
    "Yibutiano(a)",
    "Zambiano(a)",
    "Zimbabuense"
]