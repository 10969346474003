<!-- Show a Spinner while loading -->
<div *ngIf="isSaving || !userProfile" class="row justify-content-center">
    <mat-spinner></mat-spinner>
</div>
<div class="container" *ngIf="userProfile">
    <!-- <form [formGroup]="form"> -->
    <div class="row justify-content-center">
        <div class="d-grid justify-content-center gap-2 py-4">
            <div class="w-100 justify-content-center">
                <div class="rounded-circle ms-md-1 ms-lg-2 p-1 h-100 w-100 d-flex flex-row-reverse"
                    (click)="openDialog()" style="cursor:pointer">
                    <img *ngIf="userProfile.photoUrl" class="img-fluid fit-cover rounded-circle icon_photo" slot="start"
                        referrerpolicy="no-referrer" [src]="userProfile.photoUrl" alt="Profile Photo">
                    <!-- src="../../assets/images/user.png" -->
                    <div
                        class="position-absolute d-flex align-items-center bg-light justify-content-center  shadow rounded-circle ">
                        <!-- class="position-absolute d-flex align-items-center bg-light justify-content-center  shadow rounded-circle "> -->
                        <button mat-icon-button color="primary" aria-label="Icon button with a photo alternate">
                            <!-- <mat-icon>add_photo_alternate</mat-icon> -->
                            <!-- <mat-icon>add_a_photo</mat-icon> -->
                            <mat-icon>camera_alt</mat-icon>
                        </button>

                    </div>
                </div>
            </div>
            <!-- <p class="text-center"> {{ userProfile.email }} </p> -->
            <mat-form-field appearance="fill">
                <mat-label> Nombre Completo </mat-label>
                <input #fullName matInput [value]="userProfile.fullName">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label class="opacity-75 text-center"> Carrera Profesional </mat-label>
                <input #title matInput [value]="userProfile.title??''">
            </mat-form-field>
        </div>
    </div>
    <!-- Personal Data -->
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4  justify-content-center py-3">
        <div class="col ">
            <div class="d-grid align-items-center p-3 p-lg-2 p-xl-3  shadow-sm rounded-3">
                <div class="d-flex pb-2">
                    <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">people</mat-icon>
                    <p class="opacity-75 mb-0">Sexo:</p>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>Seleccionar sexo</mat-label>
                    <mat-select [(value)]="gender">
                        <mat-option value=""><i>(Prefiero no decir)</i></mat-option>
                        <mat-option value="Femenino">Femenino</mat-option>
                        <mat-option value="Masculino">Masculino</mat-option>
                        <mat-option value="Otro">Otro</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col ">
            <div class="d-grid align-items-center p-3 p-lg-2 p-xl-3  shadow-sm rounded-3">
                <div class="d-flex pb-2">
                    <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">phone</mat-icon>
                    <p class="opacity-75 mb-0">Telefono:</p>
                </div>
                <div class="flex-column ">
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>Prefijo</mat-label>
                            <!-- <input type="text" placeholder="Elija uno" aria-label="Prefijo" matInput
                                [formControl]="prefixCrl" [matAutocomplete]="auto"> -->
                            <!-- <mat-autocomplete autoActiveFirstOption  #auto="matAutocomplete"> -->
                            <mat-select [(value)]="dialCode" [formControl]="dialCodeCtrl">
                                <mat-option [value]="default_dialCode">(+57)
                                    Colombia</mat-option>
                                <mat-option>---</mat-option>
                                <mat-option *ngFor="let item of dial_codes" [value]="item">
                                    ({{item.dial_code}}) {{ item.name }}
                                </mat-option>
                            </mat-select>
                            <!-- </mat-autocomplete> -->
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Número teléfonico</mat-label>
                            <!-- <span matPrefix>+57 &nbsp;</span> -->
                            <input #phone matInput type="tel" placeholder="555-123456" pattern="[0-9]*"
                                [value]="userProfile.personalData?.phone??''">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="col ">
            <div class="d-grid align-items-center p-3 p-lg-2 p-xl-3  shadow-sm rounded-3">
                <div class="d-flex pb-2">
                    <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">email</mat-icon>
                    <p class="opacity-75 mb-0">Email:</p>
                </div>
                <div>
                    <p class="m-2 block text-break">{{ userProfile.email }}</p>
                    <div class="ms-2" style="font-style: italic;font-size: smaller;" *ngIf="userProfile.emailVerified">
                        Email verificado
                    </div>
                    <div class="ms-2" style="font-style: italic;font-size: smaller;" *ngIf="!userProfile.emailVerified">
                        Email por verificar
                    </div>
                </div>
            </div>
        </div>
        <div class="col ">
            <div class="d-grid align-items-center p-3 p-lg-2 p-xl-3  shadow-sm rounded-3">
                <div class="d-flex pb-2">
                    <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">map</mat-icon>
                    <p class="opacity-75 mb-0">Nacionalidad:</p>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>Seleccionar nacionalidad</mat-label>
                    <mat-select [(value)]="nationality">
                        <mat-option value="Colombiano(a)">Colombiano(a)</mat-option>
                        <mat-option>---</mat-option>
                        <mat-option *ngFor="let nationality of nationalities" [value]="nationality">
                            {{ nationality }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col ">
            <div class="d-grid align-items-center p-3 p-lg-2 p-xl-3  shadow-sm rounded-3">
                <div class="d-flex pb-2">
                    <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em"
                        height="1.75em">calendar_month</mat-icon>
                    <p class="opacity-75 mb-0">Fecha de Nacimiento:</p>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>Seleccionar Fecha</mat-label>
                    <input #birthDateElem matInput [matDatepicker]="picker" [formControl]="birthDateCtrl"
                        [value]="userProfile.personalData?.birthDate">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="birthDateCtrl.invalid"> Formato incorrecto, debe ser DD/MM/YYYY</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col ">
            <div class="d-grid align-items-center p-3 p-lg-2 p-xl-3  shadow-sm rounded-3">
                <div class="d-flex pb-2">
                    <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">school</mat-icon>
                    <p class="opacity-75 mb-0">Nivel académico:</p>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>Seleccionar grado académico</mat-label>
                    <mat-select [(value)]="educationLevel">
                        <mat-option value=""><i>(Prefiero no decir)</i></mat-option>
                        <mat-option value="Estudiante">Estudiante (Colegio)</mat-option>
                        <mat-option value="Bachiller">Bachiller</mat-option>
                        <mat-option value="Técnico">Técnico</mat-option>
                        <mat-option value="Pregrado">Pregrado</mat-option>
                        <mat-option value="Especialización">Especialización</mat-option>
                        <mat-option value="Maestría">Maestría</mat-option>
                        <mat-option value="Doctorado">Doctorado</mat-option>
                        <mat-option value="Post-Doctorado">Post-Doctorado</mat-option>
                        <mat-option value="Otro">Otro</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="educationLevel === 'Estudiante'">
                    <mat-form-field appearance="fill">
                      <mat-label>Nombre de la institución:</mat-label>
                      <input  #instituteName matInput [(ngModel)]="institutionName">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    

    <div class="row justify-content-center">
        <div class="d-flex justify-content-center gap-2 py-4">
            <!-- <button mat-raised-button color="primary" class=" py-2 px-3" (click)="resetProfile()"> Resetear </button> -->
            <button mat-raised-button class=" py-2 px-3" routerLink="/perfil" [disabled]="isSaving">Cancelar</button>
            <button mat-raised-button color="primary" class="py-2 px-3" (click)="saveProfile()"
                [disabled]="isSaving">Guardar</button>
            <button mat-raised-button color="accent" class="py-2 px-3" (click)="deleteProfile()"
            [disabled]="isSaving">Eliminar perfil</button>
        </div>
    </div>
    <!-- </form> -->
</div>