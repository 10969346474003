import { Injectable, OnDestroy } from '@angular/core';
import { Firestore, doc,  getDoc,  updateDoc, Timestamp, arrayUnion, arrayRemove } from "@angular/fire/firestore";
import algoliasearch from 'algoliasearch';
import { environment } from 'src/environments/environment';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { Career } from '../models/career.interface';


const searchClient = algoliasearch(
    environment.Algolia_v2.ApplicationID,
    environment.Algolia_v2.APIKey,
    {
      responsesCache: createInMemoryCache(),
      requestsCache: createInMemoryCache({ serializable: false })
    }
  );
  const indexCareers = "careers"

@Injectable({
    providedIn: 'root',
  })
  export class FavoriteService implements OnDestroy {

    collectionName="students"
    favorites: string[] = []
    
    
    constructor(public firestore: Firestore) {}


    getLengthFavorites(): number{
        return this.favorites.length
      }

      
    getFavoriteList():string[]{
        this.favorites = JSON.parse(
            localStorage.getItem(environment.localFavoriteList) ?? '[]')
        return this.favorites
      }

    removeCareerFromList(objectID: string):Promise<boolean>{
        try {
            const currentList = this.getFavoriteList()
            const filteredList = currentList.filter((item)=> item !== objectID)
            localStorage.setItem(
                environment.localFavoriteList,
                JSON.stringify(filteredList)
              );
            return Promise.resolve(true)
        } catch (error) {
            console.error("Error in removeCareerFromList", error)
            return  Promise.resolve(false)
        }
       
    }

    addCareerFromList(objectID: string):Promise<boolean>{
      try {
        const currentList = this.getFavoriteList()
        currentList.push(objectID)
        localStorage.setItem(
            environment.localFavoriteList,
            JSON.stringify(currentList)
          );
        return Promise.resolve(true)
      } catch (error) {
        console.error("Error in addCareerFromList", error)
        return  Promise.resolve(false)
      }
    }
    
    async addToFavorites(idUser:string, idRefCareer:string):Promise<boolean>{
        // const collectionRef = collection(firestore, collectionName.replace("{0}", idUser));
        const docRef = doc(this.firestore, this.collectionName,idUser);
        
        if (docRef == null) {
            console.error(`Error updating: users/${idUser}`);
            console.error(" Doesn't find User ");
            return false;
        }

        try {
            await updateDoc(docRef, {
                favorites:arrayUnion(idRefCareer)
            });
            return true;

        } catch (error) {
            console.error(`Error updating: users/${idUser}`);
            console.error(`updating value: ${idRefCareer}`);
            console.error(error);
            return false;
        }
    }

    async removeFromFavorites(idUser:string, idRefCareer:string):Promise<boolean>{
        const docRef = doc(this.firestore, this.collectionName,idUser);
           
        if (docRef == null) {
            console.error(`Error updating: users/${idUser}`);
            console.error(" Doesn't find User ");
            return false;
        }

        try {
            await updateDoc(docRef, {
                favorites:arrayRemove(idRefCareer)
                
            });
           
            return true;

        } catch (error) {
            console.error(`Error updating: users/${idUser}`);
            console.error(error);
            return false;
        }
    }

     async getListByUser(idUser:string): Promise<string[]>{
        const docRef = doc(this.firestore, this.collectionName, idUser);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data()
            if(userData && userData["favorites"] && userData["favorites"].length > 0){
                return userData["favorites"] as string[]
            }else{
                console.log("Favorites field is empty or doesnt exist")
                return[]
            }
          
          
        } else {
          console.log("No such document!");
          return []
        }
    }

    async getFavorites(props:string[]):Promise<Career[] | []>{
        try {
          const index = searchClient.initIndex(indexCareers);
          const objects = await index.getObjects(props);
          const professors = objects.results as Career[]
          return professors
        } catch (error) {
          console.error(error);
          return [];
        }
      }

      ngOnDestroy() {
        localStorage.setItem(
          environment.localFavoriteList,
          JSON.stringify(this.favorites)
        );
      }
  }