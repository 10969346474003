import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-whatsapp-float-button',
  templateUrl: './whatsapp-float-button.component.html',
  styleUrls: ['./whatsapp-float-button.component.scss']
})
export class WhatsappFloatButtonComponent {

  private phoneNumber: string = environment.whatsappnumber;
  private message = environment.whatsappmessage;

  openWhatsApp() {
    const url = `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.message)}`;
    window.open(url, '_blank');
  }

}