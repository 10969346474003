import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { RegisterModalComponent } from 'src/app/components/register/register-modal/register-modal/register-modal.component';


@Component({
  selector: 'app-dialog-session',
  templateUrl: './dialog-session.component.html',
  styleUrls: ['./dialog-session.component.scss']
})
export class DialogSessionComponent {

  constructor( public dialogRef: MatDialogRef<DialogSessionComponent>, public dialog: MatDialog,) { }

  user = JSON.parse(localStorage.getItem('user')!)
  isDisabled: boolean = false;
  saveAs:boolean = false

  ngOnInit(): void {
 
  }
  
  openRegister(){
    this.dialog.closeAll()
      this.dialog.open(RegisterModalComponent, {
      width: '85%',
      maxWidth: '850px',
      data: {
        selectedOption: "student"
      },
    });
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }




}
