import { formatCurrency } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { parseNumberInput } from 'angular-instantsearch/utils';
import { setIndexConfiguration } from 'firebase/firestore';
import { pipe } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { UiState } from 'instantsearch.js/es/types';
import { SearchAlgoliaCheckBoxComponent } from '../search-algolia-check-box/search-algolia-check-box.component';
import { NgAisSearchBox } from 'angular-instantsearch/search-box/search-box';
import { decode } from 'punycode';


// Returns a slug from the category name.
// Spaces are replaced by "+" to make
// the URL easier to read and other
// characters are encoded.
function getCategorySlug(name: string) {
  return name.split(' ').map(encodeURIComponent).join('+');
}

// Returns a name from the category slug.
// The "+" are replaced by spaces and other
// characters are decoded.
// function getCategoryName(slug: string) {
//   return slug.split('+').map(decodeURIComponent).join(' ');
// }
const indexNameAlgolia = 'careers'

const searchClient = algoliasearch(
  environment.Algolia_v2.ApplicationID,
  environment.Algolia_v2.APIKey
);

type CustomRouteState = {
  [stateKey: string]: any;
  // query?: string;
  // page:number;
  // category: string
  // cities?: string[]
  // iesCharacter?: string[]
  // levelStudy?:string[]
  // modalities?:string[]
  // periods?:string[]
};

@Component({
  selector: 'app-filter-components',
  templateUrl: './filter-components.component.html',
  styleUrls: ['./filter-components.component.scss'],
})

export class FilterComponentsComponent implements OnInit, AfterViewInit {

  constructor(private route: ActivatedRoute, private router: Router) {
    // const routePrev = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString() ?? ""
  }

  public configSuggestions = {
    indexName: environment.Algolia_v2.QuerySuggestions,
    searchClient,
    stalledSearchDelay: 500, // 200 is the default value for the delay
  };

  public config = {
    // indexName: 'demo_ecommerce',
    indexName: environment.Algolia_v2.IndexName,
    searchClient,
    stalledSearchDelay: 3000, // 200 is the default value for the delay
    routing: {
      router: historyRouter<CustomRouteState>({
        windowTitle({ category, query }) {
          const queryTitle = query ? `Resultados para "${query}"` : 'Buscar';

          if (category) {
            return `${category} – ${queryTitle}`;
          }

          return queryTitle;
        },

        createURL: ({ qsModule, routeState }) => {
          const urlParts = location.href.match(/^(.*?)\/buscador/);
          if (!urlParts) return location.href;
          const baseUrl = `${urlParts ? urlParts[1] : ''}/`;

          const queryParameters = {} as any;

          if (routeState['query']) {
            queryParameters.query = encodeURIComponent(routeState['query']);
          }
          if (routeState['page'] !== 1) {
            queryParameters.page = routeState['page'];
          }
          // if(routeState['filters']){
          //   queryParameters.filters = routeState['filters'].map(encodeURIComponent)
          // }
          if (routeState['headquarter.city']) {
            queryParameters['headquarter.city'] = routeState['headquarter.city'].map(encodeURIComponent);
          }
          if (routeState['iesCharacter']) {
            queryParameters.iesCharacter = routeState['iesCharacter'].map(encodeURIComponent);
          }
          if (routeState['levelStudy']) {
            queryParameters.levelStudy = routeState['levelStudy'].map(encodeURIComponent);
          }
          if (routeState['modalities']) {
            queryParameters.modalities = routeState['modalities'].map(encodeURIComponent);
          }
          if (routeState['periods']) {
            queryParameters.periods = routeState['periods'].map(encodeURIComponent);
          }
          if (routeState['sortBy']) {
            queryParameters.sortBy = encodeURIComponent(routeState['sortBy']);
          }
          if (routeState['institution']) {
            queryParameters.institution = encodeURIComponent(routeState['institution']);
          }

          const queryString = qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: 'repeat',
          });
          // return `/buscador/${queryString}`;
          return `${baseUrl}buscador${queryString}`;
        },

        parseURL: () => {
          const { params, queryParams } = this.route.snapshot;
          const { query = '', page, iesCharacter = [], levelStudy = [],
            modalities = [], periods = [], sortBy = '', } = queryParams;
          const cities = queryParams['headquarter.city'] ?? [];

          const allCities = [].concat(cities);
          const allCharacters = [].concat(iesCharacter);
          const allStudyLevels = [].concat(levelStudy);
          const allModalities = [].concat(modalities);
          const allPeriods = [].concat(periods);

          return {
            query: decodeURIComponent(query),
            page,
            'headquarter.city': allCities.map(decodeURIComponent),
            iesCharacter: allCharacters.map(decodeURIComponent),
            levelStudy: allStudyLevels.map(decodeURIComponent),
            modalities: allModalities.map(decodeURIComponent),
            periods: allPeriods.map(decodeURIComponent),
            sortBy: decodeURIComponent(sortBy),
            institution: decodeURIComponent(queryParams['institution']),
            // category,
          };
        },
      }),
      stateMapping: {
        stateToRoute(uiState: UiState): CustomRouteState {
          const indexUiState = uiState[indexNameAlgolia]
          return {
            query: indexUiState.query,
            page: indexUiState.page,
            // categories: indexUiState.menu && indexUiState.menu['categories'],
            'headquarter.city': indexUiState.refinementList?.["headquarter.city"],
            iesCharacter: indexUiState.refinementList?.['iesCharacter'],
            levelStudy: indexUiState.refinementList?.['levelStudy'],
            modalities: indexUiState.refinementList?.['modalities'],
            periods: indexUiState.refinementList?.['periods'],
            sortBy: indexUiState.sortBy ?? '',
            institution: indexUiState.refinementList?.['institution'],
          }
        },
        routeToState(routeState: CustomRouteState): UiState {
          // console.log("RouteSTATEEE", routeState)
          return {
            [indexNameAlgolia]: {
              query: routeState['query'],
              page: routeState['page'],
              // menu: {
              //   categories: routeState['categories'],
              // },
              // sortBy: routeState['sortBy'],
              refinementList: {
                'headquarter.city': routeState['headquarter.city'],
                iesCharacter: routeState['iesCharacter'],
                levelStudy: routeState['levelStudy'],
                modalities: routeState['modalities'],
                periods: routeState['periods'],
                institution: routeState['institution']
              },
            },
          };
        },
      },
    },
  };

  searchParameters = {
    hitsPerPage: 10,
    query: '',
    filters: '',
    // disjunctiveFacetsRefinements: {
    //   'sedes.ciudad': ['Ciudad'],
    // },
  }
  user = JSON.parse(localStorage.getItem('user')!)
  transformCurrentItems(items: any[]) {
    // console.log("transformCurrentItems: ");
    // console.log(items);
    items.forEach(item => {
      switch (item.attribute) {
        case 'headquarter.city': {
          item.label = 'Ciudad';
          break;
        }
        case 'levelStudy': {
          item.label = 'Nivel de estudio';
          break;
        }
        case 'iesCharacter': {
          item.label = 'Tipo de instituto';
          break;
        }
        case 'modalities': {
          item.label = 'Modalidad';
          break;
        }
        case 'price': {
          item.label = 'Precio';
          if (item.refinements) {
            item.refinements.forEach((elem: any) => {
              var newLabel = elem.label;
              const indexOf = newLabel.search('[0-9]');
              if (indexOf > -1) {
                var symbols = newLabel.substring(0, indexOf);
                var numbers = newLabel.substring(indexOf, newLabel.length);
                var num: number = Number(numbers);
                const price = num.toLocaleString();
                newLabel = (symbols + price);
              }
              elem.label = newLabel;
            });
            /* item.refinements.map((item: { label: string; }) => {
              var value = item.label;
              var indexOf = value.search('[0-9]');
              if (indexOf > -1) {
                var symbols = value.substring(0, indexOf);
                var numbers = value.substring(indexOf, value.length);
                var num: number = Number(numbers);
                const price = num.toLocaleString();
                value = (symbols + price);
              }
              return (
                {
                  label: value
                  // label: item.label
                })
            }); */
          }
          break;
        }
      }
    });
    return items;
  }

  // transformItems(items) {
  //   return items.map(item => ({
  //     ...item,
  //     highlighted: item.highlighted.toUpperCase(),
  //   }));
  // },

  ngOnInit(): void {

    // this.router.events.subscribe((evt: any) => {
    //   if (evt instanceof NavigationEnd) {
    //     console.log(evt.url) //path of the route
    //   }
    // });
    console.warn("searchParameters", this.searchParameters);

    const params = this.route.snapshot.queryParamMap;
    const queryParam = params.get('query');
    console.warn('INIT - Query Param:', queryParam);
    if (queryParam) {
      this.initQuery = decodeURIComponent(queryParam ?? '');
    }

    const _cityParam = params.get('headquarter.city');
    console.warn('INIT - City Param:', decodeURIComponent(_cityParam ?? ''));

    if (_cityParam) {
      this.initCity = decodeURIComponent(_cityParam ?? '');
    }

    const _studyLevelsParam = params.getAll('levelStudy');
    console.warn('INIT - Study Level Param:', _studyLevelsParam);
    if (_studyLevelsParam) {
      this.initStudyLevels = _studyLevelsParam.map(item => decodeURIComponent(item ?? ''));
    }

    // query=Sistemas
    // &headquarter.city=Bogot%25C3%25A1%252C%2520D.C.
    // &levelStudy=Especializaci%25C3%25B3n&levelStudy=Maestr%25C3%25ADa&levelStudy=Doctorado
  }

  initQuery: string = '';
  initCity: string = '';
  initStudyLevels: string[] = [];

  ngAfterViewInit(): void {

  }

  // ngOnChanges() {
  //   const routePrev = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString() ?? ""
  //   console.log("RUTAAAA Desde el Onchangeee",routePrev)
  //   }
  @ViewChild('focus', { read: ElementRef }) focus!: ElementRef;

  scrollUp(): void {
    // console.warn('scrollUp ...');
    setTimeout(() => this.focus.nativeElement.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" }));
  }

  public setQuery({ query }: { query: string }) {
    this.searchParameters.query = query;
  }

  public updateInputValue(value: string) {
    this.searchParameters.query = value;
  };

  public customCity: string = '';
  public customLevelStudy: string[] = [];

  @ViewChild('searchFilters') searchFilters: SearchAlgoliaCheckBoxComponent | undefined;
  @ViewChild('searchBox') searchBox: NgAisSearchBox | undefined;

  public setFilter(searchValue: any) {
    // alert(JSON.stringify(searchValue));
    if (searchValue.query) {
      ;

      if (this.searchBox?.state.query.toLowerCase() !== searchValue.query.trim().toLocaleLowerCase())
        this.searchBox?.state.refine(searchValue.query.trim());

      // this.searchBox?.state.refine(searchValue.query.trim());
    } else {
      if (this.searchBox?.state.query !== '')
        this.searchBox?.state.refine('');
    }

    if (searchValue.city) {
      this.customCity = searchValue.city;
    }

    this.searchFilters?.ApplyFilters(searchValue.city as string, searchValue.levelStudy as string[]);

    if (searchValue.levelStudy) {
      this.customLevelStudy = searchValue.levelStudy as string[];
    }
  }

}
