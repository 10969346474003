import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FirebaseError } from 'firebase/app';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { LoginModalComponent } from './login-modal/login-modal/login-modal.component';
import { RegisterModalComponent } from '../register/register-modal/register-modal/register-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  selectedOption: string | null = null;

  public professorsUrl = environment.publicUrls.professorsUrl;
  public institutesUrl = environment.publicUrls.institutesUrl;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private analyticsService: AnalyticsService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-google.svg'));
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-linkedin.svg'));
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-facebook.svg'));
    this.matIconRegistry.addSvgIcon(
      'apple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-apple.svg'));
    this.matIconRegistry.addSvgIcon(
      'contactus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/contactus.svg'));

  }



  ngOnInit(): void {

    // Analytics
    this.analyticsService.logEventScreenView(
      'Login',
      LoginComponent.name);
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  selectOption(option: string): void {
    this.selectedOption = option;
    this.router.navigate(["inicio-estudiantes"])
  }

  openLoginModal(): void {
    const dialogRef = this.dialog.open(LoginModalComponent, {
      width: '85%',
      maxWidth: '850px',
      data: {
        selectedOption: this.selectedOption
      }
    });
  }
}
