<div *ngIf="showIntroduction" @fadeInOut class="container intro-container">
  <h2 class="fw-bold justify-content-center align-items-center text-center my-5">
    <span class="primaryColor">Bienvenido al test vocacional de Guía Universitaria</span>
  </h2>
  <h5 class="lh-sm fw-normal text-center justify-content-center">Este test está diseñado para ayudarte a identificar tus intereses y conectarte con las carreras y universidades que mejor se alineen con ellos. No existen respuestas correctas o incorrectas; simplemente selecciona las opciones que más se ajustan a tus preferencias y deseos personales.</h5>
  <h5 class="lh-sm fw-normal text-center justify-content-center"> Responde con sinceridad a las preguntas para obtener los resultados más útiles y precisos para guiarte en tu camino académico y profesional</h5>
  <div class="my-3">
    <button mat-raised-button color="primary" (click)="startTest()">Comencemos</button>
  </div>
</div>

<div *ngIf="showForm" @fadeInOut  class="container form-container" [ngClass]="{ 'hidden': showResults }">
  <h2 class="fw-bold justify-content-center align-items-center text-center my-5">
    <span class="primaryColor">Test vocacional Guía Universitaria</span>
  </h2>
  <mat-stepper orientation="vertical" [linear]="isLinear" #stepper class="shadow-lg rounded-4 p-4">
    <mat-step
      *ngFor="let question of data; let i = index"
      [stepControl]="firstFormGroup"
    >
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel class="question-form">
          <h6>{{ question.question }}</h6></ng-template
        >

        <div *ngFor="let answer of question.answers">
          <mat-checkbox [formControlName]="'answer' + i + '_' + answer.id" class="mb-3">
            {{answer.answer}}
          </mat-checkbox>
        </div>

        <div>
          <button mat-button matStepperPrevious *ngIf="i > 0">Atrás</button>
          <button mat-button matStepperNext *ngIf="i < data.length - 1">
            Continuar
          </button>
          <button
            mat-button
            matStepperNext
            *ngIf="i === data.length - 1"
            (click)="getResults()"
          >
            Resultados
          </button>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</div>

<div *ngIf="showResults" @fadeInOut class="container p-4 results-container" #focus>
  <div *ngFor="let result of topResults">
      <h2 class="primaryColor">Área de Interés: {{ result.area }}</h2>
      <p>{{ result.description }}</p>
      <h3 class="primaryColor">Posibles sectores y cargos en los que podrías desempeñarte:</h3>
      <ul>
          <li *ngFor="let role of result.potentialJobRoles">
              {{ role.sector }}: {{ role.role }}
          </li>
      </ul>
      <h3 class="primaryColor">Carreras recomendadas:</h3>
      <ul>
          <li *ngFor="let career of result.recommendedCareers">{{ career }}</li>
      </ul>
  </div>
</div>