import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService } from '../services/analytics.service';
import { ContactRequestService } from '../services/contact-request.service';
import { ContactRequest } from '../models/contact-request.interface';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private contactRequestService: ContactRequestService,
    private formBuilder: FormBuilder,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private analyticsService: AnalyticsService
  ) {
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-google.svg'));
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-linkedin.svg'));
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-facebook.svg'));
    this.matIconRegistry.addSvgIcon(
      'apple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-apple.svg'));
    this.matIconRegistry.addSvgIcon(
      'contactus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/contactus.svg'));
  }

  phone_pattern = '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'

  name = new FormControl('');
  email = new FormControl('', [Validators.email]);
  phone = new FormControl('', [Validators.pattern(this.phone_pattern)]);
  message = new FormControl('', [Validators.required]);
  form = this.formBuilder.group({
    name: this.name,
    email: this.email,
    phone: this.phone,
    message: this.message,
  });

  ngOnInit(): void {
    // Analytics
    this.analyticsService.logEventScreenView(
      'ContactUs',
      ContactUsComponent.name);
  }

  isSaving = false;
  async sendForm(): Promise<void> {
    try {
      this.isSaving = true;

      const _name = (this.name.value ?? '') as string;
      const _email = (this.email.value ?? '') as string;
      const _phone = (this.phone.value ?? '') as string;
      const _message = (this.message.value ?? '') as string;
      var contactRequest: ContactRequest = {
        name: _name,
        email: _email,
        phone: _phone,
        message: _message,
        status: "Pendiente"
      };
      const response= await this.contactRequestService.saveRequest(contactRequest);
      const okFacedMsg = `Gracias por tu mensaje. Guardamos tu formulario, estaremos comunicándonos contigo.`;
      alert(okFacedMsg);
      const nowDate= new Date()

      const formatOptions = { 
        year: 'numeric' as const,
        month: 'short' as const,
        day: 'numeric' as const,
        hour: 'numeric' as const,
        minute: 'numeric' as const,
        hour12: true as const
      };

      await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/sendContactUsEmail", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: _name,
          email: _email,
          phone: _phone,
          message: _message,
          status: "Pendiente",
          id:response,
          createdAt: nowDate.toLocaleString('es-ES', formatOptions),
          secret: environment.secretEmail,
        }) 
    })

      this.clearForm();
    } catch (error) {
      const errorFacedMsg = 'Presentamos problemas para enviar tu solicitud. Intenta más tarde.';
      alert(errorFacedMsg);
      console.error('Error in send ContactRequest');
      console.error(error);
    } finally {
      this.isSaving = false;
    }

  }

  clearForm() {
    this.name = new FormControl('');
    this.email = new FormControl('', [Validators.email]);
    this.phone = new FormControl('', [Validators.pattern(this.phone_pattern)]);
    this.message = new FormControl('', [Validators.required]);
  }

}
