<div class="container">
    <br />
    <div class="container-about-company">
        <div class="about-company">
            <div>
                <h1>Quiénes somos</h1>
                <p class="opacity-75 mt-4">
                    Nos apasiona la educación y creemos que cada estudiante merece tener acceso a la información
                    necesaria para
                    tomar decisiones informadas sobre su futuro. Somos una plataforma innovadora que conecta a
                    estudiantes,
                    profesores y universidades, facilitando un proceso transparente y eficiente para encontrar, comparar
                    y
                    evaluar la oferta académica universitaria a nivel de pregrado, especialización, maestría y
                    doctorado.
                </p>
            </div>
            <br />
            <div>
                <h1>Nuestra misión</h1>
                <p class="opacity-75 mt-4">
                    Facilitar el acceso a la educación superior al proporcionar una plataforma integral que empodere a
                    los
                    estudiantes, profesores y universidades, permitiéndoles tomar decisiones fundamentadas y contribuir
                    al
                    crecimiento de la educación en nuestra sociedad.
                </p>
            </div>
            <br />
            <div>
                <h1>Nuestra visión</h1>
                <p class="opacity-75 mt-4">
                    Imaginamos un futuro en el que cada individuo tenga acceso a la educación superior que se alinee con
                    sus
                    metas y sueños. En Guía Universitaria, trabajamos incansablemente para hacer realidad esta visión,
                    utilizando la tecnología para conectar y construir oportunidades para todos los actores de la
                    educación
                    superior: universidades, estudiantes y profesores.
                </p>
                <p class="opacity-75 mt-4">
                    Únete a Guía Universitaria y sé parte de una revolución educativa que redefine la forma en que
                    estudiantes
                    eligen su camino académico, en la que los profesores comparten su conocimiento y las universidades
                    se
                    destacan en la formación de profesionales líderes.
                </p>
                <p class="opacity-75 mt-4">
                    ¡Descubre, Compara, Evalúa! Guía Universitaria está aquí para transformar tu experiencia educativa.
                </p>
            </div>
        </div>
        <div class="image-about">
            <img src="../../assets/svg/rocket-image.svg">
        </div>
    </div>

    <br />
    <div>
        <h1>¿Qué ofrecemos al ecosistema educativo?</h1>
        <div class="col-12 col-lg-6  ">
            <img src="../../assets/svg/college project-bro.svg">
        </div>
        <div>
            <h3>Para los Estudiantes</h3>
            <p class="opacity-75 mt-4">
                Entendemos que elegir la universidad y el programa adecuado es una decisión crucial. Nuestra plataforma
                ofrece a los alumnos herramientas para simplificar el arduo proceso para decidir qué y dónde estudiar ,
                permitiéndoles explorar diversas opciones, comparar programas académicos y acceder a contenido relevante
                de
                profesores y egresados sobre el alcance profesional de cada disciplina. Nacimos por la convicción de que
                cada estudiante tiene el derecho de encontrar la institución educativa que mejor se adapte a sus metas y
                aspiraciones para desarrollar su plan de vida.
            </p>
        </div>
        <div>
            <h3>Para los Profesores</h3>
            <p class="opacity-75 mt-4">Valoramos el talento y la experiencia de los profesores. Por eso, ofrecemos a los
                docentes actuales o potenciales la oportunidad de destacarse en el entorno académico al subir sus
                perfiles y compartir sus logros profesionales. Nuestra plataforma conecta a profesores con universidades
                que buscan excelencia académica, facilitando la exposición del talento para impartir cursos y contribuir
                al desarrollo educativo.
            </p>
        </div>
        <div>
            <h3>Para las Universidades</h3>
            <p class="opacity-75 mt-4">
                En la era digital más que nunca las universidades tienen el desafío de reinventarse y anticiparse a los
                dinámicos cambios del entorno, por ello, reconocemos la importancia de la analítica educativa para las
                instituciones académicas. Proporcionamos a las universidades información detallada sobre quiénes están
                buscando sus ofertas académicas y qué comparaciones realizan en su proceso de evaluación, permitiéndoles
                comprender mejor las necesidades y expectativas de los estudiantes. Ofrecemos datos precisos que brindan
                a las universidades una visión valiosa para optimizar sus estrategias de atracción e innovación
                educativa.
            </p>
        </div>
    </div>
    <!-- Partners -->
    <app-partners></app-partners>
    <br>

    <div>
        <h1>Resultados ESAL DIAN</h1>
        <a routerLink="/nosotros/resultados-esal-dian">Ver Resultados ESAL DIAN</a>
    </div>

</div>