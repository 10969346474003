<mat-icon
  class="position-absolute top-0 end-0"
  style="cursor: pointer"
  (click)="onNoClick()"
  >close</mat-icon
>
<div mat-dialog-content class="d-flex justify-content-center flex-column" *ngIf="data.unansweredQuestions.length > 0">
  <p>Las siguientes preguntas no han sido respondidas:</p>

  <ul>
    <li *ngFor="let question of data.unansweredQuestions">
      Pregunta: {{ question }}
    </li>
  </ul>

  <p style="color: red;">Por favor, respondalas para continuar.</p>
</div>

<div mat-dialog-content class="d-flex justify-content-center flex-column" *ngIf="data.unansweredQuestions.length === 0">
  <p>Haz contestado todas las preguntas. ¡Presiona continuar para obtener los resultados!</p>

</div>


<div
  mat-dialog-actions
  class="w-100 gap-2 d-flex justify-content-center align-items-center"
>
  <button mat-raised-button (click)="onNoClick()">Cancelar</button>
  <button mat-flat-button color="primary" disabled="data.unansweredQuestions.length > 0" (click)="onGetResults()">
    Continuar
  </button>
</div>
