import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})

export class PlansComponent implements OnInit {
  public static path = 'servicios';
  public professorsUrl = environment.publicUrls.professorsUrl;
  public institutesUrl = environment.publicUrls.institutesUrl;

  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    // Analytics
    this.analyticsService.logEventScreenView(
      'Plans',
      PlansComponent.name);
  }

}
