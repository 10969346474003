<div class="container py-4">
    <!-- START THE FEATURES -->
    <div class="row my-5 text-start g-5">
        <div class="col-lg-6 align-self-center">
            <div class="d-block gap-4 align-items-between text-center text-lg-start">
                <h3 class="featurette-heading display-4 fw-bold">Encuentra las mejores <span color="primary"
                        class="primaryColor">universidades </span></h3>
                <h5 class="lead">Estamos para ayudarte. Navega entre las mejores facultades y
                    universidades del país, realiza la comparación de todos sus programas de
                    formación y encuentra el que mejor se adapte a tus objetivos profesionales.
                </h5>
                <button class="mt-4" color="primary" mat-raised-button routerLink="/buscador">Ir al buscador</button>
            </div>
        </div>
        <div class="col-lg-6 ">
            <img class="img-fluid" src="../../assets/svg/Study abroad-amico.svg" alt="">
        </div>
    </div>
    <div class="row my-5 text-start g-5">
        <div class="col-lg-6 align-self-center order-lg-2">
            <div class="d-block gap-4 align-items-between">

                <h3 class="featurette-heading display-4 fw-bold">¿Quieres ser
                    <span class="primaryColor">profesor</span>?
                </h3>
                <h5 class="lead">¿Deseas ser docente en una institución universitaria? O ya trabajas como profesor y
                    quieres estar en el radar de todas las universidades y colegas de tu disciplina para potenciar tu
                    perfil académico.
                </h5>
                <a [href]="professorsUrl" target="_blank">
                    <button class="mt-4" color="primary" mat-raised-button>Registrame</button>
                </a>
            </div>
        </div>
        <div class="col-lg-6 align-self-center order-md-1">
            <img class="img-fluid" src="../../assets/svg/Seminar-amico.svg" alt="">
        </div>
    </div>
    <div class="row my-5 text-start g-5">
        <div class="col-lg-6 align-self-center">
            <div class="d-block gap-4 align-items-between text-center text-lg-start">
                <h3 class="featurette-heading display-4 fw-bold">Descubre hoy el <span class="primaryColor">programa
                        universitario</span> de tus sueños</h3>
                <h5 class="lead">
                    Te ofrecemos una herramienta de búsqueda en la que tú, como estudiante podrás disfrutar de
                    todas las oportunidades de educación disponibles en tiempo real.
                </h5>
                <button class="mt-4" color="primary" mat-raised-button routerLink="/buscador">Ir al buscador</button>
            </div>
        </div>
        <div class="col-lg-6">
            <img class="img-fluid" src="../../assets/svg/Thesis-amico.svg" alt="">
        </div>
    </div>
    <!-- /END THE FEATURETTES -->
</div>