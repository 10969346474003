import { DialogConfig } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComparadortestingComponent } from '../comparadortesting/comparadortesting.component';
import { Career } from '../models/career.interface';
import { ComparisonService } from '../services/comparison.service';
import { DetailsService } from '../services/details.service';
import { UtilityService } from '../services/utility.service';
import { environment } from 'src/environments/environment';
import { FavoriteService } from '../services/favorites.service';
import { DialogSaveFavoritesComponent } from '../components/dialog-save-favorites/dialog-save-favorites.component';

@Component({
  selector: 'app-filter-cards',
  templateUrl: './filter-cards.component.html',
  styleUrls: ['./filter-cards.component.scss'],
})
export class FilterCardsComponent implements OnInit {
  @Input() career: Career | undefined;
  @Output() action = new EventEmitter<Career>();
  constructor(private comparisonService: ComparisonService, private detailsService: DetailsService, public dialog: MatDialog, private utilityService: UtilityService, protected favoriteService: FavoriteService) {
    this.userFavorites = [];
  }
  imgSrc: string | undefined;
  logoSrc: string | undefined;
  price: string | undefined;
  duration: string | undefined;
  places: Array<string> = [];
  name: string | undefined;
  iesCharacter: string | undefined;

  user = JSON.parse(localStorage.getItem('user')!)
  userFavorites: Array<string>
  isCareerFavorite: boolean = false
  favColor = this.isCareerFavorite ? "accent" : ""

  ngOnInit(): void {
    if (this.career) {

      var _imgSrc = environment.publicUrls.defaultImage;
      if (this.career.iesImages.length > 0) {
        _imgSrc = this.career.iesImages[0].url;
      }
      this.imgSrc = `background-image: linear-gradient(to right,rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.65)), url('${_imgSrc}');`;


      this.logoSrc = environment.publicUrls.defaultLogo;
      if (this.career.iesLogo) {
        this.logoSrc = this.career.iesLogo;
      }

      this.iesCharacter = this.career.iesCharacter
      // Career Name
      // this.name = this.career.name?.toUpperCase();
      this.name = this.career.name;

      // Career Price
      if (!this.career.price || this.career.price < 0) {
        this.price = '- -';
      } else {
        const _price = this.utilityService.formatToMinimalistCurrency(this.career.price);
        this.price = `${_price} ${this.career.currency}`;
      }
      // Career Duration
      const _periods = this.utilityService.formatPeriods(this.career.periods);
      this.duration = `${this.career.duration} ${_periods === "mensual" ? "meses" : _periods}`;

      // Career Place
      if (this.career.headquarter) {
        for (var i = 0; i < this.career.headquarter.length; i++) {
          this.places.push(
            this.career.headquarter[i].city + ', ' + this.career.headquarter[i].region
          );
        }
      }
    }
    this.isStarred();

    this.career?.code && this.refreshList(this.career?.code)
  }

  subscriptionLevel: number = 0;
  isStarred(): void {
    if (!environment.production) {
      var randNumber = Math.random() * 1000;
      console.log("isStarred: " + randNumber);
      this.subscriptionLevel = (randNumber > 900) ? 2 : (randNumber > 800) ? 1 : 0;
    } else {
      this.subscriptionLevel = this.career?.subscriptionLevel ?? 0;
    }
  }

  isFavorite(code: string) {
    this.isCareerFavorite = this.userFavorites?.includes(code)
    return this.isCareerFavorite
  }

  refreshList(code: string) {
    this.userFavorites = this.favoriteService.getFavoriteList();
    if (code) {
      this.isFavorite(code);
      if (this.isCareerFavorite) {
        this.favColor = "accent";
      }
    }
  }

  onCompare(career: Career | undefined) {
    // Add career to Comparasion List
    if (career) {
      this.comparisonService.addItemInComparisonList(career);
    }
    this.comparisonService.openDialog();
  }

  navigate(career: Career) {
    var nombre: string = career.name;
    var objectID: string = career.objectID;

    alert('ObjectID: ' + objectID + '\n' + 'Nombre: ' + nombre);
  }

  contact(career: Career) {
    var nombre: string = career.name;
    var objectID: string = career.objectID;

    alert('Contact: \n' + 'ObjectID: ' + objectID);
  }

  compare(career: Career) {
    // var nombre: string = career.nombre;
    var objectID: string = career.objectID;

    alert('Compare: \n' + 'ObjectID: ' + objectID);
  }

  isOnCompareList(career: Career | undefined): boolean {
    if (!career || !career.objectID) return false;

    return this.comparisonService.isOnCompareList(career.objectID);
  }

  onDetail(career: Career | undefined) {
    // Add career to Comparasion List
    if (career) {
      career.subscriptionLevel = this.subscriptionLevel;
      this.detailsService.openDialog(career);
    }
  }

  formatPrice(price: number | undefined): string {
    if (price !== undefined) {
      return price.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
    } else {
      return 'N/A'; // O cualquier valor por defecto si el precio es undefined
    }
  }

  async addOrRemoveFromFavorites($event: Event) {
    $event.stopPropagation();
    if (!this.user) {
      const dialogRef = this.dialog.open(DialogSaveFavoritesComponent, {
        width: '400px',

      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');

      });
    } else {
      if (this.career?.code) {
        this.refreshList(this.career?.code)
        if (!this.isCareerFavorite) {
          await this.favoriteService.addToFavorites(this.user.uid, this.career.code)
          this.favColor = "accent"
          this.userFavorites.push(this.career?.code);
          this.favoriteService.addCareerFromList(this.career.code)
          alert("Se agregó a favoritos exitosamente, ya puedes verlo en tu lista de favoritos")
        } else {
          await this.favoriteService.removeFromFavorites(this.user.uid, this.career.code)
          this.favColor = ""
          this.userFavorites = this.userFavorites.filter((favorite: string) => favorite !== this.career?.code)
            ;
          this.favoriteService.removeCareerFromList(this.career.code)

          // setSnackbarMessage("Se eliminó de favoritos exitosamente ")
          // setOpenSnackbar(true);
          alert("Se eliminó de favoritos exitosamente")
        }
        this.favoriteService.favorites = this.userFavorites
        // if(this.favoriteListComponent){
        //   this.favoriteListComponent.updateCareers()
        // }

      };
    }
  }
}


