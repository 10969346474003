<div class="password-container">
    <!-- <div class="mt-3">
        <button mat-raised-button color="primary" routerLink="/comparador">
            <mat-icon class="pb-2">keyboard_backspace</mat-icon>
            Volver 
        </button>
    </div> -->
    <app-logo size="200px" class="d-flex"></app-logo>

    <div>
        <form  [formGroup]="form">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Correo Electrónico</mat-label>
                <input matInput [formControl]="email" placeholder="email@example.com" >
                <mat-error *ngIf="email.invalid">Email invalido</mat-error>
            </mat-form-field>
        </form>
        <div class="d-grid gap-2">
            <button mat-raised-button (click)="handleRecoverPass()" [disabled]="form.invalid || isSaving">Recuperar contraseña
            </button>
        </div>

        
    <div class="contact-recover">
      <div class="d-flex flex-column justify-content-center contact-us">
              <img class="socials" src="../../../assets/svg/logos/wa-whatsapp-icon.svg" alt="WhatsApp" width="20" height="20" style="margin-bottom: 10px;">
              <p>Contáctanos</p>
          <a href="https://wa.me/573003621500" target="_blank"><p>+57 300 3621500</p></a>
      </div>

      <div class="d-flex flex-column justify-content-center support-email">
              <mat-icon>mail_outline</mat-icon>
              <p>Escríbenos</p>
          <a href="mailto:soporte@guiauniversitaria.net"><p>soporte&#64;guiauniversitaria.net</p></a>
      </div>

  </div>
    </div>
    <h3 style="color:#007FC0">Síguenos</h3>
    <p style="width: 50%;">Entérate de las últimas noticias, estadísticas e información que podemos proporcionar acerca de las Instituciones de Educación Superior en nuestras redes.</p>

    <div>
        <ul class="list-unstyled d-flex justify-content-center justify-content-lg-start g-4">
            <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="https://www.facebook.com/GuiaUniversitariaWeb" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/facebook-app-icon.svg" alt="Facebook"> </a>
            </li>
            <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="https://www.instagram.com/guia.universitaria" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/ig-instagram-icon.svg" alt="Instragram">
              </a>
            </li>
            <!-- <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="#0" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/logo-linkedin.svg" alt="LinkedIn">
              </a>
            </li> -->
            <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="https://wa.me/573003621500" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/wa-whatsapp-icon.svg" alt="WhatsApp">
              </a>
            </li>
          </ul>
    </div>

    <!-- <div>
        <ul class="list-unstyled">
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/inicio">Inicio</a></li>
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/nosotros">Nosotros</a></li>
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/login">Iniciar sesión</a></li>
          </ul>
    </div> -->
    
</div>
