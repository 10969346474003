<app-home-search-box [hasRedirect]="false" (onSearch)="setFilter($event)" [InitValueQuery]="initQuery"
  [InitValueCity]="initCity" [InitValueStudyLevels]="initStudyLevels"></app-home-search-box>

<div *ngIf="config" class="d-flex justify-content-center w-100 p-3">
  <ais-instantsearch class="w-100 p-0 m-0 b-0" #instantSearch [config]="config">
    <ais-configure [searchParameters]="searchParameters"></ais-configure>
    <div class="row p-0">
      <div class="col-12 p-0">
        <div class="row p-0">
          <div class="col-12 col-md-7 m-0 p-0 b-0" [hidden]="true">
            <ais-search-box #searchBox placeholder="Realizar busqueda ..." submitTitle="Buscar" resetTitle="clear"
              [searchAsYouType]="true" [autofocus]="false" [hidden]="true"></ais-search-box>
          </div>

          <div style="display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; gap: 8px;"
            class="p-3 py-md-2">
            <ais-stats #focus class="px-3">
              <ng-template let-state="state">
                <div *ngIf="state.query" class="statequery">
                  {{ state.query }}
                </div>
                {{state.nbHits}} resultados encontrados.
                <!-- en {{state.processingTimeMS}}ms. -->
              </ng-template>
            </ais-stats>

            <div class="col-md-4 ps-2 p-0 m-0"
              style="display: flex; justify-content: flex-end; width: fit-content; align-items: center;">
              Ordenar por precio: &nbsp;
              <ais-sort-by [items]="[
                { value: 'careers', label: 'Predeterminado' },
                { value: 'careers_price_asc', label: 'Ascendente' },
                { value: 'careers_price_desc', label: 'Descendente' }
              ]"></ais-sort-by>
            </div>
          </div>

          <div *ngIf="currentRefinements.state.items.length > 0" class="col-6 col-md-3 p-0 ps-2 m-0">
            <ais-clear-refinements #ClearRefinements [autoHideContainer]="true" hidden="false"></ais-clear-refinements>
            <div>
              <button mat-button (click)="ClearRefinements.state.refine();" color="none">
                <mat-icon>delete</mat-icon>
                Borrar filtros
              </button>
            </div>
          </div>

          <div class="px-4">
            <ais-current-refinements #currentRefinements
              [transformItems]="transformCurrentItems"></ais-current-refinements>
          </div>
          <!-- <div class="col-12 px-4">
            <ais-current-refinements #currentRefinementsStudyLevel [includedAttributes]="['levelStudy']"
              [transformItems]="transformCurrentItems"></ais-current-refinements>
          </div> -->

        </div>
      </div>
      <!-- IZQUIERDA-->
      <div class="col-12 col-md-3">
        <app-search-algolia-check-box #searchFilters></app-search-algolia-check-box>
      </div>
      <!-- boton de filtro visible en mobile -->
      <!-- <div class="pb-3 d-md-none">
        <app-search-algolia-mobile-button></app-search-algolia-mobile-button>
      </div> -->

      <!-- menu de filtro visible en desktop -->
      <!-- <div class="col-12 col-md-3">
        <mat-card class="d-none d-md-block">
          <div class="d-none d-md-block">
            <app-search-algolia-check-box></app-search-algolia-check-box>
          </div>
        </mat-card>
      </div> -->
      <!-- END IZQUIERDA-->

      <!--  DERECHA -->
      <div class="col-12 col-md-9">
        <ais-hits>
          <ng-template let-hits="hits" let-insights="insights">
            <div class="row row-cols-1 row-cols-md-2 align-items-stretch g-1 g-md-4">
              <div *ngFor="let career of hits">
                <app-filter-cards [career]="career" class="col"></app-filter-cards>
              </div>
            </div>
          </ng-template>
        </ais-hits>
        <br />
        <div>
          <ais-pagination #paginator [padding]="2" [showFirst]="true" [showLast]="true" [showPrevious]="true"
            [showNext]="true" hidden="true"></ais-pagination>
        </div>

        <div *ngIf="paginator.state.nbPages > 0" class="search-buttons">
          <button mat-stroked-button class="search-button" (click)="paginator.state.refine(0); scrollUp();"
            [disabled]="paginator.state.isFirstPage">
            <label>
              << </label>
          </button>
          <button mat-stroked-button class="search-button"
            (click)="paginator.state.refine(paginator.state.currentRefinement - 1); scrollUp();"
            [disabled]="paginator.state.isFirstPage"><label>
              < </label>
          </button>
          <button mat-stroked-button class="search-button" *ngFor="let page of paginator.state.pages"
            (click)="paginator.state.refine(page); scrollUp();">
            <strong *ngIf="page === paginator.state.currentRefinement">{{ page + 1 }}</strong>
            <span *ngIf="page !== paginator.state.currentRefinement">{{ page + 1 }}</span>
          </button>
          <button mat-stroked-button class="search-button"
            (click)="paginator.state.refine(paginator.state.currentRefinement + 1); scrollUp();"
            [disabled]="paginator.state.isLastPage"><label> > </label></button>
          <button mat-stroked-button class="search-button"
            (click)="paginator.state.refine(paginator.state.nbPages - 1); scrollUp();"
            [disabled]="paginator.state.isLastPage">
            <label> >> </label>
          </button>
        </div>
      </div>
      <!-- END DERECHA -->
    </div>
  </ais-instantsearch>
</div>