<!-- <main>

  <app-navbar-test></app-navbar-test>

  <div class="container">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</main> -->

<!--
  <mat-sidenav-container>
  <mat-sidenav-content role="main"> -->
<!-- Header with the Menu -->
<!-- <app-header (sidenavToggle)="sidenav.toggle()"></app-header>-->

<!-- Main -->
<main>
  <div class="container-fluid p-0">
    <mat-sidenav-container>
      <div id="#moveTop"></div>
      <app-navbar-bs (opened)="onOpen($event)"></app-navbar-bs>
      <mat-sidenav #mobileSideNav [opened]="opened" mode="over" position="end">
        <div class="">
          <app-logo size="100px" (click)="onClose();"></app-logo>

          <div class="d-grid gap-2 align-items-center">
            <a mat-raised-button class="active no-rounded" color="primary" routerLink="/donaciones" (click)="onClose()">
              <span>QUIERO DONAR</span></a>
          </div>

          <div class="pt-4 p-3" *ngIf="!userAccessGuard.validateCanActivate()">
            <!-- <div class="row">
              <div>
                <p style="font-size: 12px;">Inicia sesión</p>
                < !-- <p class="fw-light">Podrás ver tu historial de navegacion y guardar tu busquedas favoritas</p> -- >
              </div>
            </div> -->
            <div class="d-grid gap-2 align-items-center">
              <a mat-raised-button class="active" color="" routerLink="/login" (click)="onClose()">
                <span>Inicia sesión</span></a>
            </div>
            <!-- <div class="d-grid gap-3 align-items-center pt-1 pb-0">
              <a mat-stroked-button class="active" routerLink="/register" (click)="onClose()">
                <span>Registrate</span></a>
            </div> -->
          </div>
          <ul class="d-grid gap-3  pt-4 list-unstyled align-items-center">
            <li class="align-items-center">
              <a mat-button routerLink="/" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">home</mat-icon>
                <span>Inicio</span> </a>
            </li>
            <li class="align-items-center">
              <a mat-button routerLink="/buscador" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">search</mat-icon>
                <span>Buscador</span></a>
            </li>
            <li class="align-items-center">
              <a mat-button routerLink="/test-vocacional" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">assignment_turned_in</mat-icon>
                <span>Test vocacional</span></a>
            </li>
            <li class="align-items-center">
              <a mat-button routerLink="/comparador" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">compare_arrows</mat-icon>
                <span>Comparador</span></a>
            </li>
            <li class="align-items-center">
              <a mat-button routerLink="/favoritos" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">favorite_border</mat-icon>
                <span>Favoritos</span></a>
            </li>
            <li class="align-items-center">
              <a mat-button routerLink="/creditos" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">paid</mat-icon>
                <span>Créditos</span></a>
            </li>
            <li class="align-items-center">
              <a mat-button routerLink="/servicios" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">format_list_bulleted</mat-icon>
                <span>Servicios</span>
              </a>
            </li>
            <li class="align-items-center">
              <a mat-button routerLink="/nosotros" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">info</mat-icon>
                <span>Nosotros</span>
              </a>
            </li>
            <!-- <li class="align-items-center">
              <a mat-button routerLink="/donaciones" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">paid</mat-icon>
                <span>Donaciones</span>
              </a>
            </li> -->
            <!-- <li class="align-items-center">
              <a mat-button (click)="openDialog();onClose();" class="full-width-btn">
                <mat-icon [matBadge]="comparisonService.getLength()"
                  [matBadgeHidden]="!(comparisonService.getLength()>0)" matBadgeSize="small"
                  matBadgeColor="primary">compare_arrows</mat-icon>
              </a>
            </li> -->
          </ul>
        </div>
        <mat-divider></mat-divider>
        <div class=" pt-4">
          <ul class="d-grid gap-3  list-unstyled align-items-center">
            <li *ngIf="userAccessGuard.validateCanActivate()">
              <a mat-button routerLink="/perfil" (click)="onClose()" class="full-width-btn">
                <mat-icon>account_circle</mat-icon>
                <span>Perfil</span></a>
            </li>
            <!-- <li *ngIf="userAccessGuard.validateCanActivate()">
              <a mat-button routerLink="/" (click)="onClose()" class="full-width-btn" disabled="true">
                <mat-icon>favorite</mat-icon>
                <span>Favoritos</span></a>
            </li> -->
            <li *ngIf="userAccessGuard.validateCanActivate()">
              <a mat-button (click)="onClose();onLogout();" class="full-width-btn">
                <mat-icon>logout</mat-icon>
                <span>Cerrar sesión</span>
              </a>
            </li>
            <li class="">
              <a mat-button routerLink="contactanos" (click)="onClose()" class="full-width-btn">
                <mat-icon color="primary">send</mat-icon>
                <span>Contáctanos</span></a>
            </li>
            <!-- <li class="">
              <a mat-button [routerLink]="Router.parese'/inicio#ayuda'" (click)="onClose()">
                <mat-icon>info</mat-icon>
                Ayuda</a>
            </li> -->
          </ul>
        </div>
        <!-- <mat-divider></mat-divider>
        <div class="ps-4 ms-1">
          <a mat-button routerLink="/nosotros" (click)="onClose()" class="full-width-btn">
            Acerca de Guía universitaria
          </a>
        </div> -->
      </mat-sidenav>
      <router-outlet></router-outlet>

      <!-- whatsapp float button -->
      <app-whatsapp-float-button></app-whatsapp-float-button>

      <app-footer></app-footer>
    </mat-sidenav-container>
  </div>
</main>
<!-- Footer -->
<!-- </mat-sidenav-content>-->

<!-- Menu navbar collapsed vertically -->
<!--
    <mat-sidenav #sidenav role="navigation" position="end">
      <app-vertical-menu (sidenavClose)="sidenav.close()"></app-vertical-menu>
    </mat-sidenav>
  </mat-sidenav-container>
  -->