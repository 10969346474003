import { Timestamp } from "firebase/firestore";

export const fromFirestore = <T extends Record< string, unknown>>(document: T) => {
  Object.entries(document).forEach(([key, value]) => {
    if (typeof value !== "object" || value === null) return;
    if (value instanceof Timestamp) {
      // @ts-ignore
      document[key] = value.toDate().toISOString();
      return;
    }
    // @ts-ignore
    document[key] = fromFirestore(value);
  });
  return document as T;
};

export const toFirestore = <T extends Record<string, unknown>>(document: T) => {
  Object.entries(document).forEach(([key, value]) => {
    if (typeof value !== "object" || value === null) return;
    // if ((/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value.toString()))) {
    if (key === "createdAt" || key === "updatedAt" || key === "date" || key === "startDate" || key === "endDate" || key === "birthDate" || key === "orderDate") {
      try {
        const dateValue: Date = new Date(value.toString());
        document = Object.assign(document, { [key]: Timestamp.fromDate(dateValue) });
      } catch (error) {
        console.error("Error in toFirestore.", `key: ${key}`, `value: ${value}`);
      }
      return;
    }
    // @ts-ignore
    document[key] = toFirestore(value);
  });
  return document as T;
};
