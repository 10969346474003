<div mat-dialog-content class="py-2 px-4 ">
    <div class="row d-flex m-0 mb-2" mat-dialog-title>
        <div class="col col-9 mt-2 header-dialog">
            <app-logo size="130px"></app-logo>
        </div>
        <div class="col d-flex justify-content-end col-3">
            <button class="shadow" mat-dialog-close mat-icon-button aria-label="dialog close">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="selected-content position-relative">
        <ng-container [ngSwitch]="selectedOption">
            <div *ngSwitchCase="'student'" class="w-100">
                <div class="mt-4" class="w-100">
                    <div class="section-title mb-4">
                        <h3 class="lh-1">Inicia sesión como Estudiante</h3>
                        <!-- <p class="opacity-75 font-italic text-sm">Inicia sesión como Estudiante y guarda programas universitarios,
                            encuentra tu próxima profesión ahora.  -->
                        <!-- <p class="opacity-75">Inicia sesión para comparar y guardar tús programas favoritos.
                            No esperes más y encuentra dónde estudiar.</p> -->
                        <!-- </p> -->
                    </div>
                    <div class="row">
                        <div class="col-xl-7 col-lg-7">
                            <div class="signup-form-wrapper">
                                <form [formGroup]="formLogin" class="example-form">
                                    <p class="opacity-75 mb-4">Inicia sesión con tu correo:</p>
                                    <mat-form-field class="w-100 pe-5" appearance="outline">
                                        <mat-label>Correo Electrónico {{ getErrorMessage() }}</mat-label>
                                        <input matInput type="email" [formControl]="email" placeholder="pat@example.com"
                                            required>
                                        <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
                                    </mat-form-field>
                                    <mat-form-field class="w-100 pe-4" appearance="outline">
                                        <mat-label>Introduzca su contraseña</mat-label>
                                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
                                            required>
                                        <button mat-icon-button matSuffix (click)="hide = !hide"
                                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <p class="ms-2"><mat-checkbox formControlName="remindMe">Recordarme</mat-checkbox>
                                    </p>

                                    <div class="d-grid gap-2 justify-content-md-center">
                                        <a class="py-2" mat-raised-button (click)="onSubmit()">
                                            <mat-icon>email</mat-icon>&nbsp;&nbsp;
                                            <span>Iniciar sesión</span>
                                        </a>
                                    </div>
                                    <div class="pt-4">
                                        ¿Olvidaste su contraseña? <a routerLink="/recover&password"
                                            (click)="onNoClick()">Recuperala aquí!</a>
                                    </div>
                                    <div> ¿Aún no tienes una cuenta?
                                        <a routerLink="/login" (click)="openRegisterModal()">
                                            Regístrate con tu correo aquí!</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class=" col-xl-5 col-lg-5 offset-xl-1 order-first">
                            <div class="signup-content-wrapper">
                                <p class="opacity-75 mb-4">Inicia sesión con:</p>
                                <div class="d-flex gap-2 gap-sm-3">
                                    <div class="col-auto">
                                        <a class="align-content-center" mat-raised-button (click)="onLoginWithGoogle()">
                                            <mat-icon class="h-100 me-3 my-2" svgIcon="google"></mat-icon>Google</a>
                                    </div>
                                    <div class="col-auto">
                                        <a class="align-content-center" mat-raised-button (click)="onLoginWithApple()">
                                            <mat-icon class="h-100 me-3 my-2" svgIcon="apple"></mat-icon>Apple</a>
                                    </div>
                                    <!-- <div class="vr"></div> -->
                                    <!-- <div class="col-auto">
                                            <a mat-raised-button (click)="onLoginWithGoogle()">
                                                <mat-icon class="h-100 me-3 my-2" svgIcon="linkedin"></mat-icon>Linkedin</a>
                                        </div> -->
                                </div>
                                <br>
                                <br>
                                <!-- <div class="d-lg-none">
                                    <hr>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="'professor'">
                <div class="p-md-2 mt-4">
                    <h3 class="lh-1">¿Eres un profesor?</h3>
                    <p class="opacity-75">Eres un profesor o quieres serlo, ingresa tus datos para tener visibilidad en
                        una Universidad o Institución Educativa.</p>
                    <p class="opacity-75">Inicia sesión o registrate aquí:</p>
                    <a [href]="professorsUrl" target="_blank">
                        <button (click)="onNoClick()" class="" mat-stroked-button>Ir a Profesores</button>
                    </a>
                </div>
            </div>
            <div *ngSwitchCase="'institute'">
                <div class="p-md-2 mt-4">
                    <h3 class="lh-1">¿Representas a una Universidad o Institución Educativa?</h3>
                    <p class="opacity-75">Quieres ser parte de las ofertas educativas a mostrar, o quieres actualizar
                        los
                        datos
                        de tu Institución. Si también estás buscando profesores de planta o cátedra.</p>
                    <p class="opacity-75">Inicia sesión o registrate aquí:</p>
                    <a [href]="institutesUrl" target="_blank">
                        <button class="" mat-stroked-button (click)="onNoClick()">Ir a Instituciones</button>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>