import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comparadortesting',
  templateUrl: './comparadortesting.component.html',
  styleUrls: ['./comparadortesting.component.scss'],
})
export class ComparadortestingComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
