import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ComparisonService } from '../services/comparison.service';
import { environment } from 'src/environments/environment';

interface Data {
  name:string
  hasCareers: boolean
}
@Component({
  selector: 'app-dialog-save-comparison',
  templateUrl: './dialog-save-comparison.component.html',
  styleUrls: ['./dialog-save-comparison.component.scss']
})
export class DialogSaveComparisonComponent implements OnInit {

  
  constructor( public dialogRef: MatDialogRef<DialogSaveComparisonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,private comparisonService: ComparisonService) { }

  user = JSON.parse(localStorage.getItem('user')!)
  isDisabled: boolean = false;
  saveAs:boolean = false

  ngOnInit(): void {
 
  }
  
  onNoClick(): void {
    this.dialogRef.close();
    this.comparisonService.closeDialog()
  }

  saveListInGroup(){
    this.comparisonService.addCurrentListInComparisonGroup(this.data.name)
    this.comparisonService.setListIsSaved(true)
    localStorage.setItem(environment.currentComparatorName,JSON.stringify(this.data.name))

  }

  closeOnlySaveDialog():void{
    this.dialogRef.close();
  }

  saveListAs():void{
    this.saveAs= true
    this.data.name = ""
  }


}
