import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validador que solo acepta números
 */
export function onlyNumbersValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value || /^\d+$/.test(value)) {
            return null; // Sin errores
        }
        return { onlyNumbers: true }; // Error si no son solo números
    };
}

/**
 * Validador para verificar que el tamaño sea exactamente de 10 dígitos
 */
export function tenDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value || value.toString().trim().length === 10) {
            return null; // Sin errores
        }
        return { tenDigits: true }; // Error si el tamaño no es 10
    };
}

/**
 * Validador que no permite más de 3 dígitos repetidos consecutivos
 */
export function noRepeatedDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value || /^(?!.*(\d)\1{3}).*$/.test(value)) {
            return null; // Sin errores
        }
        return { noRepeatedDigits: true }; // Error si hay más de 3 repetidos consecutivos
    };
}