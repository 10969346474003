import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { environment } from 'src/environments/environment';
import { DIAL_CODES } from 'src/app/util/DialCodes';
import { Dialog } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactRequestService } from 'src/app/services/contact-request.service';

export interface ContactMeData {
  institution: string;
  institution_id: string;
  career: string;
  career_id: string;
  headquarter: string;
}

@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.scss']
})
export class ContactMeComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private analyticsService: AnalyticsService,
    private contactRequestService: ContactRequestService,
    public dialogRef: MatDialogRef<ContactMeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactMeData,
  ) {
  }

  countries = DIAL_CODES;
  // phone_pattern = '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$';

  name = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  //phone = new FormControl('', [Validators.required, Validators.pattern(this.phone_pattern)]);
  phone = new FormControl('', [Validators.required]);
  document_type = new FormControl('', [Validators.required]);
  document_number = new FormControl('', [Validators.required]);
  school_company_type = new FormControl('unknow');
  school_company = new FormControl('');
  career = new FormControl({ value: this.data?.career, disabled: (this.data?.career !== '') }, [Validators.required]);
  institution = new FormControl({ value: this.data?.institution, disabled: (this.data?.institution !== '') }, [Validators.required]);
  country = new FormControl('Colombia', [Validators.required]);
  city = new FormControl('', [Validators.required]);
  message = new FormControl('');

  form = this.formBuilder.group({
    name: this.name,
    lastname: this.lastname,
    document_type: this.document_type,
    document_number: this.document_number,
    phone: this.phone,
    email: this.email,
    country: this.country,
    city: this.city,
    school_company_type: this.school_company_type,
    school_company: this.school_company,
    career: this.career,
    institution: this.institution,
    message: this.message,
  });

  toUpperCase(event: any) {
    this.document_number.setValue(event.target.value.toUpperCase());
  }
  ngOnInit(): void {

    // Analytics
    if (environment.production)
      this.analyticsService.logEventScreenView(
        'ContactMe',
        ContactMeComponent.name);
  }

  isSaving = false;
  async sendForm(): Promise<void> {
    try {
      this.isSaving = true;

      const data = {
        name: this.name.value,
        lastname: this.lastname.value,
        document_type: this.document_type.value,
        document_number: this.document_number.value,
        phone: this.phone.value,
        email: this.email.value,
        country: this.country.value,
        city: this.city.value,
        type_school_company: this.school_company_type.value,
        school_company: this.school_company.value,
        career: this.career.value,
        career_id: this.data.career_id,
        institution: this.institution.value,
        institution_id: this.data.institution_id,
        headquarter: this.data.headquarter,
        message: this.message.value,
      }

      const okFacedMsg = `Gracias por tu mensaje. Guardamos tu solicitud y estaremos comunicándonos contigo.`;
      alert(okFacedMsg);

      // Save contact-me record in database
      const response = await this.contactRequestService.saveContactMeRequest(data);
      console.log(response);
    } catch (error) {
      const errorFacedMsg = 'Presentamos problemas para enviar tu solicitud. Por favor, intenta más tarde.';
      alert(errorFacedMsg);
      console.error('Error in send ContactMeRequest');
      console.error(error);
    } finally {
      this.isSaving = false;
    }
    this.closeForm();
  }

  getSchoolCompanyLabel(): string {
    switch (this.school_company_type.value ?? 'unknow') {
      case "school":
        return "Colegio"
      case "company":
        return "Empresa"
      default:
        break;
    }
    return "Colegio / Empresa";
  }

  closeForm() {
    this.dialogRef.close();
  }

  clearForm() {
    this.form.setValue({
      name: null,
      lastname: null,
      document_type: null,
      document_number: null,
      phone: null,
      email: null,
      country: null,
      city: null,
      school_company_type: null,
      school_company: null,
      career: null,
      institution: null,
      message: null,
    });
  }

}
