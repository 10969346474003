<div class="m-3 p-3">
    <div class="col d-flex justify-content-end">
        <button class="shadow" mat-dialog-close mat-icon-button aria-label="close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="d-flex justify-content-center align-items-center mb-3">
        <h2>
            ¿Deseas recibir más información?
        </h2>
    </div>

    <div class="d-flex justify-content-center align-items-center mb-3">
        <h6>
            Estas interesado en <b>{{ data.career }}</b> de <b>{{ data.institution }}</b>
            en <b>{{ data.headquarter }}</b>. Por favor ingresa tus datos:
        </h6>
    </div>

    <form [formGroup]="form">
        <div class="d-flex justify-content-center align-items-center row">
            <!-- Nombre -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Nombre(s)</mat-label>
                <input matInput [formControl]="name">
            </mat-form-field>
            <!-- Apellido -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Apellido(s)</mat-label>
                <input matInput [formControl]="lastname">
            </mat-form-field>
            <!-- Tipo de Documento -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Tipo de documento</mat-label>
                <mat-select [formControl]="document_type">
                    <mat-option value="CC">CC - Cédula de Ciudadanía</mat-option>
                    <mat-option value="TI">TI - Tarjeta de Identidad</mat-option>
                    <mat-option value="TE">TE - Tarjeta de Extranjería</mat-option>
                    <mat-option value="PA">PA - Pasaporte</mat-option>
                    <mat-option value="NIT">NIT</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Número de documento -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Número de documento</mat-label>
                <input matInput [formControl]="document_number" [type]="document_type.value=='PA'?'text':'number'"
                    [placeholder]="document_type.value=='PA'?'EH4885522':'10200550444'"
                    [class]="document_type.value=='PA'?'':'only-numbers'" (input)="toUpperCase($event)">
                <mat-error *ngIf="document_number.invalid">Número de Identificación invalido</mat-error>
            </mat-form-field>
            <!-- Telefono/Celular -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Celular/Teléfono</mat-label>
                <input matInput [formControl]="phone" type="number" class="only-numbers"
                    placeholder="300 555-12345 ó 601 555-1234">
                <mat-error *ngIf="phone.invalid">Celular/Teléfono invalido</mat-error>
            </mat-form-field>
            <!-- Correo Electrónico -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Correo Electrónico</mat-label>
                <input matInput [formControl]="email" placeholder="name@example.com">
                <mat-error *ngIf="email.invalid">Correo Electrónico invalido</mat-error>
            </mat-form-field>
            <!-- País -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>País</mat-label>
                <mat-select name="countries" [formControl]="country">
                    <mat-option *ngFor="let country of countries" [value]="country.name">
                        {{ country.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Ciudad -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Ciudad</mat-label>
                <input matInput [formControl]="city">
            </mat-form-field>
        </div>

        <div class="d-flex justify-content-center align-items-center row">
            <!-- Select Colegio o Empresa -->
            <mat-radio-group class="col-sm-12 col-md-6 " aria-label="Selecciona una opción"
                [formControl]="school_company_type">
                <mat-radio-button class="col m-3" value="school">Colegio</mat-radio-button>
                <mat-radio-button class="col m-3" value="company">Empresa</mat-radio-button>
            </mat-radio-group>
            <!-- Input Colegio o Empresa -->
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>{{ getSchoolCompanyLabel() }}</mat-label>
                <input matInput [formControl]="school_company">
            </mat-form-field>
        </div>

        <!-- <div class="d-flex justify-content-center align-items-center row row-cols-2 rows-cols-sm-1">
            < !-- Carrera -- >
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Carrera/Programa</mat-label>
                <input matInput [formControl]="career">
            </mat-form-field>
            < !-- Universidad -- >
            <mat-form-field class="col-sm-12 col-md-6 " appearance="outline">
                <mat-label>Universidad/Institutción</mat-label>
                <input matInput [formControl]="institution">
            </mat-form-field>
        </div> -->

        <div class="d-flex justify-content-center align-items-center row">
            <!-- Mensaje -->
            <mat-form-field class="col-12 " appearance="outline">
                <mat-label>Escríbenos un mensaje</mat-label>
                <textarea matInput [formControl]="message" maxlength="500" appearance="outline"></textarea>
                <mat-hint [align]="'end'">{{ message.value?.length || 0 }}/500</mat-hint>
            </mat-form-field>
        </div>
        <br />

        <div class="d-flex justify-content-center align-items-center row g-3">
            <div class="col-sm-6 d-flex justify-content-center align-items-center">
                <button style="min-width: 200px;" mat-raised-button (click)="closeForm()" [disabled]="isSaving">
                    Cancelar <mat-icon class="ms-2">cancel</mat-icon>
                </button>
            </div>
            <div class="col-sm-6 d-flex justify-content-center align-items-center">
                <button style="min-width: 200px;" mat-raised-button (click)="sendForm()"
                    [disabled]="form.invalid || isSaving">
                    Enviar <mat-icon class="ms-2">send</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>