<h2 mat-dialog-title>Políticas de Cookies</h2>
<mat-dialog-content class="mat-typography">
    <h3>1. ¿QUÉ SON Y QUE NO SON LAS COOKIES/DATOS DE
        NAVEGACIÓN?</h3>
    <p>Las cookies/datos de navegación son pequeños archivos de información o fragmentos de texto
        enviados por un Portal o Aplicación tecnológica de propiedad de GUÍA UNIVERSITARIA o alguna de
        sus subsidiarias y que se almacenan en el navegador o dispositivo del usuario. A través de las
        cookies, los Portales recuerdan información sobre la visita de los usuarios, lo que permite que se
        proporcione una mejor y más segura experiencia de navegación en el mismo.
        Las cookies se asocian tanto a usuarios anónimos, es decir, aquellos que visitan los Portales sin
        identificarse o registrarse, como aquellos que si lo hacen.
        Por otro lado, las cookies no son un virus o cualquier otro tipo de programa malicioso que pueda
        dañar dispositivos de los usuarios. Adicionalmente las cookies no pueden borrar ni leer información
        del computador o dispositivo de los usuarios.
    </p>
    <h3>2. ¿CÓMO OBTIENE GUÍA UNIVERSITARIA LAS
        COOKIES?</h3>
    <p>Las cookies se crean y/o actualizan en el computador o dispositivo del usuario de manera
        automática, cuando éste accede al Portal de GUÍA UNIVERSITARIA o el tercero que este contrate, lo
        cual permite a realizar seguimiento a las cookies del usuario y por ende a la información que estas
        cookies contienen u obtienen del usuario. Es importante aclarar que las cookies solo son leídas por
        el sitio web que las creó.
    </p>
    <h3>3. ¿QUÉ CLASE DE COOKIES UTILIZA GUÍA
        UNIVERSITARIA Y PARA QUÉ LAS UTILIZA?
    </h3>
    <p>1. Cookies Esenciales: Estas cookies son esenciales para el Uso del Portal, en el sentido de
        facilitar el proceso de registro en el mismo, así como permitir a los usuarios un acceso más
        rápido y seguro a los servicios seleccionados dentro del Portal. Sin estas cookies, es posible
        que GUÍA UNIVERSITARIA no les pueda ofrecer a los usuarios ciertos servicios dentro del
        Portal y éste puede que no funcione con normalidad.
        2. Cookies Funcionales: Mediante el uso de las cookies funcionales, es posible para GUÍA
        UNIVERSITARIA o el tercero que este contrate, personalizar los servicios que se ofrecen en
        el Portal, facilitando a cada usuario información que es o puede ser de su interés, en
        atención al uso que realiza de los servicios y a las páginas específicas que visita dentro del
        Portal. A través de estas cookies, GUÍA UNIVERSITARIA o las terceras personas que contrate,
        podrán ofrecer a los usuarios una mejor experiencia y mejor rendimiento en la navegación
        dentro del Portal.
        3. Cookies de Publicidad: GUÍA UNIVERSITARIA o el tercero que este contrate, mediante el
        uso de estas cookies, podrá entregarle al usuario publicidad que considere que puede llegar
        a ser de su interés, según las preferencias y comportamientos que el usuario tiene o ha
        tenido dentro de la red de Portales de GUÍA UNIVERSITARIA. A través de estas cookies, se
        podrá optimizar la publicidad que el usuario ve en el Portal. Las cookies de publicidad
        podrán ser entregadas a anunciantes de GUÍA UNIVERSITARIA, para que sean utilizadas por
        estos para sus campañas de mercadeo y publicidad.
        4. Cookies de Análisis o Desempeño: A través de las cookies de análisis o desempeño, GUÍA
        UNIVERSITARIA o el tercero que éste contrate, puede realizar distintos análisis y estudios
        de la información recolectada, con el fin de mejorar los productos y servicios que GUÍA
        UNIVERSITARIA ofrece a los usuarios.
        GUÍA UNIVERSITARIA y los terceros con los que contrate servicios para el uso de cookies, son los
        únicos que podrán acceder a la información almacenada en las cookies que se han instalado dentro
        del equipo del usuario. La información almacenada en las cookies no puede ser leída por otros
        usuarios, ni estos podrán acceder a la misma.</p>
    <h3>4. ¿PUEDO DESHABILITAR LA INSTALACIÓN Y USO DE
        COOKIES POR PARTE DE GUÍA UNIVERSITARIA?
    </h3>
    <p>El usuario puede deshabilitar tanto la instalación de las cookies como el uso de estas por parte de
        GUÍA UNIVERSITARIA. Para deshabilitar la instalación y uso de cookies el usuario deberá realizar el
        procedimiento por navegador tal como se indica a continuación:
        • Internet Explorer: Herramientas -> Opciones de Internet -> Privacidad -> Configuración.
        Para más información, puede consultar el Soporte de Microsoft o la Ayuda del navegador
        • Firefox: Herramientas -> Opciones -> Privacidad -> Historial -> Configuración Personalizada.
        Para más información, puede consultar el Soporte de Mozilla o la Ayuda del navegador.
        • Chrome: Configuración -> Mostrar opciones avanzadas -> Privacidad -> Configuración de
        contenido. Para más información, puede consultar el Soporte de Google o la Ayuda del
        navegador.
        • Safari: Preferencias -> Seguridad. Para más información, puede consultar el Soporte de
        Apple o la Ayuda del navegador.</p>
    <h3>5. ¿QUÉ OCURRE SI SE DESHABILITAN LAS COOKIES?</h3>
    <p>Si el usuario deshabilita la instalación o el uso de las cookies para los Portales, podrá perder o afectar
        algunas funcionalidades del sitio. Como, por ejemplo:
        1. Poder ingresar a los Portales sin necesidad de indicar en cada ocasión su usuario y
        contraseña, esto le ahorrará tiempo y le permitirá utilizar funcionalidades como boletines,
        alertas, noticias guardadas, entre otros.
        2. Publicación de comentarios dentro de los Portales.
        3. Acceso al contenido sin restricción dentro de los Portales.
        4. Seguridad en el uso de la información que se ingresa en los Portales.
        5. Rapidez en el uso de algún servicio dentro de los Portales.
    </p>
    <h3>6. ¿GUÍA UNIVERSITARIA COMPARTE LA INFORMACIÓN
        OBTENIDA A TRAVÉS DE LAS COOKIES CON TERCERAS
        PERSONAS?</h3>
    <p>GUÍA UNIVERSITARIA podrá compartir información obtenida a través de las cookies con personas
        externas o terceros (aliados, clientes, proveedores o empresas vinculadas a GUÍA UNIVERSITARIA),
        con el fin de mejorar la usabilidad y servicios al usuario. Así mismo, la información que se recibe a
        través de las cookies será utilizada por GUÍA UNIVERSITARIA y los anteriores terceros, para los fines
        descritos en el presente documento, y cualquiera de sus actualizaciones.</p>
    <h3>7. ¿DÓNDE ESTA ALMACENADA LA INFORMACIÓN
        OBTENIDA A TRAVÉS DE LAS COOKIES?
    </h3>
    <p>GUÍA UNIVERSITARIA podrá contratar terceras personas encargadas de almacenar y obtener la
        información a través de las cookies, o que incluyan cookies dentro de los portales de GUÍA
        UNIVERSITARIA, personas que podrán estar localizadas dentro de Colombia o en el exterior. Así
        mismo, GUÍA UNIVERSITARIA podrá entregar a terceros, la información que se obtenga de las
        cookies para crear perfiles de usuarios, ofrecer campañas personalizadas, sin que lo anterior,
        implique entrega de información personal.
    </p>
    <h3>8. ¿QUÉ DEBO TENER EN CUENTA SOBRE LA POLÍTICA
        DE USO DE COOKIES Y DÓNDE ME PUEDO CONTACTAR
        EN CASO DE DUDAS?
    </h3>
    <p>La presente Política contiene la información necesaria que debe conocer todo usuario de los
        Portales sobre el uso de las cookies que realiza GUÍA UNIVERSITARIA o los terceros que este
        contrate. GUÍA UNIVERSITARIA podrá modificar el presente documento en cualquier momento y sin
        previo aviso para mantenerlos vigentes y actualizados. Por lo anterior, recomendamos a los usuarios
        revisar la fecha de elaboración o actualización de estos, la cual se establece al final del presente
        documento. En caso de dudas o inquietudes acerca del uso de cookies o sobre cualquiera de los
        puntos detallados en esta Política, por favor escriba al siguiente correo electrónico:
        info@guiauniversitaria.net</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>