<div class="container py-4">
    <div class="row my-5 text-start g-5">
        <div class="col-lg-6 align-self-center">
            <div class="d-block gap-4 align-items-between text-center text-lg-start">

                <h3 class="featurette-heading display-4 fw-bold">¿Quieres ser el <span class="primaryColor">primero</span> en estar actualizado?
                </h3>
                <h5 class="lead">¿Deseas que te informemos vía email sobre oportunidades de becas, o información acerca de los programas que guardaste en la búsqueda?Así como las ofertas o descuentos que las universidades o instituciones ponen a tu disposición sobre las carreras seleccionadas
                </h5>
                <form>
                    <mat-form-field class=" w-100" appearance="outline">
                        <mat-label>Correo Electrónico</mat-label>
                        <input matInput [formControl]="email" placeholder="pat@example.com">
                        <mat-error *ngIf="email.invalid">Email invalido</mat-error>
                    </mat-form-field>
                    <div class="d-grid gap-2">
                        <button mat-raised-button color="primary" (click)="sendForm()" [disabled]="form.invalid">Suscribirse
                          <mat-icon class="ms-2">send</mat-icon>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-6">
            <img class="img-fluid" src="../../assets/svg/sent_email.svg" alt="">
        </div>
    </div>
</div>