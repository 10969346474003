import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CareerDetailComponent } from '../career-detail/career-detail.component';
import { Career } from '../models/career.interface';

@Injectable({
  providedIn: 'root'
})
export class DetailsService {

  private selectedCareer: Career | null = null;

  constructor(public dialog: MatDialog) { }

  getSelectedCareer() {
    return this.selectedCareer;
  }

  setSelectedCareer(career: Career | null) {
    this.selectedCareer = career;
  }

  openDialog(career: Career | null) {
    this.selectedCareer = career;
    let config = new MatDialogConfig();
    config = {
      maxWidth: '98vw',
      maxHeight: '100%',

      width: '98%',
    };
    const dialogRef = this.dialog.open(CareerDetailComponent, config);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
