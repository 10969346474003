import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router, Navigation } from '@angular/router';
import { ComparisonService } from './services/comparison.service';
import { UserService } from './services/user.service';
import { UserAccessGuard } from './guards/user-access.guard';
declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Guía Universitaria';
  // showFiller = false;

  opened = false;
  @ViewChild('mobileSideNav') matSidenav: MatSidenav | undefined;

  currentRoute: string | undefined;

  constructor(private userService: UserService, protected comparisonService: ComparisonService, protected userAccessGuard: UserAccessGuard,
    private router: Router) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Scroll to the top of the page
        gtag('config','UA-G-7R1XL1SB45',{'page_path' : event.url});
        fbq('track', 'PageView');
        var scrollElem = document.getElementById('#moveTop');
        if (scrollElem) scrollElem.scrollIntoView();
        window.scrollTo(0, 0);

        // console.log("Navigation End");
        // console.log(NavigationEnd);
        // console.log(event.urlAfterRedirects);
      }
    });
  }

  onOpen(value: boolean) {
    this.opened = value;
    if (value && this.matSidenav) {
      this.matSidenav.open();
    }
  }

  onClose() {
    this.opened = false;
  }

  onLogout(): void {
    this.userService.logout();
  }

  openDialog() {
    this.comparisonService.openDialog();
  }
}
