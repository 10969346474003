<div *ngIf="!isLoading" class="transaction__container">
  <h4 class="fw-bold">
    <span class="primaryColor">Resumen de pago</span>
  </h4>

  <div class="w-100">
    <ng-container *ngFor="let status of transactionStatuses">
      <div>
        <div class="status-transaction_container">
          <mat-icon class="icons" [ngClass]="status.iconClass">{{
            status.icon
          }}</mat-icon>
          <span class="status-message" [ngClass]="status.iconClass">{{
            status.message
          }}</span>
        </div>
        <p>
          Número de transacción:
          <span class="subtitleText">{{ transaction_id }}</span>
        </p>
      </div>
    </ng-container>
  </div>
  
  <div *ngIf="error" class="w-100">
    <mat-divider class="my-3"></mat-divider>
    <span  class="status-message rejected">
      Hubo un error en el pago: {{ error }}
    </span>
  </div>

  <mat-divider class="my-3"></mat-divider>

  <p class="info-text">
    <span class="subtitleText">Banco:</span> {{ issuer_name_by_id }}
  </p>
  <p class="info-text subtitleText">
    <span>Estado:</span>
    <span [ngClass]="status">&nbsp;{{ switchStatusName(status) }}</span>
  </p>

  <p class="info-text">
    <span class="subtitleText">Nombre del servicio:</span> {{ service_name }}
  </p>
  <p class="info-text"><span class="subtitleText">NIT:</span> {{ nit }}</p>
  <p class="info-text">
    <span class="subtitleText">Dirección:</span> {{ address }}
  </p>
  <p class="info-text"><span class="subtitleText">Ciudad:</span> {{ city }}</p>

  <mat-divider class="my-3"></mat-divider>

  <p class="info-text">
    <span class="subtitleText">Fecha y hora:</span> {{ date_time }}
  </p>
  <p class="info-text">
    <span class="subtitleText">Número de transacción:</span>
    {{ transaction_number }}
  </p>
  <p class="info-text"><span class="subtitleText">Email:</span> {{ email }}</p>
  <p class="info-text">
    <span class="subtitleText">Celular:</span> {{ phone }}
  </p>
  <p class="info-text">
    <span class="subtitleText">Número de identificación:</span>
    {{ identification_number }}
  </p>
  <p class="info-text">
    <span class="subtitleText">Descripción:</span> {{ description }}
  </p>
  <p class="info-text">
    <span class="subtitleText">Valor:</span> ${{ formatValue(value)
    }}{{ currency_id }}
  </p>

  <div class="actions-buttons">
    <!-- <div class="my-3">
      <button mat-raised-button color="primary" (click)="goBack()">
        Ir atrás
      </button>
    </div> -->
    <div class="my-3">
      <button
        mat-raised-button
        color="primary"
        (click)="goBack()"
      >
      Finalizar y regresar a Donaciones
      </button>
    </div>
  </div>
  <p class="email-text" >
   *** Se te enviará un correo con el resultado de la transacción ***
  </p>
</div>

<mat-spinner *ngIf="isLoading"></mat-spinner>
