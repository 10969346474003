<h2 mat-dialog-title>Subir foto de perfil</h2>
<mat-dialog-content class="mat-typography">
  <div class="container mt-5 text-center">
    <div class="col-12 my-4">
      <input type="file" (change)="onFileChange($event)" id="file-input2" accept="image/*" capture />
      <div [hidden]="!isMobile">
        <label class="primary" for="file-input2" class="d-flex justify-content-center">
          Tomar foto 
          &nbsp;
          <mat-icon>camera_alt</mat-icon>
        </label>
        <br>
      </div>
      <input type="file" (change)="onFileChange($event)" id="file-input" accept="image/*" />
      <label class="primary" for="file-input" class="d-flex justify-content-center">
        Seleccionar foto
        &nbsp;
        <mat-icon>photo</mat-icon>
      </label>
    </div>
    <!-- <div class="row row-cols-1 row-cols-lg-2"> -->
    <div *ngIf="imgChangeEvt" class="d-grid">
      <div class="col-12 col-lg-6 justify-content-center w-100">
        <h6>Vista previa</h6>
        <img class="rounded-circle" style="max-height: 150px;" [src]="cropImgPreview" />
      </div>
      <div class="col-12 col-lg-6 justify-content-center w-100">
        <h6>Recorta la foto</h6>
        <div>
          <image-cropper style="max-height: 250px;" [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true"
            [aspectRatio]="4/4" [resizeToWidth]="256" format="png" (imageCropped)="cropImg($event)"
            (imageLoaded)="imgLoad()" (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()">
          </image-cropper>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button color="primary" [mat-dialog-close]="cropImgPreview" cdkFocusInitial>Aceptar</button>
</mat-dialog-actions>