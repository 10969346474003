import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expandable-cell',
  templateUrl: './expandable-cell.component.html',
  styleUrls: ['./expandable-cell.component.scss']
})
export class ExpandableCellComponent implements OnInit {
  @Input() content: string = '';
  @Input() maxLines: number = 2;
  @Input() lineHeight: number = 1.2;
  @Input() fontSize: number = 16; // Nuevo Input para el tamaño de fuente principal
  @Input() buttonFontSize?: number; // Tamaño de fuente opcional para el botón

  isExpanded: boolean = false;
  shouldShowButton: boolean = false;

  ngOnInit() {
    this.checkContentHeight();
  }

  ngOnChanges() {
    // Recalcular cuando cambien las propiedades de entrada
    this.checkContentHeight();
  }

  private checkContentHeight() {
    const temp = document.createElement('div');
    temp.style.visibility = 'hidden';
    temp.style.position = 'absolute';
    temp.style.width = '100%';
    temp.style.fontSize = `${this.fontSize}px`;
    temp.style.lineHeight = `${this.lineHeight}em`;
    temp.innerText = this.content;
    document.body.appendChild(temp);

    // Calcula la altura máxima basada en el número de líneas y tamaño de fuente configurado
    const maxHeight = this.lineHeight * this.fontSize * this.maxLines;
    this.shouldShowButton = temp.offsetHeight > maxHeight;

    document.body.removeChild(temp);
  }

  get effectiveButtonFontSize(): number {
    return this.buttonFontSize ?? (this.fontSize * 0.85); // 85% del tamaño del texto principal por defecto
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
}