import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ComparadortestingComponent } from '../comparadortesting/comparadortesting.component';
import { Career } from '../models/career.interface';
// import { CARRERAS } from './mock-carreras';
import { UserService } from './user.service';
import { AnalyticsService } from './analytics.service';
import { Subject } from 'rxjs';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class ComparisonService implements OnDestroy {

  careers: Career[] = [];
  listIsSaved: boolean = false

  updateTriggered = new Subject<void>();

  getLength(): number {
    return this.careers.length;
  }


  user = JSON.parse(localStorage.getItem('user')!)

  constructor(public dialog: MatDialog,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private userProfileService: UserProfileService
  ) {
    // If not PROD then initiate with mock list
    // if (!environment.production) {
    //   localStorage.clear();
    //   if (this.getComparisonList().length == 0) {
    //     localStorage.setItem(
    //       environment.localComparisionListKey,
    //       JSON.stringify(CARRERAS)
    //     );
    //   }
    // }
  }


  triggerUpdate() {
    this.updateTriggered.next();
  }

  openDialog() {
    let config = new MatDialogConfig();
    config = {
      maxWidth: '98vw',
      maxHeight: '98vh',
      width: '98%'
    };
    const dialogRef = this.dialog.open(ComparadortestingComponent, config);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  closeDialog() {
    const dialogRef = this.dialog.closeAll()
  }

  getComparisonList(): Career[] {
    this.careers = JSON.parse(
      localStorage.getItem(environment.localComparisionListKey) ?? '[]');

    return this.careers;
  }

  clearComparisonList(): void {
    localStorage.removeItem(environment.localComparisionListKey);
  }

  clearComparisonGroupList(): void {
    localStorage.removeItem(environment.localSavedComparisonByName);
  }

  removeItemInComparisonList(objectID: string) {
    let index = this.careers.findIndex((c) => c.objectID == objectID);
    if (index !== -1) {
      const career = this.careers[index];

      this.careers.splice(index, 1);
      localStorage.setItem(
        environment.localComparisionListKey,
        JSON.stringify(this.careers));

      // Analytitcs
      this.analyticsService.logCustomEvent('compare_remove', {
        institute_id: career?.uidInstitution,
        institute_name: career?.institution,
        career_id: career.objectID,
        career_name: career?.name,
        faculty_id: career?.uidFaculty,
        user_id: this.userService.user ? this.userService.user.uid : 'ANON',
        user_email: this.userService.user ? this.userService.user.email : 'ANON',
        career_object: JSON.stringify(career ?? '{}'),
        user_object: JSON.stringify(this.userService.user ?? '{}'),
      });
    }
  }

  isOnCompareList(objectID: string): boolean {
    if (!objectID) return false;
    let index = this.careers.findIndex((c) => c && c.objectID == objectID);
    return (index !== -1);
  }

  addItemInComparisonList(career: Career) {
    let index = this.careers.findIndex((c) => c.objectID == career.objectID);
    const ttlInMS = this.user && this.user.proactiveRefresh.user && this.user.providerId
      ? Date.now() + (30 * 24 * 60 * 1000)
      : Date.now() + (20 * 60 * 1000);

    if (index == -1) {
      this.careers.push(career); // Add item to last position
      if (this.careers.length > environment.MAX_COUNT_COMPARASION_LIST) {
        this.careers.pop();
      }
      // const data ={
      //   list:this.careers,
      //   ttl: Date.now() + ttlInMS
      // }
      localStorage.setItem(
        environment.localComparisionListKey,
        JSON.stringify(this.careers)
      );
      localStorage.setItem(
        environment.ttlComparisonList, JSON.stringify(ttlInMS)
      )
      this.setListIsSaved(false)
    } else if (index > 0) {
      this.careers.splice(index, 1); // Remove from array
      this.careers.push(career); // Add item to first position
      localStorage.setItem(
        environment.localComparisionListKey,
        JSON.stringify(this.careers)
      );
      localStorage.setItem(
        environment.ttlComparisonList, JSON.stringify(ttlInMS)
      )
      this.setListIsSaved(false)
    }

    // Analytitcs
    this.analyticsService.logCustomEvent('compare_add', {
      institute_id: career?.uidInstitution,
      institute_name: career?.institution,
      career_id: career.objectID,
      career_name: career?.name,
      faculty_id: career?.uidFaculty,
      user_id: this.userService.user ? this.userService.user.uid : 'ANON',
      user_email: this.userService.user ? this.userService.user.email : 'ANON',
      career_object: JSON.stringify(career ?? '{}'),
      user_object: JSON.stringify(this.userService.user ?? '{}'),
    });
  }



  async addCurrentListInComparisonGroup(name: string) {
    const ttlInMS = Date.now() + (30 * 24 * 60 * 1000)

    const savedListsRaw = localStorage.getItem(environment.localSavedComparisonByName);
    const savedLists = savedListsRaw ? JSON.parse(savedListsRaw) : {};

    const currentListRaw = localStorage.getItem(environment.localComparisionListKey);
    const currentList = currentListRaw ? JSON.parse(currentListRaw) : [];

    if (Object.keys(savedLists).length >= 10) {
      // Remove the first element
      const firstKey = Object.keys(savedLists)[0];
      delete savedLists[firstKey];
    }

    savedLists[name] = currentList;
    // const days = 30
    // const ttlInMS = days * 24 *60 * 1000
    // const data = {
    //   lists:savedLists,
    //   ttl: Date.now() + ttlInMS
    // }
    // localStorage.removeItem(environment.localSavedComparisonByName)
    localStorage.setItem(environment.localSavedComparisonByName, JSON.stringify(savedLists));
    // localStorage.removeItem(environment.localComparisionListKey); // Clear current list

    localStorage.setItem(environment.ttlComparisonGroupList, JSON.stringify(ttlInMS))

    const uid = this.user.uid
    const comparisonRef = this.user.firestoreProfile.comparisonRef

    const addListInFirestore = await this.userProfileService.createOrUpdateComparisonList(savedLists, uid, comparisonRef)
    alert("Se ha guardado tu comparación exitosamente")

    if (typeof addListInFirestore === 'string') {

      const updateFirestoreProfile = {
        ...this.user.firestoreProfile,
        comparisonRef: `students/${this.user.uid}/comparison/${addListInFirestore}`
      }
      this.user.firestoreProfile = updateFirestoreProfile

      localStorage.setItem('user', JSON.stringify(this.user))

    }

  }

  setListIsSaved(isSaved: boolean) {
    this.listIsSaved = isSaved
  }

  getTtlComparisonList(): number {
    const ttlComparisonListStr = localStorage.getItem(environment.ttlComparisonList);
    const ttlComparisonList = ttlComparisonListStr ? JSON.parse(ttlComparisonListStr) : null;

    return typeof ttlComparisonList === 'number' ? ttlComparisonList : 0;
  }

  getTtlComparisonGroupList() {
    const ttlComparisonGroupListStr = localStorage.getItem(environment.ttlComparisonGroupList);
    const ttlComparisonGroupList = ttlComparisonGroupListStr ? JSON.parse(ttlComparisonGroupListStr) : null;

    return typeof ttlComparisonGroupList === 'number' ? ttlComparisonGroupList : 0;
  }

  clearTtlComparisonList(): void {
    localStorage.removeItem(environment.ttlComparisonList);

  }

  clearTtlComparisonGroupList(): void {
    localStorage.removeItem(environment.ttlComparisonGroupList);

  }

  ngOnDestroy() {
    localStorage.setItem(
      environment.localComparisionListKey,
      JSON.stringify(this.careers)
    );
  }
}
