import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit {

  public professorsUrl = environment.publicUrls.professorsUrl;

  constructor() { }

  ngOnInit(): void {
  }

}
