import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Donation, PaymentDetails } from '../models/payment.interface';

@Injectable({
  providedIn: 'root'
})
export class DonationService {
  private donationSource = new BehaviorSubject<Donation>({ donative: 0 });
  currentDonation = this.donationSource.asObservable();

  private paymentDetailsSource = new BehaviorSubject<PaymentDetails | null>(null);
  currentPaymentDetails = this.paymentDetailsSource.asObservable();

  updateDonation(donation: Donation) {
    this.donationSource.next(donation);
  }

  updatePaymentDetails(paymentDetails: PaymentDetails) {
    this.paymentDetailsSource.next(paymentDetails);
  }
  resetDonations() {
    this.donationSource.next({ donative: 0 }); 
    this.paymentDetailsSource.next(null); 
  }
}