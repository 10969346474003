<h2 mat-dialog-title>Política de privacidad</h2>
<mat-dialog-content class="mat-typography">
  GUÍA UNIVERSITARIA, con domicilio en Bogotá D.C., Colombia, en cumplimiento de
  la Ley 1581 de 2012, es responsable del tratamiento de los Datos Personales que recolecta y
  para ello cuenta con una Política de Tratamiento y Procedimientos de Datos Personales.
  <br>
  <br>
  Los mecanismos a través de los cuales hacemos uso de éstos son seguros y confidenciales,
  pues contamos con los medios tecnológicos idóneos para asegurar que sean almacenados de
  manera tal que se impida el acceso indeseado por parte de terceras personas y, en ese mismo
  orden, aseguramos la confidencialidad de estos.
  <br>
  <br>
  Los Datos Personales que GUÍA UNIVERSITARIA solicita serán utilizados para los
  siguientes fines: Sus Datos Personales serán incluidos en una o más bases de datos y podrán
  ser transmitidos y/o transferidos entre GUÍA UNIVERSITARIA, sus subordinadas
  vinculadas, su matriz o controlante, las subordinadas de su matriz o controlante, (en adelante
  "Las Entidades Autorizadas"), para que directamente o a través de terceros, traten los Datos
  Personales de acuerdo con los fines establecidos en el presente aviso. De igual forma, las
  bases de datos de GUÍA UNIVERSITARIA podrán incluir e integrar datos transmitidos y/o
  transferidos a ésta por las Entidades Autorizadas y/o por terceros. Lograr una eficiente
  comunicación con el Titular de la información, a través de cualquier medio de contacto,
  relacionada con nuestros productos, servicios, promociones, alianzas, estudios, concursos,
  eventos, contenidos, campañas publicitarias y de mercadeo, beneficios, condiciones o
  políticas aplicables, canales de atención, redes sociales, así como los de Las Entidades
  Autorizadas. Proveer nuestros servicios y/o productos. Informar sobre cambios de nuestros
  productos o servicios o respecto de los nuevos que estén relacionados con el o los
  contratado(s) o adquirido(s). Dar cumplimiento a las obligaciones contraídas con nuestros
  clientes, proveedores y empleados Evaluar la calidad del servicio. Entregar y ofrecer al
  Titular de forma general o segmentada, información, contenido y/o publicidad, de GUÍA
  UNIVERSITARIA, las Entidades Autorizadas y/o de terceros, y calificar la propensión y/o
  afinidad a productos o servicios de GUÍA UNIVERSITARIA y/o de Las Entidades
  Autorizadas y/o de terceros para realizar segmentaciones o perfilamiento de usuarios.
  Elaborar y reportar información estadística, encuestas de satisfacción, estudios y análisis de
  mercado o de consumo, incluyendo la posibilidad de contactarlo para dichos propósitos por
  parte de GUÍA UNIVERSITARIA y/o Las Entidades Autorizadas. La información podrá ser
  anonimizada con el fin de elaborar estadísticas, estudios y análisis de mercadeo o de consumo
  que podrán ser utilizados por las Entidades Autorizadas y/o terceros, quienes podrán disponer
  discrecionalmente de la totalidad de la información. Identificar, recolectar y asociar a los
  datos, información sobre preferencias de navegación del titular en los portales de GUÍA
  UNIVERSITARIA y/o de Las Entidades Autorizadas, así como datos de georreferenciación
  o y/o ubicación específica que generen dispositivos móviles, para mejorar la experiencia del
  usuario, conocer su perfil de navegación, entregar información y/o publicidad segmentada
  sobre productos y/o servicios propios o de terceros, calificar la propensión y/o afinidad a
  productos o servicios de GUÍA UNIVERSITARIA y/o de Las Entidades Autorizadas y/o de
  terceros. Para mayor detalle, ver Política de Datos de Navegación- "Cookies". Identificar,
  recolectar y asociar a los datos, información sobre sus hábitos de compra o intereses y
  compartirlos con Las Entidades Autorizadas y/o terceros, tales como: la asistencia a eventos
  públicos o privados de GUÍA UNIVERSITARIA y/o Las Entidades Autorizadas; la
  redención o uso de promociones a las cuales haya tenido acceso por ser cliente de GUÍA
  UNIVERSITARIA y/o Las Entidades Autorizadas; el uso de la tarjeta del Club Vivamos El
  Tiempo y/o similares de marca compartida; el uso de redes sociales, en donde siga o sea
  seguido, o haya generado una interacción con GUÍA UNIVERSITARIA y/o Las Entidades
  Autorizadas. Transmitirlos, dentro o fuera de Colombia (sin consideración al país de destino),
  a terceros que actúen como encargados del tratamiento y que provean a GUÍA
  UNIVERSITARIA y/o Las Entidades Autorizadas, servicios tecnológicos, logísticos,
  administrativos, de distribución, e-mail marketing, contact center y/o cualquier otro servicio
  que requiera GUÍA UNIVERSITARIA y/o Las Entidades Autorizadas, para el desarrollo de
  las actividades contempladas en las finalidades previstas en este Aviso de Privacidad y
  siempre sujeto a las Políticas de Tratamiento y Procedimiento de Datos Personales de GUÍA
  UNIVERSITARIA. En consecuencia, el titular entiende y acepta que mediante la presente
  autorización concede a los encargados del tratamiento, autorización para acceder a sus Datos
  Personales en la medida en que así lo requieran para la prestación de los servicios para los
  cuales fueron contratados. Transferirlos, dentro o fuera de Colombia (sin consideración al
  país de destino), a Las Entidades Autorizadas y/o terceros, para el desarrollo de actividades
  comerciales y las contempladas en las finalidades descritas en este Aviso de Privacidad.
  Transferirlos y/o transmitirlos dentro o fuera de Colombia (sin consideración al país de
  destino), a la(s) compañía que a futuro pueda(n) administrar y/o adquirir, total o
  parcialmente, a GUÍA UNIVERSITARIA y/o una de las Entidades Autorizadas o alguno de
  las unidades de negocios o activos de estas. Recolección y entrega de información ante
  autoridades públicas o privadas, nacionales o extranjeras con competencia sobre GUÍA
  UNIVERSITARIA y/o Las Entidades Autorizadas, o sobre sus actividades, productos y/o
  servicios, cuando se requiera para dar cumplimiento a sus deberes legales o reglamentarios,
  incluyendo dentro de éstos, aquellos referentes a la prevención de la evasión fiscal, lavado
  de activos y financiación del terrorismo u otros propósitos similares. Hacer perfilamientos de
  bases de datos o generar perfiles básicos y demográficos con los Datos Personales, así como
  completitud de datos de contacto mediante el cruce de bases de datos de GUÍA
  UNIVERSITARIA, Las Entidades Autorizadas y/o terceros tales como la Registraduría
  Nacional del Estado Civil y sus aliados tecnológicos certificados, operadores de información
  comercial o crediticia, entidades que formen parte del Sistema de Seguridad Social Integral,
  empresas prestadoras de servicios públicos, con miras a enriquecer las bases de datos de
  GUÍA UNIVERSITARIA y/o de las Entidades Autorizadas.
  <br><br>
  Usted podrá ejercer sus derechos a conocer, actualizar, rectificar sus Datos Personales, hacer
  consultas y reclamos, y demás derechos indicados en el artículo 8° de la ley 1581 de 2012 y
  las demás normas que lo complementen o sustituya, a través de estos medios:
  Correo electrónico: info@guiauniversitaria.net
  Teléfono: +57 319 5306884
  <br>
  <br>
  En relación con datos considerados como Sensibles, en los términos de la Ley 1581 de 2012,
  el Titular no está obligado a autorizar su Tratamiento. Respecto de Datos Personales de
  Niños, Niñas y Adolescentes, cuando se recolecten, se tratarán de acuerdo con lo establecido
  en el artículo 7 de la Ley 1581 de 2013 y el artículo 12 del Decreto 1377 de 2013, y según
  las finalidades establecidas en este Aviso de Privacidad en lo que puedan ser aplicables.
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>