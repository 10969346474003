import { Component } from '@angular/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {
  paymentMethod: string= ""
  constructor() {}

  ngOnInit(): void {
    this.paymentMethod = history.state.paymentMethod 
  }
}
