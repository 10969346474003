<div mat-dialog-content class="py-2 px-4 ">
    <div class="row d-flex m-0 mb-2" mat-dialog-title>
        <div class="col col-9 mt-2 header-dialog">
            <app-logo size="130px"></app-logo>
        </div>
        <div class="col d-flex justify-content-end col-3">
            <button class="shadow" mat-dialog-close mat-icon-button aria-label="dialog close">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="selected-content position-relative">
        <ng-container [ngSwitch]="selectedOption">
            <div *ngSwitchCase="'student'">
                <div class="mt-4">
                    <div class="section-title mb-4">
                        <h3 class="lh-1">Registrarse</h3>
                        <p class="opacity-75">Al registrarte podrás acceder a funcionalidades y
                            conocer más acerca de nuestra aplicación.</p>
                        <!-- <p class="opacity-75">Registrate y podrás comparar y guardar tús programas favoritos.
                            No esperes más y encuentra dónde vas a estudiar.</p> -->
                    </div>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6">
                            <div class="signup-form-wrapper">
                                <form [formGroup]="registerForm" class="example-form">
                                    <p class="opacity-75 mb-4">Registrarse con correo:</p>
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Ingrese su nombre completo</mat-label>
                                        <input matInput formControlName="displayName" required>
                                        <mat-error *ngIf="displayName.hasError('required')">
                                            Requerido!
                                        </mat-error>
                                    </mat-form-field> <br />
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Ingrese su correo electrónico</mat-label>
                                        <input matInput type="email" [formControl]="email" placeholder="pat@example.com"
                                            required>
                                        <mat-error *ngIf="email.invalid">Correo no valido</mat-error>
                                    </mat-form-field> <br />
                                    <mat-form-field class="w-100 h-100" appearance="outline">
                                        <mat-label>Ingrese su contraseña</mat-label>
                                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
                                            required>
                                        <button mat-icon-button matSuffix (click)="hide = !hide"
                                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="password.hasError('required')">
                                            La constraseña es requerida!
                                        </mat-error>
                                        <mat-error *ngIf="password.hasError('minlength')">
                                            Debe ser mínimo de 8 carácteres!
                                        </mat-error>
                                        <mat-error *ngIf="password.hasError('maxlength')">
                                            No debe ser mayor a 30 carácteres!
                                        </mat-error>
                                        <mat-error *ngIf="password.hasError('pattern')">
                                            Debe tener al menos una minúscula, una mayúscula y un número!
                                            <!-- y un caracter especial ($/.#)! -->
                                        </mat-error>
                                    </mat-form-field> <br />
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Confirme su contraseña</mat-label>
                                        <input matInput [type]="hide2 ? 'password' : 'text'"
                                            formControlName="confirmPassword" required>
                                        <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                            <mat-icon>{{ hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="confirmPassword.hasError('not_matching')">
                                            La constraseña no concide!
                                        </mat-error>
                                    </mat-form-field> <br />
                                    <p class="ms-2"><mat-checkbox formControlName="accept">
                                            He leído y acepto la política de privacidad</mat-checkbox></p>
                                    <div class="d-grid gap-2 justify-content-md-center">
                                        <button mat-raised-button color="primary"
                                            [disabled]="registerForm.invalid || !accept.value" class="py-2"
                                            (click)="onSubmit()"> Registrarse
                                        </button>
                                    </div>
                                    <div class="mt-4">
                                        ¿Ya tienes una cuenta? <a routerLink="/inicio-estudiantes"
                                            (click)="onNoClick()">Inicia sesión aquí!</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6 offset-xl-1 order-first order-lg-last">
                            <div class="signup-content-wrapper">
                                <!-- <div class="section-title mb-4">
                                    <h2 class="lh-1">Registrarse</h2>
                                    <p class="opacity-75">Al registrarte podrás acceder a funcionalidades y conocer mas acerca de
                                        nuestra aplicación</p>
                                </div> -->
                                <p class="opacity-75 mb-4">Registrarse con:</p>
                                <div class="d-flex gap-2 gap-sm-3">
                                    <div class="col-auto">
                                        <a class="align-content-center" mat-raised-button (click)="onLoginWithGoogle()">
                                            <mat-icon class="h-100 me-3 my-2" svgIcon="google"></mat-icon>Google</a>
                                    </div>
                                    <div class="col-auto">
                                        <a class="align-content-center" mat-raised-button (click)="onLoginWithApple()">
                                            <mat-icon class="h-100 me-3 my-2" svgIcon="apple"></mat-icon>Apple</a>
                                    </div>
                                    <!-- <div class="vr"></div> -->
                                    <!-- <div class="col-auto">
                                            <a mat-raised-button (click)="onLoginWithGoogle()">
                                                <mat-icon class="h-100 me-3 my-2" svgIcon="linkedin"></mat-icon>Linkedin</a>
                                        </div> -->
                                </div>
                            </div>
                            <br />
                            <!-- <div class="d-lg-none">
                                <hr>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="'professor'">
                <div class="p-md-2 mt-4">
                    <h3 class="lh-1">¿Eres un profesor?</h3>
                    <p class="opacity-75">Eres un profesor o quieres serlo, ingresa tus datos para tener visibilidad en
                        una Universidad o Institución Educativa.</p>
                    <p class="opacity-75">Inicia sesión o registrate aquí:</p>
                    <a [href]="professorsUrl" target="_blank">
                        <button class="" mat-stroked-button (click)="onNoClick()">Ir a Profesores</button>
                    </a>
                </div>
            </div>
            <div *ngSwitchCase="'institute'">
                <div class="p-md-2 mt-4">
                    <h3 class="lh-1">¿Representas a una Universidad o Institución Educativa?</h3>
                    <p class="opacity-75">Quieres ser parte de las ofertas educativas a mostrar, o quieres actualizar
                        los
                        datos
                        de tu Institución. Si también estás buscando profesores de planta o cátedra.</p>
                    <p class="opacity-75">Inicia sesión o registrate aquí:</p>
                    <a [href]="institutesUrl" target="_blank">
                        <button class="" mat-stroked-button>Ir a Instituciones</button>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>