<div *ngIf="!user" class="p-4 position-relative">
    <mat-icon class="position-absolute top-0 end-0"  style="cursor: pointer;" (click)="onNoClick()">close</mat-icon>
    <div mat-dialog-content class="d-flex justify-content-center flex-column" >
      <p>Para guardar o borrar tus carreras favoritas debes iniciar sesión</p>
      <a routerLink="/login" class="w-100"><button mat-flat-button color="primary" class="gap-0 me-1 my-3 w-100" (click)="onNoClick()">Ingresar</button></a>
      <a routerLink="/register" class="w-100"><button mat-flat-button color="primary" class="gap-0 me-1 my-3 w-100" (click)="onNoClick()">Registrarse</button></a>
    </div>
    <div mat-dialog-actions class="w-100 justify-content-end">
      <button  mat-raised-button  (click)="onNoClick()">Cancelar</button>
    </div>
  </div>
  
  