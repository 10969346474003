<div class="container px-4 mt-5 mb-4 justify-content-center d-flex">
    <mat-card class="col-12 col-lg-8" >
        <div class="d-grid gap-2 gap-lg-5 d-lg-flex justify-content-center align-items-center">
            <h5 class="lh-sm fw-normal text-center justify-content-center m-0">
                ¿Todavía no te decides? Descúbrelo con nuestro <span class="primaryColor fw-bold">&nbsp;test vocacional.</span> 
            </h5>
            <div class="d-flex justify-content-center">
                <button  mat-raised-button color="primary" routerLink="/test-vocacional" style="max-width: 150px;">¡Vamos!</button>
            </div>
        </div>
    </mat-card>
</div>
