export const environment = {
  firebase: {
    projectId: 'guia-universitaria-dev',
    appId: '1:890220225500:web:d314b1328476ef499f2519',
    storageBucket: 'guia-universitaria-dev.appspot.com',
    apiKey: 'AIzaSyAMlSyVy3xFdWwdi1QzJ0-WHOuAUzs6JPk',
    authDomain: 'guia-universitaria-dev.firebaseapp.com',
    messagingSenderId: '890220225500',
    measurementId: 'G-B16LQSRMYK',
  },
  production: true,
  localComparisionListKey: 'localComparisonList',
  localSavedComparisonByName: 'localSavedComparisonByName',
  localFavoriteList: 'localFavoriteList',
  ttlComparisonList: 'ttlComparisonList',
  ttlComparisonGroupList: 'ttlComparisonGroupList',
  currentComparatorName: "currentComparatorName",
  MAX_COUNT_COMPARASION_LIST: 6,
  Algolia: {
    ApplicationID: '6JBZ6JVGP8',
    APIKey: 'd62690bc09f3b41c1428912f910e20ac',
    IndexName: 'carreras'
  },
  Algolia_v2: {
    ApplicationID: 'DZ8EB54INF',
    APIKey: 'b1ad56c5206d428b25839296ea9cb387',
    IndexName: 'careers',
    QuerySuggestions: 'careers_query_suggestions'
  },
  LinkedinProviderId: 'YYYYY',
  AppleProviderId: "apple.com",
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyAMlSyVy3xFdWwdi1QzJ0-WHOuAUzs6JPk",
    authDomain: "guia-universitaria-dev.firebaseapp.com",
    projectId: "guia-universitaria-dev",
    storageBucket: "guia-universitaria-dev.appspot.com",
    messagingSenderId: "890220225500",
    appId: "1:890220225500:web:d314b1328476ef499f2519",
    measurementId: "G-B16LQSRMYK"
  },
  // For MercadoPago JS SDK
  MERCADO_PAGO_PUBLIC_KEY: "APP_USR-d51272f2-a1bd-4e27-a200-eecd82e0d1f9",
  MERCADO_PAGO_ACCESS_TOKEN: "APP_USR-8955575924467114-101510-e645af717ec3a115499a4c0d079cb58d-1996500604",
  MERCADO_PAGO_BASE_URL_API: "https://api.mercadopago.com/v1",
  MERCADO_PAGO_GET_ISSUERS_API: "/payment_methods/card_issuers?payment_method_id=",
  MERCADO_PAGO_GET_PAYMENT_BY_ID: "/payments/${id}",
  GCLOUD_TOKEN: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjczZTI1Zjk3ODkxMTljNzg3NWQ1ODA4N2E3OGFjMjNmNWVmMmVkYTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIzMjU1NTk0MDU1OS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsImF1ZCI6IjMyNTU1OTQwNTU5LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTExNDcwNDk4ODU5MzQ3MTI3NTI1IiwiZW1haWwiOiJzZXJnaW9hcmV2YWxvMzAxQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoia2hXY1RFOFNEUXdVMkdPRWxBRkNRQSIsImlhdCI6MTcyODk0MTg0MiwiZXhwIjoxNzI4OTQ1NDQyfQ.j0TY6eN6r4emlO5KJB9DbWoqZvdr-HeZkRiGTlfS-wfsqwyuf70s3Awc_wLD0j9lU6R7NP9tk2kHdfp86rBHIkvCtUHtXhk1SrEXQP8s6pDNqe0RfFBY2-rlZ4dpX8K4WfSpuTKqZQ93UBk_lDXNyi4U6unGD5aEEMxgEmhUY5O1GU-sBvK3UKO_Q--dnp_XR9cTkiLWsSpOh3KsxVCTIk8x5zM_g5I14eLmQ9IwV0jQTllCYfk_hlfURCDkf_apRvlGuQjb-W3iqaOfRR6sKVG8zXqvvITvk4DbstlmKVBwtcq2FCvMHfCXTYH7Oy2wY-uJ6X8X1RIHqdzn7-QAQA",
  //------------------------------
  publicUrls: {
    defaultProfilePhoto: "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Flogo.jpg?alt=media&token=00448418-5d25-4033-84a3-648136db3bc3",
    professorsUrl: "https://profesor.guiauniversitaria.net/",
    institutesUrl: "https://institucion.guiauniversitaria.net/",
    defaultLogo: "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Flogo.jpg?alt=media&token=00448418-5d25-4033-84a3-648136db3bc3",
    defaultImage: "https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2Fdefault%2Fimagen.jpg?alt=media&token=a8a9fa5f-c879-426b-939a-6dc80f80c11a"
  },
  counters: {
    programs: 12680,
    professors: 1127,
    students: 3998
  },
  contactEmails: [
    "info@guiauniversitaria.net",
    "app.guiauniversitaria@gmail.com",
    "gomygped@gmail.com",
    // "sergio-arevalo_1@hotmail.com"
  ],
  supportEmail: "soporte@guiauniversitaria.net",
  salesEmail: "ventas@guiauniversitaria.net",
  secretEmail: "secret",
  whatsappnumber: "573003621500",
  whatsappmessage: "Hola, quiero más información.",
};
