<div class="container bgt bg-light rounded-4 py-2 my-2">
    <h3>¿Cómo deseas iniciar tu registro?</h3>
    <div class="container-cards">
            <div class="card-profile"
                 [ngClass]="{'selected-card': selectedOption === 'student'}"
                 (click)="selectOption('student')"
                 >
              <h4>Estudiante</h4>
              <img 
                   class="" slot="start"
                    referrerpolicy="no-referrer"
                   src="../../../assets/svg/student_image.svg"
                   alt="student_image">
              <button class="" mat-raised-button color="primary">Registrarse</button>
            </div>
          
            <div class="card-profile"
                 [ngClass]="{'selected-card': selectedOption === 'professor'}"
                 (click)="selectOption('professor')"
                 >
                 
              <h4>Profesor</h4>
              <img 
                   class="" slot="start"
                    referrerpolicy="no-referrer"
                   src="../../../assets/svg/professor_image.svg"
                   alt="professor_image">
              <button class="" mat-raised-button color="primary">Registrarse</button>
            </div>
          
            <div class="card-profile"
                 [ngClass]="{'selected-card': selectedOption === 'institute'}"
                 (click)="selectOption('institute')"
                 >
              <h4>Institución</h4>
              <img 
                   class="" slot="start"
                    referrerpolicy="no-referrer"
                   src="../../../assets/svg/institute_image.svg"
                   alt="institute_image">
              <button class="" mat-raised-button color="primary">Registrarse</button>
            </div>
      
      </div>
</div>

