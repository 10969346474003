import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

interface Data {
  unansweredQuestions: number[]
  setShowResults:  ()  =>  void
  setScroll:  ()  =>  void
}

@Component({
  selector: 'app-dialog-continue-results',
  templateUrl: './dialog-continue-results.component.html',
  styleUrls: ['./dialog-continue-results.component.scss']
})

export class DialogContinueResultsComponent {
  constructor( public dialogRef: MatDialogRef<DialogContinueResultsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data) { }
    
    onNoClick(): void {
      this.dialogRef.close();
    }
    
    onGetResults():void{
      this.dialogRef.close();
      this.data.setShowResults()
      this.data.setScroll()
    }
}
