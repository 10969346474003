<div class="container donation-container">
  <div class="donation-content">
    <div class="donation-left-side">
      <h6>Estás apoyando a:</h6>
      <h4 class="fw-bold">
        <span class="primaryColor">Fundación Guía Universitaria</span>
      </h4>
      <div class="d-flex justify-content-center align-items-center">
        <app-logo size="200px"></app-logo>
      </div>
      <h3 class="fw-bold">
        <span class="primaryColor">${{ formatValue(donative) }} COP</span>
      </h3>
      <p class="opacity-75 text-justify">
        Estos donativos nos permitirán seguir dando a conocer todos los
        servicios y beneficios que las instituciones educativas de alta calidad
        en Colombia tienen para los futuros estudiantes y así permitir un
        crecimiento en el desarrollo y conocimiento generado en el país
      </p>
    </div>
    <mat-divider vertical />

    <div class="donation-right-side">
      <router-outlet></router-outlet>
    </div>


  </div>
</div>
