import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  formatToMinimalistCurrency(price: number): string {
    var millions: number = Math.round(price / 1000000);
    if (millions < 1) {
      var kilos: number = Math.round(price / 1000);
      return `${kilos} m`;
    }
    return `${millions} MM`;
  }

  formatPeriods(periods: string): string {
    const _periods = periods.toLowerCase().trim();
    switch (_periods) {
      case "semestral": {
        return 'semestres';
      }
      case "anual": {
        return 'años';
      }
      default: {
        return _periods;
      }
    }
  }

  formatLocation(city: string, region: string, country: string) {
    return `${city}, ${region}, ${country}`;
  }

  base64ToFile(base64: string, fileName: string): File {
    const blob = this.dataURItoBlob(base64);
    return new File([blob], fileName, { type: 'image/png' });
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  getMatChipStyle(index: number): string {
    const primary = '#3f51b5';
    const accent = ' #ff4081';

    // console.log("getMatChipColor: " + index);
    switch (index) {
      case 0: {
        return `background-color: ${accent};color: white;`;
      }
      case 1: {
        return `background-color: ${primary};color: white;`;
      }
      case 2: {
        return `background-color: ${primary};color: white;`;
      }
      default: {
        return '';
      }
    }
  }
}
