import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringValidationService {

  constructor() { }

  public noRepeatedDigitsValidator(value: string): boolean {
    const pattern = /^(?!.*(\d)\1{3}).*$/;
    return pattern.test(value);
  }

  public onlyNumbersValidator(value: string): boolean {
    const pattern = /^\d+$/;
    return pattern.test(value);
  }

  public lenghtValidator(value: string, maxLength: number, minLength: number): boolean {
    const sValue = (value ?? '').trim();

    if (minLength > -1 && sValue.length < minLength) {
      return false;
    }

    if (maxLength > -1 && sValue.length > maxLength) {
      return false;
    }
    return true;
  }

  validateEmail(value: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(value);
  }
}