import { Injectable } from '@angular/core';

import { Firestore, doc, DocumentReference, getDoc, setDoc, updateDoc, Timestamp, deleteDoc } from "@angular/fire/firestore";
import { Storage, ref as StorageRef, getDownloadURL, uploadBytes, } from "@angular/fire/storage";

import { Router } from '@angular/router';
import { Profile } from '../models/profile';
import { UserService } from './user.service';
import { Career } from '../models/career.interface';
import { addDoc, arrayUnion, collection, serverTimestamp } from 'firebase/firestore';


@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  userPath: string = 'students'
  doc?: DocumentReference;
  constructor(public firestore: Firestore, private storage: Storage, private router: Router,
    private userService: UserService) {
  }
  // user: { displayName: string, email: string, emailVerified: boolean, photoURL: string } = JSON.parse(localStorage.getItem('user')!);

  async getUserProfile(userId: string): Promise<Profile | undefined> {
    try {
      const docRef = doc(this.firestore, `${this.userPath}/${userId}`);
      // return docData(docRef, { idField: 'id' }) as Observable<Profile>;
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        var profile: Profile = docSnap.data() as Profile;
        if (profile.personalData?.birthDate) {
          const date: Timestamp = (profile.personalData.birthDate as object) as Timestamp;
          profile.personalData.birthDate = date.toDate();
        }
        if (profile.experience) {
          profile.experience.forEach(item => {
            const start: Timestamp = (item.startDate as object) as Timestamp;
            const end: Timestamp | undefined = (item.endDate as object) as Timestamp;
            item.startDate = start.toDate();
            // item.startDate = new Date(item.startDate.getFullYear(), item.startDate.getMonth(), item.startDate.getDay());
            if (item.endDate) item.endDate = end.toDate();
          });
        }
        if (profile.education) {
          profile.education.forEach(item => {
            const start: Timestamp = (item.startDate as object) as Timestamp;
            const end: Timestamp | undefined = (item.endDate as object) as Timestamp;
            item.startDate = start.toDate();
            // item.startDate = new Date(item.startDate.getFullYear(), item.startDate.getMonth(), item.startDate.getDay());
            if (item.endDate) item.endDate = end.toDate();
          });
        }
        return profile;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return undefined;
      }
      //(docRef, { idField: 'id' }) as Observable<Profile>;
    } catch (error) {
      console.log(`error in UserProfileService.getUserProfile(${userId})`);
      console.log(error);
      throw error;
    }
  }

  async saveUserProfile(profile: Profile): Promise<boolean> {
    const userId: string | undefined = profile.id;
    if (!userId) return Promise.resolve(false);
    try {
      const docRef = doc(this.firestore, `${this.userPath}/${userId}`);
      let _profile: Profile = structuredClone(profile);

      this.removeUndefinedFields(_profile);
      await setDoc(docRef, _profile).then(() => {
        console.log('Save User Profile ended');
      });
      return Promise.resolve(true);
    } catch (error) {
      console.log(`error in UserProfileService.getUserProfile(${userId})`);
      console.log(error);
      throw error;
    }
  }

  removeUndefinedFields(_profile: Profile) {
    if (!_profile.personalData) delete _profile.personalData;
    else {
      if (!_profile.personalData.birthDate) delete _profile.personalData.birthDate;
      if (!_profile.personalData.gender) delete _profile.personalData.gender;
      if (!_profile.personalData.phone) delete _profile.personalData.phone;
      if (!_profile.personalData.nationality) delete _profile.personalData.nationality;
      if (!_profile.personalData.contactEmail) delete _profile.personalData.contactEmail;
      if (!_profile.personalData.educationLevel) delete _profile.personalData.educationLevel;
    }
    delete _profile.id;
  }

  async updateUserProfile(profile: Profile, profile_photo: File | null = null): Promise<boolean> {
    const userId: string | undefined = profile.id;
    if (!userId) return Promise.resolve(false);
    try {
      // Upload profile_photo file
      if (profile_photo) {
        // await uploadUserFile(userId, profile_photo);
        const photoFilePath: string = '/files/students/profiles/' + userId + '/ ' + profile_photo.name; //+ '_' + (new Date().getTime());
        const storageRef = StorageRef(this.storage, photoFilePath);

        await uploadBytes(storageRef, profile_photo, { contentType: profile_photo.type }).then(
          async (snapshot) => {
            await getDownloadURL(snapshot.ref).then((url) => {
              console.log("profile_photo url -------");
              console.log(url);
              profile.photoUrl = url;
              console.log("serviceee", this.userService)
              console.log("photo setted", profile.photoUrl)
              this.userService.setProfilePhoto(url);

            });
          });
      }
      const docRef = doc(this.firestore, `${this.userPath}/${userId}`);
      let _profile = { ...profile };

      // delete _profile.id;
      this.removeUndefinedFields(_profile);

      await updateDoc(docRef, _profile).then(() => {
        console.log('Save User Profile ended');
        try {
          this.userService.updateBasicProfile(_profile.fullName, _profile.photoUrl);
          console.log('Auth user Profile updated');
          console.log('Displayname:', _profile.fullName);
          console.log('PhotoURL:', _profile.photoUrl);

        } catch (error) {
          console.error(error);
        }

      });
      return Promise.resolve(true);
    } catch (error) {
      console.log(`error in UserProfileService.updateUserProfile(${userId})`);
      console.log(error);
      throw error;
    }
  }

  async createOrUpdateComparisonList(list: Career[], studentId: string, comparisonRef?: string): Promise<boolean | string> {
    const collectionRef = collection(this.firestore, this.userPath)
    const docStudentRef = doc(collectionRef, studentId)

    if (docStudentRef === null) {
      console.error(`Error to capture student´s collection with id: ${studentId}`)
      return Promise.resolve(false)
    }

    try {

      if (!comparisonRef) {
        const collectionNameComparison = `${this.userPath}/${studentId}/comparison`
        const collectionRefComparison = collection(this.firestore, collectionNameComparison)

        const docData = await addDoc(collectionRefComparison, {
          "list": list,
          createdAt: serverTimestamp()
        })

        const updateData = {
          "comparisonRef": `${collectionNameComparison}/${docData.id}`
        }

        await updateDoc(docStudentRef, updateData)

        return docData.id

      } else {
        const docRefComparison = doc(this.firestore, comparisonRef)
        const updateData = {
          "list": list,
          updatedAt: serverTimestamp()
        }
        updateDoc(docRefComparison, updateData)
        Promise.resolve(true)
      }
      return Promise.resolve(true)
    } catch (error) {
      console.error(`Error in UserProfileService.createOrUpdateComparisonList: ${error}`)
      throw error;
    }
  }

  async subscribeToNewsletter(email: string) {
    try {
      const collectionRef = collection(this.firestore, "newsletter")
      const docData = await addDoc(collectionRef, {
        "email": email,
        createdAt: serverTimestamp()
      })
    } catch (error) {
      console.error("Error in subscribeToNewletter", error)
    }

  }

  async sendTestimonial(testimonial: string, name: string | undefined, age: string | undefined) {
    try {
      const _name = name && name.split(" ")[0]
      const collectionRef = collection(this.firestore, "testimonials")
      await addDoc(collectionRef, {
        "testimonial": testimonial,
        "name": _name,
        "age": age
      })
    } catch (error) {
      console.error("Error in sendTestimonial", error)
    }
  }

  async updateUserVocationalId(userId: string, vocationalTestId: string, resultsShowed: boolean): Promise<boolean> {
    const docRef = doc(this.firestore, this.userPath, userId)

    if (docRef == null) {
      console.error(`Error updating: students/${userId}`);
      console.error(" Doesn't find student ");
      return false;
    }

    try {
      const updateData = {
        vocationalTestIds: arrayUnion(vocationalTestId),
        vocationalResultsShowed: resultsShowed,
        updatedAt: serverTimestamp(),
      };
      await updateDoc(docRef, updateData);
      return true;

    } catch (error) {
      console.error(`Error updating: students/${userId}`);
      console.error(`updating vocational test id: ${vocationalTestId}`);
      console.error(error);
      return false;
    }
  }

  async deleteUserProfile(userId: string) {
    const collectionRef = doc(this.firestore, "students", userId);
    try {
      await deleteDoc(collectionRef);
      console.log("Document deleted in firestore successfuly")
      return true;

    } catch (error) {
      console.log(`error in UserProfileService.deleteUserProfile(${userId})`);
      console.log(error);
      throw error;
    }
  }
}