export  const form = {
    id: 'form-checkout',
    cardholderName: {
      id: 'form-checkout__cardholderName',
    }, 
    cardholderEmail: {
      id: 'form-checkout__cardholderEmail',
    },
    cardNumber: {
      id: 'form-checkout__cardNumber',
      placeholder: 'Número de la tarjeta',
      style: {
        fontSize: '1rem',
      },
    },
    expirationDate: {
      id: 'form-checkout__expirationDate',
      placeholder: 'mes/año',
    },
    securityCode: {
      id: 'form-checkout__securityCode',
      placeholder: 'CVC',
    },
    installments: {
      id: 'form-checkout__installments',
      placeholder: 'Cuotas',
    },
    identificationType: {
      id: 'form-checkout__identificationType',
    },
    identificationNumber: {
      id: 'form-checkout__identificationNumber',
    },
    issuer: {
      id: 'form-checkout__issuer',
    },
  };
