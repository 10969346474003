import { AfterContentInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ComparisonService } from '../services/comparison.service';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';
import { UserAccessGuard } from '../guards/user-access.guard';
import { FavoriteService } from '../services/favorites.service';

@Component({
  selector: 'app-navbar-bs',
  templateUrl: './navbar-bs.component.html',
  styleUrls: ['./navbar-bs.component.scss']
})
export class NavbarBsComponent implements OnInit, AfterContentInit {

  constructor(protected comparisonService: ComparisonService, public userService: UserService, protected favoriteService:FavoriteService,
    private router: Router, public userAccessGuard: UserAccessGuard,
    private activatedRoute: ActivatedRoute) {
    this.comparisonService.getComparisonList();
    this.favoriteService.getFavoriteList();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        console.log({ event });
        console.log(this.router.url);
        console.log(window.location.pathname);
      }
    });
    // this.profilePhotoUrl = this.userService.PROFILE_PHOTO_URL ?? environment.publicUrls.defaultProfilePhoto;
  }

  @Output() opened = new EventEmitter<boolean>();
  didOpen = false;
  open() {
    this.opened.emit(true);
  }

  // profilePhotoUrl: string | null = null;

  currentUrl: string = "";
  ngOnInit(): void {
  }

  DefaultProfilePhoto: string = environment.publicUrls.defaultProfilePhoto;

  ngAfterContentInit(): void {
  }

  openDialog() {
    this.comparisonService.openDialog();
  }

  onLogout(): void {
    this.userService.logout();
  }

  // onClickSearchCareer(){
  //   const filters = sessionStorage.getItem("URLFilters")
  //   if(filters){
  //     this.router.navigate([`/buscador/${filters}`])
  //   }else{
  //     this.router.navigate([`/buscador/`])
  //   }
  // }

  async onClickLogout() {
    try {
      console.log('navbar.onClickLogout');
      await this.userService.logout();
      this.comparisonService.careers = [];
      this.favoriteService.favorites = [];
      this.router.navigate(['/inicio']);
    } catch (error) {
      console.log(error);
    }
    /* this.userService.logout()
      .then(() => {
        this.router.navigate(['/register']);
      })
      .catch(error => console.log(error)); */
  }
}
