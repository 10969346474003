<div class="container bgt bg-light rounded-4 py-2 my-2">
  <h3>¿Cómo deseas ingresar?</h3>
  <div class="container-cards">
    <div
      class="card-profile col-md-4"
      [ngClass]="{ 'selected-card': selectedOption === 'student' }"
      (click)="selectOption('student')"
    >
      <div>
        <h4 class="text-center">Estudiante</h4>
        <div class="d-flex justify-content-center align-items-center w-100">
          <img
            slot="start"
            referrerpolicy="no-referrer"
            src="../../../assets/svg/student_image.svg"
            alt="student_image"
          />
        </div>
        <p class="opacity-75">
          Regístrate como estudiante y accede a un mundo de oportunidades:
        </p>
        <ul class="opacity-75">
          <li>Guarda y comparte comparaciones de carreras.</li>
          <li>Recibe información actualizada sobre becas.</li>
          <li>Accede a calendarios de inscripción a universidades.</li>
          <li>Disfruta de asesoría gratuita de orientación vocacional.</li>
          <li>Aprovecha ofertas y descuentos en matrículas.</li>
          <li>Participa en convocatorias para recibir apoyo financiero.</li>
          <li>Y mucho más.</li>
        </ul>
        <p class="opacity-75">
          No dejes pasar esta oportunidad de asegurar tu futuro académico.
          ¡Regístrate hoy mismo y empieza a planear tu camino hacia el éxito!
        </p>
      </div>
      <button class="" mat-raised-button color="primary">Ingresar</button>
    </div>
    <div
      class="card-profile col-md-4"
      [ngClass]="{ 'selected-card': selectedOption === 'professor' }"
      (click)="goToLink(professorsUrl)"
    >
      <div>
        <h4 class="text-center">Profesor</h4>
        <div class="d-flex justify-content-center align-items-center w-100">
          <img
            slot="start"
            referrerpolicy="no-referrer"
            src="../../../assets/svg/professor_image.svg"
            alt="professor_image"
          />
        </div>
        <p class="opacity-75">
          Regístrate como profesor y abre las puertas a nuevas oportunidades
          académicas. Al completar tu perfil, podrás ser contactado por
          universidades dentro y fuera de Colombia para dictar clases como
          Profesor de Cátedra o de Planta.
        </p>
        <p class="opacity-75">
          ¿Qué esperas para estar en el radar de las universidades? No dejes
          pasar esta oportunidad de expandir tu carrera, sube tu CV y comparte
          tus conocimientos con estudiantes de todo el mundo.
        </p>
        <p class="opacity-75">
          Completa tu perfil hoy mismo y da el siguiente paso en tu carrera
          docente.
        </p>
      </div>
      <button class="" mat-raised-button color="primary">Ingresar</button>
    </div>
    <div
      class="card-profile col-md-4"
      [ngClass]="{ 'selected-card': selectedOption === 'institute' }"
      (click)="goToLink(institutesUrl)"
    >
      <div>
        <h4 class="text-center">Institución</h4>
        <div class="d-flex justify-content-center align-items-center w-100">
          <img
            class="d-flex justify-content-center align-items-center w-100"
            slot="start"
            referrerpolicy="no-referrer"
            src="../../../assets/svg/institute_image.svg"
            alt="institute_image"
          />
        </div>
        <p class="opacity-75">
          ¡Atención Directores de Área, Programas y vicerrectores
          Universitarios!
        </p>
        <p class="opacity-75">
          ¿Deseas mejorar la visualización de tu oferta académica, optimizar la
          captación de nuevos estudiantes y potenciar tus programas de
          especialización y maestría? ¿Quieres saber cuál es la carrera más
          buscada y con quién te comparan?
        </p>
        <p class="opacity-75">¡Tenemos la solución!</p>
        <ul class="opacity-75">
          Regístrate y obtén la versión free de nuestra plataforma. Aprovecha
          esta oportunidad para:
          <li>Mejorar la visibilidad de tus programas académicos.</li>
          <li>Optimizar la captación de más estudiantes a tu institución.</li>
          <li>Potenciar tus programas de especialización y maestría.</li>
          <li>Analítica de datos.</li>
          <li>
            Conocer las tendencias de búsqueda y comparaciones de carreras.
          </li>
        </ul>
        <p class="opacity-75">
          No dejes pasar esta oportunidad de llevar tu universidad al siguiente
          nivel. ¡Regístrate hoy mismo y comienza a disfrutar de estos
          beneficios!
        </p>
      </div>
      <button class="" mat-raised-button color="primary">Ingresar</button>
    </div>
  </div>
</div>
