<mat-form-field appearance="outline" class="w-100">
    <mat-label>Busca una carrera</mat-label>
    <input matInput #searhinput [placeholder]="'Ej: ' + careerSample" (keyup)="handleChange($event)"
        [formControl]="queryCtrl" [matAutocomplete]="auto" (input)="onInputChange($event)" [required]="isMandatory">
    <!-- (keydown.enter)="onEnterKey($event)" -->
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
        <div *ngIf="searhinput.value.trim().length > MINLENGHT">
            <div *ngFor="let index of state.indices || []">
                <mat-option *ngFor="let option of index.hits" [value]="option['query']"
                    (click)="onQuerySuggestionClick.emit({ query: option['query'] })">
                    {{ option['query'] }}
                </mat-option>
            </div>
        </div>
    </mat-autocomplete>
    <mat-icon matPrefix>search</mat-icon>
    <button mat-icon-button *ngIf="searhinput.value" matSuffix (click)="clearValue();onInputChange($event)">
        <mat-icon matSuffix>clear</mat-icon>
    </button>
</mat-form-field>

<!-- 
<div>
    <input matInput [placeholder]="'Ej: ' + careerSample" [matAutocomplete]="auto" (keyup)="handleChange($event)"
        style="width: 100%; padding: 10px" />
    <mat-autocomplete #auto="matAutocomplete">
        <div *ngFor="let index of state.indices || []">
            <mat-option *ngFor="let option of index.hits" [value]="option['name']"
                (click)="onQuerySuggestionClick.emit({ query: option['name'] })">
                {{ option['name'] }}
            </mat-option>
        </div>
    </mat-autocomplete>
</div> -->