export const CITIES = [
    "Bogotá, D.C.",
    "Medellín",
    "Cali",
    "Barranquilla",
    "Bucaramanga",
    "Cartagena de Indias",
    "Manizales",
    "Tunja",
    "Popayán",
    "Pereira",
    "Pasto",
    "Montería",
    "Santa Marta",
    "Chía",
    "Ibagué",
    "San José de Cúcuta",
    "Armenia",
    "Neiva",
    "Puerto Colombia",
    "Pamplona",
    "Sincelejo",
    "Envigado",
    "Villavicencio",
    "Quibdó",
    "Valledupar",
    "Rionegro",
    "Riohacha",
    "Bello",
    "Florencia",
    "Palmira",
    "Barrancabermeja",
    "Roldanillo",
    "Sabaneta",
    "Espinal",
    "Santa Rosa de Osos",
    "Pensilvania",
    "Yopal",
    "Fusagasugá",
    "San Gil",
    "Tuluá",
    "Ocaña",
    "Sogamoso",
    "Ciénaga",
    "Duitama",
    "Mocoa",
    "Caldas",
    "Santa Rosa de Cabal",
    "Girardot",
    "Rivera",
    "Socorro",
    "Cartago",
    "Guadalajara de Buga",
    "Madrid",
    "San Juan del Cesar",
    "San Andrés",
    "Aguachica",
    "Apartadó",
    "Chiquinquirá",
    "La Paz",
    "Buenaventura",
    "Marinilla",
    "Nilo",
    "Honda",
    "Leticia",
    "Arauca",
    "Cota",
    "Coveñas",
    "Caucasia",
    "El Carmen de Viboral",
    "Santa Fé de Antioquia",
    "Turbo",
    "Villa de San Diego de Ubaté"
]