<div class="container">
    <br />
    <div class="">
        <h1>Resultados ESAL DIAN 2024</h1>
        <div>
            <ul>
                <li><a [href]="basePath + '2024/informacion-general-fundacion-guia-universitaria.pdf'" target="_blank">
                        Información General Fundación Guía Universitaria</a></li>
                <li><a [href]="basePath + '2024/rut-fundacion-guia-universitaria.pdf'" target="_blank">
                        RUT Fundación Guía Universitaria</a></li>
                <li><a [href]="basePath + '2024/camara-de-comercio-marzo-2024.pdf'" target="_blank">
                        Cámara de Comercio Marzo 2024</a></li>
                <li><a [href]="basePath + '2024/estatutos-fundacion-guia-universitaria.pdf'" target="_blank">
                        Estatutos Fundación Guía Universitaria</a></li>
                <li><a [href]="basePath + '2024/objeto-social.pdf'" target="_blank">
                        Objeto Social Fundación Guía Universitaria</a></li>
                <!-- <li><a [href]="basePath + '2024/certificacion-concejo-2024.pdf'" target="_blank">
                    Certificación Consejo Directivo 2024</a></li> -->
                <!-- <li><a [href]="basePath + '2024/informe-gestion-2024.pdf'" target="_blank">
                    Informe de Gestión 2024</a></li> -->
                <li><a [href]="basePath + '2024/certificacion-cargos-difectivos-2024.pdf'" target="_blank">
                        Certificación Cargos Directivos 2024</a></li>
                <li><a [href]="basePath + '2024/certificación-cumplimiento-representante-legal-y-revisor-2024.pdf'"
                        target="_blank">
                        Certificación Cumplimiento Representante Legal y Revisor 2024</a></li>
                <li><a [href]="basePath + '2024/certificacion-antecedentes-disciplinarios-2024.pdf'" target="_blank">
                        Certificacion Antecedentes Disciplinarios 2024</a></li>
                <li><a [href]="basePath + '2024/balance-apertura-2024.pdf'" target="_blank">
                        Balance de Apertura 2024</a></li>
                <!-- <li><a [href]="basePath + '2024/certificacion-estados-financieros-2024.pdf'" target="_blank">
                    Certificación Estados Financieros 2024</a></li> -->
                <!-- <li><a [href]="basePath + '2024/informe-revisoria-fiscal-2024.pdf'" target="_blank">
                    Informe Revisoría Fiscal 2024</a></li> -->
                <!-- <li><a [href]="basePath + '2024/certificacion-libros-2024.pdf'" target="_blank">
                    Certificación libros</a></li> -->
                <!-- <li><a [href]="basePath + '2024/informe-social-proyectos-2024.pdf'" target="_blank">
                    Informe Social proyectos 2024</a></li> -->
                <li><a [href]="basePath + '2024/acta-consejo-social-no-2.pdf'" target="_blank">
                        Acta Consejo Social No. 2</a></li>
            </ul>
        </div>
    </div>
</div>