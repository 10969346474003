<div class="py-2 px-4 login-student-container">
  <div class="login-student-content">
    <app-logo size="200px" class="ms-1 mt-1"></app-logo>
    <div class="section-title my-4">
      <h2>Panel Estudiante</h2>
    </div>
    <div class="col-xl-7 col-lg-7">
      <div class="signup-form-wrapper">
        <form [formGroup]="formLogin" class="example-form">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Ingrese su correo {{ getErrorMessage() }}</mat-label>
            <input matInput type="email" [formControl]="email" placeholder="pat@example.com" required />
          </mat-form-field>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Ingrese su contraseña</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required />
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{
                hide ? "visibility_off" : "visibility"
                }}</mat-icon>
            </button>
          </mat-form-field>
          <p class="ms-2">
            <mat-checkbox formControlName="remindMe">Recordarme</mat-checkbox>
          </p>

          <div class="d-grid gap-2 justify-content-md-center">
            <button mat-flat-button color="primary" [disabled]="formLogin.invalid" style="width: 100%"
              (click)="onSubmit()">
              Iniciar sesión
            </button>
          </div>
          <div class="forgot-pass">
            <a routerLink="/recover&password">¿Olvidó la contraseña? </a>
          </div>
          <div class="register">
            ¿No tiene una cuenta?
            <a href="#" (click)="openRegisterModal($event)">Regístrate aquí!</a>
          </div>
        </form>
      </div>
    </div>
    <mat-divider />
    <div class="signup-content-wrapper">
      <p class="opacity-75 mb-4">Inicia sesión con:</p>
      <div class="d-flex gap-2 gap-sm-3">
        <div class="col-auto d-flex gap-4">
          <button mat-raised-button (click)="onLoginWithGoogle()">
            <mat-icon class="h-100 me-3 my-2" svgIcon="google"></mat-icon>
            Google
          </button>
          <button mat-raised-button (click)="onLoginWithApple()">
            <mat-icon class="h-100 me-3 my-2" svgIcon="apple"></mat-icon>
            Apple
          </button>
        </div>
      </div>
    </div>

  </div>

  <mat-divider />

  <div class="login-student-info">
    <div class="info-content">
      <p class="opacity-75">
        Regístrate como estudiante y accede a un mundo de oportunidades:
      </p>
      <ul class="opacity-75">
        <li>Guarda y comparte comparaciones de carreras.</li>
        <li>Recibe información actualizada sobre becas.</li>
        <li>Accede a calendarios de inscripción a universidades.</li>
        <li>Disfruta de asesoría gratuita de orientación vocacional.</li>
        <li>Aprovecha ofertas y descuentos en matrículas.</li>
        <li>Participa en convocatorias para recibir apoyo financiero.</li>
        <li>Y mucho más.</li>
      </ul>
      <p class="opacity-75">
        No dejes pasar esta oportunidad de asegurar tu futuro académico.
        ¡Regístrate hoy mismo y empieza a planear tu camino hacia el éxito!
      </p>
    </div>
  </div>
</div>