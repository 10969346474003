import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FinancialInstitutionsMP } from 'src/app/models/payment.interface';
import { DonationService } from 'src/app/services/donation.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DIAL_CODES, DialCode } from 'src/app/util/DialCodes';

@Component({
  selector: 'app-pse',
  templateUrl: './pse.component.html',
  styleUrls: ['./pse.component.scss'],
})
export class PSEComponent {
  mercadopago: any;
  paymentMethod: any;
  allMethods: any;
  donative: number = 0;
  description = 'Donación a Fundación Guía Universitaria';

  paymentForm: FormGroup;
  documentTypes: { value: string; label: string }[] = [];
  banksList: { id: string; description: string }[] = [];
  callBackURL: string;

  constructor(
    private formBuilder: FormBuilder,
    private donationService: DonationService,
    private location: Location,
    private router: Router
  ) {
    // this.callBackURL =  window.location.origin + "/donaciones/resultados" 
    this.callBackURL = "https://guiauniversitaria.net/donaciones/resultados"
    this.paymentForm = this.formBuilder.group({
      zipCode: ['', [Validators.required]],
      streetName: ['', [Validators.required]],
      streetNumber: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      city: ['', [Validators.required]],
      federalUnit: [''],
      phoneAreaCode: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      personType: ['', [Validators.required]],
      identificationType: ['', [Validators.required]],
      identificationNumber: ['', [Validators.required]],
      financialInstitution: ['', [Validators.required]],
      transactionAmount: [String(this.donative)],
      description: this.description,
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
  }

  dialCode: DialCode = { name: '', dial_code: '', code: '' };
  default_dialCode: DialCode = {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
  };
  dial_codes = DIAL_CODES;

  fullAddress: string = '';

  ngOnInit(): void {
    this.getBankList();
    this.paymentForm.valueChanges.subscribe(value => {
      this.updateFullAddress(); 
    });
    this.subscribeToDonation()
  }

  async getBankList(): Promise<void> {
    // const url = `${environment.MERCADO_PAGO_BASE_URL_API}${environment.MERCADO_PAGO_GET_PAYMENT_METHODS}`;
    return await fetch(
      'https://us-central1-guia-universitaria-dev.cloudfunctions.net/getPaymentMethodsMP',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isProd: environment.production,
        })
      }
    )
      .then(async (response) => {
        const paymentMethods = await response.json();
        const pse = paymentMethods.data.filter(
          (method: any) => method.id === 'pse'
        )[0];
        const institutes = pse.financial_institutions
        const sortList = institutes.sort((a: { description: string }, b: { description: string }) => {
          if (a.description < b.description) {
            return -1; 
          }
          if (a.description > b.description) {
            return 1; 
          }
          return 0; 
        });

        this.banksList = sortList ;
      })
      .catch(function (reason) {
        console.error('Failed to get payment methods', reason);
      });
  }

  onPersonTypeChange(event: any) {
    const selectedPersonType = event.value;
    this.updateDocumentTypes(selectedPersonType);
  }

  updateDocumentTypes(selectedPersonType: string) {
    if (selectedPersonType === 'natural') {
      this.documentTypes = [
        { value: 'CC', label: 'Cédula de Ciudadanía' },
        { value: 'RC', label: 'Registro Civil de Nacimiento' },
        { value: 'TI', label: 'Tarjeta de Identidad' },
        { value: 'TE', label: 'Tarjeta de Extranjería' },
        { value: 'CE', label: 'Cédula de Extranjería' },
        { value: 'PAS', label: 'Pasaporte' },
      ];
    } else if (selectedPersonType === 'juridica') {
      this.documentTypes = [{ value: 'NIT', label: 'NIT' }];
    }
    this.paymentForm.get('identificationType')?.setValue('');
  }

  subscribeToDonation() {
    this.donationService.currentDonation.subscribe((donation) => {
      this.donative = Number(donation.donative);
    });
  }
  goBack(): void {
    this.location.back();
  }

  async onSubmit (event: Event) {
    event.preventDefault();
    if (this.paymentForm.valid) {
      const {
        description,
        email,
        zipCode,
        streetName,
        streetNumber,
        neighborhood,
        federalUnit,
        phoneAreaCode,
        phoneNumber,
        city,
        identificationType,
        identificationNumber,
        financialInstitution,
        personType,
        firstName,
        lastName,
      }  = this.paymentForm.value
      // console.log("calueseeee", this.paymentForm.value)
      // console.log()
      await fetch(
        'https://us-central1-guia-universitaria-dev.cloudfunctions.net/createPaymentPSEMercadoPago',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            isProd: environment.production,
            transactionAmount: Number(this.donative),
            description,
            financialInstitution,
            personType: personType === "natural" ?  "individual": "association",
            callBack_URL: this.callBackURL,
            payer: {
              email,
              firstName,
              lastName,
              identification: {
                type: identificationType,
                number: identificationNumber,
              }
            },
            address: {
              zipCode: this.formatZipCode(zipCode),
              streetName,
              streetNumber,
              neighborhood,
              city,
              federalUnit
            },
            phone:{
              phoneAreaCode: this.formatPhoneAreaCode(phoneAreaCode),
              phoneNumber
            }
          }),
        }
      ).then((response) => response.json())
      .then((result) => {
        // console.log("resultado fetch payment:", result)
        window.location.href = result.transaction_details.external_resource_url;
      })

    } else {
      console.log('El formulario no es válido'); 
      Swal.fire({
        icon: 'warning',
        title: 'Campos no validos',
        text: "Por favor, revisa que todos los campos requeridos sean validos.",
      });
      // console.log("valores del form",this.paymentForm.value)

    }

  }
  
  updateFullAddress(): void {
    const streetName = this.paymentForm.get('streetName')?.value || '';
    const streetNumber = this.paymentForm.get('streetNumber')?.value || '';
    const city = this.paymentForm.get('city')?.value || '';
    const neighborhood = this.paymentForm.get('neighborhood')?.value || '';

    this.fullAddress = `${streetName} ${streetNumber ? `# ${streetNumber}`: ""} ${neighborhood  ? `, ${neighborhood}`: ""} ${city  ? `, ${city}`: ""}`.trim() ;
  }

  formatPhoneAreaCode(phoneAreaCode: string): string {
    let formattedCode = phoneAreaCode.replace('+', '');
      if (formattedCode.length === 2) {
      formattedCode = '0' + formattedCode;
    }
    return formattedCode;
  }

  formatZipCode(zipCode: string): string {
    return zipCode.slice(0, 5);
  }
}
