import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { RegisterModalComponent } from './register-modal/register-modal/register-modal.component';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

 

  selectedOption: string | null = null;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private analyticsService: AnalyticsService,
    public dialog: MatDialog

  ) {
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-google.svg'));
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-linkedin.svg'));
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-facebook.svg'));
    this.matIconRegistry.addSvgIcon(
      'apple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-apple.svg'));
    this.matIconRegistry.addSvgIcon(
      'contactus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/contactus.svg'));

    

    // userService.logout();
  }

 
  ngOnInit(): void {
    // Analytics
    this.analyticsService.logEventScreenView(
      'Register',
      RegisterComponent.name);
  }

 
 selectOption(option: string): void {
    this.selectedOption = option;
    this.openModal()

  }

  openModal(): void {
    const dialogRef = this.dialog.open(RegisterModalComponent, {
      width: '85%',
      maxWidth:'850px',
      data: {
        selectedOption: this.selectedOption 
      }
    });
  }
}
