import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  OAuthProvider,
  FacebookAuthProvider,
  sendPasswordResetEmail,
  sendEmailVerification,
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode,
  getRedirectResult,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Profile } from '../models/profile';
import { Firestore, Timestamp, doc, getDoc } from '@angular/fire/firestore';
import { fromFirestore } from '../util/firestoreAdapters';
import Swal from 'sweetalert2';
import { signInWithRedirect } from 'firebase/auth';
import { mapErrorsResetPass } from '../util/errorCodesFirebase';
import { isMobile } from '../util/isMobile';
import { AuthProvider } from '../models/user';


const nowDate = new Date();

const formatOptions = {
  year: 'numeric' as const,
  month: 'short' as const,
  day: 'numeric' as const,
  hour: 'numeric' as const,
  minute: 'numeric' as const,
  hour12: true as const,
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private auth: Auth,
    private router: Router,
    public firestore: Firestore
  ) {
    if (this.user) {
      this.PROFILE_PHOTO_URL = this.user.photoURL;
    }
  }

  user: {
    uid: string;
    displayName: string;
    email: string;
    emailVerified: boolean;
    photoURL: string;
    providerData: AuthProvider[]
  } | null = JSON.parse(localStorage.getItem('user')!);

  private loggedIn: boolean = false;
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    // const user = JSON.parse(localStorage.getItem('user')!);
    // return user !== null && user.emailVerified !== false ? true : false;
    return this.user !== null ? true : false;
  }

  getFullName(): string {
    // const user = JSON.parse(localStorage.getItem('user')!);
    if (!environment.production) console.log('User.Service.getFullName');

    console.log(this.user?.displayName);
    return this.user?.displayName!;
  }

  getUserId(): string {
    // const user = JSON.parse(localStorage.getItem('user')!);
    if (!environment.production) console.log('User.Service.getUserId');

    return this.user?.uid!;
  }

  getEmail(): string {
    // const user = JSON.parse(localStorage.getItem('user')!);
    if (!environment.production) console.log('User.Service.getEmail');

    return this.user?.email!;
  }

  getProfilePhoto(): string {
    // const user = JSON.parse(localStorage.getItem('user')!);
    if (!environment.production) console.log('User.Service.getProfilePhoto');

    return this.PROFILE_PHOTO_URL!;
    // return this.user?.photoURL!;
  }

  public PROFILE_PHOTO_URL?: string;

  setProfilePhoto(photoUrl: string): void {
    // const user = JSON.parse(localStorage.getItem('user')!);
    if (!environment.production) console.log('User.Service.getProfilePhoto');

    this.PROFILE_PHOTO_URL = photoUrl;
    if (this.user) this.user.photoURL = photoUrl;
  }

  getEmailVerified(): boolean {
    // const user = JSON.parse(localStorage.getItem('user')!);
    if (!environment.production) console.log('User.Service.getEmailVerified');

    return this.user?.emailVerified!;
  }

  getProviderData(): AuthProvider[] {
    // const user = JSON.parse(localStorage.getItem('user')!);
    if (!environment.production) console.log('User.Service.getProviderData');

    return this.user?.providerData!;
  }

  // Returns true when user is looged in and email is verified and has a professor profile
  get isProfessor(): boolean {
    // console.log('userService.isProfessor');
    const userValue = localStorage.getItem('user');
    // const userValue = this.getWithExpiry('user');
    if (userValue) {
      const user = JSON.parse(userValue);
      // console.log(user);

      // return this.isLoggedIn && user.profile == 'professor' ? true : false;
      return this.isLoggedIn && user ? true : false;
    }
    return false;
  }

  async registerWithEmail(
    email: string,
    password: string,
    displayName: string
  ) {
    try {
      const result = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );

      // console.log (" result.user.displayName",  displayName)
      // console.log("result.user.email", email)
      // console.log("secret",environment.secretEmail )
      result &&
        (await fetch(
          'https://us-central1-guia-universitaria-dev.cloudfunctions.net/sendWelcome',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              secret: environment.secretEmail,
              name: displayName,
              email: email,
              role: 'students',
            }),
          }
        ));

      this.auth.currentUser &&
        (await sendEmailVerification(this.auth.currentUser));

      if (result && displayName) {
        try {
          const updateResult = await updateProfile(result.user, {
            displayName: displayName,
          });
        } catch (error) {
          console.log(error);
        }
      }
      this.router.navigate(['/login']);
    } catch (error) {
      console.log('error in userService.registerWithEmail');
      console.log(error);
      throw error;
    }
  }

  async updateBasicProfile(
    diplayname: string,
    photoURL: string | undefined
  ): Promise<void> {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      await updateProfile(currentUser, {
        displayName: diplayname,
        photoURL: photoURL,
      });
      this.PROFILE_PHOTO_URL = photoURL;
      // await updateProfile(currentUser, { photoURL: photoURL });
    }
  }

  getAuthMessageError(error: any): string {
    const errorCode: string = error.code;
    let errorMessage: string = error.message;
    console.warn('Error Code:', errorCode);
    switch (errorCode) {
      case 'auth/wrong-password': {
        errorMessage = 'Correo o constraseña incorrecta!';
        break;
      }
      case 'auth/user-not-found': {
        errorMessage = 'Correo o constraseña incorrecta!';
        break;
      }

      case 'auth/too-many-requests': {
        errorMessage =
          'Demasiados intentos fallidos!\nSe ha deshabilitado esta cuenta temporalmente. Puede restarurar su contraseña inmediatamente o intentar más tarde.';
        break;
      }
      case 'auth/email-already-exists': {
        errorMessage = 'Usuario ya existe con este correo!';
        break;
      }
      case 'auth/email-already-in-use': {
        errorMessage = 'Usuario ya existe con este correo!';
        break;
      }
      default: {
        errorMessage = errorCode;
        break;
      }
    }
    return errorMessage;
  }

  async loginWithEmail(email: string, password: string) {
    console.log('loginWithEmail -->');
    try {
      const result = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      if (result.user.emailVerified) {
        // console.log('result:');
        // console.log(result);
        // Ref: https://stackoverflow.com/questions/38389341/firebase-create-user-with-email-password-display-name-and-photo-url
        let profile: string = 'student';
        // const now: Date = new Date();
        // const expirationTime: number = result.user.; //5 minutes for test, defult must be 30 minutes (1800 seconds)
        const userFirestore = await this.getStudentProfile(result.user.uid);
        const ttl: number = 600; //5 minutes for test, defult must be 30 minutes (1800 seconds)
        this.clearLocalUserData();

        // console.log("DATOS DEL LOGIN")
        // console.log(result.user)
        // console.log(userFirestore)
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...result.user,
            profile: profile,
            firestoreProfile: userFirestore,
          })
        );
        this.user = JSON.parse(localStorage.getItem('user')!);
        this.PROFILE_PHOTO_URL = this.user?.photoURL;
        this.loggedIn = true;
        if (userFirestore && userFirestore.favorites) {
          localStorage.setItem(
            environment.localFavoriteList,
            JSON.stringify([...userFirestore.favorites])
          );
        }
        if (userFirestore?.comparisonRef) {
          try {
            const comparisonList = await this.getListComparison(
              userFirestore.comparisonRef
            );
            const ttlComparison =
              comparisonList &&
              (comparisonList['updatedAt'] || comparisonList['createdAt']);
            const fecha30DiasDespues = new Date(ttlComparison);
            fecha30DiasDespues.setDate(fecha30DiasDespues.getDate() + 30);
            const ttlParsed = fecha30DiasDespues.getTime();
            comparisonList &&
              localStorage.setItem(
                environment.localSavedComparisonByName,
                JSON.stringify({
                  ...comparisonList['list'],
                })
              );
            comparisonList &&
              localStorage.setItem(
                environment.ttlComparisonList,
                JSON.stringify(ttlParsed)
              );
            //  const lastValueInList = comparisonList && Object.values(comparisonList["list"]).pop()
            //  comparisonList && localStorage.setItem(environment.localComparisionListKey, JSON.stringify(lastValueInList))
            comparisonList &&
              localStorage.setItem(
                environment.ttlComparisonGroupList,
                JSON.stringify(ttlParsed)
              );
          } catch (error) {
            console.error('Error to get comparisonList in UserService', error);
          }
        }
        this.router.navigate(['/perfil']);
        return Promise.resolve(true);
      } else {
        Swal.fire({
          title: 'Por favor, verifica tu correo para continuar',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#DOD2D2',
          confirmButtonText: 'Reenviar verificación',
        }).then(async (res) => {
          if (res.isConfirmed) {
            await this.resendVerificationEmail(result.user);
            Swal.fire({
              title: '¡Correo enviado!',
              text: 'Revisa tu bandeja de entrada para verificar tu correo.',
              icon: 'success',
            });
          }
        });
        return Promise.resolve(false);
      }
      // this.router.navigate(['/inicio']);
    } catch (error) {
      console.log('error in userService.loginWithEmail');
      console.log(error);
      throw error;
    }
  }

  getWithExpiry(key: string): string | null {
    console.log('userService.getWithExpiry');
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  async loginWithGoogle(isRegistering?: boolean) {
    isRegistering = isRegistering ?? false;
    try {
      const result = await signInWithPopup(this.auth, new GoogleAuthProvider());
      let profile: String = 'student';
      const userFirestore = await this.getStudentProfile(result.user.uid);
      this.clearLocalUserData();
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...result.user,
          firestoreProfile: userFirestore,
          profile: profile,
        })
      );
      this.user = JSON.parse(localStorage.getItem('user')!);
      this.PROFILE_PHOTO_URL = this.user?.photoURL;
      this.loggedIn = true;
      if (userFirestore && userFirestore.favorites) {
        localStorage.setItem(
          environment.localFavoriteList,
          JSON.stringify([...userFirestore.favorites])
        );
      }
      if (isRegistering) {
        await fetch(
          'https://us-central1-guia-universitaria-dev.cloudfunctions.net/sendWelcome',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              secret: environment.secretEmail,
              name: result.user.displayName,
              email: result.user.email,
              role: 'students',
            }),
          }
        );
      }
      if (userFirestore?.comparisonRef) {
        try {
          const comparisonList = await this.getListComparison(
            userFirestore.comparisonRef
          );
          const ttlComparison =
            comparisonList &&
            (comparisonList['updatedAt'] || comparisonList['createdAt']);
          const fecha30DiasDespues = new Date(ttlComparison);
          fecha30DiasDespues.setDate(fecha30DiasDespues.getDate() + 30);
          const ttlParsed = fecha30DiasDespues.getTime();
          //  const ttlParsed = Date.parse(ttlComparison.toLocaleString()) + (30 * 24 * 60 * 1000)
          //  console.log("TTL Comparison",ttlComparison)
          //  console.log("TTLPARSED", ttlParsed)
          //  console.log("NEW DATE IN FORMAT", new Date(ttlParsed))
          //  console.log("DATE NOW PARSED", Date.now())
          //  console.log("DIFERENCIA TTL",ttlParsed - Date.now())
          comparisonList &&
            localStorage.setItem(
              environment.localSavedComparisonByName,
              JSON.stringify({
                ...comparisonList['list'],
              })
            );

          comparisonList &&
            localStorage.setItem(
              environment.ttlComparisonList,
              JSON.stringify(ttlParsed)
            );
          //  const lastValueInList = comparisonList && Object.values(comparisonList["list"]).pop()
          //  comparisonList && localStorage.setItem(environment.localComparisionListKey, JSON.stringify(lastValueInList))
          comparisonList &&
            localStorage.setItem(
              environment.ttlComparisonGroupList,
              JSON.stringify(ttlParsed)
            );
        } catch (error) {
          console.error('Error to get comparisonList in UserService', error);
        }
      }
      // this.router.navigate(['/inicio']);
    } catch (error) {
      console.log('error in userService.loginWithGoogle');
      console.log(error);
      throw error;
    }
  }

  async loginWithApple() {
    const provider = new OAuthProvider(environment.AppleProviderId);
    provider.addScope('email');
    provider.addScope('name');
    provider.setCustomParameters({
      // Localize the Apple authentication screen in Spanish.
      locale: 'es',
    });
    // const isMobileDevice = isMobile();
    try {
      // if (isMobileDevice) {
      //   await signInWithRedirect(this.auth, provider);
      //   this.getRedirectResultApple()
      //   return
      // }
      await signInWithPopup(this.auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const user = result.user;

          let profile: String = 'student';
          const userFirestore = await this.getStudentProfile(result.user.uid);
          this.clearLocalUserData();
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...result.user,
              firestoreProfile: userFirestore,
              profile: profile,
            })
          );
          this.user = JSON.parse(localStorage.getItem('user')!);
          this.PROFILE_PHOTO_URL = this.user?.photoURL;
          this.loggedIn = true;
          if (userFirestore && userFirestore.favorites) {
            localStorage.setItem(
              environment.localFavoriteList,
              JSON.stringify([...userFirestore.favorites])
            );
          }
          if (userFirestore?.comparisonRef) {
            try {
              const comparisonList = await this.getListComparison(
                userFirestore.comparisonRef
              );
              const ttlComparison =
                comparisonList &&
                (comparisonList['updatedAt'] || comparisonList['createdAt']);
              const fecha30DiasDespues = new Date(ttlComparison);
              fecha30DiasDespues.setDate(fecha30DiasDespues.getDate() + 30);
              const ttlParsed = fecha30DiasDespues.getTime();
              comparisonList &&
                localStorage.setItem(
                  environment.localSavedComparisonByName,
                  JSON.stringify({
                    ...comparisonList['list'],
                  })
                );

              comparisonList &&
                localStorage.setItem(
                  environment.ttlComparisonList,
                  JSON.stringify(ttlParsed)
                );
              comparisonList &&
                localStorage.setItem(
                  environment.ttlComparisonGroupList,
                  JSON.stringify(ttlParsed)
                );
            } catch (error) {
              console.error('Error to get comparisonList in UserService', error);
            }
          }
          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          if (credential) {
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
          }

          // IdP data available using getAdditionalUserInfo(result)
          // ...
          console.log("AppleResponse", result.user)
          return user
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
    } catch (error) {
      console.log('error in userService.loginWithApple');
      console.log(error);
      throw error;
    }
  }

  async getRedirectResultApple() {
    try {
      await getRedirectResult(this.auth)
        .then(async (result) => {
          if (result) {
            const user = result.user;

            let profile: String = 'student';
            const userFirestore = await this.getStudentProfile(result.user.uid);
            this.clearLocalUserData();
            localStorage.setItem(
              'user',
              JSON.stringify({
                ...result.user,
                firestoreProfile: userFirestore,
                profile: profile,
              })
            );
            this.user = JSON.parse(localStorage.getItem('user')!);
            this.PROFILE_PHOTO_URL = this.user?.photoURL;
            this.loggedIn = true;
            if (userFirestore && userFirestore.favorites) {
              localStorage.setItem(
                environment.localFavoriteList,
                JSON.stringify([...userFirestore.favorites])
              );
            }
            if (userFirestore?.comparisonRef) {
              try {
                const comparisonList = await this.getListComparison(
                  userFirestore.comparisonRef
                );
                const ttlComparison =
                  comparisonList &&
                  (comparisonList['updatedAt'] || comparisonList['createdAt']);
                const fecha30DiasDespues = new Date(ttlComparison);
                fecha30DiasDespues.setDate(fecha30DiasDespues.getDate() + 30);
                const ttlParsed = fecha30DiasDespues.getTime();
                comparisonList &&
                  localStorage.setItem(
                    environment.localSavedComparisonByName,
                    JSON.stringify({
                      ...comparisonList['list'],
                    })
                  );

                comparisonList &&
                  localStorage.setItem(
                    environment.ttlComparisonList,
                    JSON.stringify(ttlParsed)
                  );
                comparisonList &&
                  localStorage.setItem(
                    environment.ttlComparisonGroupList,
                    JSON.stringify(ttlParsed)
                  );
              } catch (error) {
                console.error('Error to get comparisonList in UserService', error);
              }
            }
            // Verifica que result no sea null
            const credential = OAuthProvider.credentialFromResult(result);
            if (credential) {
              const accessToken = credential.accessToken;
              const idToken = credential.idToken;
              // Maneja los tokens si es necesario
            }

            console.log('Usuario autenticado:', user);
          } else {
            console.log(
              'No se encontraron resultados después del redireccionamiento'
            );
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);
          console.error(
            'Error al obtener los resultados del redireccionamiento:',
            error
          );
        });
    } catch (error) {
      console.log('error in userService.getRedirectResultApple');
      console.log(error);
      throw error;
    }
  }

  async deleteAccountApple() {
    const provider = new OAuthProvider(environment.AppleProviderId);
    provider.addScope('email');
    provider.addScope('name');

    await signInWithPopup(this.auth, provider).then((result) => {
      // Get the Apple OAuth access token.
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential && credential.accessToken;
      const user = result.user;
      user.delete();


      // // Revoke the Apple OAuth access token.
      // revokeAccessToken(this.auth, accessToken)
      //   .then(() => {
      //     // Token revoked.
      //     // Delete the user account.
      //     // ...
      //   })
      //   .catch((error) => {
      //     // An error happened.
      //     // ...
      //     console.log('error in userService.deleteAccountApple');
      //     console.log(error);
      //     throw error;         
      //    });

    });
  }

  async loginWithLinkedin() {
    // TODO
    console.log('loginWithLinkedin -->');
    try {
      const result = await signInWithPopup(
        this.auth,
        new OAuthProvider(environment.LinkedinProviderId)
      );
      let profile: String = 'student';
      this.clearLocalUserData();
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...result.user,
          profile: profile,
        })
      );
      this.user = JSON.parse(localStorage.getItem('user')!);
      this.PROFILE_PHOTO_URL = this.user?.photoURL;
      this.loggedIn = true;
      // this.router.navigate(['/inicio']);
    } catch (error) {
      console.log('error in userService.loginWithLinkedin');
      console.log(error);
      throw error;
    }
    // return signInWithPopup(this.auth, new SAMLAuthProvider());
  }
  // Ref: https://www.linkedin.com/pulse/firebase-authentication-angular-madhu-chenna/

  async loginWithFacebook() {
    // https://firebase.google.com/docs/auth/web/facebook-login
    try {
      const result = await signInWithPopup(
        this.auth,
        new FacebookAuthProvider()
      );
      let profile: String = 'student';
      this.clearLocalUserData();
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...result.user,
          profile: profile,
        })
      );
      this.user = JSON.parse(localStorage.getItem('user')!);
      this.PROFILE_PHOTO_URL = this.user?.photoURL;
      this.loggedIn = true;
      // this.router.navigate(['/inicio']);
    } catch (error) {
      console.log('error in userService.loginWithFacebook');
      console.log(error);
      throw error;
    }
  }

  // ---------------------------

  // Ref: https://www.positronx.io/full-angular-firebase-authentication-system/
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  /*   SetUserData(user: any) {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${user.uid}`
      );
      const userData: User = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
      };
      return userRef.set(userData, {
        merge: true,
      });
    } */

  // Sign out
  async logout() {
    console.log('userService.logout');
    try {
      const result = await signOut(this.auth);
      this.clearLocalUserData();
      localStorage.removeItem(environment.ttlComparisonGroupList);
      localStorage.removeItem(environment.ttlComparisonList);
      localStorage.removeItem(environment.localSavedComparisonByName);
      localStorage.removeItem(environment.localFavoriteList);
      localStorage.removeItem(environment.localComparisionListKey);
      localStorage.removeItem(environment.currentComparatorName);
      localStorage.removeItem('testId');
      // this.comparisonService.careers = []
      // this.favoriteService.favorites = []
      this.loggedIn = false;
      this.user = null;
      this.PROFILE_PHOTO_URL = undefined;
      this.router.navigate(['/inicio']);
      // Remove Profile Suscriptions
    } catch (error) {
      console.log(error);
    }
  }

  async sendWelcomeEmail(name: string, email: string) {
    await fetch(
      'https://us-central1-guia-universitaria-dev.cloudfunctions.net/sendWelcome',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          secret: environment.secretEmail,
          name: name,
          email: email,
        }),
      }
    );
  }

  async recoverPassWord(email: string): Promise<boolean> {
    const actionCodeSettings = {
      // url: 'http://localhost:4201/change-password/?email=' + email,
      url: 'https://guiauniversitaria.net/actions-app',
      handleCodeInApp: false,
    };
    try {
      await sendPasswordResetEmail(this.auth, email, actionCodeSettings);
      return true;
    } catch (error) {
      console.error(`Error recovering password email:${email}`);
      console.error(error);
      return false;
    }
  }

  async getListComparison(comparisonRef: string) {
    try {
      const docRef = doc(this.firestore, comparisonRef);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return fromFirestore({
          ...docSnap.data(),
        });
      }

      return null;
    } catch (error) {
      console.error(
        `Error to get list comparison data in userService, ${error}`
      );
      return null;
    }
  }

  async getStudentProfile(userId: string): Promise<Profile | undefined> {
    const userPath: string = 'students';
    try {
      const docRef = doc(this.firestore, `${userPath}/${userId}`);
      // return docData(docRef, { idField: 'id' }) as Observable<Profile>;
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        var profile: Profile = docSnap.data() as Profile;
        if (profile.personalData?.birthDate) {
          const date: Timestamp = profile.personalData
            .birthDate as object as Timestamp;
          profile.personalData.birthDate = date.toDate();
        }
        if (profile.experience) {
          profile.experience.forEach((item) => {
            const start: Timestamp = item.startDate as object as Timestamp;
            const end: Timestamp | undefined =
              item.endDate as object as Timestamp;
            item.startDate = start.toDate();
            // item.startDate = new Date(item.startDate.getFullYear(), item.startDate.getMonth(), item.startDate.getDay());
            if (item.endDate) item.endDate = end.toDate();
          });
        }
        if (profile.education) {
          profile.education.forEach((item) => {
            const start: Timestamp = item.startDate as object as Timestamp;
            const end: Timestamp | undefined =
              item.endDate as object as Timestamp;
            item.startDate = start.toDate();
            // item.startDate = new Date(item.startDate.getFullYear(), item.startDate.getMonth(), item.startDate.getDay());
            if (item.endDate) item.endDate = end.toDate();
          });
        }
        return profile;
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
        return undefined;
      }
      //(docRef, { idField: 'id' }) as Observable<Profile>;
    } catch (error) {
      console.log(`error in userService.getStudentProfile(${userId})`);
      console.log(error);
      throw error;
    }
  }

  async resendVerificationEmail(user: any) {
    console.log(user);
    await sendEmailVerification(user);
  }

  async resetPassword(
    actionCode: string,
    continueUrl: string,
    lang: string,
    newPassword: string
  ) {
    // Verify the password reset code is valid.
    verifyPasswordResetCode(this.auth, actionCode)
      .then((email) => {
        const accountEmail = email;

        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.
        // const newPassword = "...";

        // Save the new password.
        confirmPasswordReset(this.auth, actionCode, newPassword)
          .then(async (resp) => {
            // Password reset has been confirmed and new password updated.
            console.log('Pass updated successfully', resp);
            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
            Swal.fire({
              title: '¡Contraseña cambiada!',
              text: 'Ahora puedes ingresar con tu nueva contraseña',
              icon: 'success',
              timer: 4000,
            });
            this.router.navigate(['/login']);
            await fetch(
              'https://us-central1-guia-universitaria-dev.cloudfunctions.net/confirmPassChanged',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  secret: environment.secretEmail,
                  createdAt: nowDate.toLocaleString('es-ES', formatOptions),
                  name: 'usuari@',
                  email: accountEmail,
                }),
              }
            );
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
          })
          .catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            const errorMessage = mapErrorsResetPass(error.code);
            Swal.fire({
              title: 'Ocurrio un error',
              text: errorMessage,
              icon: 'error',
              timer: 4000,
            });
            console.error(
              'Error during confirmation pass in -CustomRecoverPassComp-',
              error.code
            );
          });
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        const errorMessage = mapErrorsResetPass(error.code);
        Swal.fire({
          title: 'Ocurrio un error',
          text: errorMessage,
          icon: 'error',
          timer: 4000,
        });
        console.error(
          'Error in actionCode to resetPassword -CustomRecoverPassComp-',
          error.code
        );
      });
  }

  async verifyEmail(actionCode: string, continueUrl: string, lang: string, tempKey:string|null) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    applyActionCode(this.auth, actionCode)
      .then((resp) => {
        // Email address has been verified.

        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        if(tempKey){
          Swal.fire({
            title: '¡Se te ha generado la siguiente contraseña temporal!',
            text: tempKey,
            footer: 'Por favor, guarda esta contraseña para tu primer acceso, luego podrás cambiarla.',
            icon: 'success',
            confirmButtonText: 'Entendido',
            allowOutsideClick: false,
            scrollbarPadding: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: '¡Cuenta verificada!',
                icon: 'success',
                timer: 4000,
              });
              this.router.navigate(['/login']);
            }
          });
        }else{
          Swal.fire({
            title: '¡Cuenta verificada!',
            icon: 'success',
            timer: 4000,
          });
          this.router.navigate(['/login']);
        }
       
       
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        const errorMessage = mapErrorsResetPass(error.code);
        Swal.fire({
          title: 'Ocurrio un error',
          text: errorMessage,
          icon: 'error',
          timer: 4000,
        });
        console.error(
          'Error in actionCode to verifyEmail -CustomRecoverPassComp-',
          error.code
        );
      });
  }
  // getAuth() {
  //   return this.auth;
  // }
  // /**
  //    * Initiate the password reset process for this user
  //    * @param email email of the user
  //    */
  // resetPasswordInit(email: string) {
  //   return this.auth.sendPasswordResetEmail(
  //     email,
  //     { url: 'http://localhost:4200/auth' });
  //   }


  // constructor(@Inject(DOCUMENT) private document: Document) {}

  // getSessionIdFromCookie(): string | null {
  //   const cookie = this.document.cookie.split('; ').find(row => row.startsWith('SESSION_ID='));
  //   return cookie ? cookie.split('=')[1] : null;
  // }

  getSessionId(): string | null {
    return localStorage.getItem('SESSION_ID') || sessionStorage.getItem('SESSION_ID');
  }

  saveLocalDownloadUser(value: any, expiryInHours: number = 1): void {
    const key = 'download-user';
    const now = new Date();
    const expiryTimestamp = now.getTime() + expiryInHours * 60 * 60 * 1000; // Convertir horas a milisegundos
    const valueWithExpiry = {
      ...value,
      expiry: expiryTimestamp, // Agregar el tiempo de expiración
    };
    localStorage.setItem(key, JSON.stringify(valueWithExpiry));
  }

  renewLocalDownloadUser(expiryInHours: number = 1): boolean {
    const key = 'download-user';
    const storedValue = localStorage.getItem(key);
    if (!storedValue)
      return false;

    const parsedValue = JSON.parse(storedValue);
    const now = new Date();
    const expiryTimestamp = now.getTime() + expiryInHours * 60 * 60 * 1000; // Convertir horas a milisegundos
    const valueWithExpiry = {
      ...parsedValue,
      expiry: expiryTimestamp, // Agregar el tiempo de expiración
    };

    localStorage.setItem(key, JSON.stringify(valueWithExpiry));
    return true;
  }

  getLocalDownloadUser(): any | null {
    const key = 'download-user';
    const storedValue = localStorage.getItem(key);
    if (!storedValue) {
      return null; // No existe el valor en localStorage
    }

    const parsedValue = JSON.parse(storedValue);
    const now = new Date().getTime();

    if (parsedValue.expiry && now > parsedValue.expiry) {
      // Si ha caducado, eliminarlo de localStorage
      localStorage.removeItem(key);
      return null;
    }

    // Retornar el valor sin el campo de expiración
    const { expiry, ...valueWithoutExpiry } = parsedValue;
    return valueWithoutExpiry;
  }

  clearLocalUserData(): void {
    const keyUser = 'user';
    const keyDownloadUser = 'download-user';
    localStorage.removeItem(keyUser);
    localStorage.removeItem(keyDownloadUser);
  }
}
