<div class=" container-fluid">
  <div class="row  bgg align-items-start align-items-lg-center justify-content-center hero3">
    <div class="col-md-10 col-lg-5 text-center py-5 py-md-4 text-center text-lg-start">
      <h1 class="display-2 fw-bold lh-sm mb-4 justify-content-md-center">
        Descubre <span class="primaryColor">programas universitarios</span> para tí
      </h1>
      <h5 class="lh-sm fw-normal  justify-content-md-center  px-4 mb-4">
        Usa nuestros <span class="primaryColor fw-bold"> filtros de búsqueda</span>
        y asómbrate con todas las oportunidades que esperan por tí. Regístrate y podrás <span class="primaryColor fw-bold"> guardar comparaciones</span> además de añadir carreras a <span class="primaryColor fw-bold"> favoritos</span>.
      </h5>
      <div class="d-grid gap-2 d-lg-flex justify-content-center ">
        <button class="" mat-raised-button color="primary" routerLink="/buscador" style="max-width: 150px;">Buscar Programas</button>
        <a [href]="professorsUrl" target="_blank">
          <button class="" mat-stroked-button>¿Eres Profesor?</button>
        </a>
      </div>
    </div>
    <div class="col-12 col-lg-6  ">
      <img src="../../assets/images/hero5.jpg" class=" d-none img-fluid hero4 rounded-2 shadow" alt="">
      <img src="../../assets/svg/Filter-amico.svg" alt="">

      <!--
      <img
         src="../../assets/svg/Online world-amico.svg"
        class="d-block mx-lg-auto img-fluid"
        alt="Bootstrap Themes"
        width="700"
        height="500"
        loading="lazy"
      />
      <img
        src="../../assets/images/hero1.jpg"
        class="img-fluid rounded-bottom"
        alt="Bootstrap Themes"
        width="700"
        height="500"
        loading="lazy"
      />
      -->
    </div>
  </div>
</div>
<div class="container px-5">

  <div class="row row-cols-1 row-cols-lg-3 g-4 text-center ">

    <div class="col">

      <div class="  shadow-sm p-3 rounded-4  blurThis ">
        <div class="d-grid">
          <p class="display-6 fw-bold primaryColor mb-0 num" [countUp]="programsEndVal" [options]="myOpts">0</p>
          <p class="fw-bold fs-5">Programas Universitarios</p>
        </div>
      </div>
    </div>

    <div class="col">

      <div class="  shadow-sm p-3 rounded-4  blurThis ">
        <div class="d-grid">
          <p class="display-6 fw-bold primaryColor mb-0 num" [countUp]="teacherEndVal" [options]="myOpts">0</p>
          <p class="fw-bold fs-5">Profesores Universitarios</p>
        </div>
      </div>
    </div>
    <div class="col">

      <div class="  shadow-sm p-3 rounded-4 blurThis  bg-gradient ">
        <p class="  fs-5">Únete a la comunidad</p>
        <div class="d-flex justify-content-center">
          <img class="img-fluid cover square rounded-circle "
            src="../../assets/images/close-up-portrait-professional-successful-young-african-american-man-red-hoodie-cross-arms-chest.jpg"
            alt="">
          <img class="img-fluid cover square rounded-circle"
            src="../../assets/images/young-woman-attend-courses-girl-student-studying-holding-notebooks-showing-thumb-up-approval-recommending-company-standing-blue-background.jpg"
            alt="">
          <img class="img-fluid cover square rounded-circle"
            src="../../assets/images/young-woman-attend-courses-girl-student-studying-holding-notebooks-showing-thumb-up-approval-recommending-company-standing-blue-background.jpg"
            alt="">
          <img class="img-fluid cover square rounded-circle"
            src="../../assets/images/close-up-portrait-professional-successful-young-african-american-man-red-hoodie-cross-arms-chest.jpg"
            alt="">
          <p class=" fw-bold fs-5  p-1  primaryColor z-index-1 num" [countUp]="studentEndVal" [options]="myOpts">0</p>
        </div>
      </div>
    </div>


  </div>
</div>