import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ComparisonService } from '../../services/comparison.service';
import { Dialog } from '@angular/cdk/dialog';
import { Career } from '../../models/career.interface';
import { environment } from 'src/environments/environment';
import { MatSelectChange } from '@angular/material/select';
import { AnotherTableComponent } from 'src/app/another-table/another-table.component';

interface SelectedComparator {
  value: string
  viewValue: string
}
@Component({
  selector: 'app-careers-comparison',
  templateUrl: './careers-comparison.component.html',
  styleUrls: ['./careers-comparison.component.scss']
})
export class CareersComparisonComponent implements OnInit {
  @ViewChild(AnotherTableComponent, { static: false }) anotherTableComponent: AnotherTableComponent | undefined;

  constructor(private comparisonService: ComparisonService) { }

  selectedComparator: SelectedComparator[] = []
  user = JSON.parse(localStorage.getItem('user')!)
  getComparisonGroupList: { [key: string]: Career[] } = {}
  selectedTableName: string = ''

  ngOnInit(): void {
    this.getComparisonGroupList = JSON.parse(localStorage.getItem(environment.localSavedComparisonByName) ?? '[]')

    this.selectedComparator = Object.keys(this.getComparisonGroupList).map((name: string) => ({
      value: name,
      viewValue: name
    }))
    console.log(this.selectedComparator)

    this.comparisonService.updateTriggered.subscribe(() => {
      this.updateComparisonData();
    });
  }

  onCompare() {
    this.comparisonService.openDialog();
  }

  onSelectedComparatorChange(event: MatSelectChange) {
    try {
      if (this.comparisonService.listIsSaved === false) {
        // this.comparisonService.addCurrentListInComparisonGroup("Borrador sin guardar")
        this.comparisonService.setListIsSaved(true)
        this.updateComparisonData()
      }
      const selectedValue: string = event.value;
      this.selectedTableName = selectedValue
      const newList = this.getComparisonGroupList[selectedValue]
      localStorage.setItem(environment.currentComparatorName, JSON.stringify(selectedValue))
      if (newList) {
        localStorage.setItem(environment.localComparisionListKey, JSON.stringify(newList));
        if (this.anotherTableComponent) {
          this.anotherTableComponent.refreshComparisonTable();
        }
        this.comparisonService.setListIsSaved(true)
      }
      // console.log("TABLE NAME",this.selectedTableName)

    } catch (error) {
      console.error("Cannot get this list", error)
    }
  }

  clearTable() {
    if (this.comparisonService.listIsSaved === false) {
      // this.comparisonService.addCurrentListInComparisonGroup("Borrador sin guardar")
      this.comparisonService.setListIsSaved(true)
      this.updateComparisonData()
    }
    localStorage.removeItem(environment.localComparisionListKey);
    localStorage.removeItem(environment.currentComparatorName)
    if (this.anotherTableComponent) {
      this.anotherTableComponent.refreshComparisonTable();
    }
  }

  updateComparisonData() {
    this.getComparisonGroupList = JSON.parse(localStorage.getItem(environment.localSavedComparisonByName) ?? '[]');
    this.selectedComparator = Object.keys(this.getComparisonGroupList).map((name: string) => ({
      value: name,
      viewValue: name
    }));
  }

}
