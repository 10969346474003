import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-save-favorites',
  templateUrl: './dialog-save-favorites.component.html',
  styleUrls: ['./dialog-save-favorites.component.scss']
})
export class DialogSaveFavoritesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogSaveFavoritesComponent>) { }
  user = JSON.parse(localStorage.getItem('user')!)

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
