import { Injectable } from '@angular/core';
import { getAnalytics, logEvent as analyticsLogEvent } from '@angular/fire/analytics';

declare var fbq: Function;
// declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  logEventScreenView(screenName: string, screenClass: String): void {
    this.logEvent('screen_view', {
      firebase_screen: screenName,
      firebase_screen_class: screenClass
    });
  }

  logCustomEvent(eventName: string, eventParams?: { [key: string]: any; }): void {
    this.logEvent(eventName, eventParams);
    fbq("trackCustom", eventName, {
      eventParams
    });
    // gtag("trackCustom", eventName, {
    //   eventParams
    // });
  }

  logEvent(eventName: string, eventParams?: { [key: string]: any; }): void {
    const analytics = getAnalytics();
    analyticsLogEvent(analytics, eventName, eventParams);
  }

}
