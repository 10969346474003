import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { Profile } from 'src/app/models/profile';
import { PhotoUploadDialogComponent } from 'src/app/photo-upload-dialog/photo-upload-dialog.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import { FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { DIAL_CODES, DialCode } from 'src/app/util/DialCodes';
import { NATIONALITIES } from 'src/app/util/Nationalities';
import Swal from 'sweetalert2';
import { AuthProvider } from 'src/app/models/user';

@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-CO' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class EditUserProfileComponent implements OnInit {

  constructor(private userProfileService: UserProfileService, private userService: UserService,
    public router: Router, public dialog: MatDialog,
    private utilityService: UtilityService,
    private analyticsService: AnalyticsService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string) {
    this._locale = 'es-CO';
    this._adapter.setLocale(this._locale);
  }

  dial_codes = DIAL_CODES;
  nationalities = NATIONALITIES;
  birthDateCtrl = new FormControl(new Date());
  prefixCrl = new FormControl('');

  institutionName: string = '';

  openDialog() {
    let config = new MatDialogConfig();
    config = {
      // maxWidth: '98vw',
      // maxHeight: '100%',

      // width: '98%',
    };
    const dialogRef = this.dialog.open(PhotoUploadDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result && this.userProfile) {
        const img = result as string;
        this.photoFile = this.utilityService.base64ToFile(img, "photo");
        this.userProfile.photoUrl = result as string;
      }
    });
  }

  @ViewChild('fileInput') fileInput?: ElementRef;
  fileAttr = 'Seleccionar Archivo';
  photoFile!: File;
  maxFileSize: number = 10 * (1024 * 1024); // 10 MB

  userProfile?: Profile;
  dialCode: DialCode = { name: '', dial_code: '', code: '' };
  default_dialCode: DialCode = { name: 'Colombia', dial_code: '+57', code: 'CO' };
  // _tempUserProfile?: Profile;

  ngOnInit(): void {
    console.log('EditUserProfileComponent.ngOnInit');
    const uid: string = this.userService.getUserId();
    console.log('uid: ' + uid);
    this.getUserProfile(uid);
    console.log(this.userProfile)

    // Analytics
    this.analyticsService.logEventScreenView(
      'EditUserProfileComponent',
      EditUserProfileComponent.name);
  }

  birthDateIsValid(): boolean {
    // console.warn("userProfile.birthDate", this.userProfile?.personalData?.birthDate);
    console.warn("birthDateElem", this.birthDateElem?.nativeElement.value);
    if (this.birthDateElem?.nativeElement.value) {
      try {
        const date = new Date(this.birthDateElem?.nativeElement.value);
        return true;
      } catch (error) {
      }
    }
    return false;
  }

  dialCodeCtrl = new FormControl();

  getUserProfile(id: string) {
    try {
      this.userProfileService.getUserProfile(id)
        .then(res => {
          // .subscribe(res => {
          console.log(`#COD132 this.userProfileService.getUserProfile(${id})`);
          console.log(res);
          this.userProfile = res;
          if (this.userProfile) {
            this.userProfile.id = id;
            if (this.userProfile.personalData?.gender) this.gender = this.userProfile.personalData.gender;
            if (this.userProfile.personalData?.nationality) this.nationality = this.userProfile.personalData.nationality;
            if (this.userProfile.personalData?.educationLevel) this.educationLevel = this.userProfile.personalData.educationLevel;
            if (this.userProfile.personalData?.birthDate) this.birthDateCtrl = new FormControl(this.userProfile.personalData?.birthDate);
            if (this.userProfile.personalData?.phonePrefix &&
              this.userProfile.personalData?.phoneCountry &&
              this.userProfile.personalData?.phoneCountryCode
            ) {
              const countryCode = this.userProfile.personalData?.phoneCountryCode;
              const _dialCode = this.dial_codes.find(item => item.code == countryCode);
              if (_dialCode) {
                this.dialCode = _dialCode;
                this.dialCodeCtrl = new FormControl(this.dialCode);
              }
            }
          if (this.userProfile.institutionName) this.institutionName = this.userProfile.institutionName;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  @ViewChild('fullName') fullNameElem: ElementRef | undefined;
  @ViewChild('title') titleElem: ElementRef | undefined;
  @ViewChild('linkedinUrl') linkedinUrlElem: ElementRef | undefined;
  @ViewChild('phone') phoneElem: ElementRef | undefined;
  @ViewChild('speciality') specialityElem: ElementRef | undefined;
  @ViewChild('birthDateElem') birthDateElem: ElementRef | undefined;
  @ViewChild('instituteName') instituteNameElem: ElementRef | undefined;
  gender?: string;
  nationality?: string;
  educationLevel?: string;
  isSaving = false;

  async saveProfile(): Promise<void> {
    this.isSaving = true;
    try {

      console.log("saving User Profile");
      if (this.userProfile) {
        // Update the date in User Profile
        if (this.fullNameElem) this.userProfile.fullName = this.fullNameElem.nativeElement.value;
        if (this.titleElem) this.userProfile.title = this.titleElem.nativeElement.value;
        if (this.linkedinUrlElem) this.userProfile.linkedinUrl = this.linkedinUrlElem.nativeElement.value;
        if (this.instituteNameElem) this.userProfile.institutionName = this.instituteNameElem.nativeElement.value;

        if (!this.userProfile.personalData) this.userProfile.personalData = {};
        this.userProfile.personalData.gender = this.gender;
        this.userProfile.personalData.nationality = this.nationality;
        this.userProfile.personalData.educationLevel = this.educationLevel;
        if (this.birthDateElem && this.birthDateElem.nativeElement.value) {
          try {
            const dateMomentObject = moment(this.birthDateElem.nativeElement.value, "DD/MM/YYYY");
            this.userProfile.personalData.birthDate = dateMomentObject.toDate();
          } catch (error) {
            console.warn("Birth Date bad format");
            alert('No se pudo guardar la fecha de nacimiento correctamente.');
          }
        }
        if (this.phoneElem && !isNaN(Number(this.phoneElem.nativeElement.value))) this.userProfile.personalData.phone = Number(this.phoneElem.nativeElement.value);
        if (this.specialityElem) this.userProfile.personalData.speciality = this.specialityElem.nativeElement.value;

        // await this.userProfileService.updateUserProfile(this.userProfile);

        //Save all the professor profile data
        this.userProfile.personalData.phoneCountry = this.dialCode.name;
        this.userProfile.personalData.phonePrefix = this.dialCode.dial_code;
        this.userProfile.personalData.phoneCountryCode = this.dialCode.code;

        //profile_photo: File, cv_file: File
        const uid: string = this.userProfile.id ?? '';

        // profile_photo: File = new File(, );
        // const photoBlob: Blob = UtilFile.b64toBlob(this.userProfile.photoUrl ?? '', 'image/png');

        const result = this.userProfileService.updateUserProfile(this.userProfile, this.photoFile);
        console.log("LA FOTOOOO QUE ESTA EN EL SAVEPROFILE")
        console.log(this.photoFile)
        result.finally().then(value => {
          const okMsg = 'Tus cambios han sido guardados.';
          const errorMsg = 'No hemos podido guardar tus cambios. Intenta más tarde.';
          alert((value ? okMsg : errorMsg));
          // Back to Perfil page
          if (value) this.router.navigate(['/perfil']);
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isSaving = false;
    }
  }

  deleteProfile(){
    Swal.fire({
      title: "¿Está seguro que desea eliminar su perfil?",
      text: "Si elimina su perfil todos sus datos serán removidos de la base de datos.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#DOD2D2",
      denyButtonText:"Cancelar",
      confirmButtonText: "Si, eliminar"
    }).then(async (res) => {
      if (res.isConfirmed) {
        const providerData:AuthProvider[] = this.userService.getProviderData()
        if(providerData[0].providerId){
          this.userProfile?.id && this.userProfileService.deleteUserProfile(this.userProfile?.id)
          await this.userService.deleteAccountApple()
        }
        this.userProfile?.id && this.userProfileService.deleteUserProfile(this.userProfile?.id)
        this.userService.logout()
      }
    });
  }

  resetProfile() {
    // this.userProfile = Object.assign({}, this._tempUserProfile);
    if (this.userProfile) {
      // Update the date in User Profile
      if (this.fullNameElem) this.fullNameElem.nativeElement.value = this.userProfile.fullName;
      if (this.titleElem) this.titleElem.nativeElement.value = this.userProfile.title;
      if (this.linkedinUrlElem) this.linkedinUrlElem.nativeElement.value = this.userProfile.linkedinUrl;
    }

    // if (this._tempUserProfile) this.userProfile = Object.create(this._tempUserProfile);

  }

}

