<div class="container recover-pass-container">
    <form [formGroup]="newPassForm" class="recover-form">
        <p class="opacity-75 mb-4">Escribe tu nueva contraseña:</p>
        <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Introduzca su nueva contraseña</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="password.hasError('required')">
                La constraseña es requerida!
            </mat-error>
            <mat-error *ngIf="password.hasError('minlength')">
                Debe ser mínimo de 8 carácteres!
            </mat-error>
            <mat-error *ngIf="password.hasError('maxlength')">
                No debe ser mayor a 30 carácteres!
            </mat-error>
            <mat-error *ngIf="password.hasError('pattern')">
                Debe tener al menos una minúscula, una mayúscula y un número!
                <!-- y un caracter especial ($/.#)! -->
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Confirme su nueva contraseña</mat-label>
            <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="confirmPassword" required>
            <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide2">
                <mat-icon>{{ hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="confirmPassword.hasError('not_matching')">
                La constraseña no concide!
            </mat-error>
        </mat-form-field>

        <div class="d-flex w-100 gap-2 justify-content-center">
            <button mat-raised-button color="primary" [disabled]="newPassForm.invalid" class="py-2"
                (click)="handleResetPassword()"> Cambiar contraseña
            </button>
        </div>
    </form>
</div>