<div class="table-responsive rounded">
  <div class="d-flex gap-1 w-100 justify-content-end">
    <!-- <div class="save-changes"
      *ngIf="user && user.proactiveRefresh.user && user.providerId && router.url != '/comparador'"
      (click)="closeDialog()">
      <a mat-raised-button class="active" color="primary" routerLink="/comparador">Ver mis comparaciones</a>
    </div> -->
    <div class="save-changes">
      <button mat-flat-button color="accent" (click)="validateDownload()"
        [disabled]="isDonwloading || this.careers.length < 1">Descargar</button>
    </div>
    <!-- Hide the Save button to avoid user confusion -->
    <!-- <div class="save-changes">
      <button mat-flat-button color="primary" (click)="openDialog()">Guardar</button>
    </div> -->
    <!-- <app-download-comparator></app-download-comparator> -->
  </div>
  <!-- <table class="table table-dark table-striped-columns px-3"> -->
  <div class="table px-3">
    <table>
      <thead>
        <!-- <tr>
        <th colspan="8">
          <button mat-flat-button color="primary" class="save-changes" (click)="openDialog()">Guardar</button>
        </th>
      </tr> -->
        <tr>
          <th>
            <div class="bg-white h-100 w-100 stickyWhite"></div>
          </th>

          <!-- <th>
          <app-table-header-item></app-table-header-item>
        </th> -->
          <th *ngFor="let item of careersList" scope="col">
            <div class="d-flex align-content-between flex-wrap flex-column pe-2 child" *ngIf="item; else careerEmpty">
              <div class="d-flex align-items-left gap-0 my-0 w-100" style="height: 75px;">
                <!-- <img [src]="item.iesLogo" [alt]="item.iesNombre" width="60" height="60" />  -->
                <img [src]="item.iesLogo" [alt]="item.institution" width="40vw" height="40vh"
                  class="rounded-circle border border-white  gap-0 my-0" />
                <p class="d-block text-custom-truncate ml-1 gap-0 my-0"> {{ item.name }}</p>
              </div>
              <div class="d-flex justify-content-between align-items-center  gap-0 my-0 mt-2">
                <button mat-flat-button color="primary" class=" gap-0 me-1" (click)="onDetail(item)">Ver
                  detalles</button>
                <button mat-raised-button color="basic" class=" gap-0 ms-1" aria-label="Delete Button"
                  (click)="onDelete(item)">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </div>
            </div>
          </th>

          <ng-template #careerEmpty>
            <div class="pe-2 bg-white">
              <div class="d-flex align-items-left text-top" style="height: 75px;">
                <p class="d-block text-custom-truncate ml-1"><i>Agregar carrera</i></p>
              </div>
              <div class="mt-2 w-100 d-flex justify-content-end">
                <button mat-raised-button color="basic" aria-label="Add Button" (click)="onAdd()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
              <!-- <mat-icon
            aria-hidden="false"
            aria-label="Add"
            fontIcon="add"
          ></mat-icon> -->
            </div>
          </ng-template>
        </tr>
      </thead>
      <!--SPACER-->
      <div class="row"></div>
      <!--END SPACER-->

      <tbody>
        <tr>
          <th scope="row" style="vertical-align: text-top;">
            <div class="me-1">Universidad</div>
          </th>
          <td *ngFor="let item of institutes" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: text-top;">
            <div class="me-1">Perfil de egresado</div>
          </th>
          <!-- <td *ngFor="let item of profiles" class="cell-style">{{ item }}</td> -->
          <td *ngFor="let item of profiles" class="cell-style">
            <app-expandable-cell [content]="item"></app-expandable-cell>
          </td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: text-top;">
            <div class="me-1">Tipo</div>
          </th>
          <td *ngFor="let item of iesCharacters" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: text-top;">
            <div class="me-1">Duración</div>
          </th>
          <td *ngFor="let item of durations" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: text-top;">
            <div class="me-1">Modalidad</div>
          </th>
          <td *ngFor="let item of modalities" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: text-top;">
            <div class="me-1">Sede</div>
          </th>
          <td *ngFor="let item of headquarters" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: text-top;">
            <div class="me-1">Ubicación</div>
          </th>
          <td *ngFor="let item of locations" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th scope="row" style="vertical-align: text-top;">
            <div class="me-1">Precio</div>
            <div>
              <i style="font-size: small;font-weight: normal;">aproximado</i>
            </div>
          </th>
          <td *ngFor="let item of prices; let i = index" class="cell-style">
            {{ item }}
            <mat-icon *ngIf="pricesWithoutFormat && pricesWithoutFormat[i]" class="icon-sm" color="primary"
              matTooltipClass="custom-tooltip"
              matTooltip="El precio aproximado es {{ formatPrice(pricesWithoutFormat[i])}}" matTooltipPosition="after">
              info
            </mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- <div class="save-changes">
    <button mat-flat-button color="accent" [disabled]="isCapturing" (click)="printHtml()">Imprimir PDF</button>
  </div>
  <div class="save-changes">
    <button mat-flat-button color="accent" [disabled]="isCapturing" (click)="generatePdf()">Compartir PDF</button>
  </div>
  <div class="save-changes">
    <button mat-flat-button color="accent" [disabled]="isCapturing" (click)="generatePdfv2()">Compartir PDF v2</button>
  </div> -->

  <!-- Capture div hidden -->
  <div id="screenHidden" class="px-0 overflow-scroll container-fluid justify-content-center " [hidden]="true"
    style="background-color: white; overflow: hidden !important; width: 1024px; height: 900px;" #screen>
    <div class="row justify-content-start align-items-center ">
      <div style="width: 8px"></div>
      <div id="logoHidden" class="px-0" style="width: 130px" [hidden]="false">
        <!-- <app-logo class="col" size="130px" style="display: contents;"></app-logo> -->
        <a routerlink="/">
          <img src="../../assets/images/logo.png" alt="guiauniversitaria.net" class="img-fluid">
        </a>
      </div>
      <h5 class="col" style="text-align: center;">Guía Universitaria - Comparador de carreras</h5>
    </div>
    <table style="overflow: hidden;">
      <thead>
        <tr>
          <th class="" style="width: 230px;">
            <div class="bg-white h-100 w-100"></div>
          </th>
          <th *ngFor="let item of careersList;let i = index" scope="col" style="width: 230px;">
            <div class="d-flex align-content-between flex-wrap flex-column px-0 mx-0" *ngIf="item; else careerEmpty2">
              <div class="d-flex align-items-left gap-0 my-0 w-100" style="height: 75px;">
                <!-- <img [src]="item.iesLogo" [alt]="item.iesNombre" width="60" height="60" />  -->
                <img [src]="item.iesLogo + '&withheaders=yes&not-from-cache-please'" crossorigin="*"
                  [alt]="item.institution" width="40vw" height="40vh"
                  class="rounded-circle border border-white  gap-0 my-0" />
                <p class="d-block text-custom-truncate ml-1 gap-0 my-0"> {{ item.name }}</p>
              </div>

              <div class="d-flex justify-content-center align-items-center gap-0 my-0 mt-2">
                <a [id]="'viewButton'+i"
                  [href]="'https://guiauniversitaria.net/buscador?query=' + item.name + '&institution=' + item.institution">
                  <button mat-flat-button color="primary" class=" gap-0">
                    Ver
                  </button>
                </a>
              </div>
            </div>
            <div style="width: 8px;">
            </div>
          </th>

          <ng-template #careerEmpty2>
          </ng-template>
        </tr>
      </thead>
      <!--SPACER-->
      <div class="row"></div>
      <!--END SPACER-->

      <tbody>
        <tr>
          <th style="vertical-align: text-top;">
            <div class="me-1">Universidad</div>
          </th>
          <td *ngFor="let item of institutes" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th style="vertical-align: text-top;">
            <div class="me-1">Perfil de egresado</div>
          </th>
          <td *ngFor="let item of profiles" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th style="vertical-align: text-top;">
            <div class="me-1">Tipo</div>
          </th>
          <td *ngFor="let item of iesCharacters" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th style="vertical-align: text-top;">
            <div class="me-1">Duración</div>
          </th>
          <td *ngFor="let item of durations" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th style="vertical-align: text-top;">
            <div class="me-1">Modalidad</div>
          </th>
          <td *ngFor="let item of modalities" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th style="vertical-align: text-top;">
            <div class="me-1">Sede</div>
          </th>
          <td *ngFor="let item of headquarters" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th style="vertical-align: text-top;">
            <div class="me-1">Ubicación</div>
          </th>
          <td *ngFor="let item of locations" class="cell-style">{{ item }}</td>
        </tr>
        <tr>
          <th style="vertical-align: text-top;">
            <div class="me-1">Precio
              <div>
                <i style="font-size: small;font-weight: normal;">aproximado</i>
              </div>
            </div>
          </th>
          <td *ngFor="let item of prices; let i = index" class="cell-style">
            {{ item }}
            <div *ngIf="pricesWithoutFormat && pricesWithoutFormat[i]" style="font-size: x-small;font-weight: normal;">
              El precio aproximado es {{ formatPrice(pricesWithoutFormat[i]) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

<!-- Overlay para el spinner -->
<div *ngIf="isDonwloading" class="spinner-overlay">
  <div class="spinner-container">
    <mat-spinner color="accent"></mat-spinner>
    <p>Descargando...</p>
  </div>
</div>