import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { CareerDetailComponent } from './career-detail/career-detail.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CreditosComponent } from './creditos/creditos.component';
import { AnonymousGuard } from './guards/anonymous.guard';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
// import { SearchComponent } from './pages/search/search.component';
import { PlansComponent } from './plans/plans.component';
// import { SearchFilterComponent } from './search-filter/search-filter.component';
// import { UniversityTeacherSearchComponent } from './university-teacher-search/university-teacher-search.component';
import { SearchComponent } from './pages/search/search.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { EditUserProfileComponent } from './user/edit-user-profile/edit-user-profile.component';
import { UserAccessGuard } from './guards/user-access.guard';
import { CareersComparisonComponent } from './pages/careers-comparison/careers-comparison.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { FavoriteListComponent } from './components/favorite-list/favorite-list.component';
import { CustomRecoverPassComponent } from './components/custom-recover-pass/custom-recover-pass.component';
import { CareerDetailComponent } from './career-detail/career-detail.component';
import { EsalComponent } from './pages/esal/esal.component';
import { LoginStudentComponent } from './components/login/login-student/login-student.component';
import { VocationalTestComponent } from './vocational-test/vocational-test.component';
import { DonationsComponent } from './donations/donations.component';
import { AmountMethodComponent } from './donations/components/amount-method/amount-method.component';
import { CartComponent } from './donations/components/cart/cart.component';
import { PaymentResultsComponent } from './donations/components/payment-results/payment-results.component';

const titleGU = 'Guía Universitaria';

const routes: Routes = [
  // { path: '', component: HomeComponent, title: titleGU + ' - Inicio' },
  { path: '', component: HomeComponent, title: titleGU + '' },
  { path: 'inicio', redirectTo: '/', pathMatch: 'full' }, // redirect
  // { path: 'buscar', component: SearchComponent, title: titleGU + ' - Buscador' },
  { path: 'nosotros', component: AboutComponent, title: titleGU + ' - Nosotros' },
  { path: 'nosotros/resultados-esal-dian', component: EsalComponent, title: titleGU + ' - Resultados ESAL DIAN' },
  { path: 'contactanos', component: ContactUsComponent, title: titleGU + ' - Contáctanos' },
  { path: 'contacto', redirectTo: '/contactanos', pathMatch: 'full' }, // redirect
  { path: 'creditos', component: CreditosComponent, title: titleGU + ' - Créditos' },
  { path: 'servicios', component: PlansComponent, title: titleGU + ' - Servicios' },
  // { path: 'detalle', component: CareerDetailComponent, title: titleGU + ' - Carrera - ' },
  { path: 'buscador', component: SearchComponent, title: titleGU + ' - Buscador' },
  { path: 'buscar', redirectTo: '/buscador', pathMatch: 'full' }, // redirect
  { path: 'comparador', component: CareersComparisonComponent, title: titleGU + ' - Comparador' },
  { path: 'comparar ', redirectTo: '/comparador', pathMatch: 'full' }, // redirect
  { path: 'favoritos', component: FavoriteListComponent, title: titleGU + ' - Favoritos' },
  { path: 'favoritos', redirectTo: '/favoritos', pathMatch: 'full' }, // redirect
  { path: 'register', component: RegisterComponent, title: titleGU + ' - Registro', canActivate: [AnonymousGuard] },
  { path: 'recover&password', component: RecoverPasswordComponent, title: titleGU + ' - Recuperar contraseña', canActivate: [AnonymousGuard] },
  { path: 'actions-app', component: CustomRecoverPassComponent, title: titleGU + ' - Cambiar contraseña', canActivate: [AnonymousGuard] },
  { path: 'login', component: LoginComponent, title: titleGU + ' - Autenticación', canActivate: [AnonymousGuard] },
  // { path: 'carrera/:id', component: CareerDetailComponent, title: titleGU + ' - Carrera - ' },
  // { path: 'universidad', component: UniversityTeacherSearchComponent, title: titleGU + ' - Universidad' },
  { path: 'inicio-estudiantes', component: LoginStudentComponent, title: titleGU + '- Autenticación estudiante' , canActivate: [AnonymousGuard]},
  { path: 'perfil', component: UserProfileComponent, title: titleGU + ' - Perfil', canActivate: [UserAccessGuard] },
  { path: 'perfil/editar', component: EditUserProfileComponent, title: titleGU + ' - Editar Perfil', canActivate: [UserAccessGuard] },
  { path: 'test-vocacional', component: VocationalTestComponent, title: titleGU + ' - Test Vocacional'},
  { path: 'career-test', component: CareerDetailComponent, title: titleGU + ' - Career Test' },
  { path: 'donaciones', component: DonationsComponent, title: titleGU + ' - Donaciones', children:[
    {path:"" , component: AmountMethodComponent},
    {path:"pagar" , component: CartComponent},
    {path:"resultados" , component: PaymentResultsComponent}
  ] },
  { path: '**', redirectTo: '/' },
  // { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
