<div class="row d-flex m-0" mat-dialog-title>
  <div class="d-flex justify-content-between align-items-center">
    <app-logo size="130px"></app-logo>
    <h5 class="text-center">Comparador de carreras</h5>
    <!-- </div>
  <div class="col d-flex justify-content-end col-3"> -->
    <button class="shadow " mat-dialog-close mat-icon-button aria-label="dialog close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content style="max-width: 100vw; overflow-x: hidden; padding: 0;">
  <app-another-table></app-another-table>
</mat-dialog-content>
<!--<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
   <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> 
</mat-dialog-actions>
-->
<!-- 
  <mat-card class="example-card">
    <mat-card-title-group>
      <mat-card-title>Especialización en gerencia de servicios de salud</mat-card-title>
      <mat-card-subtitle>Universidad Libre</mat-card-subtitle>
      <img mat-card-sm-image src="../../assets/images/logoUniversidad1.jpg" alt="Bootstrap"
        class="d-none d-md-block mx-1 rounded-circle" />
    </mat-card-title-group>
    <mat-card-content>
      <div class="mx-1">
        <ul class="list-unstyled">
          <li class="mb-1">
            <span class="fw-bold"> Duración: </span>
            3 semestres
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Precio: </span>
            $13M COP
          </li>
          <mat-card-actions align="start">
            <button mat-button>
              <mat-icon>open_in_new</mat-icon>
              Sitio Web
            </button>
          </mat-card-actions>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card">
    <mat-card-title-group>
      <mat-card-title>Especialización en gerencia de servicios de salud</mat-card-title>
      <mat-card-subtitle>Universidad Libre</mat-card-subtitle>
      <img mat-card-sm-image src="../../assets/images/logoUniversidad1.jpg" alt="Bootstrap"
        class="d-none d-md-block mx-1 rounded-circle" />
    </mat-card-title-group>
    <mat-card-content>
      <div class="mx-1">
        <ul class="list-unstyled">
          <li class="mb-1">
            <span class="fw-bold"> Duración: </span>
            3 semestres
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Modalidad: </span>
            Presencial
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Tipo: </span>
            Privada
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Sede: </span>
            Sede seccional/Barranqulla,Atlántico,Col
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Precio: </span>
            $13M COP
          </li>
          <mat-card-actions align="start">
            <button mat-button>
              <mat-icon>open_in_new</mat-icon>
              Sitio Web
            </button>
          </mat-card-actions>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card">
    <mat-card-title-group>
      <mat-card-title>Especialización en gerencia de servicios de salud</mat-card-title>
      <mat-card-subtitle>Universidad Libre</mat-card-subtitle>
      <img mat-card-sm-image src="../../assets/images/logoUniversidad1.jpg" alt="Bootstrap"
        class="d-none d-md-block mx-1 rounded-circle" />
    </mat-card-title-group>
    <mat-card-content>
      <div class="mx-1">
        <ul class="list-unstyled">
          <li class="mb-1">
            <span class="fw-bold"> Duración: </span>
            3 semestres
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Modalidad: </span>
            Presencial
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Tipo: </span>
            Privada
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Sede: </span>
            Sede seccional/Barranqulla,Atlántico,Col
          </li>
          <li class="mb-1">
            <span class="fw-bold"> Precio: </span>
            $13M COP
          </li>
          <mat-card-actions align="start">
            <button mat-button>
              <mat-icon>open_in_new</mat-icon>
              Sitio Web
            </button>
          </mat-card-actions>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card">
    <mat-card-title-group>
      <mat-card-title>Especialización en gerencia de servicios de salud</mat-card-title>
      <mat-card-subtitle>Universidad Libre</mat-card-subtitle>
      <img mat-card-sm-image src="../../assets/images/logoUniversidad1.jpg" alt="Bootstrap"
        class="rounded-circle border border-white" />
    </mat-card-title-group>
    <mat-card-content>
      <div class="my-1">
        <ul class="list-unstyled">
          <li class="mb-2">
            <span class="fw-bold"> Duración: </span>
            3 semestres
          </li>
          <li class="mb-2">
            <span class="fw-bold"> Modalidad: </span>
            Presencial
          </li>
          <li class="mb-2">
            <span class="fw-bold"> Sede: </span>
            Sede seccional/Barranqulla,Atlántico,Colombia
          </li>
          <li class="mb-2">
            <span class="fw-bold"> Precio: </span>
            $13.776.554 COP
          </li>
          <li class="mb-2">
            <span class="fw-bold"> Sitio web: </span>
            <a class="text-decoration-none" href="
                http://www.unilibrebaq.edu.co">
              unilibrebaq.edu.co
            </a>
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card> -->