import {
  Component,
  Inject,
  forwardRef,
  Input,
  Output,
  EventEmitter, Optional,
  AfterViewInit,
  ElementRef,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { BaseWidget, NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from "angular-instantsearch";
import { connectAutocomplete } from "instantsearch.js/es/connectors";
import { AutocompleteWidgetDescription, AutocompleteConnectorParams } from "instantsearch.js/es/connectors/autocomplete/connectAutocomplete";

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent extends TypedBaseWidget<AutocompleteWidgetDescription, AutocompleteConnectorParams>
  implements AfterViewInit {
  override state: AutocompleteWidgetDescription['renderState'] = {
    currentRefinement: "",
    refine: () => null,
    indices: []
  };

  queryCtrl = new FormControl('');

  @Input() isMandatory: boolean = true;
  @Output() onQuerySuggestionClick = new EventEmitter<{ query: string }>();

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('SearchBox');
    this!.createWidget(connectAutocomplete as any, {});
  }

  @ViewChild('searhinput', { read: ElementRef }) searhinput!: ElementRef;

  ngAfterViewInit(): void {
    // this.queryCtrl.setValue(this.initValue);

    console.warn('AfterViewInit autocomplete - initValue:', this.initValue);

  }

  public handleChange($event: KeyboardEvent): void {
    const value = ($event.target as HTMLInputElement).value;
    this.state.refine(value);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.careerSample = this.RandomValue(this.careerSamples);

    this.queryCtrl.setValue(this.initValue);

  }

  careerSample: string = '';
  careerSamples: string[] = ['Medicina', 'Ingeniería', 'Economía', 'Ingeniería Civil', 'Contaduría',
    'Enfermería', 'Diseño Gráfico', 'Gastronomía', 'Relaciones Internacionales'];

  private RandomValue(array: string[]): string {
    const max = array.length;
    const min = 1;
    const index = Math.floor(Math.random() * (max - min + 1) + min) - 1;

    return array[index];
  }

  @Input() initValue: string = '';

  @Output() inputValue = new EventEmitter<string>();

  MINLENGHT: number = 3;

  onInputChange($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    this.inputValue.emit(value);
  }

  onOptionSelected(event: any) {
    const selectedOption = event.option.value;
    this.inputValue.emit(selectedOption);
  }

  clearValue(): void {
    this.queryCtrl.setValue('');
  }
}

/*
Notes:
 TODO: https://www.algolia.com/doc/ui-libraries/autocomplete/guides/adding-suggested-searches/
  Ref: https://github.com/algolia/doc-code-samples/blob/master/angular-instantsearch/autocomplete-results-page/src/app/autocomplete.component.ts
*/