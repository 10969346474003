import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { mockDataTest, mockResultsTest } from './mockDataTest';
import {
  trigger,
  style,
  animate,
  transition,
  keyframes,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { DialogContinueResultsComponent } from './dialog-continue-results/dialog-continue-results.component';
import { MockResultsTest } from '../models/vocationalTest.interface';
import { VocationalTestService } from '../services/vocational-test.service';
import { UserProfileService } from '../services/user-profile.service';
import { Router } from '@angular/router';
import { DialogSessionComponent } from './dialog-session/dialog-session.component';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-vocational-test',
  templateUrl: './vocational-test.component.html',
  styleUrls: ['./vocational-test.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        animate(
          '1.5s ease-in-out',
          keyframes([
            style({ opacity: 0, transform: 'translateY(50px)', offset: 0 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
          ])
        ),
      ]),
      transition(':leave', [
        animate(
          '.5s ease-in-out',
          keyframes([
            style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
            style({ opacity: 0, transform: 'translateY(50px)', offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class VocationalTestComponent {
  firstFormGroup: FormGroup;
  isLinear = false;

  showIntroduction = true;
  showForm = false;
  showResults = false;
  unansweredQuestions: number[] = [];

  data = mockDataTest;
  results = mockResultsTest;
  topResults: MockResultsTest[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private vocationalTestService: VocationalTestService,
    private userProfileService: UserProfileService,
    private userService: UserService,
    public router: Router
  ) {
    this.firstFormGroup = this._formBuilder.group({});
  }
  ngOnInit(): void {
    this.data.forEach((question, index) => {
      question.answers.forEach((answer) => {
        this.firstFormGroup.addControl(
          `answer${index}_${answer.id}`,
          this._formBuilder.control(false)
        );
      });
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogContinueResultsComponent, {
      width: '400px',
      data: {
        unansweredQuestions: this.unansweredQuestions,
        setShowResults: () => {
          this.showResults = true;
        },
        setScroll: () => {
          this.scrollUp();
        },
      },
    });
  }

  async saveResultsWithUser(userId: string) {
    const idResults = await this.vocationalTestService.saveTestResults(
      this.topResults,
      userId
    );
    userId &&
      (await this.userProfileService.updateUserVocationalId(
        userId,
        idResults,
        true
      ));
      const email = this.userService.getEmail()
      const name = this.userService.getFullName()
      await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/sendTestResults",{
        method:"POST",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          secret:environment.secretEmail,
          name,
          email,
          results: this.topResults
        })
      })

    this.showResults = true;
    this.scrollUp();
  }

  async saveResultsId() {
    const idResults = await this.vocationalTestService.saveTestResults(
      this.topResults
    );
    localStorage.setItem('testId', idResults);
    this.dialog.open(DialogSessionComponent, {
      width: '400px',
    });
  }

  getResults() {
    this.unansweredQuestions = [];

    const selectedAnswers = this.firstFormGroup.value;
    let answerCounts: { [key: number]: number } = {};

    this.data.forEach((question, index) => {
      let answered = false;
      question.answers.forEach((answer) => {
        const controlName = `answer${index}_${answer.id}`;
        if (selectedAnswers[controlName]) {
          answered = true;
          if (!answerCounts[answer.id]) {
            answerCounts[answer.id] = 0;
          }
          answerCounts[answer.id]++;
        }
      });

      if (!answered) {
        this.unansweredQuestions.push(index + 1);
      }
    });

    const sortedAnswerCounts = Object.entries(answerCounts).sort(
      (a, b) => b[1] - a[1]
    );
    const topTwoAnswers = sortedAnswerCounts
      .slice(0, 2)
      .map((entry) => Number(entry[0]));

    this.topResults = this.results.filter((result) =>
      topTwoAnswers.includes(result.id)
    );

    if (this.unansweredQuestions.length > 0) {
      this.openDialog();
    } else {
      const currentUser = JSON.parse(localStorage.getItem('user')!);
      currentUser
        ? this.saveResultsWithUser(currentUser.uid)
        : this.saveResultsId();
    }
  }
  @ViewChild('focus', { read: ElementRef }) focus!: ElementRef;

  scrollUp(): void {
    setTimeout(() =>
      this.focus.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    );
  }
  startTest() {
    this.showIntroduction = false;
    this.showForm = true;
  }
}
