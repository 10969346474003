<app-home-search-box></app-home-search-box>
<app-redirect-button-vocational></app-redirect-button-vocational>
<!-- <app-action-button></app-action-button> -->
<app-hero></app-hero>
<app-programs></app-programs>
<app-student-features></app-student-features>
<app-plans></app-plans>
<app-contact-us></app-contact-us>
<app-newsletter></app-newsletter>
<!-- <app-testimonials></app-testimonials> -->
<!-- Partners -->
<app-partners></app-partners>