<div class="px-4 my-4 w-100 d-flex justify-content-between align-items-baseline flex-wrap"
  *ngIf="user && user.proactiveRefresh.user && user.providerId">
  <div class="d-flex flex-column w-300px">
    <div class="">Selecciona una comparación guardada: </div>
    <mat-form-field appearance="fill" class="d-flex justify-content-start align-items-center w-100">
      <mat-label>Nombre del comparador</mat-label>
      <mat-select (selectionChange)="onSelectedComparatorChange($event)">
        <mat-option *ngFor="let comparator of selectedComparator" [value]="comparator.value"
          [ngClass]="{'unsaved':  comparator.value === 'Borrador sin guardar'}">
          {{comparator.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <button mat-raised-button (click)="clearTable()">Limpiar comparador</button>
  </div>


</div>

<div *ngIf="!user" class="p-4">
  <button mat-flat-button color="primary" class="gap-0 me-1" (click)="onCompare()">Ver comparaciones guardadas</button>
</div>

<app-another-table [selectedTableName]="selectedTableName"></app-another-table>