<div class="container">

    <h3 style="margin: 20px 0 0 25px;">Créditos educativos</h3>

    <div class="row mx-3 g-3 my-5 row-cols-2 row-cols-md-3 row-cols-lg-5 gap-lg-0" #init>
        <div class="col">
            <mat-card  (click)="changeInstitute('U. de los Andes');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/uniandes.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card  (click)="changeInstitute('U. de La Sabana');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/images/unisabana-logo.png" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card  (click)="changeInstitute('ICETEX');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/icetex.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Bancolombia - Sufi');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/bancolombia.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Serfinanza') ;instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/serfinanza.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('BBVA') ;instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/bbva.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Pichincha') ;instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/bancopichincha.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Davivienda') ;instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/davivienda.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Banco de Bogotá') ;instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/bancoDeBogota.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Banco Av Villas') ;instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/bancoAvVillas.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Banco de Occidente') ;instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/bancoOccidente.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Lulo Bank') ;instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/lulobank.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Financiera Comultrasan');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/comultrasan.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Fincomercio');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/fincomercio.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Comfama');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/comfama.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Bancoomeva');instituteData &&  scrollDown()"><img mat-card-md-image  src="../../assets/svg/bancoomeva.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Compensar');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/compensar.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Bancamía');instituteData &&  scrollDown()"><img  mat-card-md-image  src="../../assets/svg/bancamia.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Finky');instituteData &&  scrollDown()"><img mat-card-md-image src="../../assets/svg/finky.svg" alt=""></mat-card>
        </div>
        <div class="col">
            <mat-card (click)="changeInstitute('Financiera Progressa');instituteData &&  scrollDown()"><img  mat-card-md-image src="../../assets/svg/financieraProgressa.svg" alt=""></mat-card>
        </div>


 
   
        <!-- Pichincha: https://www.bancopichincha.com.co/web/personas/credito-educativo-
        BBVA: https://www.bbva.com.co/personas/productos/prestamos/consumo/educacion.html
        Davivienda: https://www.davivienda.com/wps/portal/personas/nuevo/personas/aqui_puedo/construir_mi_futuro/educacion/
        Bancolombia: https://www.bancolombia.com/personas/creditos/estudio
        ICETEX: https://web.icetex.gov.co/atencion-al-ciudadano/preguntas-frecuentes/credito-educativo -->
    </div>
    <ng-container *ngIf="instituteData !== undefined" >
        <div class="d-flex align-items-center gap-2 py-3 w-50 justify-content-between" >
            <img src={{instituteData.image}} width="125" height="125" alt="">
            <h2><strong>{{instituteData.institute}}</strong></h2>
        </div>

        <!-- <h3>Préstamo de la entidad a estudiantes para estudios de:</h3>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">Pregrado</li>
            <li class="list-group-item">Especializaciones</li>
            <li class="list-group-item">Maestrias</li>
            <li class="list-group-item">Diplomados</li>
            <li class="list-group-item">Seminarios</li>
            <li class="list-group-item">Cursos de idioma</li>
            <li class="list-group-item">Carreras militares dentro de Colombia</li>
        </ul> -->
        <mat-tab-group>
            <mat-tab label="Tipo de Crédito">
                <div class="info-credits"  *ngIf="programData">
                    <div class="w-100 gap-5 d-flex pt-4 pb-0">
                        <h3>{{programData.title}}</h3>
                        <div>
                            <a mat-raised-button class="active" color="primary" href={{programData.link}} target="_blank" >
                                Sitio web
                            </a>
                        </div>
                    </div>
                    <!-- <div class="button-container" style="position: fixed; right: 20px; bottom: 20px; background-color:#3F51B5; color:white;" (click)="scrollUp()">
                          <mat-icon class="my-icon">arrow_up</mat-icon>
                          Volver arriba
                    </div> -->
                    <mat-tab-group class="w-100">
                        <mat-tab *ngFor="let tab of instituteData.creditPrograms; let i = index">
                            <ng-template mat-tab-label>
                                <span (click)="changeCreditProgram(i)">{{tab.title}}</span>
                            </ng-template>                               
                        </mat-tab>
                    </mat-tab-group>
                    
                    <div class="column-data" #focus>
                         <h5>Características y beneficios</h5>
                        <ul>
                            <li *ngFor="let benefit of programData.benefits">
                              {{benefit}}
                            </li>
                        </ul>
                    </div>
                    <div class="column-data">
                        <div *ngIf="programData.interestType || programData.interestType">
                            <h5>Tasa</h5>
                            <p>{{programData.interestRate}}&nbsp;{{programData.interestType}}</p>
                        </div>
                        
                        <div class="info-credits">
                            <div class="column-data">
                                <h5>Horario de atención</h5>
                                    <p *ngIf="instituteData.callCenterHours">{{instituteData.callCenterHours.startDay}} a {{instituteData.callCenterHours.endDay}}</p>
                                    <p>{{instituteData.callCenterHours?.schedule}}</p>
                                    <div *ngIf="instituteData.callCenterHours?.weekendDay">
                                        <p >{{instituteData.callCenterHours?.weekendDay}}</p>
                                        <p>{{instituteData.callCenterHours?.weekendStartHour}} a {{instituteData.callCenterHours?.weekendEndHour}}</p>
        
                                    </div>

                            </div>
                            <div class="column-data">
                                <h5>Líneas de atención</h5>
                                    <div  *ngFor="let numbers of instituteData.numberChannels">
                                        <h6>{{numbers.city}}</h6>
                                        <p>{{numbers.diallingCode}} {{numbers.phoneNumber}}</p>
                                    </div>
                            </div>
                        </div>

                        <h5>Redes sociales</h5>
                        <div class="w-100  d-flex">
                            <ul class="list-unstyled d-flex justify-content-center justify-content-lg-start gap-4">
                                <li class="d-flex justify-content-center justify-content-lg-start g-4" *ngFor="let social of instituteData.socialMedia">
                                    <a href="{{social.link}}" target="_blank">
                                      <img class="socials" src="{{getSvg(social.title)}}" alt="{{social.title}}" width="30" height="30">
                                    </a>
                                  </li>
                            </ul>
                        </div>
               
                    </div>
                   
                </div>
                
            </mat-tab>
    
            <mat-tab label="Requisitos" *ngIf="programData?.requiremets">
                <div class="py-5" *ngIf="programData">
                    <div *ngFor="let requirement of programData.requiremets">
                        <h5 *ngIf="requirement.type">{{requirement.type}}</h5>
                        <ul>
                            <li *ngFor="let eachOne of requirement.requirement">
                                {{eachOne}}
                            </li>
                        </ul>

                    </div>
                </div>
            </mat-tab>
            <!-- <mat-tab label="Beneficios">
                <div class="py-5">
                    <ul>
                        <li>Tasas fijas por utilización</li>
                        <li>Viabilidad al instante de la solicitud</li>
                        <li>Giro directo a la institución educativa*</li>
                        <li>Giro directo a la institución dentro y fuera del país</li>
                    </ul>
                </div>
            </mat-tab> -->
        </mat-tab-group>
        <!-- <mat-divider class="py-5"></mat-divider> -->
    </ng-container>
</div>