import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-use-policy',
  templateUrl: './data-use-policy.component.html',
  styleUrls: ['./data-use-policy.component.scss']
})
export class DataUsePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
