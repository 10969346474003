import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, MinLengthValidator, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Career } from 'src/app/models/career.interface';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { tenDigitsValidator, noRepeatedDigitsValidator } from '../../validators/custom.validators';
import { DownloadRequestService } from 'src/app/services/download-request.service';
import { DownloadRequest } from 'src/app/models/download-request.interface';
import { DIAL_CODES } from 'src/app/util/DialCodes';
import { UserService } from 'src/app/services/user.service';
import { UserProfileService } from 'src/app/services/user-profile.service';


@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss']
})
export class DownloadDialogComponent implements OnInit {

  countries = DIAL_CODES; // countryCodes: string[] = ['+57', '+1', '+44', '+49', '+33', '+91', '+61']; // Lista de códigos comunes
  names = ['Juana Arroyo', 'Juan Martinez', 'Ashley Cole', 'Pedro Perez'];

  fullName = new FormControl('',
    [
      Validators.required,
      Validators.minLength(6),
      // Validators.pattern(/^(\b\w{3,}\b\s*){2,}$/), // Al menos dos palabras con 3+ caracteres
      Validators.pattern(/^(?=(\b\w{3,}\b.*?){2,}).*$/), // Al menos dos palabras con 3+ caracteres

    ]);
  email = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/), // email con por lo menos un punto (.)
  ]);
  phoneCode = new FormControl('+57', [Validators.required]);
  phone = new FormControl('',
    [
      Validators.required,
      tenDigitsValidator(), // Número exactamente de 10 dígitos
      noRepeatedDigitsValidator(), // Sin más de 3 dígitos repetidos consecutivos
    ]);

  country = new FormControl('Colombia', [Validators.required]);

  form = this.formBuilder.group({
    name: this.fullName,
    email: this.email,
    phone: this.phone,
    phoneCode: this.phoneCode,
    country: this.country,
  });

  constructor(
    private formBuilder: FormBuilder,
    private analyticsService: AnalyticsService,
    private downloadRequestService: DownloadRequestService,
    public dialogRef: MatDialogRef<DownloadDialogComponent>,
    private userService: UserService,
    private userProfileService: UserProfileService,
    @Inject(MAT_DIALOG_DATA) public data: {
      careers: Career[];
      user: {
        fullName: string;
        email: string;
        phonePrefix: string;
        phone: string;
        id: string;
        isLogged: boolean;
      }
    },) {
    // alert("careers: " + JSON.stringify(data));
  }

  async ngOnInit(): Promise<void> {
    this.placeholderFullName = this.getPlaceholderFullName();
    this.fullName.setValue(this.data.user.fullName);
    this.email.setValue(this.data.user.email);
    this.phone.setValue(this.data.user.phone);
    // Get Country name by prefix
    const country = (this.data.user.phonePrefix == '+57') ? { name: 'Colombia' } : this.countries.find(country => country.code === this.data.user.phonePrefix);
    if (country) {
      this.country.setValue(country.name);
    }

    const temp1 = this.fullName.errors;
    const temp2 = this.email.errors;
    const temp3 = this.phone.errors;
    // If it is logged then disable the data filled
    if (this.data.user.isLogged) {
      if (!this.fullName.errors)
        this.fullName.disable();

      if (!this.email.errors)
        this.email.disable();

      if (!this.phone.errors)
        this.phone.disable();

      if (country)
        this.phoneCode.disable();
    }
  }

  placeholderFullName: string = '';

  getPlaceholderFullName(): string {
    const index = Math.floor(Math.random() * this.names.length);
    return this.names[(index >= 0 && index < this.names.length) ? index : 0];
  }

  isSaving = false;
  async saveData(): Promise<void> {
    let result = true;
    try {
      this.isSaving = true;
      const data = {
        fullName: this.fullName.value,
        email: this.email.value,
        phone: `${this.phoneCode.value}${this.phone.value}`,
      }
      const downloadRequest: DownloadRequest = {
        user: {
          fullName: this.fullName.value ?? '',
          email: this.email.value ?? '',
          phonePrefix: this.phoneCode.value ?? '+57',
          phone: this.phone.value ?? '',
          id: this.data.user.id
        },
        careers: this.data.careers
      };
      // Save donwload-request record in database
      this.downloadRequestService.saveRequest(downloadRequest);

      // Save 'donwload-user' in local storage
      this.userService.saveLocalDownloadUser(downloadRequest.user);

      // Update User Profile when is Logged
      if (this.data.user.isLogged) {
        this.updateUserProfile(downloadRequest.user.id, downloadRequest.user.fullName,
          downloadRequest.user.phonePrefix, downloadRequest.user.phone);
      }

    } catch (error) {
      const errorFacedMsg = 'Presentamos problemas para enviar tu solicitud. Por favor, intenta más tarde.';
      alert(errorFacedMsg);
      console.error('Error in send ContactMeRequest');
      console.error(error);
      result = false;
    } finally {
      this.isSaving = false;
    }
    this.closeDialog(result);
  }

  closeDialog(result: boolean = false) {
    this.dialogRef.close(result);
  }

  async updateUserProfile(userId: string, fullName: string, phonePrefix: string, phone: string) {
    const userProfile = await this.userProfileService.getUserProfile(userId);

    if (userProfile) {
      userProfile.id = userId;
      userProfile.fullName = fullName;
      if (!userProfile.personalData)
        userProfile.personalData = {};
      try {
        userProfile.personalData.phone = phone as any as number;
        if (this.phoneCode.value)
          userProfile.personalData.phonePrefix = phonePrefix;
      } catch (error) {
        console.error(error);
      }
      const userProfileUpdated = await this.userProfileService.updateUserProfile(userProfile);
      if (!userProfileUpdated)
        console.log('Error updating user profile');
    }
  }
}
