export const mockValues = [
    {
        id:0,
        value:50000
      },
      {
        id:1,
        value:100000
      },
      {
        id:2,
        value:200000
      },
      {
        id:4,
        value:500000
      },
      {
        id:5,
        value:1000000
      }
]