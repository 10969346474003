import { Component } from '@angular/core';

@Component({
  selector: 'app-esal',
  templateUrl: './esal.component.html',
  styleUrls: ['./esal.component.scss']
})
export class EsalComponent {

  public basePath: string = '../assets/files/resultados-esal-dian/';
}
