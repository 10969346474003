import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { RegisterModalComponent } from 'src/app/components/register/register-modal/register-modal/register-modal.component';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  public professorsUrl = environment.publicUrls.professorsUrl;
  public institutesUrl = environment.publicUrls.institutesUrl;

  selectedOption: string;

  formLogin: FormGroup;
  hide = true;

  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    public dialogRef: MatDialogRef<LoginModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog
  ) {

    this.selectedOption = data.selectedOption

    this.formLogin = new FormGroup({
      email: this.email,
      password: new FormControl(),
      remindMe: new FormControl()
    });
  }

  ngOnInit(): void {
  }

  getErrorMessage() {
    // if (this.email.hasError('required')) {
    //   return 'Debes ingresar este campo';
    // }

    return this.email.hasError('email') ? '(No es un correo valido)' : '';
  }

  async onSubmit() {
    try {

      const email: string = this.formLogin.value.email;
      const password: string = this.formLogin.value.password;
      const response = await this.userService.loginWithEmail(email, password);

      if (response) {
        this.onNoClick()
        // this.router.navigate(['/perfil']);
      }

      // console.log(response);
      // this.router.navigate(['/inicio']);

    } catch (error) {
      // https://firebase.google.com/docs/auth/admin/errors
      const errorMessage = this.userService.getAuthMessageError(error);
      alert('Error de autenticación: ' + errorMessage);
      console.error(error);
    }
  }

  onLoginWithGoogle() {
    this.userService.loginWithGoogle()
      .then(response => {
        console.log(response);
        // this.router.navigate(['/inicio']);
        this.onNoClick()
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onLoginWithLinkedin() {
    // TODO
    this.userService.loginWithLinkedin()
      .then(response => {
        console.log(response);
        // this.router.navigate(['/inicio']);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onLoginWithFacebook() {
    this.userService.loginWithFacebook()
      .then(response => {
        console.log(response);
        // this.router.navigate(['/inicio']);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onLoginWithApple() {
    this.userService.loginWithApple()
      .then(response => {
        console.log(response);
        // this.router.navigate(['/inicio']);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  openRegisterModal(): void {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(RegisterModalComponent, {
      width: '85%',
      maxWidth: '850px',
      data: {
        selectedOption: this.selectedOption
      }
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
