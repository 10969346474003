import { Injectable } from '@angular/core';

import { Firestore, DocumentReference } from '@angular/fire/firestore';

import { Router } from '@angular/router';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  FieldValue,
  getDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { FirstDataResultsTest, MockResultsTest } from '../models/vocationalTest.interface';
import { Profile } from '../models/profile';
import { fromFirestore } from '../util/firestoreAdapters';

interface VocationalUpdate {
  vocationalResultsShowed: boolean,
  updatedAt: FieldValue,
  vocationalTestIds: string[] | FieldValue
}
@Injectable({
  providedIn: 'root',
})
export class VocationalTestService {
  path: string = 'vocationalTest';
  doc?: DocumentReference;
  constructor(public firestore: Firestore) {}

  async saveTestResults(
    results: MockResultsTest[],
    userId?: string
  ): Promise<string> {
    const collectionRef = collection(this.firestore, this.path);
    const userRef = doc(this.firestore, `students/${userId}`);
    try {
      const docSnap = await getDoc(userRef);
      let user;
      if (docSnap.exists()) {
        user = docSnap.data() as Profile;
      }
      const data = {
        createdAt: serverTimestamp(),
        results,
        ...(user
          ? {
              userData: {
                id: userId,
                email: user.email,
                fullName: user.fullName,
                phone: user.personalData?.phone ?? null,
                phonePrefix: user.personalData?.phonePrefix ?? '',
                phoneCountry: user.personalData?.phoneCountry ?? '',
                phoneCountryCode: user.personalData?.phoneCountryCode ?? '',
              },
            }
          : {}),
      };
      const docData = await addDoc(collectionRef, data);
      return docData.id;
    } catch (error) {
      console.error('Error in saveTestResults', error);
      return Promise.reject(
        new Error(
          'An error occurred while saving test results. Please try again later.'
        )
      );
    }
  }

  async getResults(testId: string) {
    try {
      const docRef = doc(this.firestore, `${this.path}/${testId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        return fromFirestore({...data}) as FirstDataResultsTest
      }
      return null;
    } catch (error) {
      console.error('No such document!');
      console.error('Error obtained results in getResults', error);
      return null;
    }
  }
  
  async getAllResults(testIds:string[]){
   try {
    const results = await Promise.all(
      testIds.map((testId) => this.getResults(testId))
    );
    return results;
   } catch (error) {
    console.error('No such document!');
    console.error('Error obtained results in getAllResults', error);
    return [];
   }
  }

  async updateResultsUser(userId: string, testId: string) {
    const testRef = doc(this.firestore, `${this.path}/${testId}`);
    const userRef = doc(this.firestore, `students/${userId}`);
    try {
      const docSnapUser = await getDoc(userRef);
      let user;
      if (docSnapUser.exists()) {
        user = docSnapUser.data() as Profile;
      }

      let updateDataInUser: Partial<VocationalUpdate> = {
        vocationalResultsShowed: true,
        updatedAt: serverTimestamp(),
      };
      if (user && user.vocationalTestIds) {
        updateDataInUser.vocationalTestIds = arrayUnion(testId);
      } else {
        updateDataInUser.vocationalTestIds = [testId]
      }

      await updateDoc(userRef, updateDataInUser);

      const updateUserInResults = {
        ...(user
          ? {
              userData: {
                id: userId,
                email: user.email,
                fullName: user.fullName,
                phone: user.personalData?.phone ?? null,
                phonePrefix: user.personalData?.phonePrefix ?? '',
                phoneCountry: user.personalData?.phoneCountry ?? '',
                phoneCountryCode: user.personalData?.phoneCountryCode ?? '',
              },
            }
          : {}),
      };

      await updateDoc(testRef, updateUserInResults);

      return true;
    } catch (error) {
      console.error(`Error updating results data and user in updateResultsUser`);
      console.error(`updating vocational test id: ${testId} and userId: ${userId}`);
      console.error(error);
      return false;
    }
  }
}
