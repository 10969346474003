<div class="container bg-light bg-opacity-75 rounded-4 py-2 my-2">
  <div class=" p-md-5">
    <div class="text-center text-lg-start">
      <h2 class="fw-bold display-4">Contáctanos</h2>
    </div>
    <div class="row">
      <div class="col-xl-5 col-lg-6 text-start">
        <p class="opacity-75" style="font-size: 20px;">Atendemos tus dudas o sugerencias. Diligencia el formulario y a la brevedad te
          contactaremos.
        </p>
        <div class=" row g-4 mb-2 justify-content-center justify-content-lg-start">
          <img class="w-100 h-100" src="../../assets/svg/contactus.svg" alt="">
        </div>
      </div>
      <div class="col-xl-6 col-lg-6">
        <div class="signup-form-wrapper">
          <form class="example-form" [formGroup]="form">
            <mat-form-field class="example-full-width " appearance="outline">
              <mat-label>Nombre</mat-label>
              <input matInput [formControl]="name">
            </mat-form-field>
            <mat-form-field class="example-full-width " appearance="outline">
              <mat-label>Correo Electrónico</mat-label>
              <input matInput [formControl]="email" placeholder="pat@example.com">
              <mat-error *ngIf="email.invalid">Email invalido</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width " appearance="outline">
              <mat-label>Teléfono</mat-label>
              <input matInput [formControl]="phone" type="number" placeholder="+57 555-12345">
              <mat-error *ngIf="phone.invalid">Teléfono invalido</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Escríbenos un mensaje</mat-label>
              <textarea matInput required [formControl]="message" maxlength="500"></textarea>
              <mat-error *ngIf="message.invalid">Mensaje Requerido</mat-error>
              <mat-hint [align]="'end'">{{ message.value?.length || 0 }}/500</mat-hint>
            </mat-form-field>
            <div class="d-grid gap-2">
              <button mat-raised-button (click)="sendForm()" [disabled]="form.invalid || isSaving">Enviar Formulario
                <mat-icon class="ms-2">send</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>