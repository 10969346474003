import {
  Component, ViewChild,
  EventEmitter,
  ElementRef,
  OnDestroy,
  OnInit,
  Output,
  HostListener,
  AfterViewInit,
  ViewChildren,
  Directive,
  Input,
  Inject,
  forwardRef,
  Optional
} from '@angular/core';
import { MatAccordion, MatExpansionPanelHeader } from '@angular/material/expansion';
import { fromEvent, Observable, Subscription } from "rxjs";
import { NgAisNumericMenu } from 'angular-instantsearch/numeric-menu/numeric-menu';
import connectNumericMenu, {
  NumericMenuRenderState,
  NumericMenuConnectorParams
} from 'instantsearch.js/es/connectors/numeric-menu/connectNumericMenu';
import { NgAisInstantSearch, TypedBaseWidget, NgAisIndex } from 'angular-instantsearch';
import connectSortBy, {
  SortByWidgetDescription,
  SortByConnectorParams
} from 'instantsearch.js/es/connectors/sort-by/connectSortBy';
import { NgAisRefinementList } from 'angular-instantsearch/refinement-list/refinement-list';

@Component({
  selector: 'app-search-algolia-check-box',
  templateUrl: './search-algolia-check-box.component.html',
  styleUrls: ['./search-algolia-check-box.component.scss'],
})

// export class SearchAlgoliaCheckBoxComponent extends TypedBaseWidget<SortByWidgetDescription, SortByConnectorParams> implements OnInit, OnDestroy, AfterViewInit {
export class SearchAlgoliaCheckBoxComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatAccordion)
  accordion: MatAccordion = new MatAccordion();

  // @ViewChild('expPanelHeader', { static: true }) expPanelHeader: MatExpansionPanelHeader | undefined;
  @ViewChild('expPanelHeader', { static: true, read: ElementRef }) expPanelHeader: ElementRef | undefined;
  @ViewChild('expansionPanel', { static: true, read: ElementRef }) expansionPanel: ElementRef | undefined;



  // @ViewChild('expPanelHeader', { static: true }) expPanelHeader: ElementRef | undefined;
  // @ViewChild('accordion', { static: true }) accordion: MatAccordion | undefined;

  // imagine we have some product element that we want to track
  elementToTrack = document.getElementById("expPanelHeader");

  // we are creating intersection observer
  // and passing the callback that will be called
  // whenever something changes
  observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      console.log("Intersecting : ", entry.isIntersecting)
    });
  })
  panelOpenState = false;

  @Output()
  visibile = new EventEmitter<boolean>();
  // public state: SortByWidgetDescription['renderState']
  constructor(
    // @Inject(forwardRef(() => NgAisIndex))
    // @Optional()
    // public parentIndex: NgAisIndex,
    // @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,

  ) {

    // this.expIndicator1 = new ElementRef(document.createElement('span'));
    // this.expIndicator2 = new ElementRef(document.createElement('span'));
    // this.expIndicator3 = new ElementRef(document.createElement('span'));
    // this.expIndicator4 = new ElementRef(document.createElement('span'));
    // this.expIndicator5 = new ElementRef(document.createElement('span'));

    // this.accordion.openAll();
    // this.accordion.displayMode = "flat";
    // super('SortBy')
  }
  // https://javascript.plainenglish.io/track-the-elements-visibility-in-angular-55641597bfcd

  resizeObservable$: Observable<Event> | undefined;
  resizeSubscription$: Subscription | undefined;

  @ViewChild('PriceMenu') priceMenuState: NumericMenuRenderState | undefined;

  ngAfterViewInit(): void {
    // console.log("TESTING ================>");
    // console.log(this.instantSearchInstance);
    this.instantSearchInstance.change.subscribe(next => {
      this.suscribeNext(next);

    });
    /*   const name = prompt('What is your name?');
      alert(`Hello ${name}, nice to see you!`);
      headingA.textContent = `Welcome ${name}`; */


  }

  suscribeNext(event: any): void {
    // console.log("suscribeNext: ");
    // console.log("Event suscribeNext",event);
    // console.log("PriceMenu", this.priceMenu);
    // console.log("StatePriceMenu", this.priceMenu?.state);
    if (this.priceMenu) {
      var i: number = 0;
      this.priceMenu.state.items.forEach(item => {
        // console.log("item[", i, "]: ");
        // console.log(item);
        if (item.isRefined) {
          this.priceSelected = item.value;
          return;
        }
      });
    }
  }

  step = 0;

  ngOnInit(): void {
    // console.log(this.priceNuemricMenu.state);
    // console.log("TEST ===>");
    // const jsonBody0: string = JSON.stringify(this.priceMenu?.state);
    // console.log(this.priceMenuState);
    // console.log(this.priceMenu);
    // console.log("jsonBody0: ");
    // console.log(jsonBody0);



    if (this.priceMenu) {
      var i = 0;
      console.log("state: ");
      console.log(this.priceMenu.state);
      console.log(this.priceMenuState);
      // const jsonBody: string = JSON.stringify(this.priceMenu);
      // const priceMenuState: NumericMenuRenderState = this.priceMenu.state;
      // const jsonBody2: string = JSON.stringify(priceMenuState);
      // console.log("jsonBody: ");
      // console.log(jsonBody);
      // console.log("jsonBody2: ");
      // console.log(jsonBody2);

      this.priceMenu.state.items.forEach(item => {
        console.log("item[", i, "]: ");
        console.log(item);
        if (item.isRefined) {
          this.priceSelected = item.value;
          return;
        }
      });
    }

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      // console.log('event: ', evt)
      this.onResize(evt);
    });

    this.calculateMobile();
    if (this.elementToTrack) {
      this.observer.observe(this.elementToTrack);
    }
    // this.createWidget(connectSortBy, {
    //   // instance options
    //   items: [
    //     { label: 'Featured', value: 'instant_search' },
    //     { label: 'Price (asc)', value: 'instant_search_price_asc' },
    //     { label: 'Price (desc)', value: 'instant_search_price_desc' },
    //   ],
    // });
    // super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    if (this.resizeSubscription$) this.resizeSubscription$.unsubscribe()
    this.instantSearchInstance.change.unsubscribe();
  }

  isHidden(elem: ElementRef<any> | undefined): boolean {
    const element = elem?.nativeElement;
    return (element.offsetParent === null);
  }

  cont: number = 0;
  calculateMobile(): void {
    this.cont++;
    const element = this.expPanelHeader?.nativeElement;
    console.log("isMobile(" + this.cont + ")");
    console.log(this.expPanelHeader);
    console.log(element);
    console.log(element.offsetParent);
    if (element.offsetParent === null) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    // console.log('Is mobile: ', this.isMobile);
  }
  isMobile: boolean = false;
  // @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calculateMobile();
  }

  priceSelected: string | undefined;
  /*   priceSelected: Array<{
      label: string,
      value: string
    }> | undefined; */

  @ViewChild('PriceMenu') priceMenu: NgAisNumericMenu | undefined;

  changePrice(value: any, isMobile: boolean): void {
    this.priceSelected = value;
    if (this.priceMenu)
      this.priceMenu.state.refine(value);
  }

  printAccordionStatus(): void {
    alert("expansionPanel is Hidden: " + this.isHidden(this.expansionPanel));
    alert("expPanelHeader is Hidden: " + this.isHidden(this.expPanelHeader));
    alert("is Mobile: " + this.isMobile);
    // console.log(this.accordion);
    // this.accordion.openAll();
    console.log(this.expansionPanel);
    if (this.expPanelHeader) console.log(this.expPanelHeader.nativeElement);

  }

  activeAllways(): boolean {
    return true;
  }

  onHide() {
    alert("Hola mundo");
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  closePanel() {
    console.log("cerrando panel")
    this.step = -1;
  }

  // transformItems(items: any[]) {
  //   return items.map(item => ({
  //     ...item,
  //     highlighted: item.highlighted.toUpperCase(),
  //   }));
  // }

  @ViewChild('LevelStudy') levelStudy: NgAisRefinementList | undefined;
  @ViewChild('Cities') cities: NgAisRefinementList | undefined;

  public ApplyFilters(city: string, studyLevels: string[]) {
    // const temp = { city: city, studyLevels: studyLevels };
    // alert(JSON.stringify(temp));

    if (city === '') {
      // Clear cities
      this.cities?.state.items.forEach(item => {
        if (item.isRefined)
          this.cities?.state.refine(item.value);
      });
    } else {
      let _isRefined = false;
      this.cities?.state.items.forEach(item => {
        if (item.value.toLowerCase() == city.toLowerCase()) {
          _isRefined = true;
          if (!item.isRefined)
            this.cities?.state.refine(city);
        } else {
          if (item.isRefined)
            this.cities?.state.refine(item.value);
        }
      });
      if (!_isRefined)
        this.cities?.state.refine(city);
    }

    if (studyLevels.length == 0) {
      // Clear studyLevels
      this.levelStudy?.state.items.forEach(item => {
        if (item.isRefined)
          this.levelStudy?.state.refine(item.value);
      });
    } else {
      this.levelStudy?.state.items.forEach(item => {
        // If not exists in new refinement list
        if (studyLevels.indexOf(item.value) < 0) {
          // Clear if it's refined
          if (item.isRefined)
            this.levelStudy?.state.refine(item.value);
        } else {
          // if not exists in new refinement list
          // Refined if is not
          if (!item.isRefined)
            this.levelStudy?.state.refine(item.value);
        }
      });
    }
  }
}
