import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  public professorsUrl = environment.publicUrls.professorsUrl;
  public institutesUrl = environment.publicUrls.institutesUrl;

  programsEndVal = environment.counters.programs;
  teacherEndVal = environment.counters.professors;
  studentEndVal = environment.counters.students;

  myOpts = {
    duration: 5,
    suffix: '+',
  };

  ngOnInit(): void { }

  constructor() { }

}
