import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from 'src/app/terms-and-conditions/terms-and-conditions.component';

import { PrivacyPolicyComponent } from 'src/app/privacy-policy/privacy-policy.component';
import { DataUsePolicyComponent } from 'src/app/data-use-policy/data-use-policy.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})



/**
 * @title Dialog with header, scrollable content and actions
 */

export class FooterComponent {
  constructor(public dialog: MatDialog) { }

  openDialogTermsAndConditionsComponent() {
    const dialogRef = this.dialog.open(TermsAndConditionsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDialogPrivacyPolicyComponent() {
    const dialogRef = this.dialog.open(PrivacyPolicyComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDialogDataUsePolicyComponent() {
    const dialogRef = this.dialog.open(DataUsePolicyComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}





