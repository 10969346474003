import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { CITIES } from '../../util/cities'
import algoliasearch from 'algoliasearch/lite';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-search-box',
  templateUrl: './home-search-box.component.html',
  styleUrls: ['./home-search-box.component.scss']
})
export class HomeSearchBoxComponent implements OnInit {

  @Input() hasRedirect: boolean = true;
  @Output() onSearch = new EventEmitter<any>();

  searchClient = algoliasearch(
    environment.Algolia_v2.ApplicationID,
    environment.Algolia_v2.APIKey,
    // {
    //   responsesCache: createInMemoryCache(),
    //   requestsCache: createInMemoryCache({ serializable: false })
    // }
  );

  config = {
    indexName: environment.Algolia_v2.QuerySuggestions,
    searchClient: this.searchClient,
    stalledSearchDelay: 250, // 200 is the default value for the delay
  };

  public searchParameters = {
    query: ''
  };

  public setQuery({ query }: { query: string }) {
    console.warn("setQuery", query);
    this.searchParameters.query = query;
  }

  public updateInputValue(value: string) {
    this.query = value;
  };

  constructor(private router: Router) { }
  cityCtrl = new FormControl('');


  @Input() InitValueCity: string = '';
  @Input() InitValueQuery: string = '';
  @Input() InitValueStudyLevels: string[] = [];

  ngOnInit(): void {
    // Set previous values
    this.cityCtrl.setValue(this.InitValueCity);


    this.careerSample = this.RandomValue(this.careerSamples);
    this.citySample = this.RandomValue(this.citySamples);

    // console.warn("studyLevels", this.studyLevels);

    if (this.InitValueStudyLevels.includes('Pregrado'))
      this.selectedStudyLevels.push('Pregrado');

    if (this.InitValueStudyLevels.includes('Especialización')
      || this.InitValueStudyLevels.includes('Maestría')
      || this.InitValueStudyLevels.includes('Doctorado'))
      this.selectedStudyLevels.push('Postgrado');

    if (this.InitValueStudyLevels.includes('Técnico')
      || this.InitValueStudyLevels.includes('Técnico')
      || this.InitValueStudyLevels.includes('Tecnólogo'))
      this.selectedStudyLevels.push('Técnico');


    this.cities = CITIES.sort(); //(a, b) => b.localeCompare(a));

    this.filteredCities = this.cityCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.cities.filter(city => this._removeAccent(city).toLowerCase().includes(this._removeAccent(filterValue)));
  }

  private _removeAccent(value: string): string {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  private RandomValue(array: string[]): string {
    const max = array.length;
    const min = 1;
    const index = Math.floor(Math.random() * (max - min + 1) + min) - 1;

    return array[index];
  }

  query: string = '';
  search() {
    const _query = (this.query ?? '').trim();
    const query = encodeURIComponent(_query);
    console.warn("query", query);

    const city_value = (this.cityCtrl.value ?? '').trim();
    let city: string[] = [];
    if (city_value !== '')
      city = this._filter(city_value || '');
    console.warn("city[]: ", city);
    console.warn("city_value: ", city_value);

    if (!this.hasRedirect) {
      console.warn("Search hasn't redirect.");
      const _city: string = city.length > 0 ? city[0] : '';

      if (_city !== '')
        this.cityCtrl.setValue(_city);

      const _levelStudy: string[] = [];
      this.getSelectedStudyLevels().forEach(item => {
        _levelStudy.push(item);
      });
      const searchValue = {
        query: _query,
        city: _city,
        levelStudy: _levelStudy
      };

      this.onSearch.emit(searchValue);
    } else {

      let searchParams = '?query=' + query;

      if (city.length > 0) {
        searchParams += '&headquarter.city=' + encodeURIComponent(city[0]);
      }

      this.getSelectedStudyLevels().forEach(item => {
        const levelStudy = encodeURIComponent(item);
        searchParams += '&levelStudy=' + levelStudy;
      });

      // searchParams += '&iesCharacter=Pública';

      console.warn("redirect search Url", `/buscador${searchParams}`);

      this.router.navigateByUrl(`/buscador${searchParams}`);
      // http://localhost:4200/buscador/?
      // query=ingener%25C3%25ADa%2520de%2520sistemaws
      // &cities=Bogot%25C3%25A1%252C%2520D.C.&cities=Puerto%2520Colombia&iesCharacter=Privada
      // &levelStudy=Pregrado&levelStudy=Tecn%25C3%25B3logo
      // &modalities=Presencial
    }

  }

  getSelectedStudyLevels(): string[] {
    if (this.selectedStudyLevels.length == this.studyLevels.length)
      return [];
    let result: string[] = [];
    if (this.selectedStudyLevels.includes('Pregrado'))
      result.push('Pregrado');
    if (this.selectedStudyLevels.includes('Postgrado')) {
      result.push('Especialización');
      result.push('Maestría');
      result.push('Doctorado');
    }
    if (this.selectedStudyLevels.includes('Técnico')) {
      result.push('Técnico');
      result.push('Tecnólogo');
    }
    return result;
  }
  careerSample: string = '';

  // TODO: parse arrays to file or variables
  careerSamples: string[] = ['Medicina', 'Ingeniería', 'Economía', 'Ingeniería Civil', 'Contaduría',
    'Enfermería', 'Diseño Gráfico', 'Gastronomía', 'Relaciones Internacionales'];

  citySample: string = '';
  citySamples: string[] = ['Cali', 'Bogotá', 'Barranquilla', 'Medellín', 'Bucaramanga', 'Pasto', 'Cartagena', 'Pereira'];

  cities: string[] = []; //['Cali', 'Bogotá', 'Barranquilla', 'Medellín', 'Bucaramanga', 'Pasto', 'Cartagena', 'Pereira'];
  filteredCities: Observable<string[]> = new Observable<string[]>;

  studyLevels: string[] = ['Pregrado', 'Postgrado', 'Técnico'];
  selectedStudyLevels: string[] = [];

  isAllSelected(): boolean {
    return (this.selectedStudyLevels.length == this.studyLevels.length);
  }

  isSelected(level: string): boolean {
    return this.selectedStudyLevels.includes(level);
  }

  toggleAllSelection(): void {
    if (this.isAllSelected()) {
      this.selectedStudyLevels = [];
    } else {
      this.selectedStudyLevels = this.studyLevels.filter(c => c == c);
    }
  }

  toggleSelection(level: string): void {
    if (this.isSelected(level)) {
      this.selectedStudyLevels = this.selectedStudyLevels.filter(c => c !== level);
    } else {
      this.selectedStudyLevels.push(level);
    }
  }

  removeLevel(level: string): void {
    const index = this.studyLevels.indexOf(level);
    if (index >= 0) {
      this.studyLevels.splice(index, 1);
      this.selectedStudyLevels = this.selectedStudyLevels.filter(c => c !== level);
    }
  }

}
