<div class="container__payment">
  <h5>Ingresa tus datos</h5>
  <form
    id="form-checkout"
    [formGroup]="paymentForm"
    (ngSubmit)="onSubmit($event)"
    class="form-paymentData"
  >
    <div class="form-container">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Nombres</mat-label>
        <input
          matInput
          name="firstName"
          type="text"
          formControlName="firstName"
        />
      </mat-form-field>

      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Apellidos</mat-label>
        <input
          matInput
          name="lastName"
          type="text"
          formControlName="lastName"
        />
      </mat-form-field>
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Correo Electrónico</mat-label>
        <input
          matInput
          id="form-checkout__email"
          name="email"
          type="text"
          formControlName="email"
        />
      </mat-form-field>

      <div class="inputs-container">
        <div class="input-wrapper-PSE">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Tipo de Persona</mat-label>
            <mat-select
              id="form-checkout__personType"
              name="personType"
              formControlName="personType"
              (selectionChange)="onPersonTypeChange($event)"
            >
              <mat-option value="natural">Natural</mat-option>
              <mat-option value="juridica">Jurídica</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="input-wrapper-PSE">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Tipo de Documento</mat-label>
            <mat-select
              id="form-checkout__identificationType"
              name="identificationType"
              formControlName="identificationType"
            >
              <mat-option
                *ngFor="let docType of documentTypes"
                [value]="docType.value"
              >
                {{ docType.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Número del Documento</mat-label>
        <input
          matInput
          id="form-checkout__identificationNumber"
          name="identificationNumber"
          type="text"
          formControlName="identificationNumber"
        />
      </mat-form-field>

      <div class="inputs-container">
        <mat-form-field class="input-prefix" appearance="outline">
          <mat-label>Prefijo</mat-label>
          <mat-select
            [(value)]="dialCode.dial_code"
            id="form-checkout__phoneAreaCode"
            name="phoneAreaCode"
            type="text"
            formControlName="phoneAreaCode"
          >
            <mat-option [value]="default_dialCode.dial_code">(+57) Colombia</mat-option>
            <mat-option>---</mat-option>
            <mat-option *ngFor="let item of dial_codes" [value]="item.dial_code">
              ({{ item.dial_code }}) {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="input-wrapper-PSE">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Número de Teléfono</mat-label>
            <input
              matInput
              id="form-checkout__phoneNumber"
              name="phoneNumber"
              type="text"
              formControlName="phoneNumber"
            />
          </mat-form-field>
        </div>
      </div>

      <mat-divider class="mb-4"></mat-divider>

      <h5>Datos del domicilio:</h5>

      <div class="inputs-container">
        <div class="input-wrapper-PSE">
          <label
            class="input-mercadoPago-label"
            for="form-checkout__streetName"
          >
            Nombre Calle/Avenida/Carrera...
          </label>
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Ej: Av. Rojas , Calle 123...</mat-label>
            <input
              matInput
              id="form-checkout__streetName"
              name="streetName"
              type="text"
              formControlName="streetName"
            />
          </mat-form-field>
        </div>

        <div class="input-wrapper-PSE">
          <label
            class="input-mercadoPago-label"
            for="form-checkout__streetNumber"
          >
            Número de la calle...
          </label>
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Ej: 456 38</mat-label>
            <input
              matInput
              id="form-checkout__streetNumber"
              name="streetNumber"
              type="text"
              formControlName="streetNumber"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="inputs-container">
        <div class="input-wrapper-PSE">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Barrio</mat-label>
            <input
              matInput
              id="form-checkout__neighborhood"
              name="neighborhood"
              type="text"
              formControlName="neighborhood"
            />
          </mat-form-field>
        </div>
        <div class="input-wrapper-PSE">
          <mat-form-field class="input-full-width" appearance="outline">
            <mat-label>Ciudad</mat-label>
            <input
              matInput
              id="form-checkout__city"
              name="city"
              type="text"
              formControlName="city"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="inputs-container">
      <div class="input-wrapper-PSE">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Código Postal</mat-label>
          <input
            matInput
            id="form-checkout__zipCode"
            name="zipCode"
            type="text"
            formControlName="zipCode"
          />
        </mat-form-field>
      </div>

      <div class="input-wrapper-PSE">
        <mat-form-field class="input-full-width" appearance="outline">
          <mat-label>Provincia / Departamento</mat-label>
          <input
            matInput
            id="form-checkout__federalUnit"
            name="federalUnit"
            type="text"
            formControlName="federalUnit"
          />
        </mat-form-field>
      </div>
    </div>

    <h6 *ngIf="fullAddress" style="color: rgba(0, 0, 0, 0.54)">
      Dirección: {{ fullAddress }}
    </h6>

    <mat-divider class="mb-4"></mat-divider>

    <h5>Seleccione la entidad financiera:</h5>

    <div class="select-bank-section">
      <div class="image-container">
        <img
          mat-card-sm-image
          src="../../assets/svg/logos/payments/pse.png"
          alt="logo PSE"
        />
      </div>
  
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Banco</mat-label>
        <mat-select formControlName="financialInstitution">
          <mat-option *ngFor="let bank of banksList" [value]="bank.id">
            {{ bank.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div>
      <div>
        <input
          type="hidden"
          name="transactionAmount"
          id="transactionAmount"
          [value]="donative"
        />
        <input
          type="hidden"
          name="description"
          id="description"
          [value]="description"
        />
        <br />
        <div class="actions-buttons">
          <div class="my-3">
            <button mat-raised-button color="primary" (click)="goBack()">
              Ir atrás
            </button>
          </div>
          <div class="my-3">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              id="form-checkout__submit"
              class="btn btn-primary btn-block"
            >
              Continuar con el pago
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
