import { Component } from '@angular/core';
import { mockValues } from './valuesMock';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DonationService } from '../services/donation.service';

@Component({
  selector: 'app-donations',
  templateUrl: './donations.component.html',
  styleUrls: ['./donations.component.scss'],
})

export class DonationsComponent {

  donative:number


  
  constructor(private donationService: DonationService){
    this.donative=0
  }


  ngOnInit(): void {
    this.donationService.currentDonation.subscribe(details => {
      this.donative = details.donative;
    });
  }
  formatValue(value: number ): string {
    return value.toLocaleString('es-CO'); 
  }

  
}
