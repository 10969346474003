<div *ngIf="true">
    <!-- <br>
    <div>
        <div class="pb-4">
            <h2 class="featurette-heading display-6 fw-bold text-center">Los que nos apoyan</h2>
        </div>
        <div class="d-flex align-items-center justify-content-around row gap-3 text-center">
            <figure class="col">
                <img src="/logos-Aliados-InnColWorley.png"
                    alt="" loading="lazy" style="max-width: 250px;" />
            </figure>
            <figure class="col">
                <img src="/logos-Aliados-Fundation.png"
                    alt="" loading="lazy" style="max-width: 250px;" />
            </figure>
        </div>
    </div> -->
    <br>
    <div class="container">
        <div class="pb-4">
            <h2 class="featurette-heading display-5 fw-bold text-center">Nuestros aliados</h2>
            <!-- <h2 class="featurette-heading display-6 fw-bold text-center">Nuestros aliados</h2> -->
        </div>
        <div class="d-flex align-items-center justify-content-around row gap-3 text-center">
            <span class="partner__card">
                <a href="https://www.google.com/intl/es/nonprofits/" target="_blank">
                    <figure class="col">
                        <img src="../../../assets/partners/aliado-google-nonprofits.png" alt="google-non-profits-logo"
                            loading="lazy" style="max-width: 200px;" />
                    </figure>
                </a>
            </span>
            <span class="partner__card">
                <a href="https://redinncol.com/" target="_blank">
                    <figure class="col">
                        <img src="../../../assets/partners/aliado-redinncol.png" alt="redinncol-logo" loading="lazy"
                            style="max-width: 200px;" />
                    </figure>
                </a>
            </span>
            <span class="partner__card">
                <a href="https://beacons.ai/efectodirectoagencia" target="_blank">
                    <!-- <a href="https://efectodirecto.co/" target="_blank"> -->
                    <figure class="col">
                        <img src="../../../assets/partners/aliado-efecto-directo.png" alt="efectodirecto-logo"
                            loading="lazy" style="max-width: 200px;" />
                    </figure>
                </a>
            </span>
            <span class="partner__card">
                <a href="https://appredsaber.redinncol.com/" target="_blank">
                    <!-- <a href="https://efectodirecto.co/" target="_blank"> -->
                    <figure class="col">
                        <img src="../../../assets/partners/red-saber.png" alt="red-saber-logo" loading="lazy"
                            style="max-width: 200px;" />
                    </figure>
                </a>
            </span>
            <span class="partner__card">
                <a href="https://congrupo.com.co/" target="_blank">
                    <figure class="col">
                        <img src="../../../assets/partners/congrupo-logo.png" alt="congrupo-logo" loading="lazy"
                            style="max-width: 200px;" />
                    </figure>
                </a>
            </span>
            <span class="partner__card">
                <a href="https://www.apple.com/co/" target="_blank">
                    <figure class="col">
                        <img src="../../../assets/partners/apple-logo.png" alt="apple-logo" loading="lazy"
                            style="max-width: 200px;" />
                    </figure>
                </a>
            </span>
            <span class="partner__card">
                <a href="https://metricool.com/es/" target="_blank">
                    <figure class="col">
                        <img src="../../../assets/partners/metricool-logo.png" alt="merticool-logo" loading="lazy"
                            style="max-width: 200px;" />
                    </figure>
                </a>
            </span>
            <span class="partner__card">
                <a href="https://asocolclubes.com/" target="_blank">
                    <figure class="col">
                        <img src="../../../assets/partners/aliado-asocolclubes.png" alt="merticool-logo" loading="lazy"
                            style="max-width: 200px;" />
                    </figure>
                </a>
            </span>
        </div>
    </div>
    <br>
</div>