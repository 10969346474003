<h2 mat-dialog-title>Términos y condiciones</h2>
<mat-dialog-content class="mat-typography">
    <p>El portal www.guiauniversitaria.net (en adelante, el "Portal"), tiene el acceso, participación
        y uso gratuito y está regido por los términos y condiciones que se incluyen a continuación,
        los cuales se entienden conocidos y aceptados por los (las) usuarios(as) del Portal (en
        adelante, el "Usuario"):</p>
    <h3>1. Información contenida en el Portal</h3>
    <h4>1.1 Propiedad del contenido del Portal - Copyright</h4>
    <p>Esta página de Internet y su contenido son de propiedad de GUÍA UNIVERSITARIA. Está
        prohibida su reproducción total o parcial, su traducción, inclusión, transmisión,
        almacenamiento o acceso a través de medios analógicos, digitales o de cualquier otro sistema
        o tecnología creada o por crearse, sin autorización previa y escrita de GUÍA
        UNIVERSITARIA. No obstante, es posible descargar material de
        www.guiauniversitaria.net para uso personal y no comercial, siempre y cuando se haga
        expresa mención de la propiedad en cabeza de GUÍA UNIVERSITARIA. Los enlaces a otras
        páginas de Internet, distintas de aquellas de las cuales GUÍA UNIVERSITARIA es
        propietaria, son ofrecidas como un servicio a los usuarios. GUÍA UNIVERSITARIA no
        estuvo involucrada en su producción y por lo tanto no es responsable por su contenido. El
        Usuario es titular de los derechos de autor sobre su contenido. El Usuario concede a GUÍA
        UNIVERSITARIA en forma permanente, una licencia de uso gratuita, no exclusiva, para la
        reproducción, adaptación, compilación, almacenamiento y distribución de los contenidos por
        él suministrados a través de esta página de Internet. GUÍA UNIVERSITARIA reconocerá al
        Usuario su autoría sobre los contenidos suministrados. GUÍA UNIVERSITARIA podrá
        sublicenciar a sus filiales y subsidiarias los mismos contenidos. El Usuario garantiza que los
        contenidos suministrados son de su autoría, que no está violando derechos de autor de
        terceras personas y que mantendrá indemne a GUÍA UNIVERSITARIA frente a cualquier
        reclamación que se presente con ocasión de su uso.
    </p>
    <h4>1.2 Privacidad</h4>
    <p>Es interés de GUÍA UNIVERSITARIA la protección de la privacidad de la información
        personal del Usuario obtenida a través del Portal, comprometiéndose a adoptar una política
        de confidencialidad según lo que se establece más adelante Se entiende por información
        personal aquella suministrada por el Usuario para el registro, la cual incluye datos tales como:
        nombre, identificación, edad, género, dirección, correo electrónico, teléfono. El Usuario
        reconoce que el ingreso de información personal, lo realiza de manera voluntaria y teniendo
        en cuenta las características del Portal y las facultades de uso por parte de GUÍA
        UNIVERSITARIA, y en el entendido que tal información hará parte de un archivo y/o base
        de datos que contenderá su perfil, la cual podrá ser usado por GUÍA UNIVERSITARIA en
        los términos aquí establecidos. El Usuario podrá modificar o actualizar la información
        suministrada en cualquier momento. GUÍA UNIVERSITARIA aconseja que el Usuario
        mantenga actualizada la información para optimizar el beneficio que puede recibir del Portal.
        La información personal proporcionada por el Usuario, está asegurada por una clave de
        acceso a la cual sólo el Usuario podrá acceder y que sólo él conoce. El Usuario es el único
        responsable de mantener en secreto, dicha clave y la información incluida. GUÍA
        UNIVERSITARIA se compromete a no intentar tener acceso ni pretender conocer dicha
        clave. Debido a que ninguna transmisión por Internet es absolutamente segura ni puede
        garantizarse dicho extremo, el Usuario asume el hipotético riesgo que ello implica, el cual
        acepta y conoce. El Usuario podrá solicitar que su información personal no sea entregada por
        GUÍA UNIVERSITARIA a sus filiales y/o subsidiarias, y/o a terceros, a través del envío de
        correo electrónico a la dirección info@guiauniversitaria.net en cuyo caso GUÍA
        UNIVERSITARIA no entregará dicha información salvo en caso que la misma sea requerida
        por orden judicial o para proteger los derechos de propiedad o cualquier otro derecho o
        garantía de GUÍA UNIVERSITARIA. GUÍA UNIVERSITARIA no se responsabiliza por
        cualquier consecuencia derivada del ingreso indebido de terceros a la base de datos y/o por
        alguna falla técnica en el funcionamiento y/ o conservación de datos en el sistema en
        cualquiera de los capítulos del Portal. GUÍA UNIVERSITARIA no garantiza que terceros no
        autorizados no puedan tener conocimiento de la clase, condiciones, características y
        circunstancias del uso que los Usuarios hacen del Portal.</p>
    <h4>1.3. Bases de Datos e información</h4>
    <p>Quien diligencia el formulario de registro acepta que sus datos se incorporen a las bases de
        datos de GUÍA UNIVERSITARIA, sus filiales o subsidiarias. En todo caso, el Usuario podrá
        en cualquier momento solicitar el retiro de su información personal de las bases de datos de
        GUÍA UNIVERSITARIA, para lo cual deberá enviar un e - mail
        a info@guiauniversitaria.net, en cuyo caso perderá los privilegios derivados del registro.
        Así mismo, el Usuario podrá modificar o actualizar la información suministrada en cualquier
        momento, ingresando a la Zona de Usuario en el Portal.</p>
    <h4>1.4. Contenidos de los Usuarios
    </h4>
    <p>Teniendo en cuenta la posibilidad del Usuario de incluir en el Portal fotos y/ o imágenes y/o
        textos y/u otro tipo de material de titularidad del Usuario o bien por éste de titularidad de un
        tercero, se deja expresamente establecido que al incluirlo en el Portal se considerará que el
        Usuario autoriza a GUÍA UNIVERSITARIA para que ese material aparezca y quede en el
        Portal sin derecho a contraprestación alguna por parte de GUÍA UNIVERSITARIA. En el
        caso de incluirlo, el Usuario lo podrá mantener en el Portal por el lapso que considere
        pertinente. En relación con cualquier tipo de material y/o información remitida al Portal por
        los Usuarios, incluyendo fotos y/o imágenes y/o textos y/u otro tipo de material de propiedad
        de los Usuarios o de un tercero que los hubieren facultado, se considerará que el Usuario es
        titular de los derechos de autor sobre dicho contenido y que al haber sido incluidos en el
        Portal el Usuario otorga licencia gratuita, incondicional y no exclusiva de uso de tal contenido
        a favor de GUÍA UNIVERSITARIA, sin derecho a percibir contraprestación, remuneración,
        retribución o reclamo posterior alguno, cualquiera sea su tipo o índole. GUÍA
        UNIVERSITARIA podrá a su vez hacer cualquier tipo de uso de dicho material, en virtud de
        la licencia de uso así concedida. El Usuario reconoce que el ingreso o suministro de contenido
        de su propiedad o de terceros lo realiza de manera voluntaria y teniendo en cuenta las
        características del Portal y las facultades de uso por parte de GUÍA UNIVERSITARIA. En
        todo caso, para el suministro de cualquier tipo de material y/o información el Usuario deberá
        observar y tendrán aplicación las Condiciones de Uso y Participación dentro del portal que
        se señalan más adelante.
    </p>
    <h3>2. Condiciones de Uso y Participación dentro
        del portal</h3>
    <h4>2.1 Condiciones de Uso
    </h4>
    <p>2.1 Por el hecho de ingresar al Portal y para garantizar el buen y adecuado uso del mismo, el
        Usuario reconoce en cabeza de GUÍA UNIVERSITARIA: - El derecho de modificar en
        cualquier tiempo y por cualquier razón sin previo aviso los Términos y Condiciones del
        Portal. - El derecho de negar el registro a cualquier persona, en cualquier momento y por
        cualquier razón. - El derecho de incluir o no en el Portal el material recibido de los usuarios
        a su criterio. En el caso de incluirlo, podrá mantener en el Portal dicho material por el lapso
        que considere pertinente o modificarlo. - Remover, sin que sea obligatorio, contenidos que a
        juicio de GUÍA UNIVERSITARIA sean ilegales, ofensivos, difamatorios o que de cualquier
        otra forma violen estos Términos y Condiciones. Así mismo, podrán ser retirados los
        contenidos que violen derechos intelectuales, ya sean de GUÍA UNIVERSITARIA o de un
        tercero, a solicitud de éste. - Utilizar la información personal y/o contenidos suministrados
        por los Usuarios de acuerdo con los Términos y Condiciones del Portal. 2.1.2. El Portal,
        contiene links que remiten a otras páginas de internet. Teniendo en cuenta que GUÍA
        UNIVERSITARIA no es responsable por la disponibilidad de dichos sitios, el Usuario deberá
        dirigirse directamente al administrador de dicho sitio y usar dichos sitios de acuerdo con los
        términos de uso respectivos. 2.1.3. El registro al Portal podrá darse por terminado por el
        Usuario en cualquier momento enviando un e-mail a: info@guiauniversitaria.net. 2.1.4. El
        Usuario deberá cumplir los Términos y Condiciones del Portal, así como toda condición
        adicional que se establezca en el Portal. 2.1.5. GUÍA UNIVERSITARIA no garantiza la
        disponibilidad y continuidad del funcionamiento del Portal. Cuando ello sea razonablemente
        posible, GUÍA UNIVERSITARIA advertirá previamente las interrupciones en el
        funcionamiento del Portal. GUÍA UNIVERSITARIA tampoco garantiza la utilidad del Portal
        para la realización de ninguna actividad en particular, ni su infalibilidad y, en particular,
        aunque no de modo exclusivo, que los Usuarios puedan efectivamente utilizar el Portal,
        acceder a las distintas páginas web o secciones que forman el Portal. 2.1.6. GUÍA
        UNIVERSITARIA excluye cualquier responsabilidad por los daños y perjuicios de toda
        naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del
        funcionamiento del Portal, a la defraudación de la utilidad que los Usuarios hubieren podido
        atribuir al Portal y a los servicios, a la falibilidad del Portal, y en particular, aunque no de
        modo exclusivo, a las fallas en el acceso a las distintas páginas web o secciones del Portal.
        2.1.7. GUÍA UNIVERSITARIA no controla ni garantiza, y por lo tanto no se hace
        responsable por, la ausencia de virus ni de otros elementos en los contenidos del Portal que
        puedan producir alteraciones en el sistema informático (software y hardware) del Usuario o
        en los documentos electrónicos y ficheros almacenados en el sistema informático del
        Usuario. 2.1.8. Es interés de GUÍA UNIVERSITARIA ser una fuente de información con
        contenido de actualidad y de interés para los Usuarios. No obstante, GUÍA
        UNIVERSITARIA no puede garantizar que dicho contenido esté exento de errores o
        imprecisiones, en cuyo caso podrán solicitarse las aclaraciones o correcciones que sean del
        caso. GUÍA UNIVERSITARIA tampoco puede garantizar que el contenido del Portal sea
        suficiente y/o útil para el Usuario. 2.1.9. GUÍA UNIVERSITARIA no garantiza y por lo tanto
        no es responsable de, la licitud, fiabilidad, exactitud, exhaustividad, actualidad y utilidad de
        las columnas de opinión, y/o las opiniones o contenidos de los Usuarios incluidos en el Portal.
        2.1.10. Estos Términos y Condiciones han sido dispuestos de conformidad con las leyes
        colombianas. Cualquier acción o reclamación deberá hacerse ante los Tribunales de
        Colombia.
    </p>
    <h4>2.2 Registro y Participación del Usuario.</h4>
    <p>Por el hecho de ingresar al Portal y para garantizar el buen y adecuado uso del mismo, el
        Usuario deberá cumplir con lo siguiente: - Observar el Código de Ética, los Términos y
        Condiciones y cualquier otra condición establecida en este Portal. - Ser responsable por
        cualquier actividad que se lleve a cabo bajo su registro. - Ser responsable de la seguridad de
        su contraseña. - No abusar, acosar, amenazar o intimidar a otros usuarios del Portal ya sea a
        través de los chats, foros, blogs o cualquier otro espacio de participación. - No usar ésta
        página como medio para desarrollar actividades ilegales o no autorizadas tanto en Colombia,
        como en cualquier otro país. - Ser el único responsable por su conducta y por el contenido de
        textos, gráficos, fotos, videos o cualquier otro tipo de información de la cual haga uso o
        incluya en el Portal. - Utilizar el Portal única y exclusivamente para uso personal. Cualquier
        uso para beneficio corporativo o colectivo está prohibido. - Abstenerse de enviar correo
        electrónico no deseado (SPAM) a otros Usuarios de esta página, así como también le está
        prohibido transmitir virus o cualquier código de naturaleza destructiva. - Canalizar sus
        quejas, reclamos y denuncias a través de la sección de Contacto en el Portal. Mientras en el
        Portal estén prohibidas estas conductas, GUÍA UNIVERSITARIA no será responsable por
        su cumplimiento y el Usuario lo mantendrá indemne por todo concepto.</p>
    <h4>2.3 Promociones, concursos y eventos</h4>
    <p>Las promociones, concursos, sorteos y eventos que se implementen en el Portal estarán
        sujetas a las reglas y condiciones que en cada oportunidad se establezca por parte de GUÍA
        UNIVERSITARIA, siendo necesario como requisito mínimo para acceder a tales
        oportunidades o beneficios comerciales, que el Usuario se encuentre debidamente registrado
        como usuario del Portal. GUÍA UNIVERSITARIA no se responsabiliza por cualquier tipo
        de daño -incluyendo moral, físico, material, ni de cualquier otra índole- que pudiera invocarse
        como relacionado con la recepción por parte del Usuario registrado de cualquier tipo de
        obsequios y/o regalos remitidos por GUÍA UNIVERSITARIA. Así mismo, GUÍA
        UNIVERSITARIA no será responsable por las consecuencias que pudiere causar el ingreso
        al Portal y/o la presencia en cualquier evento y/o reunión organizada por éste. El Usuario
        reconoce que GUÍA UNIVERSITARIA no asume responsabilidad alguna que corresponda a
        un anunciante y/o el proveedor de los servicios que se ofrezcan en el Portal, siendo entendido
        que el Portal no se responsabiliza por la calidad ni la entrega de los productos o prestación
        de servicios que se publican en este sitio. Por tal motivo no será responsable por cualquier
        problema, queja o reclamo de los usuarios por cuestiones atinentes a dichos productos y/o
        servicios. Cada promoción, concurso o evento que se promueva o realice a través del Portal,
        estará sujeto a las reglas de Privacidad que para el mismo se indiquen, por lo que la
        participación en los mismos deberá atenerse a lo que en cada caso se señale, lo cual será
        complementario a las políticas de privacidad señaladas anteriormente, siempre y que no sea
        excluyente.
    </p>
    <h4>2.4 Foros, Blogs, Chats, Comentarios y otros
        espacios de participación</h4>
    <p>El Usuario reconoce que su participación en cualquier foro, chat, comentario, blog y/o
        cualquier otro espacio de participación del Portal, será bajo su exclusiva responsabilidad, por
        lo cual deberá ser mayor de edad, y que de igual forma, las opiniones y/o acciones y/o
        comportamiento de otros Usuarios en tales espacios son responsabilidad exclusiva de quienes
        las emiten o realizan, por lo cual GUÍA UNIVERSITARIA no se hace responsable ni
        garantiza la calidad o idoneidad de tales conductas u opiniones, ni por las consecuencias que
        ellas pudieren acarrear a favor y/ o en contra de otros Usuarios o de terceros. Particularmente
        los usuarios deberán tener en cuenta las recomendaciones que se incluyan en el Portal
        encaminadas a propiciar una adecuada convivencia y participación en tales espacios de
        participación. El diseño, manejo, finalidad y características de los diferentes espacios de
        participación del Portal es discrecional de GUÍA UNIVERSITARIA, quien podrá en
        cualquier momento cambiarlos y/o eliminarlos, y/o determinar la cantidad de participantes
        admitidos en cada uno de ellos.</p>
    <h4>2.5 Guía de principios y recomendaciones para una
        sana participación en las actividades y servicios del
        Portal.</h4>
    <p>El Usuario entiende que a través del Portal se invita y promueve una activa y libre
        participación, comunicación y expresión por parte de los Usuarios, hasta donde la
        Constitución y la ley lo permiten. De igual forma el Usuario entiende y acepta que es de todo
        interés de GUÍA UNIVERSITARIA y de la comunidad en general, que el Portal sea un medio
        amigable, pacífico y sano de convivencia y participación, por lo que tanto GUÍA
        UNIVERSITARIA como la comunidad de Usuarios espera de cada Usuario el
        comportamiento y conducta que permita lograr tal propósito, a lo cual se compromete cada
        Usuario con el solo acceso al Portal. Cada Usuario acepta y faculta expresa e
        irrevocablemente a GUÍA UNIVERSITARIA para revisar los comentarios u opiniones
        vertidos en los Espacios y/o suprimir los que no se adecuen a las normas de convivencia
        plasmadas en los Términos y Condiciones del Portal, así como a interrumpir la comunicación
        en caso de que lo considere conveniente por tales motivos. De igual forma GUÍA
        UNIVERSITARIA se reserva el derecho de ejercer tal facultad cuando así lo estime
        conveniente, a su discreción, sin que por tal razón sea factible imputar responsabilidad alguna
        a GUÍA UNIVERSITARIA por el no ejercicio de la facultad y/o por la existencia, ingreso,
        participación de Usuarios no deseables y/o de comentarios u opiniones que no atienden estas
        recomendaciones. Teniendo en cuenta que los comentarios y opiniones vertidas en los foros,
        comentarios y Blogs no provendrán de GUÍA UNIVERSITARIA sino de terceros
        absolutamente ajenos, GUÍA UNIVERSITARIA no se responsabiliza por el tenor de los
        mismos, así como tampoco presta conformidad ni discrepa de ellos, siendo entendido que
        emanan exclusivamente de su autor, y quedan bajo su completa responsabilidad. GUÍA
        UNIVERSITARIA entiende que cada uno de los comentarios, mensajes, opiniones,
        información o similares que se viertan en los Espacios son de autoría exclusiva de quienes
        los ingresen. Asimismo, queda absolutamente prohibido ingresar comentarios, mensajes,
        opiniones, información, o similares, de contenido difamatorio, abusivo, contrario a la moral
        y las buenas costumbres, discriminatorio, ofensivo, obsceno, intimidatorio, calumnioso,
        inapropiado, ilegal, violatorio de derechos de terceros de cualquier índole, incluidos los
        derechos de los menores de edad, que cause daños y/o perjuicios, o impida o limite el derecho
        propio o ajeno a usar los Espacios y demás capítulos del sitio, constituya un delito o apología
        a un delito y/o incite a la violencia y/o a la comisión de delitos. Tampoco está permitido
        publicitar productos y/o servicios de cualquier tipo dentro de "los espacios", ni utilizar o
        enviar virus, o desviarse de los temas propuestos en los foros. Ahora bien, en el supuesto de
        que este tipo de comentarios, mensajes, opiniones, información, o similares, ingrese en "los
        espacios", los Usuarios, aceptan en forma expresa e incondicionada que GUÍA
        UNIVERSITARIA, sus empleados, proveedores, o anunciantes, NO serán responsables en
        modo alguno por las consecuencias de cualquier tipo y alcance que los mismos pudieran
        generar, frente a cualquier tercero, ya sea en virtud de su inclusión dentro de "los espacios"
        o por cualquier causa relacionada directa o indirectamente con el uso de los mismos.
        Asimismo, GUÍA UNIVERSITARIA, sus empleados, proveedores o anunciantes, NO serán
        responsables de modo alguno en el supuesto que los comentarios, información, mensajes,
        opiniones, o similares, se vean afectados, eliminados, alterados, o modificados de alguna
        manera. Los Usuarios se abstendrán de iniciar cualquier acción o reclamación contra GUÍA
        UNIVERSITARIA relacionada con, o derivada de, la información, contenido, opinión o
        comentario proveniente de otro Usuario y/o de un tercero ajeno a GUÍA UNIVERSITARIA,
        siendo aceptado por los Usuarios que tales acciones o reclamaciones solo podrán ser iniciadas
        contra su directo responsable, para lo cual deberán promover las acciones legales pertinentes,
        bajo los procedimientos legalmente establecidos para el efecto. GUÍA UNIVERSITARIA
        podrá abstenerse de suministrar la información disponible sobre el presunto infractor cuando
        a su juicio tal información esté protegida por la confidencialidad ofrecida a los Usuarios, en
        cuyo caso, el suministro estará condicionado a la solicitud de autoridad competente. GUÍA
        UNIVERSITARIA, a su sólo juicio, se reserva el derecho de excluir de los Espacios, a
        aquellos Usuarios que no se atengan a las presentes reglas o que no respeten los principios
        básicos de sana convivencia. Así como también de interrumpir y/o eliminar y/o excluir, total
        o parcialmente, en todos los casos, todo aquel mensaje, opinión, información o similares que
        no se adecuen o resulten violatorios de las reglas y/o principios antes indicados. GUÍA
        UNIVERSITARIA asume que cada usuario se obliga a cumplir y respetar las presentes reglas
        y que asume todo tipo de consecuencias que su indebido uso pudiere ocasionar. Ello tanto
        frente a GUÍA UNIVERSITARIA como frente a cualquier tercero. Los Usuarios deberán
        denunciar cualquier violación a los Términos y Condiciones del Portal por parte de otros
        Usuarios, de la que tenga conocimiento, para lo cual remitirá un e - mail
        a info@guiauniversitaria.net a fin de que GUÍA UNIVERSITARIA tome las medidas que
        estén a su alcance respecto del Portal.
    </p>
    <h3>Código de ética</h3>
    <h4>Parámetros de comportamiento en participación</h4>
    <p>Al hacer parte de la sección /PARTICIPACION en eltiempo.com y/o cualquiera de los blogs
        que pertenecen a productos GUÍA UNIVERSITARIA, usted se compromete a respetar y
        ayudar a cumplir las normas de uso de la comunidad: - Recordar que este es un espacio para
        la participación sobre asuntos afines a las temáticas de eltiempo.com. - No escribir textos, ni
        subir imágenes o cualquier otro material que atenten contra la integridad humana; la crítica
        es muy importante, pero enfóquela para que sea constructiva. - Ayudar a construir la
        comunidad, filtrando los malos comentarios; visitando el trabajo de los demás para que otros
        visiten el suyo; respondiendo a los comentarios que otros usuarios hacen dentro de su blog
        de manera coherente y respetuosa, el objetivo es construir un diálogo alrededor de los temas
        propuestos en cada entrada. - Si ve algo anormal en los comentarios, blogs, reportajes, notas,
        etc. denúncielo a info@guiauniversitaria.net. - Mantener la frecuencia de publicación a la
        cual se comprometió al abrir su blog dentro de participación</p>
    <h4>Especificaciones generales para la publicación de
        contenidos.</h4>
    <p>Identificarse correctamente Cuando usted deje comentarios en el blog de otra persona, es de
        buena etiqueta identificarse correctamente con un nombre real y un email siempre que sea
        posible. Esto fomenta un discurso positivo y discusiones sanas. Escribir en un formato claro
        y sencillo Usted no debe tener un talento extraordinario de escritor para ser un buen blogger.
        Claro está, no puede esperar respeto si escribe todo en MAYUSCULAS o escribe de una
        foRma eXtrAñA. Escribir en mayúsculas en la comunidad online es considerado como gritar.
        Usar formas no convencionales de escribir no genera confianza. Permitir que sus lectores se
        contacten con usted No importa si es a través de correos electrónicos, comentarios, cajas de
        chat o alguna otra forma. Es de buena etiqueta dar la oportunidad a sus lectores de interactuar
        con usted. Verificación sobre información médica Absténgase de sugerir tratamientos,
        prescripciones médicas o sugerencia sobre ingestión de medicamentos tanto caseros como
        farmacológicos. Dar crédito a sus fuentes Cuando utilice alguna parte de un artículo o blog
        es muy importante que le dé los créditos necesarios a su autor. También es buena práctica
        mencionar la fuente y compartir su enlace con el lector. Respetar los derechos de autor No
        publique textos escritos por otras personas en su blog sin cita bibliográfica. No utilice fotos,
        videos o materiales que no sean suyos sin conseguir el permiso del autor. Es muy importante
        seguir las leyes de autor, sean estas de copyleft o copyright. PARA MAYOR
        INFORMACIÓN: http://es.wikipedia.org/wiki/Copyleft http://es.wikipedia.org/wiki/Derec
        ho_de_autor Verificar la veracidad de su información Si está publicado en Internet no quiere
        decir que es verdad. Siempre verifique sus fuentes. Si no está seguro de la validez de la
        información, pero igual la quiere publicar, comparta su duda en vez de publicarlo como un
        hecho. Respetar las guías de uso de los enlaces directos Los enlaces directos pueden ser
        incluidos por un blogger o reportero gráfico desde su página de eltiempo.com. Usualmente
        se pueden enlazar imágenes y sitios web sin permiso de su autor, pero cada persona debe
        tener sus reglas con respecto a los enlaces directos que incluye. Si no está seguro, recuerde
        que la mayoría de los sitios o blogs no dejan hacer esto sin previo aviso. Nunca dejar
        comentarios de spam No todos los comentarios tienen que ser sobre viagra o casinos en línea
        para ser considerados spam. Cuando usted publica comentarios como: "Visite my blog" sin
        comentar algo relevante a la discusión, también puede ser considerado como spam. Corregir
        sus errores, publicar las actualizaciones y aclaraciones cuando sea necesario. No siempre
        podemos tener la razón. Algunas veces publicamos en el blog sin pensar. Cuando ocurren
        estos errores, acéptelos y publique los cambios y/o actualizaciones. Si tiene algún conflicto
        de interés o está apoyando un proyecto personal, siempre es mejor decirlo de frente. Sus
        lectores merecen saber la verdad.</p>






</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>