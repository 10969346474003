import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {

  private userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera;

  constructor() { }

  getUserAgent(): string {
    return this.userAgent;
  }

  isIOSDevice(): boolean {
    return /iPad|iPhone|iPod/.test(this.userAgent) && !(window as any).MSStream;
  }

  isMacDevice(): boolean {
    return /Macintosh/.test(this.userAgent);
  }
}
