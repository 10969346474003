<div class="col h-100" (click)="onDetail(career)" style="cursor: pointer;">
  <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg backgroundGradient1"
    [style]="imgSrc">
    <div class="d-flex flex-column h-100 p-3 gap-2 text-white text-shadow-1 justify-content-between">
      <div class="head-card">
        <ul class="d-flex list-unstyled justify-content-between">
          <div class="head-card-left">
            <li>
              <img [src]="logoSrc" alt="logo img" width="48" height="48" class="rounded-circle border border-white" />
            </li>
            <li class="d-flex ms-1">
              <!--   <i class="fi fi-sr-location-alt me-2 pt-1"></i> -->
              <small class="career-institution fst-normal">{{ career?.institution }}</small>
            </li>
          </div>
          <div class="head-card-right">
            <li *ngIf="(subscriptionLevel > 0)? true : false" class="d-flex align-items-center ms-3">
              <i class="fi fi-sr-star text-warning me-2 pt-1"></i>
              <mat-icon class="text-warning">verified</mat-icon>&nbsp;
              <small class="fw-bold d-none d-sm-block">Destacada</small>
            </li>
            <button mat-button (click)="addOrRemoveFromFavorites($event)" class="button-favorite"
              style="width:25px;  display: flex; justify-content: flex-start; align-items: flex-start; padding: 0 5px;">
              <mat-icon [color]="favColor">favorite</mat-icon>
            </button>
          </div>
        </ul>
      </div>

      <div class="d-flex flex-column justify-content-between flex-grow-1">
        <div class="career-name">
          <h2 class="mb-4 fs-2 lh-0 fw-bold">{{ name }}</h2>
        </div>
        <div class="row row-cols-2 gy-2">
          <div class="col">
            <div class="d-flex align-items-start justify-content-start ">
              <mat-icon class="text-light d-inline" style="min-width: 24px;">place</mat-icon>&nbsp;
              <small *ngFor="let place of places" class="fst-normal">{{ place }}</small>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-start justify-content-start ">
              <mat-icon class="text-light d-inline">corporate_fare</mat-icon>&nbsp;
              <small class="fst-normal">{{iesCharacter}}</small>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-start justify-content-start ">
              <mat-icon class="text-light d-inline">monetization_on</mat-icon>&nbsp;
              <small class="fst-normal">{{ price }}</small>
              <mat-icon #tooltip="matTooltip" (click)="tooltip.show()" class="icon-sm" matTooltipClass="custom-tooltip"
                [matTooltip]="((career?.price?? -1) >= 0 )? 'El precio aproximado es ' + formatPrice(career?.price) : 'No hay datos'"
                matTooltipPosition="after">
                info
              </mat-icon>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-start justify-content-start ">
              <mat-icon class="text-light d-inline">schedule</mat-icon>&nbsp;
              <small class="fst-normal">{{ duration }}</small>
            </div>
          </div>
        </div>
        <div class="row mt-2 justify-content-end">
          <div class="col-12">
            <div class="d-grid align-items-center">
              <button class="w-100" mat-raised-button color="accent"
                (click)="$event.stopPropagation();onCompare(career)">
                <mat-icon class="mat-badge-custom " matBadge="✓" [matBadgeHidden]="!(isOnCompareList(career))"
                  matBadgeColor="warn" matBadgeSize="small">compare_arrows</mat-icon>
                <span *ngIf="isOnCompareList(career)">&nbsp;</span>&nbsp;Comparar</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>