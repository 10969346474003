<div class="container__payment">
  <h5>Ingresa tus datos</h5>

  <!-- form userData  -->

  <form class="form-userData" [formGroup]="formUserData">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Nombres y apellidos / Razón social</mat-label>
      <input matInput [formControl]="name" />
    </mat-form-field>

    <div class="inputs-container">
      <mat-form-field class="input-prefix" appearance="outline">
        <mat-label>Prefijo</mat-label>
        <mat-select [(value)]="dialCode" [formControl]="dialCodeCtrl">
          <mat-option [value]="default_dialCode">(+57) Colombia</mat-option>
          <mat-option>---</mat-option>
          <mat-option *ngFor="let item of dial_codes" [value]="item">
            ({{ item.dial_code }}) {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Celular o Número telefónico</mat-label>
        <input matInput [formControl]="phone" type="number" placeholder="555-12345" />
        <mat-error *ngIf="phone.invalid">Número invalido</mat-error>
      </mat-form-field>
    </div>

    <!-- <div class="inputs-container">
      <mat-form-field class="input-prefix" appearance="outline">
        <mat-label>Tipo</mat-label>
        <mat-select
          [(value)]="identificationsType"
          [formControl]="identificationTypeCtrl"
        >
          <mat-option [value]="default_Identification"
            >C.C. Cédula de ciudadanía</mat-option
          >
          <mat-option>---</mat-option>
          <mat-option *ngFor="let item of identificationsTypes" [value]="item">
            ({{ item.indicator }}) {{ item.name }}
          </mat-option>
          <mat-option [value]="otherType">Otro</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Número de identificación</mat-label>
        <input matInput [formControl]="phone" type="number" />
      </mat-form-field>
    </div> -->
  </form>

  <mat-divider class="mb-4"></mat-divider>
  <!------------------->

  <h5>Paga con tu tarjeta, aceptamos:</h5>
  <div class="images-container align-items-center ">
    <img mat-card-sm-image style="max-width: 60px;" src="../../assets/svg/logos/payments/visa.svg" alt="logo visa" />
    <img mat-card-sm-image src="../../assets/svg/logos/payments/masterCard.svg" alt="logo master card" />
    <img mat-card-sm-image src="../../assets/svg/logos/payments/AMEX.svg" alt="logo american express" />
  </div>

  <!-- form PaymentData  -->

  <form class="form-paymentData" [formGroup]="paymentForm" id="form-checkout">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Nombre en la tarjeta</mat-label>
      <input required matInput formControlName="cardholderName" id="form-checkout__cardholderName" />
    </mat-form-field>

    <div class="input-wrapper">
      <label class="input-mercadoPago-label" for="form-checkout__cardNumber">
        Número de la tarjeta
      </label>
      <div class="input-mercadoPago" id="form-checkout__cardNumber" required></div>
    </div>

    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Correo Electrónico</mat-label>
      <input required matInput [formControl]="emailMp" formControlName="emailMp" placeholder="correo@example.com"
        id="form-checkout__cardholderEmail" />
      <!-- <mat-error *ngIf="email.invalid">Email invalido</mat-error> -->
    </mat-form-field>

    <div class="inputs-container">
      <div class="input-wrapper">
        <label class="input-mercadoPago-label" for="form-checkout__expirationDate">
          Fecha de expiración
        </label>
        <div required id="form-checkout__expirationDate" class="input-mercadoPago"></div>
      </div>

      <div class="input-wrapper">
        <label class="input-mercadoPago-label" for="form-checkout__securityCode">
          CVC (Código de seguridad)
        </label>
        <div required id="form-checkout__securityCode" class="input-mercadoPago"></div>
      </div>
    </div>

    <div class="inputs-container">
      <select required formControlName="identificationType" id="form-checkout__identificationType"
        class="input-mercadoPago select-option"></select>

      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Número de identificación</mat-label>
        <input required matInput formControlName="identificationNumber" type="number"
          id="form-checkout__identificationNumber" />
      </mat-form-field>
    </div>

    <div class="form-group col-sm-12">
      <select required id="form-checkout__installments" name="installments" type="text"
        class="input-mercadoPago select-option"></select>
    </div>

    <select id="form-checkout__issuer" name="issuer" class="d-none"></select>
    <input type="hidden" id="amount" />
    <input type="hidden" id="description" />

    <div class="actions-buttons">
      <div class="my-3">
        <button mat-raised-button color="primary" (click)="goBack()">
          Ir atrás
        </button>
      </div>
      <div class="my-3">
        <button mat-raised-button color="primary" type="submit" id="form-checkout__submit"
          class="btn btn-primary btn-block" [disabled]="isFetching || isSubmitDisabled">
          Continuar con el pago
        </button>
      </div>
    </div>
  </form>
  <!------------------->

</div>