<div class="row row-cols-2 d-flex m-0 mb-3" mat-dialog-title>
  <div class="col">
    <h3>Detalle de la carrera</h3>
  </div>
  <div class="col d-flex justify-content-end">
    <button class="shadow" mat-dialog-close mat-icon-button aria-label="close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div class="container p-0">
    <div *ngIf="career" class="overflow-hidden h-100 d-flex justify-content-center p-5 w-80">
      <div class="row flex-column p-2 rounded-2 border shadow">
        <div class=" col-lg-12 pt-lg-5 text-center rounded">
          <img class="rounded img-fluid w-100" [src]="iesImage" alt="iesImagen"
            style="max-height: 250px; object-fit: cover" />
        </div>
        <div class="col-lg-12 p-3 pt-lg-5">

          <div class="d-flex justify-content-start gap-3 mb-4 mb-lg-3">
            <div>
              <img [src]="iesLogo" alt="iesLogo" width="50vw" height="50vh"
                class="rounded-circle border border-white" />
            </div>
            <div class="d-flex align-items-start gap-1">
              <h1 class="display-6 fw-bold lh-1 pt-2">{{ career.name }}</h1>
              <mat-icon *ngIf="((career.subscriptionLevel?? 0) > 0)" class="text-warning"
                style="overflow: visible;">verified</mat-icon>
              <button mat-icon-button (click)="addOrRemoveFromFavorites($event)">
                <mat-icon [color]="favColor">favorite</mat-icon>
              </button>
            </div>
          </div>

          <!-- Descripción -->
          <div *ngIf="career.description">
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">badge</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Descripción:</p>
                  <!-- <p>{{ career.description }}</p> -->
                  <app-expandable-cell [content]="career.description" [maxLines]="4"></app-expandable-cell>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3">
            <!-- Institucion Educativa Superior (IES) -->
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">
                  account_balance</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Institución:</p>
                  <p>{{career.institution}}</p>
                </div>
              </div>
            </div>
            <!-- Carácter -->
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em"
                  height="1.75em">corporate_fare</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Carácter:</p>
                  <p>{{ career.iesCharacter }}</p>
                </div>
              </div>
            </div>
            <!-- Duración -->
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">schedule</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Duración: </p>
                  <p>{{ duration }}</p>
                </div>
              </div>
            </div>
            <!-- Modalidad -->
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">co_present</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Modalidad: </p>
                  <p>{{career.modalities}}</p>
                </div>
              </div>
            </div>
            <!-- Sede -->
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em"
                  height="1.75em">location_on</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Sede: </p>
                  <p>{{location}}</p>
                </div>
              </div>
            </div>
            <!-- Sede - Dirección -->
            <div *ngIf="address.length > 0" class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">map</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Dirección: </p>
                  <p>{{address}}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Título Otorgado -->
          <div *ngIf="career.obtainedTitle || career.profileGraduated">
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">school</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Título Otorgado:</p>
                  <!-- <p>{{ career.obtainedTitle?? career.profileGraduated }}</p> -->
                  <app-expandable-cell [content]="career.obtainedTitle?? career.profileGraduated "
                    [maxLines]="3"></app-expandable-cell>
                </div>
              </div>
            </div>
          </div>

          <!-- Perfil de Aspirante -->
          <div *ngIf="career.candidateProfile">
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">badge</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Perfil del aspirante:</p>
                  <!-- <p>{{ career.candidateProfile }}</p> -->
                  <app-expandable-cell [content]="career.candidateProfile" [maxLines]="3"></app-expandable-cell>
                </div>
              </div>
            </div>
          </div>

          <!-- Perfil de Egresado -->
          <div>
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em"
                  height="1.75em">history_edu</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Perfil del egresado:</p>
                  <!-- <p>{{ career.profileGraduated }}</p> -->
                  <app-expandable-cell [content]="career.profileGraduated" [maxLines]="3"></app-expandable-cell>
                </div>
              </div>
            </div>
          </div>

          <!-- Financiero -->
          <div>
            <!-- Precio -->
            <div class="col">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em"
                  height="1.75em">monetization_on</mat-icon>
                <div>
                  <p class="opacity-75 mb-0">Precio: </p>
                  <p>{{price}} &nbsp;
                    <mat-icon #tooltip="matTooltip" class="icon-sm" matTooltipClass="custom-tooltip"
                      (click)="tooltip.show()"
                      [matTooltip]="((this.career.price?? -1) >= 0 )? 'El precio aproximado es ' + formatPrice(this.career.price) : 'No hay datos'"
                      matTooltipPosition="after" [matTooltipHideDelay]="3000">
                      info
                    </mat-icon>
                  </p>
                </div>
              </div>
            </div>
            <!-- Apoyo Financiero -->
            <div class="col" *ngIf="getSubscriptionLevel() > SubscriptionEnum.Silver && career.hasFinancialSupport">
              <div class="d-flex align-items-start p-3 shadow-sm rounded-3">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 " width="1.75em" height="1.75em">credit_score
                </mat-icon>
                <div>
                  <div>
                    <p class="opacity-75 mb-0">Apoyo financiero: </p>
                    <p>Puedes solicitar apoyo financiero directamente con la institución.</p>
                  </div>
                  <!-- Contáctanos por Whatsapp: -->
                  <div class="d-flex align-items-center"
                    *ngIf="career.financialSupport_hasWhatsapp && career.financialSupport_whatsapp">
                    <span>
                      Escríbenos:
                    </span>
                    <a class="p-2" [href]="'https://api.whatsapp.com/send?phone='+career.financialSupport_whatsapp"
                      (click)="onRedirect('https://api.whatsapp.com/send?phone='+career.financialSupport_whatsapp)"
                      target="_blank">
                      <img class="socials" [src]="getSvg('whatsapp')" alt="whatsapp" width="30" height="30">
                      <!-- {{ career.financialSupport_whatsapp }} -->
                    </a>
                  </div>
                  <!-- Telefono de Contacto -->
                  <div class="row row-cols-1 row-cols-md-2 row-cols-xl-2 g-2">
                    <div class="d-flex" *ngIf="career.financialSupport_contactPhone">
                      <!-- Phone -->
                      <a class="d-flex p-2" style="color: inherit;"
                        [href]="'tel:' + career.financialSupport_contactPhone"
                        (click)="onRedirect('tel:' + (career.financialSupport_phone_code_country?? '+57')+ career.financialSupport_contactPhone)"
                        target="_blank">
                        <mat-icon class="pb-2">phone</mat-icon>
                        <div style="width: 5px;"></div>
                        <div class="d-flex">
                          <span>
                            ({{career.financialSupport_phone_code_country?? "+57"}})
                          </span>
                          <span> &nbsp; </span>
                          <span>
                            {{career.financialSupport_contactPhone }}
                          </span>
                        </div>
                      </a>
                    </div>
                    <!-- Email -->
                    <div class="d-flex" *ngIf="career.financialSupport_email">
                      <a class="d-flex p-2" style="color: inherit;" [href]="'mailto:' + career.financialSupport_email"
                        (click)="onRedirect('mailto:' + career.financialSupport_email)" target="_blank">
                        <mat-icon class="pb-2">email</mat-icon>
                        <div style="width: 5px;"></div>
                        <span>
                          {{ career.financialSupport_email }}
                        </span>
                      </a>
                    </div>
                  </div>
                  <!-- Sitio Web -->
                  <div class="d-flex" *ngIf="career.financialSupport_website">
                    <a class="d-flex p-2" mat-raised-button [href]="career.financialSupport_website"
                      (click)="onRedirect(career.financialSupport_website)" target="_blank">
                      <mat-icon class="pb-2">open_in_new</mat-icon>
                      Sitio Web del Apoyo Financiero
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Botones -->
          <div class="d-flex flex-wrap my-3 mb-lg-3">
            <!-- Contacto Básico -->
            <div *ngIf="getSubscriptionLevel() > SubscriptionEnum.Silver" class="d-flex flex-wrap
               w-lg-auto mr-auto justify-content-lg-start justify-content-center 
              align-items-center my-3 mb-lg-3">
              <!-- Phone -->
              <div class="d-flex" *ngIf="career.contactPhone">
                <a class="d-flex p-2" style="color: inherit;" [href]="'tel:' + career.contactPhone"
                  (click)="onRedirect('tel:' + (career.phone_code_country?? '+57')+ career.contactPhone)"
                  target="_blank">
                  <mat-icon class="pb-2">phone</mat-icon>
                  <div style="width: 5px;"></div>
                  <div class="d-flex">
                    <span>
                      ({{career.phone_code_country?? "+57"}})
                    </span>
                    <span> &nbsp; </span>
                    <span>
                      {{career.contactPhone }}
                    </span>
                  </div>
                </a>
              </div>
              <!-- Email -->
              <div class="d-flex" *ngIf="career.contactEmail">
                <a class="d-flex p-2" style="color: inherit;" [href]="'mailto:' + career.contactEmail"
                  (click)="onRedirect('mailto:' + career.contactEmail)" target="_blank">
                  <mat-icon class="pb-2">email</mat-icon>
                  <div style="width: 5px;"></div>
                  <span>
                    {{ career.contactEmail }}
                  </span>
                </a>
              </div>
            </div>
            <!-- Redes Sociales -->
            <div *ngIf="getSubscriptionLevel() > SubscriptionEnum.Silver" class="d-flex flex-wrap col 
              w-lg-auto mr-auto justify-content-lg-start justify-content-center 
              align-items-center my-3 mb-lg-3">
              <div class="d-flex">
                <!-- Whatsapp -->
                <a *ngIf="career.hasWhatsapp && career.whatsapp" class="p-2"
                  [href]="'https://api.whatsapp.com/send?phone='+career.whatsapp"
                  (click)="onRedirect('https://api.whatsapp.com/send?phone='+career.whatsapp)" target="_blank">
                  <img class="socials" [src]="getSvg('whatsapp')" alt="whatsapp" width="30" height="30">
                </a>
              </div>
              <div class="d-flex">
                <!-- Facebook -->
                <a *ngIf="career.hasFacebook && career.facebook" class="col p-2" [href]="career.facebook"
                  (click)="onRedirect(career.facebook)" target="_blank">
                  <img class="socials" [src]="getSvg('facebook')" alt="facebook" width="30" height="30">
                </a>
              </div>
              <div class="d-flex">
                <!-- Linkedin -->
                <a *ngIf="career.hasLinkedin && career.linkedin" class="col p-2" [href]="career.linkedin"
                  (click)="onRedirect(career.linkedin)" target="_blank">
                  <img class="socials" [src]="getSvg('linkedin')" alt="linkedin" width="30" height="30">
                </a>
              </div>
            </div>
            <!-- Botones de acción -->
            <div class="d-flex flex-wrap w-100 justify-content-lg-end justify-content-center 
                      align-items-center my-3 mb-lg-3 gap-3">
              <!-- Sitio Web -->
              <div class="d-flex justify-content-center">
                <a class="p-2" mat-raised-button [href]="career.webSite" (click)="onRedirect(career.webSite)"
                  target="_blank">
                  <mat-icon class="pb-2">open_in_new</mat-icon>
                  Sitio Web{{ getSubscriptionLevel() > SubscriptionEnum.Freemium ? ' del Programa': '' }}
                </a>
              </div>
              <!-- Contáctame -->
              <div class="d-flex justify-content-center">
                <button class="p-2" mat-raised-button (click)="onConactMe(career)">
                  <mat-icon class="pb-2">contact_support</mat-icon>
                  Quiero más información
                </button>
              </div>
              <!-- Comparar -->
              <div class="d-flex justify-content-center">
                <button class="p-2" color="accent" mat-raised-button (click)="onCompare(career)">
                  <mat-icon class="pb-2">compare_arrows</mat-icon>
                  Comparar
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>