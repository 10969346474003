import { Component } from '@angular/core';

@Component({
  selector: 'app-redirect-button-vocational',
  templateUrl: './redirect-button-vocational.component.html',
  styleUrls: ['./redirect-button-vocational.component.scss']
})
export class RedirectButtonVocationalComponent {

}
