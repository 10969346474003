import { DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-photo-upload-dialog',
  templateUrl: './photo-upload-dialog.component.html',
  styleUrls: ['./photo-upload-dialog.component.scss']
})
export class PhotoUploadDialogComponent implements OnInit {
  // constructor(private dialogRef: MatDialogRef<PhotoUploadDialogComponent>){  }

  imgChangeEvt: string = '';

  @Output()
  cropImgPreview: string = '';
  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }
  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64 ?? '';
  }
  imgLoad() {
    // display cropper tool
  }
  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }

  // onSave() {
  //   if(this.cropImgPreview){
  //   this.dialogRef.close();
  //   }
  // }

  isMobile = false;
  ngOnInit(): void {
    var ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      this.isMobile = true;
    else if (/Chrome/i.test(ua))
      this.isMobile = false;
    else
      this.isMobile = true;
    // console.warn("isMobile:", this.isMobile);
  }

}
