<div class="container">
    <div class="row row-cols-1 row-cols-lg-2 justify-content-center align-items-center">
        <div class="col">
            <img src="../../assets/svg/Analytics-amico.svg" alt="">
        </div>
        <div class="col">
            <div class="d-grid gap-4 text-center text-lg-start">
                <h4 class="fw-bold display-4">
                    ¿Buscas una universidad? <span class="primaryColor">Tenemos </span>
                    todos los detalles que necesitas
                </h4>
                <p class="text-muted" style="font-size: 20px;">
                    Si eres estudiante te ayudaremos a encontrar los mejores programas y carreras
                    para tus objetivos ajustados a tus necesidades. Si eres docente, regístrate como
                    profesor, posiciónate en el radar de todas las universidades y prepárate a ser contactado.
                </p>
                <div class="d-flex justify-content-between">
                    <div class="text-center">

                        <h4>2000 </h4>
                        <h6 class="opacity-75">Estudiantes</h6>
                    </div>
                    <div class="vr"></div>
                    <div class="text-center">

                        <h4>500 </h4>
                        <h6 class="opacity-75">Profesores</h6>
                    </div>
                    <div class="vr"></div>
                    <div class="text-center">
                        <h4>5000</h4>

                        <h6 class="opacity-75">Universidades</h6>
                    </div>
                </div>
            </div>
            <button class="mt-4" color="primary" mat-raised-button routerLink="/servicios"> Conocer Servicios</button>
        </div>

    </div>
</div>