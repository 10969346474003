import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { mockValues } from '../../valuesMock';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DonationService } from 'src/app/services/donation.service';
import Swal from 'sweetalert2';

interface CardValues{
  id: number
  value: number
}

@Component({
  selector: 'app-amount-method',
  templateUrl: './amount-method.component.html',
  styleUrls: ['./amount-method.component.scss']
})
export class AmountMethodComponent implements OnInit {
  
  donative: number = 0;
  cardsValues: CardValues[] = [];
  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private router:Router,  private donationService: DonationService, private el: ElementRef) {
    this.form = this.formBuilder.group({
      donativeControl: new FormControl(this.donative),
    });
  }

  ngOnInit(): void {
    this.cardsValues = mockValues; 

    this.form.get('donativeControl')?.valueChanges.subscribe(value => {
      this.donative = value ? Number(value) : 0;
      this.donationService.updateDonation({ donative: this.donative });
    });

    const elements = this.el.nativeElement.querySelectorAll('.ng-star-inserted');
    
    elements.forEach((element: HTMLElement) => {
      element.style.width = 'auto'; 
    });
  }

  changeValueDonation(value: number): void {
    this.donative = value;
    this.form.get('donativeControl')?.setValue(Number(value)); // Actualiza el valor del formulario
    this.donationService.updateDonation({ donative: this.donative }); 
  }

  formatValue(value: number): string {
    return value.toLocaleString('es-CO'); 
  }

  goToCart(paymentMethod: string){
    if(this.donative < 20000){
      Swal.fire({
        icon: 'error',
        title: 'Monto mínimo',
        text: "Para continuar debes seleccionar un monto mínimo de $20.000COP",
      });
    }else{
      this.router.navigate(['donaciones/pagar'], {state:{paymentMethod}}); 
    }
  }
}
