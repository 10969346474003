import { Component } from '@angular/core';
import { RegisterModalComponent } from '../../register/register-modal/register-modal/register-modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-login-student',
  templateUrl: './login-student.component.html',
  styleUrls: ['./login-student.component.scss']
})
export class LoginStudentComponent {
  public professorsUrl = environment.publicUrls.professorsUrl;
  public institutesUrl = environment.publicUrls.institutesUrl;

  formLogin: FormGroup;
  hide = true;

  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {

    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-google.svg'));
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-linkedin.svg'));
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-facebook.svg'));
    this.matIconRegistry.addSvgIcon(
      'apple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-apple.svg'));
    this.matIconRegistry.addSvgIcon(
      'contactus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/contactus.svg'));

    this.formLogin = new FormGroup({
      email: this.email,
      password: new FormControl(),
      remindMe: new FormControl()
    });
  }

  ngOnInit(): void {
  }

  getErrorMessage() {
    return this.email.hasError('email') ? '(No es un correo valido)' : '';
  }

  async onSubmit() {
    try {

      const email: string = this.formLogin.value.email;
      const password: string = this.formLogin.value.password;
      const response = await this.userService.loginWithEmail(email, password);

    } catch (error) {
      const errorMessage = this.userService.getAuthMessageError(error);
      alert('Error de autenticación: ' + errorMessage);
      console.error(error);
    }
  }

  onLoginWithGoogle() {
    this.userService.loginWithGoogle()
      .then(response => {
        console.log(response);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onLoginWithLinkedin() {
    this.userService.loginWithLinkedin()
      .then(response => {
        console.log(response);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onLoginWithFacebook() {
    this.userService.loginWithFacebook()
      .then(response => {
        console.log(response);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  async onLoginWithApple() {
    await this.userService.loginWithApple()
    this.router.navigate(['/perfil']);

  }

  openRegisterModal(event: Event): void {
    event.preventDefault();
    const dialogRef = this.dialog.open(RegisterModalComponent, {
      width: '90%',
      maxWidth: '850px',
      data: {
        selectedOption: 'student'
      }
    });
  }
}
