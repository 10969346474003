import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Timestamp } from 'firebase/firestore';
import { Profile } from 'src/app/models/profile';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import { VocationalTestService } from 'src/app/services/vocational-test.service';
import { DialogResultsTestComponent } from 'src/app/vocational-test/dialog-results-test/dialog-results-test.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  constructor(private userService: UserService, private userProfileService: UserProfileService,
    private utilityService: UtilityService, private vocationalTestService: VocationalTestService, private analyticsService: AnalyticsService, private router: Router, private formBuilder: FormBuilder, public dialog: MatDialog,) {
  }

  userProfile?: Profile;

  defaultFullName: string = 'Nombre Completo';
  defaultPhotoUrl: string = environment.publicUrls.defaultProfilePhoto;

  testimonial = new FormControl("", [Validators.required])
  form = this.formBuilder.group({
    email: this.testimonial,
  
  });

  getDefaultProfile(): Profile {
    return {
      email: '',
      fullName: '',
      profile: 'student',
      emailVerified: false,
      photoUrl: this.defaultPhotoUrl,
      personalData: {
        birthDate: undefined,
        gender: undefined,
        nationality: undefined,
        phone: undefined,
        location: {
          city: 'Bogotá',
          country: 'Colombia'
        },
      },
    };
  }


  parseDateToString2(_date?: Timestamp): string {
    if (!_date) return '';
    // console.log(_date);
    var date: Date = _date.toDate();
    return this.parseDateToString(date);
  }

  parseDateToString(date?: Date): string {
    if (!date) return '';
    // console.log(date);
    const year: number = date.getFullYear();
    const months: Array<string> = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

    return `${months[date.getMonth()]}  ${year}`;
  }

  getAge(birthDate?: Date): string {
    if (!birthDate) return '  ';// return '(Pendiente)';
    var today: Date = new Date()
    today.setHours(0, 0, 0, 0);
    var date: Date = birthDate;
    date.setHours(0, 0, 0, 0);

    var diff: number = today.getTime() - birthDate.getTime();
    var age = Math.floor(diff / (1000 * 3600 * 24) / 365);
    return age.toString();
  }

  getTotalTime2(_start: Timestamp, _end?: Timestamp): string {
    // start: '01/26/2021' end: undefined (now -> new Date())
    var start: Date = _start.toDate();
    start.setHours(0, 0, 0, 0);
    var end: Date | undefined = _end?.toDate();

    return this.getTotalTime(start, end);
  }

  getTotalTime(start: Date, end?: Date): string {
    // start: '01/26/2021' end: undefined (now -> new Date())
    start.setHours(0, 0, 0, 0);
    if (!end) end = new Date();
    end.setHours(0, 0, 0, 0);
    // console.log('startDate: ' + start);
    // console.log('endDate: ' + end);
    const difference_In_Time: number = (end.getTime() - start.getTime()); // 65797070738
    const difference_In_Days: number = difference_In_Time / (1000 * 3600 * 24); // 761.5401705787037

    var totalMonths: number = Math.floor(difference_In_Days / 30); // 25 -> 25.384672352623458
    var years: number = Math.floor(totalMonths / 12); // 2 -> 2.1153893627186213
    var months: number = Math.floor(totalMonths - (years * 12));

    var sYears: string = (years < 1) ? '' : (years > 1) ? years + ' años' : years + ' año';
    var sMonths: string = (months < 1) ? '' : (months > 1) ? ' ' + months + ' meses' : ' ' + months + ' mes';

    var sTotalTime: string = sYears + sMonths;

    if (sTotalTime === '') sTotalTime = '0 meses';
    return sTotalTime;
  }

   ngOnInit() {
    console.log('UserProfileComponent.ngOnInit');
    const uid: string = this.userService.getUserId();
    // console.log('uid: ' + uid);
    const getUser = this.getUserProfile(uid);
    
    this.openResultTest()
    // Analytics
    this.analyticsService.logEventScreenView(
      'UserProfileComponent',
      UserProfileComponent.name);
  }

  ngOnDestroy(): void {
  }

  async openResultTest(){
    const testId = localStorage.getItem("testId")
    if(testId) {
      const data = await this.vocationalTestService.getResults(testId)
      const currentUser = JSON.parse(localStorage.getItem('user')!);
      const updateUser = await this.vocationalTestService.updateResultsUser(currentUser.uid, testId)
      const email = this.userService.getEmail()
      const name = this.userService.getFullName()
      if(updateUser){
        this.userProfile?.vocationalTestIds?.push(testId)
        localStorage.removeItem("testId")
      } 
      data && this.dialog.open(DialogResultsTestComponent, {
        width: '70%',
        maxWidth:"850px",
        height:'80%',
        maxHeight:"700px",
        data, 
      });
      await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/sendTestResults",{
        method:"POST",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          secret:environment.secretEmail,
          name,
          email,
          results: data?.results
        })
      })
    }
    return null
  }

  async getTestResults(){
    const firstId = this.userProfile?.vocationalTestId
    if(firstId){
      this.userProfile?.vocationalTestIds?.push(firstId)
    }
    const ids = this.userProfile?.vocationalTestIds
    if(ids && ids?.length > 0){
      const results = await this.vocationalTestService.getAllResults(ids)
      this.dialog.open(DialogResultsTestComponent, {
        width: '70%',
        height:'80%',
        maxWidth:"850px",
        maxHeight:"700px",
        data: results
      });
    }

  }

  getMatChipStyle(index: number): string {
    return this.utilityService.getMatChipStyle(index);
  }

  ChangeIsProfessor(enabled: boolean) {
    if (this.userProfile) this.userProfile.isProfessor = enabled;
  }

  async getUserProfile(id: string) {
    try {
      const res = await this.userProfileService.getUserProfile(id);
      // .subscribe(res => {
      // console.log(`#COD133 this.userProfileService.getUserProfile(${id})`);
      // console.log(res);
      if(res){
        this.userProfile = {
          ...res,
          vocationalTestIds: res.vocationalTestIds || []
        }

      }
      // this.initUserProfile(id);
      if (!this.userProfile) {
        // Fill default User Profile when it's empty
        console.log('Fill User Profile with Default data');
        this.userProfile = this.getDefaultProfile();
        this.userProfile.id = id;
        // console.log('#COD323 UserProfile.id: ' + this.userProfile.id);
        // console.log('#COD324 id: ' + id);

        // Try to get Personal Data from Auth Service
        try {
          this.userProfile.fullName = this.userService.getFullName() ?? this.defaultFullName;
        } catch (error) {
          console.log(error);
        }

        try {
          this.userProfile.email = this.userService.getEmail() ?? '';
        } catch (error) {
          console.log(error);
        }

        try {
          this.userProfile.photoUrl = this.userService.getProfilePhoto() ?? this.defaultPhotoUrl;
        } catch (error) {
          console.log(error);
        }

        try {
          this.userProfile.emailVerified = this.userService.getEmailVerified() ?? false;
        } catch (error) {
          console.log(error);
        }

        // Save User Profile in Firestore
        // console.log(this.userProfile);
        this.userProfileService.saveUserProfile(this.userProfile);

      } else {
        if (!this.userProfile.photoUrl || this.userProfile.photoUrl === '') this.userProfile.photoUrl = this.defaultPhotoUrl;
      }
    } catch (error) {
      console.log(error);
    }
  }
  // experienceList: Array<Experience> = [];

  initUserProfile(id: string): void {
    // console.log(this.userProfile);
    // if (uid) this.userProfile.id = uid;
    // Validate if some fields are empty and try to get from auth Service
    if (!this.userProfile) {
      // Fill default User Profile when it's empty
      console.log('Fill User Profile with Default data');
      this.userProfile = this.getDefaultProfile();
      this.userProfile.id = id;

      // Try to get Personal Data from Auth Service
      try {
        const fullName: string = this.userService.getFullName();
        console.log('fullName: ' + fullName);
        if (fullName && fullName !== '') this.userProfile.fullName = fullName;
        console.log('this.UserProfile.fullName: ' + this.userProfile.fullName);
      } catch (error) {
        console.log(error);
      }

      try {
        const email = this.userService.getEmail();
        if (email && email != '') this.userProfile.email = email;
      } catch (error) {
        console.log(error);
      }

      try {
        const photoUrl: string = this.userService.getProfilePhoto();
        if (photoUrl && photoUrl != '') this.userProfile.photoUrl = photoUrl;
      } catch (error) {
        console.log(error);
      }

      try {
        const emailVerified: boolean = this.userService.getEmailVerified();
        if (emailVerified) this.userProfile.emailVerified = emailVerified;
      } catch (error) {
        console.log(error);
      }
      // Save User Profile in Firestore
      // console.log(this.userProfile);
      this.userProfileService.saveUserProfile(this.userProfile);
    } else {
      if (!this.userProfile.photoUrl || this.userProfile.photoUrl === '') this.userProfile.photoUrl = this.defaultFullName;
    }
  }

  goToEdit(){
    this.router.navigate(["/perfil/editar"])
  }

  goToComparisons(){
    this.router.navigate(["/comparador"])
  }

  goToFavorites(){
    this.router.navigate(["/favoritos"])
  }

  async sendTestimonial():Promise<void>{
    try {
      const _testimonial = (this.testimonial.value ?? "") as string
      await this.userProfileService.sendTestimonial(_testimonial,this.userProfile?.fullName, this.getAge(this.userProfile?.personalData?.birthDate) )
      alert("Tu testimonio ha sido enviado satisfactoriamente")
      this.clearForm()
    } catch (error) {
      console.error("Error sending testimonial form in user-profile component", error)
      alert("Hubo un error al momento de enviar su testimonio")
    }
  }
    
  clearForm() {
    this.testimonial = new FormControl('', [Validators.required]);
  }
}
