<div class="container-fluid py-5 mt-5 px-3">
  <div class="widget-wrapper">
    <div class="row text-center text-lg-start">
      <div class="col-xl-3 col-lg-4 col-md-6 justify-content-center d-sm-flex w-sm-100">
        <div class="footer-info-gu justify-content-center align-items-center" style="max-width: 450px;">
          <app-logo size="160px"></app-logo>
          <div>
            <p class="opacity-75 mt-4 mb-0 ">Guía Universitaria te ayuda a buscar los programas universitarios,
              empieza ya a formar tu educación</p>
            <p class="mt-0 fw-bolder fs-4" style="color:#002e83">Explora, compara y conquista</p>
            <!-- <p class="mt-0 fw-bolder fs-4" style="color:#0080c1">Explora, compara y conquista</p> -->
          </div>
          <ul class="list-unstyled d-flex justify-content-center justify-content-lg-start g-4">
            <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="https://www.tiktok.com/@guia.universitaria" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/tiktok-logo.svg" alt="TikTok"> </a>
            </li>
            <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="https://www.facebook.com/GuiaUniversitariaWeb" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/facebook-app-icon.svg" alt="Facebook"> </a>
            </li>
            <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="https://www.instagram.com/guia.universitaria" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/ig-instagram-icon.svg" alt="Instragram">
              </a>
            </li>
            <!-- <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="#0" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/logo-linkedin.svg" alt="LinkedIn">
              </a>
            </li> -->
            <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="https://wa.me/573003621500" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/wa-whatsapp-icon.svg" alt="WhatsApp">
              </a>
            </li>
            <li class="d-flex justify-content-center justify-content-lg-start g-4">
              <a href="https://www.linkedin.com/company/gu%C3%ADa-universitaria/" target="_blank">
                <img class="socials" src="../../../assets/svg/logos/lkd-linkedin-icon.svg" alt="LinkedIn">
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xl-2 offset-xl-1 col-lg-2 col-md-6 col-sm-6">
        <div class="footer-widget">
          <h6 class="fw-bold my-3 my-md-4 my-lg-3 ms-2">
            Enlace Rápido</h6>
          <ul class="list-unstyled">
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/inicio">Inicio</a></li>
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/nosotros">Nosotros</a></li>
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/buscador">Buscador</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xl-2 offset-xl-1 col-lg-2 col-md-6 col-sm-6">
        <div class="footer-widget">
          <h6 class="fw-bold my-3 my-md-4 my-lg-3 ms-2">Servicios</h6>
          <ul class="list-unstyled">
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/servicios">Servicios</a></li>
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/creditos">Créditos</a></li>
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted" href="/contactanos">Contáctanos</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xl-2 offset-xl-1 col-lg-2 col-md-6 col-sm-6">
        <div id="ayuda" class="footer-widget">
          <h6 class="fw-bold my-3 my-md-4 my-lg-3 ms-2">Ayuda &amp; Soporte</h6>
          <ul class="list-unstyled">
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted"
                (click)="openDialogTermsAndConditionsComponent()">Términos y condiciones</a></li>
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted"
                (click)="openDialogPrivacyPolicyComponent()">Política de privacidad</a></li>
            <li class="nav-item mb-2"><a mat-button class="nav-link text-muted"
                (click)="openDialogDataUsePolicyComponent()">Política de uso de datos</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column flex-sm-row justify-content-between pt-4 mt-4 border-top">
    <p>© 2023 Company, Inc. All rights reserved.</p>

    <p style="font-size: 7px;"><a href="https://storyset.com/education" target="_blank">Education illustrations by
        Storyset</a></p>
  </div>
</div>