import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccessGuard implements CanActivate {

  constructor(public userService: UserService,
    public router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userService.isLoggedIn) {
      // console.log("user is not LoggedIn");
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

  validateCanActivate(): boolean {
    // TODO: uncomment console log lines
    // console.log("cAccessGuard-validateCanActivate");
    if (!this.userService.isLoggedIn) {
      // console.log("user is not LoggedIn");
      return false;
    }
    return true;
  }

}
