<div class="cell-content" [style.fontSize.px]="fontSize">
    <div [class.truncated]="!isExpanded" [class.expanded]="isExpanded" [style.--max-lines]="maxLines"
        [style.lineHeight]="lineHeight + 'em'">
        {{ content }}
    </div>
    <button *ngIf="shouldShowButton" (click)="toggleExpand()" class="expand-button"
        [style.fontSize.px]="effectiveButtonFontSize">
        {{ isExpanded ? '[Ver menos]' : '[Ver más]' }}
        <!-- {{ isExpanded ? '[-]' : '[+]' }} -->
    </button>
</div>