export function mapErrorsResetPass(code:string){
    switch(code){
        case 'auth/expired-action-code':
            return 'El token para cambiar la contraseña expiró, inicie nuevamente el proceso.'
        
        case 'auth/invalid-action-code':
            return 'El token proporcionado es inválido o ya ha sido usado.'
        
        case 'auth/user-disabled':
            return 'Su cuenta de usuario ha sido deshabilitada.'
        
        case 'auth/user-not-found':
            return 'Usuario no encontrado para el cambio de la contraseña.'

        case 'auth/weak-password':
            return 'La constraseña proporcionada es demasiado débil.'

        default:
            return ''
        }
}