import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentDetails } from 'src/app/models/payment.interface';
import { DonationService } from 'src/app/services/donation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-results',
  templateUrl: './payment-results.component.html',
  styleUrls: ['./payment-results.component.scss'],
})
export class PaymentResultsComponent {
  transaction_id: string = '';
  issuer_id: string = '';
  status: string = '';
  date_time: string = '';
  transaction_number: string = '';
  service_name: string = 'Fundación Guia Universitaria';
  nit: string = '901815502-2';
  address: string = 'Carrera 20 No.185-58';
  city: string = 'Bogotá, D.C. Colombia';
  email: string = '';
  phone: string = '';
  identification_number: string = '';
  description: string = 'Donación a la Fundación Guía Universitaria';
  value: number = 0;
  issuer_name_by_id: string = '';
  payment_method_id: string = '';
  currency_id: string = '';

  isLoading: boolean = true

  transactionStatuses: Array<{
    icon: string;
    message: string;
    iconClass: string;
  }> = [];

  paymentId: string | null = null;

  error: string | null = null;

  constructor(
    private donationService: DonationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isLoading=true
    this.route.queryParams.subscribe((params) => {
      this.paymentId = params['payment_id'] || null; 

      if (this.paymentId) {
        this.getPaymentDetailsById(this.paymentId)
      } else {
        this.subscribeToDonation();
        this.checkPaymentError(this.transaction_id)
        this.setTransactionMessage(this.status);
        this.getIssuerNameById(this.issuer_id, this.payment_method_id);
        this.isLoading=false
      }
    });
  }
  subscribeToDonation() {
    this.donationService.currentPaymentDetails.subscribe(
      (details: PaymentDetails | null) => {
        details && this.setPaymentDetails(details);
      }
    );
  }
  setTransactionMessage(status: string) {
    switch (status) {
      case 'approved':
        this.transactionStatuses = [
          {
            icon: 'check_circle',
            message: 'Su transacción fue aprobada',
            iconClass: 'approved',
          },
        ];
        break;
      case 'rejected':
        this.transactionStatuses = [
          {
            icon: 'cancel',
            message: 'Su transacción fue rechazada',
            iconClass: 'rejected',
          },
        ];
        break;
      case 'in_process':
        this.transactionStatuses = [
          {
            icon: 'error',
            message: 'Su transacción se encuentra pendiente',
            iconClass: 'in_process',
          },
        ];
        break;
      default:
        this.transactionStatuses = [
          {
            icon: 'error',
            message: 'Su transacción se encuentra pendiente',
            iconClass: 'in_process',
          },
        ];
        break;
    }
  }

  async getIssuerNameById(
    issuerId: string,
    paymentMethodId: string
  ): Promise<string> {
    const url = "https://us-central1-guia-universitaria-dev.cloudfunctions.net/getBankById";
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body:JSON.stringify ({
        paymentMethodId,
        isProd: environment.production,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const issuer = data.find((issuer: any) => issuer.id === issuerId);
        console.log('resultado', issuer);
        return (this.issuer_name_by_id = issuer ? issuer.name : 'Otro');
      })
      .catch((error) => {
        console.error('Error:', error);
        return (this.issuer_name_by_id = 'Otro');
      });
  }

  switchStatusName(status: string): string {
    switch (status) {
      case 'rejected':
        return 'Rechazado';
      case 'approved':
        return 'Aprobado';
      case 'in_process':
        return 'En proceso';
      default:
        return 'En proceso';
    }
  }

  setPaymentDetails(details: PaymentDetails) {
    this.transaction_id = details.id;
    this.issuer_id = details.issuer_id;
    this.status = details.status || 'in_process';
    this.date_time = new Date(details.date_created).toLocaleString();
    this.transaction_number = details.id;
    this.email = details.email || '';
    this.phone = details.phone || '';
    this.identification_number = details.identificationNumber || '';
    this.value = details.transaction_amount || details.net_amount;
    this.payment_method_id = details.payment_method_id;
    this.currency_id = details.currency_id;
  }
  formatValue(value: number): string {
    return value.toLocaleString('es-CO');
  }

  goBack(): void {
    this.router.navigate(['/donaciones']);
    this.donationService.resetDonations();
  }

  goHome(): void {
    this.router.navigate(['/']);
  }

  async getPaymentDetailsById(id:string){
   await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getPaymentById", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${environment.GCLOUD_TOKEN}`,
      },
      body:JSON.stringify ({
        id,
        isProd: environment.production,
      }),
    })
    .then((response) => response.json())
    .then(response => {
      // console.log('Payment details:', response);
      const {data, error, message} = response
      if(error){
        this.error = message
        this.setTransactionMessage("rejected")
      }
      const details = {
        status:error ? "rejected": data.status,
        authorization_code:data.authorization_code,
        currency_id:data.currency_id,
        date_created:data.date_created,
        date_approved:data.date_approved,
        date_last_updated:data.date_last_updated,
        description:data.description,
        detail:data.status_detail,
        issuer_id:data.issuer_id,
        net_amount: data.net_amount,
        payment_method_id:data.payment_method_id,
        status_detail:data.status_detail,
        transaction_amount: data.transaction_amount,
        id:data.id,
        installments: data.installments,
        identificationType:data.payer.identification.type,
        identificationNumber:data.payer.identification.number,
        email: data.payer.email,
        phone: data.payer.phone.number,
      }
      this.donationService.updateDonation({donative:data.transaction_amount})
      this.setPaymentDetails(details)
      this.getIssuerNameById(data.issuer_id,data.payment_method_id)
      this.setTransactionMessage(data.status)
      this.isLoading= false
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }

  async checkPaymentError(id:string){
    await fetch("https://us-central1-guia-universitaria-dev.cloudfunctions.net/getPaymentById", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${environment.GCLOUD_TOKEN}`,
      },
      body:JSON.stringify ({
        id,
        isProd: environment.production,
      }),
    })
    .then((response) => response.json())
    .then(response => {
      // console.log("respuesta getOPaymentID", response)
      const {error, message} = response
      if(error){
        this.error = message
        this.setTransactionMessage("rejected")
        this.status= "rejected"
      }
     })
  }
}
