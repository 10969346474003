import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialModule } from './modules/material/material.module';

import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { SearchComponent } from './pages/search/search.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CareerComponent } from './pages/career/career.component';
import { InstituteComponent } from './pages/institute/institute.component';

import { CareerCardComponent } from './components/career-card/career-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { HorizontalMenuComponent } from './components/horizontal-menu/horizontal-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
//import { ContainerComponent } from './container/container.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeroComponent } from './hero/hero.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { FilterButtonsComponent } from './filter-buttons/filter-buttons.component';
import { FilterCardsComponent } from './filter-cards/filter-cards.component';
import { FilterInputComponent } from './filter-input/filter-input.component';
import { ComparisonTableComponent } from './comparison-table/comparison-table.component';
import { CareerDetailComponent } from './career-detail/career-detail.component';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { NavbarTestComponent } from './navbar-test/navbar-test.component';
import { ComparadortestingComponent } from './comparadortesting/comparadortesting.component';
import { FilterComponentsComponent } from './filter-components/filter-components.component';
import { AnotherTableComponent } from './another-table/another-table.component';
import { TableHeaderItemComponent } from './table-header-item/table-header-item.component';
import { RelatedComponent } from './related/related.component';
import { CareerDetailBetaComponent } from './career-detail-beta/career-detail-beta.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DataUsePolicyComponent } from './data-use-policy/data-use-policy.component';
import { CreditosComponent } from './creditos/creditos.component';
import { PlansComponent } from './plans/plans.component';
// import { TeacherProfileComponent } from './teacher-profile/teacher-profile.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';

// Firebase
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
// import { getFirestore } from 'firebase/firestore';
// import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, DEBUG_MODE } from '@angular/fire/analytics';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
// import { APP_NAME, APP_VERSION, CONFIG, DEBUG_MODE } from '@angular/fire/compat/analytics';
// import { getAnalytics } from 'firebase/analytics';

import { environment } from '../environments/environment';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFilm, faFish } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { NavbarBsComponent } from './navbar-bs/navbar-bs.component';
import { NavbarMbComponent } from './navbar-mb/navbar-mb.component';
import { LogoComponent } from './logo/logo.component';

import { DatePipe } from '@angular/common';

import { EditProfessorProfileComponent } from './professor/edit-professor-profile/edit-professor-profile.component';
import { EditProfessorExperienceComponent } from './professor/edit-professor-experience/edit-professor-experience.component';
import { EditProfessorEducationComponent } from './professor/edit-professor-education/edit-professor-education.component';
import { ProfessorExperienceComponent } from './professor/professor-experience/professor-experience.component';
import { ProgramsComponent } from './programs/programs.component';
import { ProfessorEducationComponent } from './professor/professor-education/professor-education.component';
import { CareersComparisonComponent } from './pages/careers-comparison/careers-comparison.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { PhotoUploadDialogComponent } from './photo-upload-dialog/photo-upload-dialog.component';
import { StudentFeaturesComponent } from './student-features/student-features.component';
import { UniversityTeacherSearchComponent } from './university-teacher-search/university-teacher-search.component';
import { CountUpModule } from 'ngx-countup';

// Algolia
import { NgAisModule } from 'angular-instantsearch';
import { SearchAlgoliaMobileButtonComponent } from './search-algolia-mobile-button/search-algolia-mobile-button.component';
import { SearchAlgoliaCheckBoxComponent } from './search-algolia-check-box/search-algolia-check-box.component';
import { ProfessorProfileComponent } from './professor/professor-profile/professor-profile.component';
import { EditProfessorAreasOfInterstComponent } from './professor/edit-professor-areas-of-interst/edit-professor-areas-of-interst.component';

import { provideAnimations } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { EditUserProfileComponent } from './user/edit-user-profile/edit-user-profile.component';
import { FavoriteListComponent } from './components/favorite-list/favorite-list.component';
import { DialogSaveComparisonComponent } from './dialog-save-comparison/dialog-save-comparison.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { DialogSaveFavoritesComponent } from './components/dialog-save-favorites/dialog-save-favorites.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { DownloadComparatorComponent } from './download-comparator/download-comparator.component';
import { CustomRecoverPassComponent } from './components/custom-recover-pass/custom-recover-pass.component';
import { LoginModalComponent } from './components/login/login-modal/login-modal/login-modal.component';
import { RegisterModalComponent } from './components/register/register-modal/register-modal/register-modal.component';
import { ContactMeComponent } from './components/contact-me/contact-me.component';
import { HomeSearchBoxComponent } from './components/home-search-box/home-search-box.component';
import { PartnersComponent } from './components/partners/partners.component';
import { WhatsappFloatButtonComponent } from './components/whatsapp-float-button/whatsapp-float-button.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { EsalComponent } from './pages/esal/esal.component';
import { LoginStudentComponent } from './components/login/login-student/login-student.component';
import { VocationalTestComponent } from './vocational-test/vocational-test.component';
import { RedirectButtonVocationalComponent } from './vocational-test/redirect-button-vocational/redirect-button-vocational.component';
import { DialogContinueResultsComponent } from './vocational-test/dialog-continue-results/dialog-continue-results.component';
import { DialogSessionComponent } from './vocational-test/dialog-session/dialog-session.component';
import { DialogResultsTestComponent } from './vocational-test/dialog-results-test/dialog-results-test.component';
import { DonationsComponent } from './donations/donations.component';
import { CartComponent } from './donations/components/cart/cart.component';
import { ActionButtonComponent } from './donations/components/action-button/action-button.component';
import { AmountMethodComponent } from './donations/components/amount-method/amount-method.component';
import { MercadoPagoComponent } from './donations/components/cart/integrations/mercado-pago/mercado-pago.component';
import { OtherMethodsComponent } from './donations/components/cart/integrations/other-methods/other-methods.component';
import { PSEComponent } from './donations/components/cart/integrations/pse/pse.component';
import { CreditCardComponent } from './donations/components/cart/integrations/credit-card/credit-card.component';
import { PaymentResultsComponent } from './donations/components/payment-results/payment-results.component';
import { ExpandableCellComponent } from './components/expandable-cell/expandable-cell.component';
import { LoginAlertComponent } from './components/login-alert/login-alert.component';
import { DownloadDialogComponent } from './components/download-dialog/download-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    SearchComponent,
    ContactComponent,
    CareerComponent,
    CareerCardComponent,
    FooterComponent,
    HorizontalMenuComponent,
    InstituteComponent,
    HeaderComponent,
    VerticalMenuComponent,
    //ContainerComponent,
    NavbarComponent,
    HeroComponent,
    SearchFilterComponent,
    FilterButtonsComponent,
    FilterCardsComponent,
    FilterInputComponent,
    ComparisonTableComponent,
    CareerDetailComponent,
    FilterModalComponent,
    NavbarTestComponent,
    ComparadortestingComponent,
    SearchAlgoliaMobileButtonComponent,
    SearchAlgoliaCheckBoxComponent,
    FilterComponentsComponent,
    AnotherTableComponent,
    TableHeaderItemComponent,
    RelatedComponent,
    CareerDetailBetaComponent,
    ContactUsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    DataUsePolicyComponent,
    CreditosComponent,
    PlansComponent,
    // TeacherProfileComponent,
    LoginComponent,
    RegisterComponent,
    NavbarBsComponent,
    NavbarMbComponent,
    LogoComponent,
    EditProfessorProfileComponent,
    EditProfessorExperienceComponent,
    EditProfessorEducationComponent,
    ProfessorExperienceComponent,
    ProgramsComponent,
    ProfessorEducationComponent,
    PhotoUploadDialogComponent,
    StudentFeaturesComponent,
    UniversityTeacherSearchComponent,
    ProfessorProfileComponent,
    EditProfessorAreasOfInterstComponent,
    UserProfileComponent,
    EditUserProfileComponent,
    FavoriteListComponent,
    CareersComparisonComponent,
    DialogSaveComparisonComponent,
    RecoverPasswordComponent,
    FavoritesComponent,
    DialogSaveFavoritesComponent,
    NewsletterComponent,
    TestimonialsComponent,
    DownloadComparatorComponent,
    CustomRecoverPassComponent,
    LoginModalComponent,
    RegisterModalComponent,
    ContactMeComponent,
    HomeSearchBoxComponent,
    PartnersComponent,
    WhatsappFloatButtonComponent,
    AutocompleteComponent,
    EsalComponent,
    LoginStudentComponent,
    VocationalTestComponent,
    RedirectButtonVocationalComponent,
    DialogContinueResultsComponent,
    DialogSessionComponent,
    DialogResultsTestComponent,
    DonationsComponent,
    CartComponent,
    ActionButtonComponent,
    AmountMethodComponent,
    CreditCardComponent,
    MercadoPagoComponent,
    OtherMethodsComponent,
    PSEComponent,
    PaymentResultsComponent,
    ExpandableCellComponent,
    LoginAlertComponent,
    DownloadDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgAisModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    ImageCropperModule,
    CountUpModule,
    DragDropModule,
  ],
  providers: [
    DatePipe,
    ScreenTrackingService,
    UserTrackingService,
    MatNativeDateModule,
    provideAnimations(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFilm, faFish, faWhatsapp);
    library.addIconPacks(fas, far, fab);
  }
}
