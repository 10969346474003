<div class="amount-container">
  <h6>Indica el donativo</h6>
  <div class="cards-container">
    <ng-container *ngFor="let item of cardsValues" class="d-flex w-100">
      <mat-card (click)="changeValueDonation(item.value)" class="price-cards">
        ${{ formatValue(item.value) }}
      </mat-card>
    </ng-container>
  </div>
  <form [formGroup]="form" class="w-100 mt-4">
    <mat-form-field class="col-sm-12 col-md-6" appearance="outline">
      <mat-label>Ingresa la cantidad </mat-label>
      <span matPrefix>$&nbsp;</span>
      <input matInput formControlName="donativeControl" type="number" />
      <span matSuffix>&nbsp;COP</span>
    </mat-form-field>
  </form>

  <mat-divider></mat-divider>

  <section class="mt-4">
    <h6>Método de pago</h6>
    <p class="opacity-75 text-justify">
      Por favor, seleccione el método de pago que se ajuste para continuar.
    </p>

    <div class="row g-3 row-cols-1 row-cols-md-2">
      <div class="col">
        <mat-card (click)="goToCart('creditCard')" class="card-payment">
          <div class="images-card-container align-items-center">
            <img style="max-width: 60px;" mat-card-sm-image src="../../assets/svg/logos/payments/visa.svg"
              alt="logo visa" />
            <img mat-card-sm-image src="../../assets/svg/logos/payments/masterCard.svg" alt="logo master card" />
            <img mat-card-sm-image src="../../assets/svg/logos/payments/AMEX.svg" alt="logo american express" />
          </div>
          Pagos con tarjeta
        </mat-card>
      </div>
      <!-- 
      <div class="col">
        <mat-card (click)="goToCart('mercadoPago')" class="card-payment">
          <div class="image-container">
            <img
              mat-card-sm-image
              src="../../assets/svg/logos/payments/mercadoPago.png"
              alt="logo mercado pago"
            />
          </div>
          Pagos con mercado pago
        </mat-card>
      </div> -->

      <div class="col">
        <mat-card (click)="goToCart('PSE')" class="card-payment">
          <div class="image-container">
            <img mat-card-sm-image src="../../assets/svg/logos/payments/pse.png" alt="logo PSE" />
          </div>

          Pagos con PSE
        </mat-card>
      </div>

      <!-- <div class="col">
        <mat-card (click)="goToCart('others')" class="card-payment">
          <div class="image-container">
            <img
              mat-card-sm-image
              src="../../assets/svg/logos/payments/efecty.png"
              alt="logo otros medios"
            />
          </div>

          Pagos con otros medios
        </mat-card>
      </div> -->
    </div>
  </section>
</div>