import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserProfileService } from '../services/user-profile.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  constructor( private formBuilder: FormBuilder, private userProfileService: UserProfileService) { }

  email = new FormControl('', [Validators.email]);
  form = this.formBuilder.group({
    email: this.email,
  
  });
  ngOnInit(): void {
  }

  async sendForm():Promise<void>{
    try {
      const _email = (this.email.value ?? '') as string;
      await this.userProfileService.subscribeToNewsletter(_email)
      console.log("Formulario enviado correctamente")
      alert("Te has suscrito exitosamente")
      this.clearForm()
    } catch (error) {
      console.error("Error in sendForm newsletter component", error)
      alert("Hubo un error al momento de suscribirse")
    }
  }
  
  clearForm() {
      this.email = new FormControl('', [Validators.email]);
  }
}
