import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ConfirmPasswordValidator } from 'src/app/util/confirmPasswordValidator';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {

  public professorsUrl = environment.publicUrls.professorsUrl;
  public institutesUrl = environment.publicUrls.institutesUrl;
  selectedOption: string;

  registerForm: FormGroup;
  displayName = new FormControl('', [Validators.required]);
  // displayName = new FormControl('', [Validators.pattern(/\s/), Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(30),
  Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$')]);
  confirmPassword = new FormControl('', [Validators.required]);
  accept = new FormControl(false);

  constructor(
    public dialogRef: MatDialogRef<RegisterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {

    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-google.svg'));
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-linkedin.svg'));
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-facebook.svg'));
    this.matIconRegistry.addSvgIcon(
      'apple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/logo-apple.svg'));
    this.matIconRegistry.addSvgIcon(
      'contactus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/contactus.svg'));


    this.selectedOption = data.selectedOption

    this.registerForm = new FormGroup({
      displayName: this.displayName,
      email: this.email,
      password: this.password,
      confirmPassword: this.confirmPassword,
      accept: this.accept
    }, { validators: ConfirmPasswordValidator });
  }

  passwordMismatch(): boolean {
    return this.confirmPassword.hasError('passwordMismatch') && this.confirmPassword.touched;
  }

  getEmailErrorMessage() {
    return this.email.hasError('email') ? '(No es un correo valido)' : '';
  }

  hide = true;
  hide2 = true;

  ngOnInit(): void {
  }
  async onSubmit() {
    try {

      console.log(this.registerForm.value);
      const email: string = this.registerForm.value.email;
      const password: string = this.registerForm.value.password;
      const displayName: string = this.registerForm.value.displayName;
      const response = await this.userService.registerWithEmail(email, password, displayName);

      console.log(response);
      const loginRes = await this.userService.loginWithEmail(email, password);
      alert("Usuario creado satistactoriamente")
      console.log(loginRes);
      // this.router.navigate(['/inicio']);
      this.onNoClick()
      this.router.navigate(['/inicio-estudiantes']);

    } catch (error) {
      const errorMessage = this.userService.getAuthMessageError(error);
      alert('Error registrandose: ' + errorMessage);
      console.log(error);
    }
  }

  onLoginWithGoogle() {
    this.userService.loginWithGoogle(true)
      .then(response => {
        console.log(response);
        // this.router.navigate(['/inicio']);
        this.onNoClick()
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onLoginWithLinkedin() {
    this.userService.loginWithLinkedin()
      .then(response => {
        console.log(response);
        // this.router.navigate(['/inicio']);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onLoginWithFacebook() {
    this.userService.loginWithFacebook()
      .then(response => {
        console.log(response);
        // this.router.navigate(['/inicio']);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onLoginWithApple() {
    this.userService.loginWithApple()
      .then(response => {
        console.log(response);
        this.dialogRef.close();
        // this.router.navigate(['/inicio']);
        this.router.navigate(['/perfil']);
      })
      .catch(error => console.log(error))
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
