import { Component } from '@angular/core';

@Component({
  selector: 'app-other-methods',
  templateUrl: './other-methods.component.html',
  styleUrls: ['./other-methods.component.scss']
})
export class OtherMethodsComponent {

}
