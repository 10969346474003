<div class="container mt-1 mt-lg-3">
  <div class="row row-cols-1 row-cols-lg-1 justify-content-center  align-items-md-center g-5  py-2">
    <div class="d-grid gap-4 text-start">
      <h3 class="fw-bold display-4">
        <span class="primaryColor">Conoce </span>lo que ofrecemos
      </h3>
      <p style="font-size: 20px;">
        Nuestra plataforma es parte de la Fundación Guía Universitaria, una entidad sin animo de lucro, donde estamos
        comprometidos con el futuro educativo de los estudiantes colombianos. Nuestra misión es brindarles las
        herramientas necesarias para tomar decisiones informadas y alcanzar sus metas académicas con éxito.
      </p>
      <div class="justify-content-center ">
        <img class="img-fluid w-50" src="../../assets/svg/Data points-amico.svg" alt="">
      </div>
      <p style="font-size: 20px;">
        Nos enorgullece llevar a cabo una serie de iniciativas de impacto social que están transformando la educación en
        Colombia. Una de nuestras actividades principales es la visita a colegios en todo el país, donde impartimos
        talleres prácticos sobre el uso de nuestra plataforma. A través de esta herramienta, los estudiantes pueden
        explorar una amplia gama de opciones educativas y tomar decisiones fundamentadas sobre su futuro académico.
      </p>
      <p style="font-size: 20px;">
        Además, ofrecemos charlas de orientación vocacional en los colegios, donde les proporcionamos a los estudiantes
        información valiosa sobre diversas carreras y campos profesionales. Creemos que es crucial orientar a los
        jóvenes en este proceso de toma de decisiones, y estamos comprometidos en brindarles el apoyo necesario para que
        elijan el camino que mejor se adapte a sus intereses y habilidades.
      </p>
      <p style="font-size: 20px;">
        La asesoría sobre créditos educativos es otro aspecto fundamental de nuestro trabajo. Entendemos que el acceso a
        la financiación es una preocupación importante para muchos estudiantes y sus familias, por lo que ofrecemos
        orientación experta sobre las diferentes opciones de financiamiento disponibles.
      </p>
      <p style="font-size: 20px;">
        Además, colaboramos estrechamente con universidades y otras instituciones para facilitar el acceso a apoyo
        financiero y opciones de becas. Nuestro objetivo es garantizar que ningún estudiante talentoso se vea limitado
        por barreras económicas en su búsqueda de una educación superior.
      </p>
      <p style="font-size: 20px;">
        En Guía Universitaria, creemos en el poder transformador de la educación y estamos comprometidos en hacerla
        accesible para todos. Únete a nosotros en nuestro esfuerzo por construir un futuro más brillante para la
        juventud colombiana.
      </p>
    </div>
    <div class="row g-5 row-cols-1 row-cols-lg-2">
      <div class="col">
        <div class="rounded-4 p-4 shadow-sm">
          <div class="d-inline d-flex gap-2  align-items-start">
            <button class="shadow-sm mb-4" mat-icon-button routerLink="/buscador">
              <mat-icon>school</mat-icon>
            </button>
            <h3 class="ps-2 pt-1 fs-2">Estudiantes</h3>
          </div>
          <div class="d-grid gap-2  align-items-start ">
            <p style="font-size: 20px;">Ingreso gratuito a la oferta académica de todas las universidades suscriptas a
              fin de optimizar
              la búsqueda especifica de interés. Test vocacionales en los colegios.</p>
          </div>
          <button class="align-self-end mt-4" color="primary" mat-raised-button routerLink="/buscador">Ir al
            buscador</button>
        </div>
      </div>
      <div class="col">
        <div class="bg-light  shadow-sm rounded-4 p-4   ">
          <div class="d-inline d-flex gap-2  align-items-start">
            <button class="shadow-sm mb-4" mat-icon-button routerLink="/register">
              <mat-icon>work</mat-icon>
            </button>
            <h3 class="ps-2 pt-1 fs-2">Profesores</h3>
          </div>
          <div class="d-grid gap-2 align-items-start">
            <p style="font-size: 20px;">Información de visualización a su perfil y data de otras ofertas disponibles
              acordes
              con su área de especialización, así como intereses de los posibles empleadores. </p>
          </div>
          <a [href]="professorsUrl" target="_blank">
            <button class="align-self-end mt-4" color="primary" mat-raised-button>Crear perfil</button>
          </a>
        </div>
      </div>
      <div class="col">
        <div class="bg-light  shadow-sm rounded-4 p-4  ">
          <div class="d-inline d-flex gap-2  align-items-start">
            <button class="shadow-sm mb-4" mat-icon-button routerLink="/contactanos">
              <mat-icon>history_edu</mat-icon>
            </button>
            <h3 class="ps-2 pt-1 fs-2">Universidades</h3>
          </div>
          <div class="d-grid gap-2 align-items-start">
            <p style="font-size: 20px;"> Analítica de datos con recomendaciones estratégicas para
              propuestas de captación y conversión. Acceso a profesores/capacitadores. </p>
          </div>
          <a [href]="institutesUrl" target="_blank">
            <button class="align-self-end mt-4" color="primary" mat-raised-button>Registrarse</button>
          </a>
        </div>
      </div>
      <div class="col">
        <div class="bg-light  shadow-sm rounded-4 p-4 ">
          <div class="d-inline d-flex gap-2  align-items-start">
            <button class="shadow-sm mb-4" mat-icon-button routerLink="/contactanos">
              <mat-icon>work</mat-icon>
            </button>
            <h3 class="ps-2 pt-1 fs-2">Empresas</h3>
          </div>
          <div class="d-grid gap-2 align-items-start">
            <p style="font-size: 20px;">Acceso a amplia red de académicos y potenciales capacitadores para el desarrollo
              interno de actividades de formación y desarrollo.
            </p>
          </div>
          <button class="align-self-end mt-4" color="primary" mat-raised-button
            routerLink="/contactanos">Contáctanos</button>
        </div>
      </div>
    </div>
  </div>
</div>