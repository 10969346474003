<div class="container p-4 position-relative" #focus>
    <mat-icon
      class="position-absolute top-0 end-0"
      style="cursor: pointer"
      (click)="onNoClick()"
    >
      close
    </mat-icon>
  
    <div *ngIf="isArray; else singleData">
      <div *ngFor="let item of dataArray" >
        <div *ngFor="let result of item.results">
          <p>Fecha del test: {{ formatDate(item.createdAt) }}</p>
          <h2 class="primaryColor">Área de Interés: {{ result.area }}</h2>
          <p>{{ result.description }}</p>
          <h3 class="primaryColor">Posibles sectores y cargos en los que podrías desempeñarte:</h3>
          <ul>
            <li *ngFor="let role of result.potentialJobRoles">
              {{ role.sector }}: {{ role.role }}
            </li>
          </ul>
          <h3 class="primaryColor">Carreras recomendadas:</h3>
          <ul>
            <li *ngFor="let career of result.recommendedCareers">{{ career }}</li>
          </ul>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  
    <ng-template #singleData>
      <div *ngFor="let result of dataArray[0].results">
        <h2 class="primaryColor">Área de Interés: {{ result.area }}</h2>
        <p>{{ result.description }}</p>
        <h3 class="primaryColor">Posibles sectores y cargos en los que podrías desempeñarte:</h3>
        <ul>
          <li *ngFor="let role of result.potentialJobRoles">
            {{ role.sector }}: {{ role.role }}
          </li>
        </ul>
        <h3 class="primaryColor">Carreras recomendadas:</h3>
        <ul>
          <li *ngFor="let career of result.recommendedCareers">{{ career }}</li>
        </ul>
      </div>
    </ng-template>
  </div>
  