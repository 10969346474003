<!-- 
<div class="d-flex flex-column mb-3 gap-4">
  <h5>Ciudades</h5>
  <ais-refinement-list attribute="sedes.ciudad" operator="or" [searchable]="true" searchPlaceholder="Buscar ciudad ..."
    [limit]="10" [showMoreLimit]="5" showMoreLabel="Ver más" showLessLabel="Ver menos"
    [autoHideContainer]="false"></ais-refinement-list>
  < !-- [transformItems]="transformItems" -- >

  <mat-divider></mat-divider>
  <h5>Nivel de Estudio</h5>
  <ais-refinement-list attribute="nivelEstudio" operator="or" [searchable]="false"></ais-refinement-list>
  < !-- searchPlaceholder="Buscar nivel de estudio ..." -- >
  < !-- <ais-configure [searchParameters]="{ hitsPerPage: 8 }"></ais-configure> -- >

  <mat-divider></mat-divider>
  <h5>Precio</h5>
  <ais-numeric-menu attribute="costoNumber" [items]="[
      { label: 'Mostrar todo' },
      { end: 10000000, label: '< 10MM' },
      { start: 10000000, end: 20000000, label: '10 MM - 20MM' },
      { start: 20000000, end: 40000000, label: '20MM - 40MM' },
      { start: 40000000, label: '> 40MM' }
    ]">
  </ais-numeric-menu>
</div>
-->

<!-- IZQUIERDA-->
<!-- boton de filtro visible en mobile -->
<button *ngIf="false" mat-button (click)="printAccordionStatus()">Validar accordion</button>
<div class="pb-3 d-md-none">
  <!-- <mat-accordion>
    <mat-expansion-panel  expanded="false">
      <mat-expansion-panel-header #expPanelHeader class="pb-3 d-md-none">
        <mat-icon>manage_search</mat-icon>
        <mat-panel-title>Filtros de busqueda</mat-panel-title> -->
  <!-- <mat-panel-description> Detalles de busqueda</mat-panel-description> -->
  <!-- </mat-expansion-panel-header> -->
  <!-- <div> -->

  <mat-accordion #expansionPanel>
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header #expPartnerPanelHeader>
        <mat-panel-title>
          <strong style="font-size: 17px;">Filtros</strong>
        </mat-panel-title>
        <!-- <span #expIndicator1 class="mat-expansion-indicator m-2" style="transform: rotate(0deg);" (click)="toggleIndicator(expIndicator1)"></span> -->
      </mat-expansion-panel-header>
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header #expPanelHeader>
          <mat-panel-title>
            <strong>Ciudades</strong>
          </mat-panel-title>
          <!-- <span #expIndicator1 class="mat-expansion-indicator m-2" style="transform: rotate(0deg);" (click)="toggleIndicator(expIndicator1)"></span> -->
        </mat-expansion-panel-header>
        <ais-refinement-list #Cities attribute="headquarter.city" operator="or" [limit]="5" [showMoreLimit]="10"
          showMoreLabel="Ver más" showLessLabel="Ver menos" [searchable]="true" searchPlaceholder="Buscar ciudad ..."
          [autoHideContainer]="false" (onValueSelected)="closePanel()"></ais-refinement-list>
        <!-- [transformItems]="transformItems" -->
        <mat-action-row>
          <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- <mat-divider></mat-divider> -->
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
        <mat-expansion-panel-header #expPanelHeader>
          <mat-panel-title>
            <strong>Nivel de estudio</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ais-refinement-list #LevelStudy attribute="levelStudy" operator="or"
          [searchable]="false"></ais-refinement-list>
        <!-- searchPlaceholder="Buscar nivel de estudio ..." -->
        <!-- <ais-configure [searchParameters]="{ hitsPerPage: 8 }"></ais-configure> -->
        <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Anterior</button>
          <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
        </mat-action-row>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
        <mat-expansion-panel-header #expPanelHeader>
          <mat-panel-title>
            <strong>Tipo de instituto</strong>
          </mat-panel-title>

        </mat-expansion-panel-header>

        <ais-refinement-list attribute="iesCharacter" operator="or" [searchable]="false"></ais-refinement-list>
        <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Anterior</button>
          <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
        </mat-action-row>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
        <mat-expansion-panel-header #expPanelHeader>
          <mat-panel-title>
            <strong>Modalidad</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ais-refinement-list attribute="modalities" operator="or" [searchable]="false"></ais-refinement-list>
        <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Anterior</button>
          <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
        </mat-action-row>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
        <mat-expansion-panel-header #expPanelHeader>
          <mat-panel-title>
            <strong>Precio</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- <ais-sort-by
          [items]="[
            { value: 'instant_search', label: 'Predeterminado' },
            { value: 'careers_price_asc', label: 'Ascendente.' },
            { value: 'careers_price_desc', label: 'Descendente.' }
          ]"
        ></ais-sort-by> -->
        <ais-numeric-menu #PriceMenu style="display: none;" attribute="price" [items]="[
              { label: 'Mostrar todo' },
              { end: 10000000, label: '< 10MM' },
              { start: 10000000, end: 20000000, label: '10 MM - 20MM' },
              { start: 20000000, end: 40000000, label: '20MM - 40MM' },
              { start: 40000000, label: '> 40MM' }
            ]">
        </ais-numeric-menu>

        <!-- <label>{{ JSON.stringify(PriceMenu.state.items)}}</label> -->

        <mat-radio-group #PriceMenuMobile [value]="priceSelected" class="example-radio-group">
          <mat-radio-button *ngFor="let item of PriceMenu.state.items" [value]="item.value"
            (change)="changePrice(item.value, true)" class="example-radio-button">
            {{ item.label }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Anterior</button>
          <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
        </mat-action-row>
      </mat-expansion-panel>

    </mat-expansion-panel>
  </mat-accordion>

</div>
<!-- <div class="d-flex pb-3 d-md-none justify-content-end">
        <button mat-mini-fab (click)="accordion.closeAll()" color="none"> -->
<!-- Colapsar -->
<!-- <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion> -->
<!-- </div> -->

<!-- menu de filtro visible en desktop -->
<mat-card class="d-none d-md-block p-0">
  <mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" (opened)="panelOpenState = true"
      (closed)="panelOpenState = false">
      <mat-expansion-panel-header #expPanelHeader>
        <mat-panel-title class="title-panel">
          <h5>Ciudades</h5>
        </mat-panel-title>
        <!-- <span #expIndicator1 class="mat-expansion-indicator m-2" style="transform: rotate(0deg);" (click)="toggleIndicator(expIndicator1)"></span> -->
      </mat-expansion-panel-header>
      <ais-refinement-list attribute="headquarter.city" operator="or" [limit]="8" [showMoreLimit]="10"
        showMoreLabel="Ver más" showLessLabel="Ver menos" [searchable]="true" searchPlaceholder="Buscar ciudad ..."
        [autoHideContainer]="false" (onValueSelected)="closePanel()"></ais-refinement-list>
      <!-- [transformItems]="transformItems" -->
      <mat-action-row>
        <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
      </mat-action-row>
    </mat-expansion-panel>

    <!-- <mat-divider></mat-divider> -->
    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
      <mat-expansion-panel-header #expPanelHeader>
        <mat-panel-title class="title-panel">
          <h5>Nivel de estudio </h5>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ais-refinement-list attribute="levelStudy" operator="or" [searchable]="false"></ais-refinement-list>
      <!-- searchPlaceholder="Buscar nivel de estudio ..." -->
      <!-- <ais-configure [searchParameters]="{ hitsPerPage: 8 }"></ais-configure> -->
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Anterior</button>
        <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
      <mat-expansion-panel-header #expPanelHeader>
        <mat-panel-title class="title-panel">
          <h5>Tipo de instituto </h5>
        </mat-panel-title>

      </mat-expansion-panel-header>

      <ais-refinement-list attribute="iesCharacter" operator="or" [searchable]="false"></ais-refinement-list>
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Anterior</button>
        <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
      <mat-expansion-panel-header #expPanelHeader>
        <mat-panel-title class="title-panel">
          <h5>Modalidad </h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ais-refinement-list attribute="modalities" operator="or" [searchable]="false"></ais-refinement-list>
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Anterior</button>
        <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
      <mat-expansion-panel-header #expPanelHeader>
        <mat-panel-title class="title-panel">
          <h5>Precio</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- <ais-sort-by
        [items]="[
          { value: 'instant_search', label: 'Predeterminado' },
          { value: 'careers_price_asc', label: 'Ascendente.' },
          { value: 'careers_price_desc', label: 'Descendente.' }
        ]"
      ></ais-sort-by> -->
      <ais-numeric-menu #PriceMenu style="display: none;" attribute="price" [items]="[
            { label: 'Mostrar todo' },
            { end: 10000000, label: '< 10MM' },
            { start: 10000000, end: 20000000, label: '10 MM - 20MM' },
            { start: 20000000, end: 40000000, label: '20MM - 40MM' },
            { start: 40000000, label: '> 40MM' }
          ]">
      </ais-numeric-menu>

      <!-- <label>{{ JSON.stringify(PriceMenu.state.items)}}</label> -->

      <mat-radio-group #PriceMenuMobile [value]="priceSelected" class="example-radio-group">
        <mat-radio-button *ngFor="let item of PriceMenu.state.items" [value]="item.value"
          (change)="changePrice(item.value, true)" class="example-radio-button">
          {{ item.label }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Anterior</button>
        <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
      </mat-action-row>
    </mat-expansion-panel>

  </mat-accordion>
</mat-card>
<!-- END IZQUIERDA  -->