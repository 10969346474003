<!-- Show a Spinner while loading -->
<div *ngIf="!userProfile" class="row justify-content-center">
    <mat-spinner></mat-spinner>
</div>
<div class="container" *ngIf="userProfile">
    <!-- Datos de Perfil - ProfileData -->
    <div class="row justify-content-center">
        <div class="position-relative">
            <div class="d-grid justify-content-center gap-2 py-4">
                <div class="d-grid w-100 justify-content-center">
                    <div
                        class="item rounded-circle ms-md-1 ms-lg-2 p-1 h-100 w-100 d-flex justify-content-center align-items-center">
                        <img class="img-fluid fit-cover rounded-circle icon_photo" slot="start"
                            referrerpolicy="no-referrer" [src]="userProfile.photoUrl" alt="Profile Photo"
                            (click)="goToEdit()" style="cursor: pointer; width: 200px; height: 200px;">
                    </div>
                    <div class="d-grid justify-content-center align-items-center">
                        <div class="d-flex w-100 justify-content-center">
                            <h3 class="text-center">{{ userProfile.fullName }} </h3>
                        </div>
                        <button mat-raised-button color="primary" (click)="goToEdit()">
                            Editar perfil
                        </button>
                        <!-- <button class="mt-2"
                            mat-raised-button color="primary" (click)="getTestResults()">
                            Resultados test
                        </button> -->
                        <div class="edit-button">
                            <button mat-raised-button color="primary" (click)="goToComparisons()">
                                Comparaciones
                            </button>
                            <button mat-raised-button color="primary" (click)="goToFavorites()">
                                Favoritos
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- Datos Personales - PersonalData -->
        <div
            class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 gap-3 g-3 m-1 justify-content-center py-3 ">
            <div class="col d-flex align-items-center p-3 shadow-sm rounded-3" (click)="goToEdit()"
                style="cursor: pointer;">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3  mb-auto" width="1.75em"
                    height="1.75em">people</mat-icon>
                <div class="mb-auto ">
                    <p class="bi opacity-75 mb-0">Sexo:</p>
                    <p>{{ userProfile.personalData?.gender }}</p>
                </div>
            </div>
            <div class="col d-flex align-items-center p-3 shadow-sm rounded-3" (click)="goToEdit()"
                style="cursor: pointer;">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3  mb-auto" width="1.75em"
                    height="1.75em">phone</mat-icon>
                <div class="mb-auto ">
                    <p class="opacity-75 mb-0">Teléfono:</p>
                    <p><span *ngIf="userProfile.personalData?.phonePrefix">({{ userProfile.personalData?.phonePrefix }})
                        </span>{{ userProfile.personalData?.phone }}</p>
                </div>
            </div>
            <div class="col d-flex align-items-center p-3 shadow-sm rounded-3" (click)="goToEdit()"
                style="cursor: pointer;">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3  mb-auto" width="1.75em"
                    height="1.75em">email</mat-icon>
                <div class="mb-auto ">
                    <p class="opacity-75 mb-0">E-mail:</p>
                    <p class="block text-break">{{ userProfile.email }}</p>
                    <div style="font-style: italic;font-size: smaller;" *ngIf="userProfile.emailVerified">Email
                        verificado
                    </div>
                    <div style="font-style: italic;font-size: smaller;" *ngIf="!userProfile.emailVerified">Email
                        por verificar
                    </div>
                </div>
            </div>
            <div class="col d-flex align-items-center p-3 shadow-sm rounded-3" (click)="goToEdit()"
                style="cursor: pointer;">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3  mb-auto" width="1.75em"
                    height="1.75em">map</mat-icon>
                <div class="mb-auto ">
                    <p class="opacity-75 mb-0">Nacionalidad:</p>
                    <p>{{ userProfile.personalData?.nationality }}</p>
                </div>
            </div>
            <div class="col d-flex align-items-center p-3 shadow-sm rounded-3" (click)="goToEdit()"
                style="cursor: pointer;">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3 mb-auto" width="1.75em"
                    height="1.75em">calendar_month</mat-icon>
                <div class="mb-auto ">
                    <p class="opacity-75 mb-0">Fecha de Nacimiento:</p>
                    <p class="m-0 p-0">{{ userProfile.personalData?.birthDate?.toLocaleDateString() }}</p>
                    <p class="m-0 p-0" style="font-style: italic;font-size: smaller;"
                        *ngIf="!userProfile.emailVerified">
                        Edad: {{ getAge(userProfile.personalData?.birthDate) }}
                    </p>
                </div>
            </div>
            <div class="col d-flex align-items-center p-3 shadow-sm rounded-3" (click)="goToEdit()"
                style="cursor: pointer;">
                <mat-icon class="bi opacity-75 flex-shrink-0 me-3  mb-auto" width="1.75em"
                    height="1.75em">school</mat-icon>
                <div class="mb-auto ">
                    <p class="opacity-75 mb-0">Nivel Académico:</p>
                    <p>{{ userProfile.personalData?.educationLevel }}</p>
                </div>
            </div>
        </div>

        <div class="row my-5 text-start g-5">
            <div class="col-lg-6 align-self-center">
                <div class="d-block gap-4 align-items-between text-center text-lg-start">

                    <h3 class="featurette-heading display-4 fw-bold">¿Quieres ayudar a otros a tomar una decisión
                        asertiva con tu <span class="primaryColor">testimonio</span>?
                    </h3>
                    <h5 class="lead">
                        Ayúdanos a mejorar nuestra plataforma con tu testimonio, de esta manera podremos entregarte un
                        mejor servicio y ayudar a la comunidad de Guía Universitaria a tomar una mejor decisión.
                    </h5>
                    <form>
                        <mat-form-field class=" w-100" appearance="outline">
                            <mat-label>Testimonio</mat-label>
                            <textarea matInput [formControl]="testimonial"
                                placeholder="Ejemplo: Mi experiencia con Guía Universitaria fue... El comparador de carreras nos sirvió muchísimo para encontrar la carrera ideal."></textarea>
                            <mat-error *ngIf="testimonial.invalid">El testimonio es requerido</mat-error>
                        </mat-form-field>
                        <div class="d-grid gap-2">
                            <button mat-raised-button color="primary" (click)="sendTestimonial()"
                                [disabled]="form.invalid">Enviar testimonio
                                <mat-icon class="ms-2">send</mat-icon>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <img class="img-fluid" src="../../assets/svg/testimonial.svg" alt="">
            </div>
        </div>
    </div>
</div>