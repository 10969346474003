import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  constructor(private userService: UserService,  private formBuilder: FormBuilder,) { }
  
  email = new FormControl('', [Validators.email]);
  form = this.formBuilder.group({
     email: this.email,
  });

  isSaving = false;

  ngOnInit(): void {
  }
  
  async handleRecoverPass():Promise<void>{
    try {
      this.isSaving = true;

      const _email = (this.email.value ?? '') as string;
      const response = await this.userService.recoverPassWord(_email)
      if(response){
        const message = `Por favor, revise su correo para recuperar su contraseña`;
        alert(message);
      }else{
        const message = `Hubo un error en el envío del correo para recuperar la contraseña, por favor intente nuevamente`;
        alert(message);
      }
      this.clearForm()
    } catch (error) {
      console.error("Error in handleRecoverPass", error)
    } finally {
      this.isSaving = false;
    }
   
  }

  clearForm(){
    this.email = new FormControl('', [Validators.email]);
  }
}
