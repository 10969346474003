<!-- 

    <mat-form-field appearance="fill">
        <mat-label>Teléfono</mat-label>
        <input matInput formControlName="telefono" />
    </mat-form-field> -->

<div class="dialog-container">
    <!-- Botón de cierre -->
    <div class="d-flex justify-content-end">
        <button class="shadow" mat-dialog-close mat-icon-button aria-label="close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <!-- Títulos -->
    <div class="d-flex justify-content-center align-items-center mb-3">
        <h2>Descarga la comparación</h2>
    </div>
    <div class="d-flex justify-content-center align-items-center mb-3">
        <h6>Para descargar por favor ingresa tus datos:</h6>
    </div>

    <!-- Contenedor del formulario -->
    <div class="form-container">
        <form [formGroup]="form">
            <!-- Nombre Completo -->
            <div class="mb-3 w-100">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput [formControl]="fullName" name="name" id="name" [placeholder]="placeholderFullName"
                        minlength="3" />
                    <mat-error *ngIf="fullName.hasError('required')">
                        El nombre es obligatorio.
                    </mat-error>
                    <mat-error *ngIf="!fullName.hasError('required') && fullName.hasError('minlength')">
                        Cada palabra debe tener al menos 6 caracteres.
                    </mat-error>
                    <mat-error
                        *ngIf="!fullName.hasError('required') && !fullName.hasError('minlength') && fullName.hasError('pattern')">
                        Debe incluir al menos dos palabras de 3 caracteres cada una.
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Correo Electrónico -->
            <div class="mb-3 w-100">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput [formControl]="email" placeholder="name@example.com" />
                    <mat-error *ngIf="email.hasError('required')">
                        Correo electrónico es obligatorio.
                    </mat-error>
                    <mat-error *ngIf="!email.hasError('required') &&
                        (email.hasError('email') || email.hasError('pattern'))">
                        Correo electrónico inválido.
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Teléfono y Código del País -->
            <div class="mb-3 w-100 responsive-phone-container">
                <!-- Código del País -->
                <div class="country-code-field">
                    <mat-form-field appearance="outline" class="m-0">
                        <mat-label>Código País</mat-label>
                        <mat-select name="countries" [formControl]="country">
                            <mat-option *ngFor="let country of countries" [value]="country.name">
                                {{ country.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Teléfono -->
                <div class="phone-field">
                    <mat-form-field appearance="outline" class="m-0 w-100">
                        <mat-label>Celular/Teléfono</mat-label>
                        <input matInput [formControl]="phone" type="number" class="only-numbers"
                            placeholder="30055512345 / 6015551234" min="0" minlength="10" maxlength="10" />
                        <mat-error *ngIf="phone.hasError('required')">
                            Celular/Teléfono es obligatorio.
                        </mat-error>
                        <mat-error *ngIf="!phone.hasError('required') && phone.hasError('noRepeatedDigits')">
                            Solo se acepta números y sin repetir más de 3 digitos consecutivos.
                        </mat-error>
                        <mat-error *ngIf="!phone.hasError('required') && !phone.hasError('noRepeatedDigits') &&
                            phone.hasError('tenDigits')">
                            Celular/Teléfono debe tener exactamente 10 números.
                        </mat-error>

                    </mat-form-field>
                </div>
            </div>

            <!-- Botones -->
            <div class="d-flex justify-content-center align-items-center row g-3">
                <div class="col-sm-6 d-flex justify-content-center align-items-center">
                    <button style="min-width: 200px;" mat-raised-button (click)="closeDialog()" [disabled]="isSaving">
                        Cancelar <mat-icon class="ms-2">cancel</mat-icon>
                    </button>
                </div>
                <div class="col-sm-6 d-flex justify-content-center align-items-center">
                    <button style="min-width: 200px;" mat-raised-button color="primary" (click)="saveData()"
                        [disabled]="form.invalid || isSaving">
                        Descargar <mat-icon class="ms-2">download</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>