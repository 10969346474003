import { Component, OnDestroy, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { simple as simpleMapping } from 'instantsearch.js/es/lib/stateMappings';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

const searchClient = algoliasearch(
  '6JBZ6JVGP8',
  'd62690bc09f3b41c1428912f910e20ac'
);

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  config = {
    // indexName: 'demo_ecommerce',
    indexName: environment.Algolia_v2.IndexName,
    searchClient,
  //   routing: {
  //     stateMapping: {
  //       stateToRoute(uiState) {
  //         const indexUiState = uiState[indexName];
  //         return {
  //           q: indexUiState.query,
  //           categories: indexUiState.menu && indexUiState.menu.categories,
  //           brand:
  //             indexUiState.refinementList && indexUiState.refinementList.brand,
  //           page: indexUiState.page,
  //         }
  //       },
  //       routeToState(routeState) {
  //         return {
  //           [indexName]: {
  //             query: routeState.q,
  //             menu: {
  //               categories: routeState.categories,
  //             },
  //             refinementList: {
  //               brand: routeState.brand,
  //             },
  //             page: routeState.page,
  //           },
  //         };
  //       },
  //     },
  //   },
  // };
  }
  transformItemsCurrentRefinements(items: any) {
    console.log('transformItemsCurrentRefinements:');
    console.log(items);
    return items.map((item: { label: string }) => {
      // var temp = Object.assign({}, item);
      if (item.label === 'headquarter.city') {
        item.label = 'Ciudad';
      } else {
        item.label = 'Nivel de estudio';
      }
      return item;
    });
  }
  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    // Analytics
    this.analyticsService.logEventScreenView(
      'Search',
      SearchComponent.name);
    
    
  }

}
