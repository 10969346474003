import { Component, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { loadMercadoPago } from '@mercadopago/sdk-js';
import { DIAL_CODES, DialCode } from 'src/app/util/DialCodes';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { DonationService } from 'src/app/services/donation.service';
import { form } from './formMock';
import { Router } from '@angular/router';
import {
  BasicError,
  CardError,
  PaymentDetails,
} from 'src/app/models/payment.interface';
import Swal from 'sweetalert2';

declare global {
  interface Window {
    MercadoPago: any;
  }
}

export interface Identifications {
  name: string;
  indicator: string;
}

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditCardComponent {
  paymentForm: FormGroup;
  donative: number = 0;
  summaryQuantity = '1';
  loading = false;
  mercadopago: any;
  description = 'Donación a Fundación Guía Universitaria';
  cardForm: any;

  isFetching = false;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private donationService: DonationService,
    private router: Router
  ) {
    this.paymentForm = this.formBuilder.group({
      cardholderEmail: ['', Validators.required],
      identificationType: ['', Validators.required],
      identificationNumber: ['', Validators.required],
      cardholderName: ['', Validators.required],
      emailMp: ['', Validators.required],
      installments: ['', Validators.required],
      amount: [String(this.donative)],
      description: [this.description],
    });
  }
  phone_pattern = '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$';
  dial_codes = DIAL_CODES;

  cardholderName: string = '';
  identificationType: string = '';
  identificationNumber: string = '';

  isSubmitDisabled: boolean = false;
  errorMessage: string = '';

  dialCode: DialCode = { name: '', dial_code: '', code: '' };
  default_dialCode: DialCode = {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
  };
  dialCodeCtrl = new FormControl();

  identificationsType: Identifications = { name: '', indicator: '' };
  default_Identification: Identifications = {
    name: 'C.C.',
    indicator: 'Cédula de ciudadanía',
  };
  otherType: Identifications = { name: 'OD', indicator: 'otro' };
  identificationTypeCtrl = new FormControl('');

  identificationsTypes = [
    { indicator: 'C.C.', name: 'Cédula de ciudadanía' },
    { indicator: 'T.E.', name: 'Tarjeta de extranjería' },
    { indicator: 'C.E.', name: 'Cédula de extranjería' },
    { indicator: 'N.I.T.', name: 'Número de identificación tributaria' },
    { indicator: 'PP.', name: 'Pasaporte' },
    { indicator: 'T.I.', name: 'Tarjeta de identidad' },
    { indicator: 'C.C.', name: 'Cédula de ciudadanía' },
  ];

  name = new FormControl('');
  prefix = new FormControl('');
  phone = new FormControl('', [Validators.pattern(this.phone_pattern)]);
  formUserData = this.formBuilder.group({
    name: this.name,
    phone: this.phone,
  });

  requestStatus = {
    identificationTypes: false,
    paymentMethods: false,
    installments: false,
  };

  ngOnInit(): void {
    this.subscribeToDonation();
    this.loadMercadoPagoInstance()
      .then(() => {
        this.loadCardForm();
      })
      .catch((error) => {
        console.error('Error al cargar MercadoPago o el formulario:', error);
      });
  }
  get emailMp() {
    return this.paymentForm.get('emailMp') as FormControl;
  }
  async loadMercadoPagoInstance(): Promise<void> {
    try {
      await loadMercadoPago();
      this.mercadopago = new window.MercadoPago(
        environment.MERCADO_PAGO_PUBLIC_KEY
      );
    } catch (error) {
      console.error('Error al cargar MercadoPago:', error);
    }
  }
  subscribeToDonation() {
    this.donationService.currentDonation.subscribe((donation) => {
      this.donative = Number(donation.donative);
    });
  }

  checkFormValidity() {
    const cardholderNameValid = this.cardholderName.trim() !== '';
    const emailValid = this.emailMp && this.emailMp.value.trim() !== '';
    const identificationNumberValid = this.identificationNumber.trim() !== '';

    const isFormValid =
      cardholderNameValid && emailValid && identificationNumberValid;

    this.isSubmitDisabled = !isFormValid;
  }

  loadCardForm() {
    if (!this.mercadopago) {
      console.error('MercadoPago instance is not available');
      return;
    }
    const payButton = document.getElementById(
      'form-checkout__submit'
    ) as HTMLButtonElement;
    const validationErrorMessages = document.getElementById(
      'validation-error-messages'
    ) as HTMLElement;
    

    this.cardForm = this.mercadopago.cardForm({
      amount: String(this.donative),
      iframe: true,
      form,
      callbacks: {
        onFormMounted: (error?: Error | null) => {
          if (error) {
            console.warn('Form Mounted handling error: ', error);
            this.cardForm.unmount();
          } else {
            console.log('Form mounted');
          }
        },
        onSubmit: (event: Event) => {
          event.preventDefault();
          const loadingMessage = document.getElementById('loading-message');
          if (loadingMessage) {
            loadingMessage.style.display = 'block';
          }

          const {
            paymentMethodId,
            issuerId,
            cardholderEmail: email,
            amount,
            token,
            installments,
            identificationNumber,
            identificationType,
          } = this.cardForm.getCardFormData();
          const isValid = this.validateForm();
          if (isValid) {
            fetch(
              'https://us-central1-guia-universitaria-dev.cloudfunctions.net/paymentMercadoPago',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  isProd: environment.production,
                  token,
                  issuerId,
                  paymentMethodId,
                  transactionAmount: Number(amount),
                  installments: Number(installments),
                  description: this.description,
                  payer: {
                    email,
                    identification: {
                      type: identificationType,
                      number: identificationNumber,
                    },
                  },
                }),
              }
            )
              .then((response) => response.json())
              .then((result) => {
                this.checkAllRequestsComplete();
                const paymentDetails: PaymentDetails = {
                  status: result.status,
                  authorization_code: result.authorization_code,
                  currency_id: result.currency_id,
                  date_created: result.date_created,
                  date_approved: result.date_approved,
                  date_last_updated: result.date_last_updated,
                  description: result.description,
                  detail: result.detail,
                  issuer_id: result.issuer_id,
                  net_amount: result.net_amount,
                  payment_method_id: result.payment_method_id,
                  status_detail: result.status_detail,
                  transaction_amount: result.transaction_amount,
                  id: result.id.toString(),
                  installments: result.installments ?? 0,
                  identificationType: result.payer.identification.type,
                  identificationNumber: result.payer.identification.number,
                  email: result.payer.email,
                  phone: this.phone.value ?? '',
                };
                this.donationService.updatePaymentDetails(paymentDetails);
                this.cardForm.unmount();
                this.router.navigate(['/donaciones/resultados']);
              })
              .catch((error) => {
                console.log('Error en el createPayment', error);
              });
          }
        },
        onFetching: (resource: string) => {
          this.isFetching = true;

          if (resource === 'identificationTypes') {
            this.requestStatus.identificationTypes = true;
          } else if (resource === 'paymentMethods') {
            this.requestStatus.paymentMethods = true;
          } else if (resource === 'installments') {
            this.requestStatus.installments = true;
          }
          if (this.checkAllRequestsComplete()) {
            setTimeout(() => {
              this.isFetching = false;
            }, 1000);
          }
        },
        onCardTokenReceived: (
          errorData: (CardError | BasicError)[] | null,
          token: string
        ) => {
          if (errorData) {
            console.log(' ERROR EN LA DATA', errorData);
            this.handleCardErrors(errorData);
          }
          return token;
        },
        onValidityChange: (
          error: { message: string }[] | null,
          field: keyof typeof form
        ) => {
          const fieldData = form[field];

          if (typeof fieldData === 'object' && 'id' in fieldData) {
            const input = document.getElementById(
              fieldData.id
            ) as HTMLInputElement;
          }
        },
      },
    });
  }

  checkAllRequestsComplete() {
    return Object.values(this.requestStatus).every((status) => status === true);
  }

  processPayment(formData: any) {
    this.loading = false;
  }

  goBack(): void {
    this.location.back();
    this.cardForm.unmount();
  }

  private showErrorAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: errorMessage,
    });
  }
  validateForm(): boolean {
    const { identificationNumber, cardholderName, emailMp } =
      this.paymentForm.controls;

    let allValid = true;

    if (identificationNumber.invalid && identificationNumber.touched) {
      this.showErrorAlert('El número de identificación es requerido.');
      allValid = false;
    }
    if (cardholderName.invalid && cardholderName.touched) {
      this.showErrorAlert('El nombre del titular es requerido.');
      allValid = false;
    }
    if (emailMp.invalid && emailMp.touched) {
      this.showErrorAlert(
        emailMp.errors?.['required']
          ? 'El correo electrónico es requerido.'
          : 'El formato del correo electrónico es inválido.'
      );
      allValid = false;
    }

    return allValid;
  }

  handleCardErrors(errors: (CardError | BasicError)[]) {
    errors.forEach((error) => {
      let errorMessage = '';

      if ('field' in error) {
        const { field, cause, details } = error;

        switch (field) {
          case 'cardNumber':
            errorMessage += 'El número de tarjeta ';
            break;
          case 'securityCode':
            errorMessage += 'El código de seguridad ';
            break;
          case 'expirationMonth':
            errorMessage += 'El mes de expiración ';
            break;
          case 'expirationYear':
            errorMessage += 'El año de expiración ';
            break;
          case 'expirationDate':
            errorMessage += 'La fecha de expiración ';
            break;
          case 'cardholderName':
            errorMessage += 'El nombre del titular ';
            break;
          default:
            errorMessage += 'El campo ';
        }

        switch (cause) {
          case 'invalid_type':
            errorMessage += `debe ser un valor de tipo ${details?.expectedType}.`;
            break;
          case 'invalid_value':
            if (details?.reason === 'empty_value') {
              errorMessage += 'no puede estar vacío.';
            } else {
              errorMessage += 'es un valor no válido.';
            }
            break;
          case 'invalid_length':
            errorMessage += `debe tener una longitud entre ${details?.minLength} y ${details?.maxLength} caracteres.`;
            break;
          default:
            errorMessage += 'tiene un error.';
        }
      } else if ('code' in error) {
        const { code } = error;

        switch (code) {
          case '205':
            errorMessage = 'Ingresa el número de tu tarjeta.';
            break;
          case '208':
            errorMessage = 'Elige un mes.';
            break;
          case '209':
            errorMessage = 'Elige un año.';
            break;
          case '212':
            errorMessage = 'Ingresa tu tipo de documento.';
            break;
          case '213':
            errorMessage = 'Ingresa tu documento.';
            break;
          case '214':
            errorMessage = 'Ingresa tu documento.';
            break;
          case '220':
            errorMessage = 'Ingresa tu banco.';
            break;
          case '221':
            errorMessage = 'Ingresa el nombre y apellido.';
            break;
          case '224':
            errorMessage = 'Ingresa el código de seguridad.';
            break;
          case 'E203':
            errorMessage = 'Revisa el código de seguridad.';
            break;
          case 'E301':
            errorMessage = 'Ingresa un número de tarjeta válido.';
            break;
          case '316':
            errorMessage = 'Ingresa un nombre válido.';
            break;
          case '322':
            errorMessage = 'El tipo de documento es inválido.';
            break;
          case '323':
            errorMessage = 'Revisa tu documento.';
            break;
          case '324':
            errorMessage = 'El documento es inválido.';
            break;
          case '325':
            errorMessage = 'El mes es inválido.';
            break;
          case '326':
            errorMessage = 'El año es inválido.';
            break;
          default:
            errorMessage = 'Revisa los datos.';
            break;
        }
      }

      this.showErrorAlert(errorMessage);
    });
  }
}
