
export interface Career {
  objectID: string;
  id?: string;
  active: boolean;
  name: string;
  code: string;
  levelStudy: string;
  academicLevel?: string;
  candidateProfile?: string;
  obtainedTitle?: string;
  profileGraduated: string;
  modalities: string;
  price: number | undefined;
  currency: string;
  credits: number | undefined;
  duration: number | undefined;
  periods: string;
  admissionsPeriodicity?: string;
  schedule: string;
  institution: string;
  nationalAccreditation: boolean;
  internationalAccreditation?: boolean;
  ministryRecognition?: string;
  headquarter?: LocationProps[];
  description: string;
  uidFaculty: string;
  uidInstitution: string;
  iesCharacter: string;
  iesImages: ImageProps[];
  defaultImage?: ImageProps
  iesLogo: string;
  startYear?: string;
  costCredit: number | undefined;
  inscriptions?: string;
  startClass?: string;
  compatiblePrograms?: string[];
  exchange?: boolean;
  dualProgram?: boolean;
  summerProgram?: boolean;
  mandatoryInterships?: boolean;
  mandatoryThesis?: boolean;
  updateAt?: string;
  subscriptionLevel: number | undefined;
  facultyName: string | null | undefined;
  // Contact Info
  webSite: string;
  contactEmail?: string;
  contactPhone?: number | undefined;
  phone_code_country?: string;
  facebook?: string;
  hasFacebook?: boolean;
  whatsapp?: number | undefined;
  hasWhatsapp?: boolean;
  linkedin?: string;
  hasLinkedin?: boolean;
  // Financial Support
  hasFinancialSupport?: boolean;
  financialSupport_email?: string;
  financialSupport_hasWhatsapp?: boolean;
  financialSupport_contactPhone?: string;
  financialSupport_phone_code_country?: string;
  financialSupport_website?: string;
  financialSupport_whatsapp?: number;
  contactInfo?: {
    default?: string;
    career?: {
      contactEmail: string;
      contactPhone: string;
      phone_code_country: string;
      webSite: string;
    },
    faculty?: {
      contactEmail: string;
      contactPhone: string;
      phone_code_country: string;
      webSite: string;
    },
    ies?: {
      contactEmail: string;
      contactPhone: string;
      phone_code_country: string;
      webSite: string;
    },
  },
  contactSocialMedia?: {
    default?: string;
    career?: {
      facebook?: string;
      hasFacebook?: boolean;
      linkedin?: string;
      hasLinkedin?: boolean;
      whatsapp?: string;
      hasWhatsapp?: boolean;
    },
    faculty?: {
      facebook?: string;
      hasFacebook?: boolean;
      linkedin?: string;
      hasLinkedin?: boolean;
      whatsapp?: string;
      hasWhatsapp?: boolean;
    },
    ies?: {
      facebook?: string;
      hasFacebook?: boolean;
      linkedin?: string;
      hasLinkedin?: boolean;
      whatsapp?: string;
      hasWhatsapp?: boolean;
    },
  },
  financialSupport?: {
    default?: string;
    career?: {
      contactPhone?: string;
      email?: string;
      hasFinancialSupport?: boolean;
      hasWhatsapp?: boolean;
      phone_code_country?: string;
      whatsapp?: string;
      website?: string;
    },
    faculty?: {
      contactPhone?: string;
      email?: string;
      hasFinancialSupport?: boolean;
      hasWhatsapp?: boolean;
      phone_code_country?: string;
      whatsapp?: string;
      website?: string;
    },
    ies?: {
      contactPhone?: string;
      email?: string;
      hasFinancialSupport?: boolean;
      hasWhatsapp?: boolean;
      phone_code_country?: string;
      whatsapp?: string;
      website?: string;
    },
  },
  ref?: string
}

export enum CONTACT_TYPES {
  Career = "career",
  Faculty = "faculty",
  Institute = "ies"
}

export interface ImageProps {
  title?: string; // 'Sede Principal'
  url: string; // 'https://firebasestorage.googleapis.com/v0/b/guia-universitaria-dev.appspot.com/o/ies%2F1102%2Fimagen.jpg?alt=media&token=4cb2706e-1ccd-44b9-85d2-4f82a009085f',
  alt?: string; // 'Imagen Sede Principal'
}

export interface LocationProps {
  title: string; // 'Sede Principal' // [MANDATORY]
  address: string // '' // [MANDATORY]
  country: string; // 'Colombia' // [MANDATORY]
  region: string; // 'Atlantico' // [MANDATORY]
  city: string; // 'Barranquilla' // [MANDATORY]
  zipCode?: string; // '11014'
  geoLocation?: GeoPointProps; // GeoPoint { _lat: 4.6381938, _long: -74.228242 },
}

export interface GeoPointProps {
  latitude: number;
  longitude: number;
}
