<div class="p-4 position-relative" *ngIf="user && user.proactiveRefresh.user && user.providerId">
  <mat-icon class="position-absolute top-0 end-0" style="cursor: pointer;" (click)="closeOnlySaveDialog()">close</mat-icon>
  <div mat-dialog-content class="d-flex justify-content-center flex-column" >
    <p>¿Cómo deseas guardar tus carreras comparadas?</p>
    <mat-form-field appearance="fill">
      <mat-label>Nombre</mat-label>
      <input matInput [(ngModel)]="data.name">
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-center flex-column w-100">
    <!-- <button  
      mat-raised-button 
      class="saved-button-style my-2 w-100" 
      cdkFocusInitial 
      (click)="saveListAs()"
      *ngIf="!saveAs"
    >
      Guardar como
    </button> -->
    <button  mat-raised-button color="primary" class="saved-button-style my-2 w-100" [mat-dialog-close]="data.name" cdkFocusInitial (click)="saveListInGroup()">Guardar</button>
  </div>
</div>

<div *ngIf="!user" class="p-4 position-relative">
  <mat-icon class="position-absolute top-0 end-0"  style="cursor: pointer;" (click)="closeOnlySaveDialog()">close</mat-icon>
  <div mat-dialog-content class="d-flex justify-content-center flex-column" >
    <p>Para guardar y ver tus comparaciones debes iniciar sesión</p>
    <a routerLink="/login" class="w-100"><button mat-flat-button color="primary" class="gap-0 me-1 my-3 w-100" (click)="onNoClick()">Ingresar</button></a>
    <a routerLink="/register" class="w-100"><button mat-flat-button color="primary" class="gap-0 me-1 my-3 w-100" (click)="onNoClick()">Registrarse</button></a>
  </div>
  <div mat-dialog-actions class="w-100 justify-content-end">
    <button  mat-raised-button  (click)="onNoClick()">Cancelar</button>
  </div>
</div>

