<div *ngIf="config" class="d-grid justify-content-center align-items-center w-100  p-3">
    <h1 class="fw-bold mb-4 justify-content-center align-items-center text-center ">
        Encuentra <span class="primaryColor">la mejor opción</span> para estudiar
    </h1>
    <div class="d-flex justify-content-center align-items-center w-100">
        <mat-chip-list [multiple]="true" #chipList class="pb-2 ">
            <mat-chip [selectable]="true" [selected]="isAllSelected()" (click)="toggleAllSelection()" color="warn">
                <!-- class="mat-chip-all"
                    <mat-icon matChipAvatar *ngIf="isAllSelected()" style="margin-right: 4px;">done</mat-icon> -->
                Todos
            </mat-chip>
            <mat-chip *ngFor="let level of studyLevels" [selectable]="true" [selected]="isSelected(level)"
                (click)="toggleSelection(level)" color="accent">
                <!-- class="mat-chip-level" 
                     <mat-icon matChipAvatar *ngIf="isSelected(level)" style="margin-right: 4px;">done</mat-icon> -->
                {{level}}
            </mat-chip>
        </mat-chip-list>
    </div>

    <ais-instantsearch [config]="config">
        <ais-configure [searchParameters]="searchParameters"></ais-configure>
        <div class="searchbox">
            <app-autocomplete [isMandatory]="hasRedirect" [initValue]="InitValueQuery"
                (onQuerySuggestionClick)="setQuery($event)" (inputValue)="updateInputValue($event)"></app-autocomplete>
        </div>
    </ais-instantsearch>

    <mat-form-field appearance="outline" class="pt-20 w-100">
        <mat-label>¿Dónde?</mat-label>
        <input type="text" matInput [placeholder]="'Ej: ' + citySample" [formControl]="cityCtrl" aria-label="¿Dónde?"
            [formControl]="cityCtrl" [matAutocomplete]="auto">
        <mat-icon matSuffix>place</mat-icon>
        <mat-autocomplete [autoActiveFirstOption]="false" #auto="matAutocomplete">
            <mat-option *ngFor="let city of filteredCities | async" [value]="city">
                {{city}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <div class="d-grid justify-content-center align-items-center w-100">
        <button mat-flat-button color="primary" style="min-width: 150px;" (click)="search()">Buscar</button>
    </div>
</div>