import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Career, ImageProps, LocationProps } from '../models/career.interface';
import { ComparisonService } from '../services/comparison.service';
import { DetailsService } from '../services/details.service';
import { UtilityService } from '../services/utility.service';
import { DialogSaveComparisonComponent } from '../dialog-save-comparison/dialog-save-comparison.component';
import { NgxCaptureService } from "ngx-capture";
import { elementAt, tap } from 'rxjs';
import { object } from 'rxfire/database';
import { cloneElement } from 'preact';
import { DocumentCloner } from 'html2canvas/dist/types/dom/document-cloner';
import { DeviceDetectorService } from '../services/device-detector.service';

import { HTMLOptions, jsPDF, jsPDFOptions } from "jspdf";

import * as html2pdf from 'html2pdf.js'; // declare let html2pdf: any;
import html2canvas from 'html2canvas';
import { UserService } from '../services/user.service';
import { LoginAlertComponent } from '../components/login-alert/login-alert.component';
import { DownloadDialogComponent } from '../components/download-dialog/download-dialog.component';
import { error } from 'console';
import { UserProfileService } from '../services/user-profile.service';
import { DownloadRequest } from '../models/download-request.interface';
import { DownloadRequestService } from '../services/download-request.service';
import { StringValidationService } from '../services/string-validation-service.service';

@Component({
  selector: 'app-another-table',
  templateUrl: './another-table.component.html',
  styleUrls: ['./another-table.component.scss'],
})
export class AnotherTableComponent implements OnInit {
  @Input() selectedTableName?: string = ''

  careers: Career[] = [];
  careersList: (Career | null)[] = [];
  institutes: string[] = [];
  profiles: string[] = [];
  iesCharacters: string[] = [];
  durations: string[] = [];
  modalities: string[] = [];
  locations: string[] = [];
  times: string[] = [];
  prices: string[] = [];
  headquarters: string[] = [];
  pricesWithoutFormat: number[] = []

  user = JSON.parse(localStorage.getItem('user')!)
  listName: string = ""
  now = new Date()
  month = this.now.toLocaleString('default', { month: 'short' })
  monthUpperCase = this.month.charAt(0).toUpperCase() + this.month.slice(1)
  // currentDate = this.now.getFullYear() + '-' + (this.now.getMonth()+1) + '-' + this.now.getDate()
  currentDate = this.now.getDate() + ' ' + this.monthUpperCase

  constructor(private comparisonService: ComparisonService, private detailsService: DetailsService,
    private utilityService: UtilityService,
    public dialog: MatDialog,
    public router: Router,
    private captureService: NgxCaptureService,
    private deviceDetectorService: DeviceDetectorService,
    private userService: UserService,
    private userProfileService: UserProfileService,
    private downloadRequestService: DownloadRequestService,
    private stringValidationService: StringValidationService) {
    //this.comparisonService.carreras;
    this.refreshComparisonTable();
    this.listName = this.selectedTableName ? this.selectedTableName : ""
  }


  ngOnInit(

  ): void {


    // console.log(this.comparisonService.listIsSaved)
  }


  openDialog(): void {
    const comparatorName = localStorage.getItem(environment.currentComparatorName)
    if (comparatorName) {
      this.selectedTableName = JSON.parse(comparatorName)
    }
    console.log("Antes de abrir dialog", this.selectedTableName)
    if (this.selectedTableName) {
      this.listName = this.selectedTableName
    } else {
      this.listName = `Comp. ${this.currentDate}`
    }
    console.log("ABRIENDO DIALOGO CON EL NAME", this.listName)
    const dialogRef = this.dialog.open(DialogSaveComparisonComponent, {
      width: '400px',
      data: {
        name: this.listName,
        hasCareers: this.careers.length > 0
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.listName = result;
      this.triggerUpdateInParent();
    });
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

  triggerUpdateInParent() {
    this.comparisonService.triggerUpdate();
  }

  onDelete(career: Career) {
    this.comparisonService.removeItemInComparisonList(career.objectID);
    // let index = this.careers.findIndex((c) => c?.objectID == career.objectID);
    // if (index !== -1) {
    //   this.carreras.splice(index, 1);
    // }
    this.refreshComparisonTable();
  }

  onDetail(career: Career) {
    var _career = this.careers[
      this.careers.indexOf(career)
    ];
    this.detailsService.openDialog(_career);
  }

  onAdd() {
    this.dialog.closeAll();
    this.router.navigate(['/buscador']);
    // ## Accessing the Previous URL in Angular ##
    // https://jacobneterer.medium.com/accessing-the-previous-url-in-angular-32cf97bec3ce#:~:text=In%20any%20component%20you%20want,a%20variable%20in%20the%20component.&text=Now%20you%20can%20use%20the,any%20changes%20to%20its%20value!
  }

  clearComparisonTable() {
    this.careers = [];
    this.careersList = [];
    this.institutes = [];
    this.profiles = [];
    this.iesCharacters = [];
    this.durations = [];
    this.modalities = [];
    this.locations = [];
    this.times = [];
    this.prices = [];
    this.headquarters = [];
    this.pricesWithoutFormat = []

  }

  refreshComparisonTable() {
    this.clearComparisonTable();
    const MAX: number = environment.MAX_COUNT_COMPARASION_LIST;
    const ttlComparisonList = this.comparisonService.getTtlComparisonList()
    const ttlComparisonGroupList = this.comparisonService.getTtlComparisonGroupList()
    const dateNow = Date.now()
    try {
      if (dateNow > ttlComparisonList) {
        this.comparisonService.clearComparisonList();
        this.comparisonService.clearTtlComparisonList();
        this.comparisonService.careers = []
        this.clearComparisonTable();
        for (let i = 0; i < MAX; i++) {
          this.addItemToComparasionList(null);
        }
      } else {
        this.careers = this.comparisonService.getComparisonList();
        this.careers.forEach((career) => {
          this.addItemToComparasionList(career);
        });
        for (let i = this.careers.length; i < MAX; i++) {
          this.addItemToComparasionList(null);
        }
      }

      if (dateNow > ttlComparisonGroupList) {
        this.comparisonService.clearComparisonGroupList();
        this.comparisonService.clearTtlComparisonGroupList();
      }

    } catch (error) {
      console.error(error);
      this.comparisonService.clearComparisonList();
      this.clearComparisonTable();
      for (let i = 0; i < MAX; i++) {
        this.addItemToComparasionList(null);
      }
    }
  }

  headquarterEmpty: LocationProps = { title: '', city: '', region: '', country: '', address: '' }

  defaultImage: ImageProps = { url: environment.publicUrls.defaultImage };
  addItemToComparasionList(career: Career | null) {
    if (career) {
      if (career.iesImages.length < 1) {
        career.iesImages.push();
      }
      if (!career.iesLogo) {
        career.iesLogo = environment.publicUrls.defaultLogo;
      }
      this.careersList.push(career);
      this.institutes.push(career.institution);
      this.profiles.push(career.profileGraduated);
      this.iesCharacters.push(career.iesCharacter);
      // Career Duration
      const _periods = this.utilityService.formatPeriods(career.periods);
      this.durations.push(`${career.duration} ${_periods}`);
      this.modalities.push(career.modalities);
      var headquarter = (career.headquarter && career.headquarter.length > 0) ? career.headquarter[0] : this.headquarterEmpty;
      var location: string = this.utilityService.formatLocation(headquarter.city, headquarter.region, headquarter.country);
      this.locations.push(location);
      this.headquarters.push(headquarter.title);
      this.times.push(`${career.duration} ${career.periods}`);
      if (!career.price || career.price < 0) {
        this.prices.push('--');
        this.pricesWithoutFormat.push()
      } else {
        this.prices.push(`${this.utilityService.formatToMinimalistCurrency(career.price)} ${career.currency} `);
        this.pricesWithoutFormat.push(career.price)
      }
    } else {
      this.careersList.push(null);
      this.institutes.push('');
      this.profiles.push('');
      this.iesCharacters.push('');
      this.durations.push('');
      this.modalities.push('');
      this.locations.push('');
      this.headquarters.push('');
      this.times.push('');
      this.prices.push('');
      this.pricesWithoutFormat.push();
    }
  }

  formatMinimalistPrice(price: number) {
    this.utilityService.formatToMinimalistCurrency(price)
  }

  formatPrice(price: number | undefined): string {
    if (price !== undefined) {
      return price.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
    } else {
      return '--';
    }
  }
  // https://stackoverflow.com/questions/18311048/how-to-make-a-2-dimensional-comparison-table-in-angular-js

  @ViewChild('screen', { static: true }) screen: any;

  public isCapturing: boolean = false;
  capture() {
    const temp: HTMLElement = this.screen.nativeElement as HTMLElement;
    // const temp = elem.cloneNode(true);
    this.isCapturing = true;
    temp.hidden = false;
    // console.warn("Screem nativeElement (old):", temp);
    // console.warn("style.display (old):", temp.style.display);
    temp.hidden = false;
    // temp.style.display = "contents !important";

    const _width = 230 * (this.careers.length + 1);
    temp.style.width = _width + "px";
    // console.warn("Screem nativeElement:", this.screen.nativeElement);
    // console.warn("Screem nativeElement (new):", temp);
    // console.warn("style.display (new):", temp.style.display);

    // const dimensions = temp.getBoundingClientRect();
    this.captureService.getImage(temp, true).pipe(
      tap((img) => {
        // console.warn("imageCaptured", img);
        // console.warn("dimensions", dimensions);
        try {
          this.shareImage(img);
        } catch (error) {
          console.error(error);
        }
        temp.hidden = true;
        // temp.style.display = "none !important";
        this.isCapturing = false;
        temp.hidden = true;
      })).subscribe();
  }

  async shareImage(imgBase64: string) {
    // const navigator = window.navigator;
    const file = this.dataBase64toFile(imgBase64, "ComparacionGuiaUniversitaria.png");
    if (file) {
      // alert(`File name: '${file.name}', size:${file.size}`);

      // alert("shareImage0: " + JSON.stringify(window.navigator));
      // alert("shareImage2: " + navigator.canShare);
      // console.warn("shareImage3", navigator);
      // console.warn("shareImage4", window.navigator);
      // console.warn("shareImage5", navigator.canShare);
      // console.warn("getUserAgent: ", this.deviceDetectorService.getUserAgent());
      const isIOS: boolean = this.deviceDetectorService.isIOSDevice() || this.deviceDetectorService.isMacDevice();
      if (!isIOS && navigator.canShare && navigator.canShare({ files: [file] })) {
        // navigator.share({
        //   title: 'Share Image',
        //   text: 'Check out this image!',
        //   url: imgBase64
        await navigator.share({
          title: 'Share Image',
          text: 'Check out this image!',
          files: [file]
        }).then(() => {
          // alert('Image shared successfully.');
          console.log('Image shared successfully.');
        }).catch((error) => {
          alert('Error sharing image:' + error);
          console.error('Error sharing image:', error);
        });
      } else {
        // alert("Web Share API is not supported in this browser.");
        console.warn('Web Share API is not supported in this browser.');
        const imageBlob = this.dataURItoBlob(imgBase64);
        const blobUrl = URL.createObjectURL(imageBlob);

        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = 'image.jpg';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }
    } else {
      // alert("shareImage file null. Navigator CanShare: " + navigator.canShare);
      if (navigator.canShare && navigator.canShare({ url: imgBase64 })) {
        await navigator.share({
          title: 'Share Image',
          text: 'Check out this image!',
          url: imgBase64
        }).then(() => {
          console.log('Image shared successfully.');
        }).catch((error) => {
          console.error('Error sharing image:', error);
        });
      } else {
        // alert("Web Share API is not supported in this browser.");
        console.warn('Web Share API is not supported in this browser.');
        const imageBlob = this.dataURItoBlob(imgBase64);
        const blobUrl = URL.createObjectURL(imageBlob);

        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = 'image.jpg';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }
    }
  }

  // Convert a Base64 string to File
  dataBase64toFile(imgBase64: string, filename: string): File | null {
    console.warn("dataBase64toFile");
    const arr = imgBase64.split(",");
    console.warn("arr", arr);
    var temp = false;
    if (temp)
      return null;
    if (arr.length > 1) {
      const mimeTypeArr = arr[0].match(/:(.*?);/);
      console.warn("mimeTypeArr", mimeTypeArr);
      console.warn("mimeTypeArr.length ", mimeTypeArr?.length);
      if (mimeTypeArr && mimeTypeArr.length > 1) {
        const mimeType = mimeTypeArr[1];
        console.warn("mimeType", mimeType);
        const decodedData = atob(arr[1]);
        let lengthOfDecodedData = decodedData.length;
        const u8array = new Uint8Array(lengthOfDecodedData);
        while (lengthOfDecodedData--) {
          u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
        }
        return new File([u8array], filename, { type: mimeType });
      }
    }
    return null;
  }

  // Convert a Base64 string to Blob
  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  isLogged(): boolean {
    // TODO: uncomment console log lines
    // console.log("cAccessGuard-validateCanActivate");
    if (!this.userService.isLoggedIn) {
      // console.log("user is not LoggedIn");
      return false;
    }
    return true;
  }

  async validateDownload() {
    this.isDonwloading = true;
    try {
      var disableAutenticate = true;
      if (!disableAutenticate) {
        if (!this.isLogged()) {
          const dialogRef = this.dialog.open(LoginAlertComponent, {
            data: { message: 'Debes estar autenticado para descargar el comparador.' }
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              console.log('El usuario fue al login de Estudiantes.');
              this.closeDialog();
            } else {
              console.log('El usuario canceló el diálogo de Estudiantes.');
            }
          });
        }
        return;
      }

      // Logged is not required
      let user: {
        fullName: string;
        phonePrefix: string;
        phone: string;
        email: string;
        id: string;
      } = {
        fullName: '',
        phonePrefix: '+57',
        phone: '',
        email: '',
        id: `ANON-${this.userService.getSessionId() ?? Math.random()}`,
      };

      // Logged => Check if has all data to show form request or Save inmediatly and then generatePdf
      const isLogged = this.isLogged();
      if (isLogged) {
        user.fullName = this.userService.user?.displayName ?? '';
        user.email = this.userService.user?.email ?? '';
        user.id = this.userService.user?.uid ?? user.id;
        if (this.userService.user?.uid) {
          const profile = await this.userProfileService.getUserProfile(this.userService.user?.uid);
          user.fullName = profile?.fullName ?? user.fullName;
          user.phone = (profile?.personalData?.phone) ? `${profile?.personalData?.phone}` : user.phone;
          user.phonePrefix = profile?.personalData?.phonePrefix ?? '+57';
        }
        if (user.fullName?.trim() != '' && user.email?.trim() != '' &&
          user.phone?.trim() != '' && user.phonePrefix?.trim() != '' &&
          this.stringValidationService.noRepeatedDigitsValidator(user.fullName) &&
          this.stringValidationService.onlyNumbersValidator(user.phone) &&
          this.stringValidationService.lenghtValidator(user.phone, 10, 10) &&
          this.stringValidationService.validateEmail(user.email) &&
          user.phonePrefix?.trim() != '') {
          const downloadRequest: DownloadRequest = {
            user: {
              fullName: user.fullName,
              email: user.email,
              phonePrefix: user.phonePrefix ?? '+57',
              phone: user.phone ?? '',
              id: user.id
            },
            careers: this.careers
          };
          this.downloadRequestService.saveRequest(downloadRequest);
          await this.generatePdf();
          return;
        }
      }

      // Not logged => Open Form Dialog or validate if it is saved in Local Storage to generatePdf
      var localUser = this.userService.getLocalDownloadUser();
      if (localUser) {
        if (localUser.fullName?.trim() != '' && localUser.email?.trim() != '' &&
          localUser.phone?.toString().trim() != '' && localUser.phonePrefix?.trim() != '' &&
          this.stringValidationService.noRepeatedDigitsValidator(localUser.fullName) &&
          this.stringValidationService.onlyNumbersValidator(localUser.phone.toString()) &&
          this.stringValidationService.lenghtValidator(localUser.phone.toString(), 10, 10) &&
          this.stringValidationService.validateEmail(localUser.email)) {
          const downloadRequest: DownloadRequest = {
            user: {
              fullName: localUser.fullName,
              email: localUser.email,
              phonePrefix: localUser.phonePrefix ?? '+57',
              phone: (localUser.phone ?? '').toString(),
              id: localUser.id
            },
            careers: this.careers
          };
          this.downloadRequestService.saveRequest(downloadRequest);
          await this.generatePdf();

          // Renew the 'donwload-user' in local storage
          this.userService.renewLocalDownloadUser();
          return;
        }
        user.fullName = localUser.fullName;
        user.email = localUser.email;
        user.phone = localUser.phone;
        user.phonePrefix = localUser.phonePrefix;
      }

      // Open Download Form to register the user data
      console.log('Usuario no autenticado intentando descargar tabla comparativa.');
      const dialogRef = this.dialog.open(DownloadDialogComponent, {
        data: {
          careers: this.careers,
          user: {
            fullName: user.fullName,
            phone: user.phone,
            email: user.email,
            id: user.id,
            isLogged: isLogged
          }
        }
      });
      this.isDonwloading = true;

      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result > 0) {
            console.log('El usuario no autenticado o autenticado que le falta un dato ingresó sus datos.');
            // alert('DOWNLOAD - Incia descarga ...');
            this.isDonwloading = false;
            this.generatePdf();
          } else {
            // alert('CANCEL - Canceló descarga ...');
            console.log('El usuario canceló el diálogo de Estudiantes.');
            this.isDonwloading = false;
          }
        }, error: (error) => {
          alert('Se ha presentado un error, intente más tarde.');
          console.error(error);
        }
      });
    } catch (error) {
      alert('Se ha presentado un error, intente más tarde.');
      console.error(error);
    } finally {
      this.isDonwloading = false;
    }
  }

  // Convert to PDF
  isDonwloading: boolean = false;
  async generatePdf() {
    this.isDonwloading = true;
    await new Promise(resolve => setTimeout(resolve, 10)); // Espera 10 milisegundos
    try {
      let screenHtmlContent: HTMLElement = this.screen.nativeElement as HTMLElement;
      try {
        screenHtmlContent.hidden = false;

        const scale = 1; // 0.48; 1;
        const widthHtml = 1024; // 297;
        const heightHtml = 900; // 210;
        const unit = 'px'; // 'mm'

        screenHtmlContent.style.width = "auto"; // widthHtml.toString() + unit; // "fit-content";
        screenHtmlContent.style.height = "auto"; // heightHtml.toString() + unit;
        // screenHtmlContent.style.scale = scale.toString();
        const rectParent = screenHtmlContent.getBoundingClientRect();

        const margin = 20; // 10; //
        const width = rectParent.width + (4 * margin);
        const height = rectParent.height;
        // alert(`[w, h]: [${width}, ${height}]`);

        let logo: { url: string, x: number, y: number, w: number, h: number } = {
          url: '',
          x: 0,
          y: 0,
          w: 0,
          h: 0
        };
        const logoElement = document.getElementById('logoHidden');

        console.warn('logoElement: ', logoElement);
        if (logoElement) {
          const rect = logoElement.getBoundingClientRect();
          console.warn('logoHidden: ', rect);
          logo.x = ((rect.left - rectParent.left) * scale) + margin;
          logo.y = ((rect.top - rectParent.top) * scale) + margin;
          logo.w = rect.width;
          logo.h = rect.height;
          logo.url = "https://guiauniversitaria.net/";
        }
        // Obtener los enlaces
        const hyperlinks: { url: string, x: number, y: number, w: number, h: number }[] = [];
        for (let i = 0; i < environment.MAX_COUNT_COMPARASION_LIST; i++) {
          const detail = document.getElementById('viewButton' + i);
          if (detail) {
            const rect = detail.getBoundingClientRect();
            console.warn('viewButton' + i + ': ', rect);
            const x = ((rect.left - rectParent.left)) + margin;
            // const x = (rect.left - rectParent.left) * scale - (4 * margin);
            const y = ((rect.top - rectParent.top)) + margin;
            const href = encodeURI(detail.getAttribute("href") ?? "https://guiauniversitaria.net/");
            hyperlinks.push({
              url: href,
              x: x,
              y: y,
              w: rect.width,
              h: rect.height
            });
          } else {
            break;
          }
        }

        // const nodeElement = screenHtmlContent.cloneNode(true);
        // // screenHtmlContent.hidden = true;
        // const element = nodeElement as HTMLElement;
        // element.hidden = false;
        // element.style.width = width.toString() + unit;; // "fit-content";
        // element.style.height = height.toString() + unit; // "auto";
        // element.style.scale = scale.toString();

        const pdfOptions: jsPDFOptions = {
          compress: true,
          format: [(width + (4 * margin)), (height + (4 * margin))], // Ancho y alto en 'unit defined'
          orientation: 'portrait', // 'landscape', // 
          unit: unit, // unit: 'mm', // 	297 × 210 mm (horizontal)
        }
        const doc = new jsPDF(pdfOptions);

        const options: HTMLOptions = {
          x: 0,
          y: 0,
          margin: margin, // Márgenes del contenido
          width: width + (4 * margin), // Ancho del contenido en el PDF
          html2canvas: {
            x: 0,
            y: 0,
            scale: scale, // Ajustar la escala si es necesario
            useCORS: true, // Si tienes problemas con imágenes de otros dominios
            width: width, // Ancho del contenido en el PDF
            height: height, // Ancho del contenido en el PDF
          },
        };

        // Agregar HtmlConent al PDF
        await doc.html(screenHtmlContent, options);

        // Agregar link del logo
        if (logo.url !== '') {
          doc.link(logo.x, logo.y, logo.w, logo.h, { url: logo.url });
          // doc.rect(logo.x, logo.y, logo.w, logo.h, 'F');
          // doc.textWithLink("GU", logo.x, logo.y, { url: logo.url });
        } else {
          doc.link(10, 10, 70, 40, { url: "https://guiauniversitaria.net/" });
        }

        // Agregar liks de cada carrera
        console.warn("hyperlinks: ", hyperlinks);
        for (let i = 0; i < hyperlinks.length; i++) {
          const hyperlink = hyperlinks[i];
          doc.link(hyperlink.x, hyperlink.y, hyperlink.w, hyperlink.h, { url: hyperlink.url });
          // doc.rect(hyperlink.x, hyperlink.y, hyperlink.w, hyperlink.h, 'F');
          // doc.textWithLink("Ver_" + i, hyperlink.x, hyperlink.y, { url: hyperlink.url });
        }

        // Guardar PDF
        doc.save('Guía Universitaria - Comparación.pdf');

      } catch (error) {
        console.error(error);
      } finally {
        screenHtmlContent.hidden = true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isDonwloading = false;
    }
  }

  async generatePdfv2() {
    let screenHtmlContent: HTMLElement = this.screen.nativeElement as HTMLElement;
    screenHtmlContent.hidden = false;
    const elementNode = screenHtmlContent.cloneNode(true);
    const element = elementNode as HTMLElement;
    console.warn("-- print content v2 ---");
    try {
      html2canvas(screenHtmlContent).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        console.warn("[imgWidth, imgHeight]: ", "[" + imgWidth + ", " + imgHeight + "]");

        const opt = {
          unit: 'px',
          x: 0,
          y: 0,
          margin: 10,
          filename: 'Guía Universitaria - Comparador.pdf',
          image: { type: 'jpeg', quality: 0.9 },
          html2canvas: { scale: 1, useCORS: true },
          jsPDF: {
            unit: 'px',
            format: [imgWidth, imgHeight], // Ancho y alto en milímetros
            orientation: 'landscape',
            margin: 10,
          }
        };
        console.warn("element.outerHTML (v2): ", element?.outerHTML);
        console.warn("element.style (v2): ", element.style);
        console.warn("element.style.width v2: ", element.style.width);
        console.warn("element.style.height v2: ", element.style.height);

        if (element) {
          html2pdf().from(element).set(opt).save();
        } else {
          alert("element to print no found");
        }
      });
    } catch (error) {
      console.error(error);
    }
    screenHtmlContent.hidden = true;
  }

  async printHtml() {
    this.isCapturing = true;
    try {
      let screenHtmlContent: HTMLElement = this.screen.nativeElement as HTMLElement;
      screenHtmlContent.hidden = false;
      const element = screenHtmlContent.cloneNode(true);
      screenHtmlContent.hidden = true;
      const htmlContent = element as HTMLElement;
      try {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
          const printDoc = printWindow.document;

          // Agregar el header con los styles
          printDoc.head.innerHTML = document.head.innerHTML;

          // Agregar estilos CSS para la impresión
          const printStyle = printDoc.createElement('style');
          printStyle.textContent = `
                @media print {
                    body {
                      orientation: portrait;
                      size: Tabloid;
                    }
                }`;
          printDoc.head.appendChild(printStyle);
          printDoc.title = "Guía Universitaria - Comparador";

          // Agregar el contenido a imprimir
          printDoc.body.appendChild(htmlContent.cloneNode(true));
          // printDoc.write(htmlContent.outerHTML);

          // Imprimir y cerrar ventana

          console.warn('Content to print: ', printDoc);
          printWindow.print();
          printWindow.onafterprint = (event) => {
            printWindow.close();
            // console.log("After print");
          };
        }
      } catch (error) {
        console.error("Error in printHtml");
        console.error(error);
      }
      htmlContent.hidden = true;
    } catch (error) {
      console.error("Error in printHtml");
      console.error(error);
    }
    this.isCapturing = false;
  }
}
