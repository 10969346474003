import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MockResultsTest } from 'src/app/models/vocationalTest.interface';

interface Data {
  createdAt: string
  results: MockResultsTest[]
}
@Component({
  selector: 'app-dialog-results-test',
  templateUrl: './dialog-results-test.component.html',
  styleUrls: ['./dialog-results-test.component.scss']
})
export class DialogResultsTestComponent implements OnInit{
  isArray: boolean;
  dataArray: Data[];

  constructor( public dialogRef: MatDialogRef<DialogResultsTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data | Data[]) {
      if (Array.isArray(data)) {
        this.isArray = true;
        this.dataArray = data;
      } else {
        this.isArray = false;
        this.dataArray = [data];
      }
     }
    
    ngOnInit(){
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    formatDate(dateString: string): string {
      if(!dateString) return "No registramos data"
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0'); 
      return `${year}-${month}-${day}`;
    }
    
}
