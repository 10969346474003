import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { Career } from 'src/app/models/career.interface';
import { environment } from 'src/environments/environment';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { FavoriteService } from 'src/app/services/favorites.service';

const searchClient = algoliasearch(
  environment.Algolia_v2.ApplicationID,
  environment.Algolia_v2.APIKey,
  {
    responsesCache: createInMemoryCache(),
    requestsCache: createInMemoryCache({ serializable: false })
  }
);
const indexCareers = "careers"


@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss']  
})
export class FavoriteListComponent implements OnInit {
  constructor(protected favoriteService:FavoriteService) { 
    this.userFavorites = [];
  }

  userFavorites: Array<string>
  careers: Career[] | []=[]

  ngOnInit(): void {
   this.updateCareers()
  }

  async updateCareers() {
    this.userFavorites = this.favoriteService.getFavoriteList();
    this.careers  = await this.favoriteService.getFavorites(this.userFavorites);
  }


  @ViewChild('focus', { read: ElementRef }) focus!: ElementRef;

  scrollUp(): void {
  // console.warn('scrollUp ...');
  setTimeout(() => this.focus.nativeElement.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" }));
  }
 

}
