import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const ConfirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  if (password?.value !== confirmPassword?.value) {
    confirmPassword?.setErrors({ ...confirmPassword.errors, not_matching: true });
    return { not_matching: true };
  } else {
    confirmPassword?.setErrors(confirmPassword.errors);
  }

  return null;
};
