import { Injectable } from '@angular/core';
import { DownloadRequest } from '../models/download-request.interface';
import { Firestore, serverTimestamp } from "@angular/fire/firestore";
import { addDoc, collection } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class DownloadRequestService {
  path: string = 'downloadRequests';

  constructor(public firestore: Firestore) { }

  async saveRequest(downloadRequest: DownloadRequest): Promise<boolean | string> {
    try {
      const collectionRef = collection(this.firestore, this.path);
      const docData = await addDoc(collectionRef, {
        ...downloadRequest,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      return docData.id
    } catch (error) {
      console.error(`Error in saveContactRequest`, downloadRequest);
      console.error(error);
      throw error;
    }
  }
}



/*
import { Injectable } from '@angular/core';
import { ContactRequest } from '../models/contact-request.interface';
import { Firestore, serverTimestamp } from "@angular/fire/firestore";
import { addDoc, collection } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContactRequestService {
  path: string = 'contactUs';
  contactMe_path: string = 'contactMe';
  constructor(public firestore: Firestore) {
  }

  async saveRequest(contactRequest: ContactRequest): Promise<boolean | string> {
    try {
      const collectionRef = collection(this.firestore, this.path);
      const docData = await addDoc(collectionRef, {
        ...contactRequest,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      return docData.id
    } catch (error) {
      console.error(`Error in saveContactRequest`, contactRequest);
      console.error(error);
      throw error;
    }
  }

  async saveContactMeRequest(data: any): Promise<boolean | string> {
    try {
      const collectionRef = collection(this.firestore, this.contactMe_path);
      const docData = await addDoc(collectionRef, {
        ...data,
        _sent: false,
        _createdAt: serverTimestamp(),
        _updatedAt: serverTimestamp()
      });
      return docData.id
    } catch (error) {
      console.error(`Error in saveContactMeRequest`, data);
      console.error(error);
      throw error;
    }
  }
}

*/